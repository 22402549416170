/* eslint-disable @typescript-eslint/no-unused-vars */
import { put, call, takeLatest, select, takeEvery } from 'redux-saga/effects';
import guidance from '../../assets/json/guidance.json';
import { getBBCodes } from '../../core/api/bbCodesApi';
import { SystemApi } from '../../core/api/systemApi';
import { getAllStages } from '../../core/api/stageApi';
import {
   getBreezeMolecules as getAllBreezeMolecules,
   getBreezeChromoSteps as getAllBreezeChromoSteps,
   getBreezeChromoStepsDetails as getAllBreezeChromoStepsDetails,
   getTablePreview,
} from '../../core/api/breeze.api';

import { BBCode } from '../../core/models/bbCodes';
import { Molecule } from '../../core/models/molecule/molecule';
import { StageInterface } from '../../core/models/stage';
import { SystemTypes } from '../../core/models/systemType/systemType';
import {
   getBBCodesSuccess,
   getFailure,
   getMoleculesSuccess,
   getStagesSuccess,
   getSystemTypesSuccess,
   getSystemNubersSuccess,
   getSystemSuccess,
   getGuidanceSuccess,
   getScopesSuccess,
   getBreezeMoleculesSuccess,
   getBreezeChromoStepsSuccess,
   getBreezeChromoStepsDetailsSuccess,
   updateBreezeMoleculeSelectionSuccess,
   updateStepSelectionSuccess,
   updateRunsSelectionSuccess,
   updateQaMetadataSelectionSuccess,
   updateQaSelectionSuccess,
   updateKpiSelectionSuccess,
   updateKpiComputerSelectionSuccess,
   getWorkingDocumentDriveIdSuccess,
   updateInputSelectionSuccess,
   updateQaComputerSelectionSuccess,
   updateInputComputerSelectionSuccess,
   getRoNumbersSuccess,
   getgetRoNumbersFailure,
   getRoNumberSuccess,
   getRoNumberFailure,
   updateCachesSuccess,
   updateCachesFailure,
} from '../actions/globals';

import { globalsTypes } from '../types/globals';
import { Scopes, createScopeObject } from '../../core/models/scopes';
import { ChromoSteps } from '../../core/models/chromoSteps/chromoSteps';
import { BreezeMolecules } from '../../core/models/breezeMolecule/breezeMolecule';
import { OperationsList } from '../../core/models/operationsList/operationsList';
import { BreezeMetadata } from '../../core/models/workingDocument/breezeMetadata/breezeMetadata';
import { ApiErrorHandler } from '../../core/api/apiUtils';

// const user: UserState = yield select(getUser);

function* getSystemTypes({ callback }: { type: string; callback: Function }) {
   try {
      const systemTypes: SystemTypes[] = yield call(() =>
         SystemApi.getSystemTypes()
      );
      yield put(getSystemTypesSuccess(systemTypes));
      callback(null, systemTypes);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getScopes({ callback }: { type: string; callback: Function }) {
   try {
      const result: Scopes[] = yield call(() => SystemApi.getScopes());
      const scopes =
         result?.map((scopeResponse: any) =>
            createScopeObject(scopeResponse)
         ) || [];
      yield put(getScopesSuccess(scopes));
      callback(null, scopes);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getMolecules({ callback }: { type: string; callback: Function }) {
   try {
      const data: Molecule[] = yield call(() => SystemApi.getAllMolecules());
      yield put(getMoleculesSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getRoNumbers({
   requestFrom,
   callback,
}: {
   type: string;
   requestFrom: string;
   callback: Function;
}) {
   try {
      const roNumbers: {
         roNumber: string;
         productFamilyName: string;
      }[] = yield call(() => SystemApi.retriveRoNumbers(requestFrom));
      yield put(
         getRoNumbersSuccess({
            isAll: requestFrom !== 'project',
            data: roNumbers,
         })
      );
      callback(null, roNumbers);
   } catch (error) {
      if (error instanceof Error) {
         callback(JSON.parse(error.message), null);
      }
      yield put(getgetRoNumbersFailure());
   }
}

function* getBreezeMolecules({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const data: BreezeMolecules[] = yield call(() => getAllBreezeMolecules());
      yield put(getBreezeMoleculesSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getBreezeChromoSteps({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const breezeMetadata: BreezeMetadata = yield select(
         (state: any) => state.workingDocument.newDocument.breezeMetadata
      );
      const steps: ChromoSteps[] = yield call(() =>
         getAllBreezeChromoSteps(breezeMetadata.molecule)
      );
      yield put(getBreezeChromoStepsSuccess(steps));
      callback(null, steps);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getBreezeChromoStepsDetails({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const breezeMetadata: BreezeMetadata = yield select(
         (state: any) => state.workingDocument.newDocument.breezeMetadata
      );
      const data: OperationsList[] = yield call(() =>
         getAllBreezeChromoStepsDetails(
            breezeMetadata.molecule,
            breezeMetadata.unit_ops
         )
      );
      yield put(getBreezeChromoStepsDetailsSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* updateBreezeMoleculeSelection({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const data: any[] = yield call(() => {});

      yield put(updateBreezeMoleculeSelectionSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* updateStepSelection({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const data: any[] = yield call(() => {});

      yield put(updateStepSelectionSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* updateRunsSelection({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const data: any[] = yield call(() => {});

      yield put(updateRunsSelectionSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getBigQueryTablePreview({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const breezeMetadata: BreezeMetadata = yield select(
         (state: any) => state.workingDocument.newDocument.breezeMetadata
      );
      const bigQueryTablePreview = yield call(() =>
         getTablePreview(breezeMetadata)
      );
      callback(null, bigQueryTablePreview);
   } catch (error) {
      callback(JSON.parse(error.message), null);
   }
}

function* updateQaMetadataSelection({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const data: any[] = yield call(() => {});

      yield put(updateQaMetadataSelectionSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getWorkingDocumentDriveId({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const driveId: String = yield call(() => getAllStages());
      yield put(getWorkingDocumentDriveIdSuccess(driveId));
      callback(null, driveId);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getBbCodes({ callback }: { type: string; callback: Function }) {
   try {
      const bbCodes: BBCode[] = yield call(() => getBBCodes());
      yield put(getBBCodesSuccess(bbCodes));
      callback(null, bbCodes);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getStages({ callback }: { type: string; callback: Function }) {
   try {
      const stages: StageInterface[] = yield call(() => getAllStages());
      yield put(getStagesSuccess(stages));
      callback(null, stages);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getNumbers(action: {
   type: string;
   payload: string;
   callback: Function;
}) {
   try {
      const systemNumbers: [] = yield call(() =>
         SystemApi.getSystemNumbers(action.payload)
      );
      yield put(getSystemNubersSuccess(systemNumbers));
      action.callback(null, systemNumbers);
   } catch (error) {
      action.callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getSystems(action: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const { projectType, eventName } = action.payload;
      const projectTypes: {} = yield call(() =>
         SystemApi.getSystem(projectType, eventName)
      );
      yield put(getSystemSuccess({ ...projectTypes }));
      action.callback(null, projectTypes);
   } catch (error) {
      action.callback(JSON.parse(error.message), null);
      yield put(getFailure(error));
   }
}

function* getGuidance() {
   try {
      const parsedData = JSON.stringify(guidance);
      const parsedDataObject = JSON.parse(parsedData);
      yield put(getGuidanceSuccess(parsedDataObject));
   } catch (error) {
      yield put(getFailure(error));
   }
}

function* getRoNumber(action: {
   type: string;
   payload: string;
   callback: Function;
}) {
   try {
      const number = action.payload;
      const result = yield call(() => SystemApi.getRoNumber(number));
      yield put(getRoNumberSuccess({ result, number }));
   } catch (error) {
      yield put(getRoNumberFailure(error));
      action.callback(JSON.parse(error.message));
   }
}

export function* updateCaches(action: {
   type: string;
   payload: string;
   callback: Function;
   errorCallback: Function;
}) {
   try {
      const result = yield call(() => SystemApi.updateCache());
      yield put(updateCachesSuccess());
      action.callback(result);
   } catch (error) {
      yield put(updateCachesFailure(error));
      action.errorCallback(JSON.parse(error.message));
   }
}

export default function* globalsWatcher() {
   yield takeLatest(globalsTypes.GET_SYSTEM_TYPES, getSystemTypes);
   yield takeLatest(globalsTypes.GET_SCOPES, getScopes);
   yield takeLatest(globalsTypes.GET_MOLECULES, getMolecules);
   yield takeLatest(globalsTypes.GET_BREEZE_MOLECULES, getBreezeMolecules);
   yield takeLatest(globalsTypes.GET_CHROMO_STEPS, getBreezeChromoSteps);
   yield takeLatest(
      globalsTypes.GET_OPERATIONS_LIST,
      getBreezeChromoStepsDetails
   );
   yield takeLatest(
      globalsTypes.UPDATE_SELECTED_MOLECULE,
      updateBreezeMoleculeSelection
   );
   yield takeLatest(
      globalsTypes.GET_BIG_QUERY_TABLE_PREVIEW_OF_SELECTIONS,
      getBigQueryTablePreview
   );
   yield takeLatest(globalsTypes.UPDATE_SELECTED_STEP, updateStepSelection);
   yield takeLatest(globalsTypes.UPDATE_SELECTED_RUNS, updateRunsSelection);
   yield takeLatest(
      globalsTypes.UPDATE_SELECTED_INPUT_SUCCESS,
      updateInputSelectionSuccess
   );
   yield takeLatest(
      globalsTypes.UPDATE_SELECTED_INPUT_COMPUTER_SUCCESS,
      updateInputComputerSelectionSuccess
   );
   yield takeLatest(
      globalsTypes.UPDATE_SELECTED_KPI_SUCCESS,
      updateKpiSelectionSuccess
   );
   yield takeLatest(
      globalsTypes.UPDATE_SELECTED_KPI_COMPUTER_SUCCESS,
      updateKpiComputerSelectionSuccess
   );
   yield takeLatest(
      globalsTypes.UPDATE_SELECTED_QA_SUCCESS,
      updateQaSelectionSuccess
   );

   yield takeLatest(
      globalsTypes.UPDATE_SELECTED_QA_COMPUTER_SUCCESS,
      updateQaComputerSelectionSuccess
   );
   yield takeLatest(
      globalsTypes.UPDATE_SELECTED_QA_METADATA,
      updateQaMetadataSelection
   );

   yield takeLatest(
      globalsTypes.GET_WORKING_DOCUMENT_DRIVE_ID,
      getWorkingDocumentDriveId
   );
   yield takeLatest(globalsTypes.GET_BB_CODES, getBbCodes);
   yield takeLatest(globalsTypes.GET_STAGES, getStages);
   yield takeLatest(globalsTypes.GET_SYSTEM_NUMBERS, getNumbers);
   yield takeLatest(globalsTypes.GET_SYSTEM, getSystems);
   yield takeLatest(globalsTypes.GET_GUIDANCE, getGuidance);
   yield takeLatest(globalsTypes.GET_RO_NUMBERS, getRoNumbers);
   yield takeEvery(globalsTypes.GET_RO_NUMBER, getRoNumber);
   yield takeEvery(globalsTypes.UPDATE_CACHES, updateCaches);
}
