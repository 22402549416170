import { put, call, takeLatest } from 'redux-saga/effects';
import { TutorialApi } from '../../core/api';
import { TutorialItemFromDBInterface } from '../../core/models/tutorial/tutorials';
import {
   getAllTutorialsSuccess,
   deleteTutorialSuccess,
   tutorialActionsFail,
} from '../actions/tutorial';
import { tutorialTypes } from '../types/tutorial';

function* getAllTutorials({
   payload,
   callback,
}: {
   type: string;
   payload: number;
   callback: Function;
}) {
   try {
      const data: TutorialItemFromDBInterface[] = yield call(() =>
         TutorialApi.loadTutorials(payload)
      );
      yield put(getAllTutorialsSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(tutorialActionsFail(error));
   }
}

function* deleteTutorial({
   payload,
   callback,
}: {
   type: string;
   payload: number;
   callback: Function;
}) {
   try {
      const data: number | boolean = yield call(() =>
         TutorialApi.deleteTutorial(payload)
      );
      yield put(deleteTutorialSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(tutorialActionsFail(error));
   }
}

export default function* tutorialWatcher() {
   yield takeLatest(tutorialTypes.GET_ALL_TUTORIALS, getAllTutorials);
   yield takeLatest(tutorialTypes.DELETE_TUTORIAL, deleteTutorial);
}
