import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function AdminIcon(props: any) {
   return (
      <SvgIcon {...props} viewBox="0 0 426.7 426.7">
         <g>
            <polygon
               className="st0"
               points="133.2,375.4 206.2,426.7 206.2,0 43.2,61.2 	"
            />
            <polygon
               className="st0"
               points="220.5,0 220.5,54.6 324.5,90.5 264.8,337.6 220.5,372.9 220.5,425 293.4,375.4 383.5,61.2 	"
            />
         </g>
      </SvgIcon>
   );
}
