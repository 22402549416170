import React from 'react';
import { CircularProgress } from '@material-ui/core';

export const Loading = () => {
   return (
      <>
         <div className="align-loading">
            <span
               style={{
                  margin: '100px auto',
                  display: 'block',
                  fontWeight: 'bold',
               }}
            >
               Loading Intelligent Authoring
            </span>
         </div>
         <span
            style={{
               margin: '50px 47%',
               display: 'block',
            }}
         >
            <CircularProgress value={60} size="100px" />
         </span>
      </>
   );
};
