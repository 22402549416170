export const generatedDocumentTypes = {
   GET_GENERATED_DOCUMENTS: 'GET_GENERATED_DOCUMENTS',
   GET_GENERATED_DOCUMENTS_SUCCESS: 'GET_GENERATED_DOCUMENTS_SUCCESS',
   UPDATE_GENERATED_DOCUMENT: 'UPDATE_GENERATED_DOCUMENT',
   UPDATE_GENERATED_DOCUMENT_SUCCESS: 'UPDATE_GENERATED_DOCUMENT_SUCCESS',
   TRANSFER_GENERATED_DOCUMENT: 'TRANSFER_GENERATED_DOCUMENT',
   TRANSFER_GENERATED_DOCUMENT_SUCCESS: 'TRANSFER_GENERATED_DOCUMENT_SUCCESS',
   DELETE_GENERATED_DOCUMENT: 'DELETE_GENERATED_DOCUMENT',
   DELETE_GENERATED_DOCUMENT_SUCCESS: 'DELETE_GENERATED_DOCUMENT_SUCCESS',
   GENERATED_DOCUMENT_FAILURE: 'GENERATED_DOCUMENT_FAILURE',
   GET_ASSIGNED_GENERATED_DOCUMENTS: 'GET_ASSIGNED_GENERATED_DOCUMENTS',
   GET_ASSIGNED_GENERATED_DOCUMENTS_SUCCESS:
      'GET_ASSIGNED_GENERATED_DOCUMENTS_SUCCESS',
   CLEAR_GENERATED_DOCUMENT_STATE: 'CLEAR_GENERATED_DOCUMENT_STATE',
   ARCHIVE_GENERATED_DOCUMENT: 'ARCHIVE_GENERATED_DOCUMENT',
   ARCHIVE_GENERATED_DOCUMENT_SUCCESS: 'ARCHIVE_GENERATED_DOCUMENT_SUCCESS',
   GET_GENERATED_DOCUMENT_FILTER_DATA: 'GET_GENERATED_DOCUMENT_FILTER_DATA',
   GET_GENERATED_DOCUMENT_FILTER_DATA_SUCCESS:
      'GET_GENERATED_DOCUMENT_FILTER_DATA_SUCCESS',
   GET_GENERATED_DOCUMENT_FILTER_DATA_FAILURE:
      'GET_GENERATED_DOCUMENT_FILTER_DATA_FAILURE',
};
