import produce from 'immer';
import { UserState } from '../../core/models/user/userState';
import { userTypes } from '../types/user';
import { DEFAULT_PERMISSIONS_OBJECT } from '../../shared/CONSTANTS';
import { Role } from '../../core/models/role/role';
import {UserInterface} from "../../core/models/user/userInterface";

const initialState: UserState = {
   currentUser: {
      id: 0,
      firstName: '',
      lastName: '',
      role: Role.createRolesObject({
         id: 0,
         name: '',
         description: '',
         permissions: { ...DEFAULT_PERMISSIONS_OBJECT },
      }),
      status: '',
      lastLogin: '',
      email: '',
      img: '',
      emailAddress: '',
   },
   isUserReceived: false,
   users: [],
   onlineUsers: [],
};

export const user = (
   state: UserState = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case userTypes.GET_CURRENT_USER_SUCCESS:
            draft.currentUser = payload;
            draft.isUserReceived = true;
            localStorage.setItem('isLoggedIn', 'true');
            return draft;
         case userTypes.GET_ALL_USERS_SUCCESS:
            draft.users = payload;
            draft.users.sort((user: UserInterface, nextUser: UserInterface) =>
               user.firstName.trim().localeCompare(nextUser.firstName.trim())
            );
            return draft;
         case userTypes.UPDATE_USER_SUCCESS: {
            if (!payload) return draft;

            const index = draft.users.findIndex((u) => u.id === payload.id);
            draft.users[index] = payload;
            if (draft.currentUser.id === payload.id) {
               draft.currentUser = payload;
            }
            return draft;
         }
         case userTypes.LOG_OUT_USER: {
            return draft;
         }
         case userTypes.LOG_OUT_USER_SUCCESS: {
            console.log(
               `${payload?.firstName} has logged out successfully, clearing state`
            );
            draft = { ...initialState };
            return draft;
         }
         case userTypes.LOG_OUT_USER_FAILURE: {
            console.error(
               `Failed to log out ${JSON.stringify(
                  payload
               )}, clearing state anyway`
            );
            draft = { ...initialState };
            return draft;
         }
         case userTypes.GET_USER_FAILURE:
            draft.isUserReceived = false;
            return draft;
         case userTypes.ADD_USER_SUCCESS:
            draft.currentUser = payload;
            return draft;
         case userTypes.SET_ONLINE_USERS:
            draft.onlineUsers = payload;
            return draft;
         case userTypes.GET_CURRENT_USER:
            draft.isUserReceived = false;
            return draft;
         case userTypes.SET_IS_USER_RECEIVED:
            draft.isUserReceived = payload;
            return draft;
         default:
            return draft;
      }
   });
