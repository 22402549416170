import { PasswordAuthenticator } from '../../core/models/loginInterface';
import { UserInterface } from '../../core/models/user/userInterface';
import { userTypes } from '../types/user';

export const getCurrentUser = (callback: Function = () => {}) => ({
   type: userTypes.GET_CURRENT_USER,
   callback,
});

export const getCurrentUserSuccess = (data: UserInterface) => ({
   type: userTypes.GET_CURRENT_USER_SUCCESS,
   payload: data,
});

export const getUserFailure = (err: any) => ({
   type: userTypes.GET_USER_FAILURE,
   err,
});

export const addUser = (user: UserInterface, callback: Function) => ({
   type: userTypes.ADD_USER,
   payload: user,
   callback,
});

export const addUserSuccess = (user: UserInterface) => ({
   type: userTypes.ADD_USER_SUCCESS,
   payload: user,
});

export const loginUser = (
   credentials: PasswordAuthenticator,
   callback: Function
) => ({
   type: userTypes.LOGIN_USER,
   payload: credentials,
   callback,
});

export const updateUser = (payload: UserInterface, callback: Function) => ({
   type: userTypes.UPDATE_USER,
   payload,
   callback,
});

export const LOG_OUT_USER_ACTION = (
   payload: UserInterface,
   callback: Function
) => ({
   type: userTypes.LOG_OUT_USER,
   payload,
   callback,
});

export const LOG_OUT_USER_ACTION_SUCCESS = (payload: UserInterface) => ({
   type: userTypes.LOG_OUT_USER_SUCCESS,
   payload,
});

export const LOG_OUT_USER_ACTION_FAILURE = (payload: UserInterface) => ({
   type: userTypes.LOG_OUT_USER_FAILURE,
   payload,
});

export const updateUserSuccess = (payload: UserInterface) => ({
   type: userTypes.UPDATE_USER_SUCCESS,
   payload,
});

export const getAllUsers = (callback: Function = () => {}) => ({
   type: userTypes.GET_ALL_USERS,
   callback,
});

export const getAllUsersSuccess = (payload: UserInterface[]) => ({
   type: userTypes.GET_ALL_USERS_SUCCESS,
   payload,
});

export const setOnlineUsers = (
   payload: UserInterface[],
   callback: Function = () => {}
) => ({
   type: userTypes.SET_ONLINE_USERS,
   payload,
   callback,
});
export const setIsUserReceived = (payload: boolean) => ({
   type: userTypes.SET_IS_USER_RECEIVED,
   payload,
});

export const sendSSOCode = (payload: string, callback: Function) => ({
   type: userTypes.SEND_SSO_CODE,
   payload,
   callback,
});
