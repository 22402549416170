import produce from 'immer';
import { ConversionStyleState } from '../../core/models/conversionStyle/conversionStyleState';
import { conversionTypes } from '../types/conversionStyles';

const initialState: ConversionStyleState = {
   conversionStyles: [],
   selectedStyle: null,
   error: null,
};

export const conversions = (
   state: ConversionStyleState = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft: ConversionStyleState) => {
      const { type, payload } = action;
      switch (type) {
         case conversionTypes.GET_CONVERSIONS_SUCCESS:
            draft.conversionStyles = payload;
            return draft;
         case conversionTypes.UPDATE_CONVERSION_SUCCESS: {
            const { id, data } = payload;
            if (id) {
               const index = draft.conversionStyles.findIndex(
                  (style) => style.id === id
               );
               const prevDefaultIndex = draft.conversionStyles.findIndex(
                  (style) => style.isDefault
               );
               const styles = [...draft.conversionStyles];
               styles[index].styles = data.styles;
               if (data.isDefault) {
                  styles[prevDefaultIndex].isDefault = false;
                  styles[index].isDefault = data.isDefault;
               }
               draft.conversionStyles = styles;
            }
            return draft;
         }
         case conversionTypes.SELECT_CONVERSION_SUCCESS:
            draft.selectedStyle = payload;
            return draft;
         default:
            return draft;
      }
   });
