export const conversionTypes = {
   GET_CONVERSIONS_START: 'GET_CONVERSIONS_START',
   GET_CONVERSIONS_SUCCESS: 'GET_CONVERSIONS_SUCCESS',
   GET_CONVERSIONS_FAILURE: 'GET_CONVERSIONS_FAILURE',
   UPDATE_CONVERSION_START: 'UPDATE_CONVERSION_START',
   UPDATE_CONVERSION_SUCCESS: 'UPDATE_CONVERSION_SUCCESS',
   UPDATE_CONVERSION_FAILURE: 'UPDATE_CONVERSION_FAILURE',
   SELECT_CONVERSION_START: 'SELECT_CONVERSION_START',
   SELECT_CONVERSION_SUCCESS: 'SELECT_CONVERSION_SUCCESS',
   SELECT_CONVERSION_FAILURE: 'SELECT_CONVERSION_FAILURE',
};
