import React, { useContext } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import ButtonContext from '../../context/ButtonPopupContext';

export default function LanguageDialog(props: any) {
   const languages = [
      { label: 'English', code: 'en' },
      { label: 'Deutsch', code: 'de' },
   ];
   const buttonPopupData = useContext(ButtonContext);

   const { onClose, selectedValue, open } = props;

   const handleClose = () => {
      onClose(selectedValue);
   };

   const handleListItemClick = (value: string) => {
      localStorage.setItem('lang', value);
      buttonPopupData.changeBrowserLang(value);
      onClose(value);
   };

   return (
      <Dialog
         onClose={handleClose}
         aria-labelledby="simple-dialog-title"
         open={open}
      >
         <DialogTitle id="simple-dialog-title">
            Set tutorials language
         </DialogTitle>
         <List>
            {languages.map((language) => (
               <ListItem
                  button
                  onClick={() => handleListItemClick(language.code)}
                  key={language.code}
               >
                  <ListItemText primary={language.label} />
               </ListItem>
            ))}
         </List>
      </Dialog>
   );
}

LanguageDialog.propTypes = {
   onClose: PropTypes.func.isRequired,
   selectedValue: PropTypes.string.isRequired,
   open: PropTypes.bool.isRequired,
};
