import {
   GeneratedDocumentInterface,
   GeneratedDocumentCallback,
} from '../../core/models/generatedDocument/generatedDocument';
import { generatedDocumentTypes } from '../types/generatedDocument';
import { COMPONENT_ERROR } from '../../shared/CONSTANTS';

export const getGeneratedDocuments = (
   payload: { page: number; size: number; filterQuery: string },
   callback: GeneratedDocumentCallback = () => {}
) => ({
   type: generatedDocumentTypes.GET_GENERATED_DOCUMENTS,
   payload,
   callback,
});

export const getGeneratedDocumentsSuccess = (
   payload: GeneratedDocumentInterface[]
) => ({
   type: generatedDocumentTypes.GET_GENERATED_DOCUMENTS_SUCCESS,
   payload,
});

export const transferGeneratedDocuments = (
   payload: GeneratedDocumentInterface,
   callback: Function
) => ({
   type: generatedDocumentTypes.TRANSFER_GENERATED_DOCUMENT,
   payload,
   callback,
});

export const transferGeneratedDocumentsSuccess = (payload: Object) => ({
   type: generatedDocumentTypes.TRANSFER_GENERATED_DOCUMENT_SUCCESS,
   payload,
});

export const updateGeneratedDocument = (payload: any, callback: Function) => ({
   type: generatedDocumentTypes.UPDATE_GENERATED_DOCUMENT,
   payload,
   callback,
});

export const updateGeneratedDocumentSuccess = (
   payload: GeneratedDocumentInterface | boolean
) => ({
   type: generatedDocumentTypes.UPDATE_GENERATED_DOCUMENT_SUCCESS,
   payload,
});

export const deleteGeneratedDocument = (
   payload: number,
   callback: Function
) => ({
   type: generatedDocumentTypes.DELETE_GENERATED_DOCUMENT,
   payload,
   callback,
});

export const deleteGeneratedDocumentSuccess = (payload: number | boolean) => ({
   type: generatedDocumentTypes.DELETE_GENERATED_DOCUMENT_SUCCESS,
   payload,
});

export const generatedDocumentActionsFail = (error: COMPONENT_ERROR) => ({
   type: generatedDocumentTypes.GENERATED_DOCUMENT_FAILURE,
   error,
});

export const getAssignedGeneratedDocuments = (callback: Function) => ({
   type: generatedDocumentTypes.GET_ASSIGNED_GENERATED_DOCUMENTS,
   callback,
});

export const getAssignedGeneratedDocumentsSuccess = (
   payload: GeneratedDocumentInterface[]
) => ({
   type: generatedDocumentTypes.GET_ASSIGNED_GENERATED_DOCUMENTS_SUCCESS,
   payload,
});

export const CLEAR_GENERATED_DOCUMENT_STATE = () => ({
   type: generatedDocumentTypes.CLEAR_GENERATED_DOCUMENT_STATE,
});

export const archiveGeneratedDocument = (
   documentId: number,
   archived: boolean,
   callback: Function
) => ({
   type: generatedDocumentTypes.ARCHIVE_GENERATED_DOCUMENT,
   payload: { documentId, archived },
   callback,
});

export const archiveGeneratedDocumentSuccess = (documentId: number | null) => ({
   type: generatedDocumentTypes.ARCHIVE_GENERATED_DOCUMENT_SUCCESS,
   payload: { documentId },
});

export const getGeneratedDocumentFiltersData = (
   query: string | null,
   callback: Function
) => ({
   type: generatedDocumentTypes.GET_GENERATED_DOCUMENT_FILTER_DATA,
   payload: query,
   callback,
});

export const getGeneratedDocumentFiltersDataSuccess = (payload: any) => ({
   type: generatedDocumentTypes.GET_GENERATED_DOCUMENT_FILTER_DATA_SUCCESS,
   payload,
});

export const getGeneratedDocumentFiltersDataFailure = () => ({
   type: generatedDocumentTypes.GET_GENERATED_DOCUMENT_FILTER_DATA_FAILURE,
});
