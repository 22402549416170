import { favoriteTypes } from '../types/favorite';

export const getAllFavorites = () => ({
   type: favoriteTypes.GET_ALL_FAVORITES,
});

export const getAllFavoritesSuccess = (payload: []) => ({
   type: favoriteTypes.GET_ALL_FAVORITES_SUCCESS,
   payload,
});

export const getAllFavoritesFailure = () => ({
   type: favoriteTypes.GET_ALL_FAVORITES_FAILURE,
   payload: 'Something went wrong while getting favorites list.',
});

export const changeProjectFavorite = (id: number, isFavorite: boolean) => ({
   type: favoriteTypes.CHANGE_PROJECT_FAVORITE,
   payload: {
      id,
      isFavorite,
   },
});

export const changeProjectFavoriteSuccess = (favorite: boolean) => ({
   type: favoriteTypes.CHANGE_PROJECT_FAVORITE_SUCCESS,
   payload: favorite
      ? 'Successfully added to favorite list'
      : 'Successfully removed from favorite list',
});

export const changeProjectFavoriteFailure = (favorite: boolean) => ({
   type: favoriteTypes.CHANGE_PROJECT_FAVORITE_FAILURE,
   payload: favorite
      ? 'Something went wrong while adding to favorite list'
      : 'Something went wrong while removing from favorite list',
});

export const changeDocumentFavorite = (id: number, isFavorite: boolean) => ({
   type: favoriteTypes.CHANGE_DOCUMENT_FAVORITE,
   payload: {
      id,
      isFavorite,
   },
});

export const changeDocumentFavoriteSuccess = (favorite: boolean) => ({
   type: favoriteTypes.CHANGE_DOCUMENT_FAVORITE_SUCCESS,
   payload: favorite
      ? 'Successfully added to favorite list'
      : 'Successfully removed from favorite list',
});

export const changeDocumentFavoriteFailure = (favorite: boolean) => ({
   type: favoriteTypes.CHANGE_DOCUMENT_FAVORITE_FAILURE,
   payload: favorite
      ? 'Something went wrong while adding to favorite list'
      : 'Something went wrong while removing from favorite list',
});

export const changeTemplateFavorite = (id: number, isFavorite: boolean) => ({
   type: favoriteTypes.CHANGE_TEMPLATE_FAVORITE,
   payload: {
      id,
      isFavorite,
   },
});

export const changeTemplateFavoriteSuccess = (favorite: boolean) => ({
   type: favoriteTypes.CHANGE_TEMPLATE_FAVORITE_SUCCESS,
   payload: favorite
      ? 'Successfully added to favorite list'
      : 'Successfully removed from favorite list',
});

export const changeTemplateFavoriteFailure = (favorite: boolean) => ({
   type: favoriteTypes.CHANGE_TEMPLATE_FAVORITE_FAILURE,
   payload: favorite
      ? 'Something went wrong while adding to favorite list'
      : 'Something went wrong while removing from favorite list',
});

export const clearSuccessMessage = () => ({
   type: favoriteTypes.CLEAR_SUCCESS_MESSAGE,
});

export const clearErrorMessage = () => ({
   type: favoriteTypes.CLEAR_ERROR_MESSAGE,
});
