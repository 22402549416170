import { put, call, takeLatest } from 'redux-saga/effects';
import { ConfigurationApi } from '../../core/api/configurationApi';
import {
   setSessionTimeIntervalSuccess,
   setSessionTimeIntervalFailure,
   getSessionDataSuccess,
   getSessionDataFailure,
} from '../actions/configuration';
import { configurationTypes } from '../types/configuration';

function* setSessionTimeInterval({
   payload,
   callback,
}: {
   payload: {
      timeout: boolean;
      interval: number;
   };
   type: string;
   callback: Function;
}) {
   try {
      const data = yield call(() =>
         ConfigurationApi.setSessionTimeInterval(payload)
      );
      yield put(setSessionTimeIntervalSuccess(data));
      callback(null);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(setSessionTimeIntervalFailure(error));
   }
}

function* getSessionData({ callback }: { type: string; callback: Function }) {
   try {
      const data = yield call(() => ConfigurationApi.getSessionInterval());
      yield put(getSessionDataSuccess(data));
      callback(null);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(getSessionDataFailure(error));
   }
}

export default function* configurationWatcher() {
   yield takeLatest(
      configurationTypes.SET_SESSION_TIME_OUT,
      setSessionTimeInterval
   );
   yield takeLatest(configurationTypes.GET_SESSION_DATA, getSessionData);
}
