import {
   InternalReportCreateForm,
   InternalReportsActivationForm,
   InternalReportUpdateForm,
} from '../models/internalReports/internalReportForm';
import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class InternalReportsApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static getInternalReports() {
      return fetch(`${baseUrl}api/v1/internalReport`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            const internalReports = result.data;
            return internalReports;
         })
         .catch(handleError);
   }

   static getActiveInternalReports() {
      return fetch(`${baseUrl}api/v1/internalReport/active`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            const internalReports = result.data;
            return internalReports;
         })
         .catch(handleError);
   }

   static createInternalReport(data: InternalReportCreateForm) {
      return fetch(`${baseUrl}api/v1/internalReport`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static updateInternalReport(
      groupId: number,
      titleId: number,
      data: InternalReportUpdateForm
   ) {
      return fetch(`${baseUrl}api/v1/internalReport/${groupId}/${titleId}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static activateInternalReport(data: InternalReportsActivationForm) {
      return fetch(`${baseUrl}api/v1/internalReport`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static updateInternalReportTitle(
      titleId: number,
      data: { title: string; groupName: string }
   ) {
      return fetch(`${baseUrl}api/v1/internalReport/${titleId}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
