import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function TagIcon(props: any) {
   return (
      <SvgIcon {...props} viewBox="0 0 31 23">
         <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M25 4.5L9 4.5C8.46957 4.5 7.96086 4.71071 7.58579 5.08579C7.21071 5.46086 7 5.96957 7 6.5L7 18.5C7 19.0304 7.21071 19.5391 7.58579 19.9142C7.96086 20.2893 8.46957 20.5 9 20.5H17V13.5H27V6.5C27 5.96957 26.7893 5.46086 26.4142 5.08579C26.0391 4.71071 25.5304 4.5 25 4.5ZM9 18.5V14.5L16 14.5V18.5H9ZM18 12.5V8.5H25V12.5H18ZM9 12.5V8.5H16V12.5H9Z"
            fill="#878787"
         />
         <path
            d="M23.4531 15.1981C23.7194 14.1006 25.2806 14.1006 25.5469 15.1981C25.5868 15.363 25.6651 15.5161 25.7754 15.645C25.8858 15.7739 26.0249 15.8749 26.1817 15.9398C26.3384 16.0048 26.5082 16.0318 26.6774 16.0187C26.8465 16.0056 27.0102 15.9527 27.155 15.8644C28.1194 15.2769 29.2237 16.3806 28.6362 17.3456C28.5481 17.4904 28.4953 17.654 28.4822 17.823C28.4691 17.992 28.4961 18.1617 28.561 18.3184C28.6258 18.475 28.7267 18.6141 28.8555 18.7244C28.9842 18.8347 29.1371 18.9131 29.3019 18.9531C30.3994 19.2194 30.3994 20.7806 29.3019 21.0469C29.137 21.0868 28.9839 21.1651 28.855 21.2754C28.7261 21.3858 28.6251 21.5249 28.5602 21.6817C28.4952 21.8384 28.4682 22.0082 28.4813 22.1774C28.4944 22.3465 28.5473 22.5102 28.6356 22.655C29.2231 23.6194 28.1194 24.7237 27.1544 24.1362C27.0096 24.0481 26.846 23.9953 26.677 23.9822C26.508 23.9691 26.3383 23.9961 26.1816 24.061C26.025 24.1258 25.8859 24.2267 25.7756 24.3555C25.6653 24.4842 25.5869 24.6371 25.5469 24.8019C25.2806 25.8994 23.7194 25.8994 23.4531 24.8019C23.4132 24.637 23.3349 24.4839 23.2246 24.355C23.1142 24.2261 22.9751 24.1251 22.8183 24.0602C22.6616 23.9952 22.4918 23.9682 22.3226 23.9813C22.1535 23.9944 21.9898 24.0473 21.845 24.1356C20.8806 24.7231 19.7763 23.6194 20.3637 22.6544C20.4519 22.5096 20.5047 22.346 20.5178 22.177C20.5309 22.008 20.5039 21.8383 20.439 21.6816C20.3742 21.525 20.2733 21.3859 20.1445 21.2756C20.0158 21.1653 19.8629 21.0869 19.6981 21.0469C18.6006 20.7806 18.6006 19.2194 19.6981 18.9531C19.863 18.9132 20.0161 18.8349 20.145 18.7246C20.2739 18.6142 20.3749 18.4751 20.4398 18.3183C20.5048 18.1616 20.5318 17.9918 20.5187 17.8226C20.5056 17.6535 20.4527 17.4898 20.3644 17.345C19.7769 16.3806 20.8806 15.2763 21.8456 15.8637C22.4706 16.2437 23.2806 15.9075 23.4531 15.1981Z"
            fill="#878787"
            stroke="#878787"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <path
            d="M24.5 21.875C25.5355 21.875 26.375 21.0355 26.375 20C26.375 18.9645 25.5355 18.125 24.5 18.125C23.4645 18.125 22.625 18.9645 22.625 20C22.625 21.0355 23.4645 21.875 24.5 21.875Z"
            stroke="#878787"
            strokeLinecap="round"
            strokeLinejoin="round"
         />
         <circle cx="24.5" cy="20" r="2.5" fill="white" />
      </SvgIcon>
   );
}
