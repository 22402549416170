import { Metadata } from './metadata';
import Members from './modelDocument/members';
import { BreezeMetadata } from './workingDocument/breezeMetadata/breezeMetadata';

export const MIME_TYPE_FOLDER = 'application/vnd.google-apps.folder';

export interface FileInterface {
   breezeMetadata: BreezeMetadata;
   driveId: string | undefined;
   id: number | null;
   section: string | undefined;
   mimeType: string | undefined;
   modifiedDate: string | number | Date | undefined;
   title: string;
   owner: any;
   templateId: string | undefined;
   documentId: string;
   modifier: DocumentModifier;
   fullName: string;
   Projects: any[];
   metadata: Metadata;
   members: Members;
   Description: string;
   moleculeId: string;
   sectionId: string;
   viewer: number[];
   type: number;
   effectiveVersion: string;
   archived: boolean;
   bbCode: any;
   countries: any;
   managers: number[];
   countryGroupName: any;
   productArea: any;
   effectiveDate: string;
   sectionTitle: any;
   stage: number;
   stageType: string | undefined;
   sectionCode: any;
   driveName: string;
   name: string;
   moleculeType: string | undefined;
   createdDate: string | number | Date | undefined;
   // TODO: TEMPORARY. CHANGE STRUCTURE TO MATCH OBJECTS FROM BACKEND.
}

export class DocumentModifier {
   constructor(public firstName: string = '', public lastName: string = '') {}
}
