import produce from 'immer';
import { ModelDocument } from '../../core/models/modelDocument/modelDocument';
import { ModelDocumentState } from '../../core/models/modelDocument/modelDocumentState';
import { modelDocTypes } from '../types/modelDocument';
import * as CONSTANTS from '../../shared/CONSTANTS';

const initialState: ModelDocumentState = {
   modelDocuments: [],
   modelDocument: new ModelDocument(),
   modelDocumentTypes: [],
   modelDocumentCount: 0,
   baseTemplates: [],
   modelDocumentFilters: [],
   modelDocumentVariables: [],
   isFullyLoaded: false,
   error: null,
   modelDocumentStatuses: [],
   isModelDocDeletedSuccessfully: false,
};

export const modelDocument = (
   state = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;

      switch (type) {
         case modelDocTypes.GET_MODEL_DOCUMENTS_SUCCESS:
            if (
               payload.modelDocuments.length <
                  CONSTANTS.DEFAULT_MODEL_DOCUMENT_SIZE ||
               payload.modelDocuments.length %
                  CONSTANTS.DEFAULT_MODEL_DOCUMENT_SIZE !==
                  0
            ) {
               draft.isFullyLoaded = true;
            }
            draft.modelDocuments = payload.modelDocuments;
            draft.modelDocumentCount = payload.count;
            draft.error = null;
            draft.isModelDocDeletedSuccessfully = false;
            draft.modelDocument = new ModelDocument();
            return draft;
         case modelDocTypes.GET_MODEL_DOCUMENT_BY_ID_SUCCESS:
            draft.modelDocument = ModelDocument.createModelDocumentObject(
               payload
            );
            draft.error = null;
            return draft;
         case modelDocTypes.UPDATE_MODEL_DOCUMENT_SUCCESS: {
            const index = draft.modelDocuments.findIndex(
               (modelDocument) => modelDocument.id === payload.id
            );
            const newModelDocument = ModelDocument.createModelDocumentObject(
               payload
            );
            if (index !== -1) {
               draft.modelDocuments[index] = newModelDocument;
               draft.modelDocuments.sort(
                  (document: ModelDocument, nextDocument: ModelDocument) =>
                     new Date(`${nextDocument.modifiedDate}`).getTime() -
                     new Date(`${document.modifiedDate}`).getTime()
               );
            }
            draft.modelDocument = newModelDocument;
            draft.error = null;
            return draft;
         }
         case modelDocTypes.UPDATE_MODEL_DOCUMENT_FAVORITE_SUCCESS: {
            const index = draft.modelDocuments.findIndex(
               (modelDocument) => modelDocument.id === payload.id
            );

            const newModelDocument = {
               ...draft.modelDocuments[index],
               isFavorite: payload.isFavorite,
            };

            if (index !== -1) {
               draft.modelDocuments[index] = newModelDocument;
            }
            draft.modelDocument = newModelDocument;
            draft.error = null;
            return draft;
         }
         case modelDocTypes.ARCHIVE_MODEL_DOCUMENT_SUCCESS:
            {
               const { documentId } = payload;

               if (documentId) {
                  draft.modelDocuments = draft.modelDocuments.filter(
                     (document) => document.id !== documentId
                  );
                  draft.modelDocumentCount -= 1;
               }
               draft.isModelDocDeletedSuccessfully = true;
            }
            return draft;
         case modelDocTypes.ARCHIVE_MODEL_DOCUMENT_FAILURE:
            draft.error = payload;
            draft.isModelDocDeletedSuccessfully = false;
            return draft;
         case modelDocTypes.DELETE_MODEL_DOCUMENT_SUCCESS: {
            draft.modelDocuments = draft.modelDocuments.filter(
               (modelDocument) => {
                  return modelDocument.id !== payload;
               }
            );
            return draft;
         }
         case modelDocTypes.UPDATE_MODEL_DOCUMENT_MODIFIER_SUCCESS: {
            const index = draft.modelDocuments.findIndex(
               (modelDocument) => modelDocument.id === payload.id
            );
            if (index !== -1) {
               draft.modelDocuments[index] = payload;
               draft.modelDocuments.sort(
                  (document: ModelDocument, nextDocument: ModelDocument) =>
                     new Date(`${nextDocument.modifiedDate}`).getTime() -
                     new Date(`${document.modifiedDate}`).getTime()
               );
            }
            return draft;
         }
         case modelDocTypes.GET_BASE_TEMPLATE_SUCCESS:
            draft.baseTemplates = payload;
            return draft;
         case modelDocTypes.MODEL_DOCUMENT_ACTION_FAILURE:
            draft.error = payload;
            return draft;
         case modelDocTypes.UPDATE_MODEL_DOCUMENT_MODIFIER_FAILURE:
            draft.error = payload;
            return draft;
         case modelDocTypes.GET_MODEL_DOCUMENT_TYPES_SUCCESS:
            draft.modelDocumentTypes = payload;
            return draft;
         case modelDocTypes.GET_MODEL_DOCUMENT_FILTERS_DATA_SUCCESS:
            draft.modelDocumentFilters = payload;
            return draft;
         case modelDocTypes.GET_MODEL_DOCUMENT_FILTERS_DATA_FAILURE:
            draft.modelDocumentFilters = [];
            return draft;
         case modelDocTypes.GET_MODEL_DOCUMENT_VARIABLES_SUCCESS:
            draft.modelDocumentVariables = payload;
            return draft;
         case modelDocTypes.GET_MODEL_DOCUMENT_VARIABLES_FAILURE:
            draft.modelDocumentVariables = [];
            return draft;
         case modelDocTypes.CLEAR_MODEL_DOCUMENT_STATE:
            draft = { ...initialState };
            return draft;
         case modelDocTypes.GET_MODEL_DOCUMENT_STATUSES_SUCCESS:
            draft.modelDocumentStatuses = payload;
            return draft;
         default:
            return draft;
      }
   });
