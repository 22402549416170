import { DocumentMetadata } from '../models/documentMetadata';
import { handleError, handleResponse } from './apiUtils';
import { BreezeWorkingDocument } from '../models/workingDocument/breezeWorkingDocument';
import { PV_REPORTS_BACKEND_BASE_URL } from '../../shared/CONSTANTS';
import { getCookie } from '../../shared/helpers';

let baseUrl = process.env.REACT_APP_API_URL;

export class WorkingDocumentApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static addDocument(metadata: DocumentMetadata | BreezeWorkingDocument) {
      return fetch(`${baseUrl}api/v1/document`, {
         credentials: 'include',
         method: 'POST',
         headers: {
            'content-type': 'application/json',
            Authorization: getCookie('cmsAuth'),
            'x-id-token': getCookie('id_token'),
         },
         body: JSON.stringify(metadata),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            result.data.moleculeType = metadata.moleculeType;
            return result.data;
         })
         .catch(handleError);
   }

   static updateDocument(documentId: number | null, data: any) {
      return fetch(`${baseUrl}api/v1/document/${documentId}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static addPlaceholderDocument(metadata: DocumentMetadata) {
      return fetch(`${baseUrl}api/v1/document`, {
         credentials: 'include',
         method: 'POST',
         headers: {
            'content-type': 'application/json',
            Authorization: getCookie('cmsAuth'),
            'x-id-token': getCookie('id_token'),
         },
         body: JSON.stringify(metadata),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            result.data.moleculeType = metadata.moleculeType;
            return result.data;
         })
         .catch(handleError);
   }

   static mergeBreezeImagesInDocument(driveId: {
      workingDocumentDriveId: string;
   }) {
      return fetch(`${PV_REPORTS_BACKEND_BASE_URL}gFunction/imageMerge`, {
         credentials: 'include',
         method: 'POST',
         headers: {
            'content-type': 'application/json',
            Authorization: getCookie('cmsAuth'),
            'x-id-token': getCookie('id_token'),
         },
         body: JSON.stringify(driveId),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data.workingDocumentDriveId as String;
         })
         .catch(handleError);
   }

   static getPublicDocuments(page: number, size: number, searchQuery?: string) {
      return fetch(
         `${baseUrl}api/v1/document/public?page=${page}&size=${size}&searchQuery=${searchQuery}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getWorkingDocuments(
      page: number,
      size: number,
      searchQuery?: string
   ) {
      return fetch(
         `${baseUrl}api/v1/document/all?page=${page}&size=${size}&searchQuery=${searchQuery}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getAssignDocuments(page: number, size: number) {
      return fetch(
         `${baseUrl}api/v1/document/assigned?page=${page}&size=${size}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static deleteDocument(documentId: number) {
      return fetch(`${baseUrl}api/v1/document/${documentId}`, {
         credentials: 'include',
         method: 'DELETE',
         headers: { 'content-type': 'application/json' },
      }).then(async (response) => {
         const result = await handleResponse(response);
         return result.status < 300 ? documentId : false;
      });
   }

   static getFileMetadata(documentId: string, attributes: string) {
      return fetch(`${baseUrl}api/documents/${documentId}`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({
            attributes,
         }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   // no endpoint for this call ?
   static getWorkingDocumentById(id: number) {
      return fetch(`${baseUrl}api/v1/document/${id}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      }).then(async (response) => {
         const result = await handleResponse(response);
         // console.log('this is the result.data');
         return result.data;
      });
   }

   static archiveWorkingDocument(
      documentId: number | null,
      projectId: number | null,
      archived: boolean
   ) {
      return fetch(`${baseUrl}api/v1/document/archive`, {
         credentials: 'include',
         method: 'PATCH',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({
            documentId,
            projectId,
            archived,
         }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static getCountries() {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/roNumbers/countries`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data?.[0];
         })
         .catch(handleError);
   }

   static getDocumentFiltersData(query: string | null) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/document/filterData?${query}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getVariableValues(
      id: number | null,
      query: string,
      roNumber: string
   ) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/document/metadataValues?roche_number=${roNumber}&for=${id}&${query}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.result;
         })
         .catch(handleError);
   }

   static getAutopopulatedVariablesValues(metadata: any) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/document/autopopulateMetadataValues`,
         {
            credentials: 'include',
            method: 'POST',
            headers: {
               'content-type': 'application/json',
            },
            body: JSON.stringify(metadata),
         }
      )
         .then(async (response) => {
            const result = await response.json();
            return result;
         })
         .catch(handleError);
   }

   static getDocumentWithDriveId(driveId: string) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/document/getByDriveId/${driveId}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }
}
