import { call, put, takeLatest } from 'redux-saga/effects';
import { ApiErrorHandler } from '../../core/api/apiUtils';
import { InternalReportsApi } from '../../core/api/internalReports';
import {
   InternalReportsActivationForm,
   InternalReportUpdateForm,
   InternalReportUpdateTitleForm,
} from '../../core/models/internalReports/internalReportForm';
import { InternalReportInterface } from '../../core/models/internalReports/internalReports';
import {
   activateInternalReportsSuccess,
   getActiveInternalReportsSuccess,
   getInternalReportsSuccess,
   internalReportFalure,
   updateInternalReportTitleSuccess,
} from '../actions/internalReports';
import { internalReportTypes } from '../types/internalReports';

export function* getInternalReports({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const result: InternalReportInterface[] = yield call(() =>
         InternalReportsApi.getInternalReports()
      );
      yield put(getInternalReportsSuccess(result));
      callback();
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(internalReportFalure(ApiErrorHandler.createErrorObject(error)));
   }
}

export function* getActiveInternalReports({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const result: InternalReportInterface[] = yield call(() =>
         InternalReportsApi.getActiveInternalReports()
      );
      yield put(getActiveInternalReportsSuccess(result));
      callback();
   } catch (error) {
      console.log(error);
      callback(JSON.parse(error.message), null);
      yield put(internalReportFalure(ApiErrorHandler.createErrorObject(error)));
   }
}

export function* createInternalReports({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const result: InternalReportInterface = yield call(() =>
         InternalReportsApi.createInternalReport(payload)
      );
      callback(null, result);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(internalReportFalure(error));
   }
}
export function* updateInternalReport({
   payload,
   callback,
}: {
   type: string;
   payload: {
      groupId: number;
      titleId: number;
      data: InternalReportUpdateForm;
   };
   callback: Function;
}) {
   try {
      const result: InternalReportInterface = yield call(() =>
         InternalReportsApi.updateInternalReport(
            payload.groupId,
            payload.titleId,
            payload.data
         )
      );
      callback(null, result);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(internalReportFalure(error));
   }
}
export function* activateInternalReport({
   payload,
   callback,
}: {
   type: string;
   payload: InternalReportsActivationForm;
   callback: Function;
}) {
   try {
      const result: {
         activatedTitles: { id: number; reportGroupId: number }[];
         inActivatedTitles: { id: number; reportGroupId: number }[];
      } = yield call(() => InternalReportsApi.activateInternalReport(payload));
      yield put(activateInternalReportsSuccess(result));
      callback(null, result);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(internalReportFalure(error));
   }
}

export function* updateInternalReportTitle({
   payload,
   callback,
}: {
   type: string;
   payload: InternalReportUpdateTitleForm;
   callback: Function;
}) {
   try {
      const { title, titleId, groupName } = payload;
      const result: InternalReportInterface = yield call(() =>
         InternalReportsApi.updateInternalReportTitle(titleId, {
            title,
            groupName,
         })
      );
      yield put(
         updateInternalReportTitleSuccess({
            reportTitle: result,
            groupName,
         })
      );
      callback(null, result);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(internalReportFalure(error));
   }
}

export default function* internalReportsWatcher() {
   yield takeLatest(
      internalReportTypes.GET_INTERNAL_REPORTS,
      getInternalReports
   );
   yield takeLatest(
      internalReportTypes.GET_ACTIVE_INTERNAL_REPORTS,
      getActiveInternalReports
   );
   yield takeLatest(
      internalReportTypes.CREATE_INTERNAL_REPORTS,
      createInternalReports
   );
   yield takeLatest(
      internalReportTypes.UPDATE_INTERNAL_REPORT,
      updateInternalReport
   );
   yield takeLatest(
      internalReportTypes.ACTIVATE_INTERNAL_REPORT,
      activateInternalReport
   );
   yield takeLatest(
      internalReportTypes.UPDATE_INTERNAL_REPORT_TITLE,
      updateInternalReportTitle
   );
}
