export interface CtdTitleInterface {
   code: string;
   title: string;
   id: number;
   activeFlag: boolean;
   creator: string;
   createdBy: string;
   modifiedBy: number;
   createdDate: string;
   updatedDate: string;
   ctdCode: string;
   ctdCodeTransformed: string;
   ctdTitle: string;
   ctdTitleTransformed: string;
}

export class CtdTitle implements Partial<CtdTitleInterface> {
   constructor(
      public code: string = '',
      public title: string = '',
      public ctdCodeTransformed: string = '',
      public ctdTitleTransformed: string = '',
      public id: number = 0,
      public activeFlag: boolean = false,
      public createdDate: string = '',
      public updatedDate: string = '',
      public creator: string = '',
      public modifiedBy: number = 0
   ) {}

   public static createCtdTitleObjectg(data: CtdTitleInterface) {
      return new CtdTitle(
         data.ctdCode,
         data.ctdTitle,
         data.ctdCodeTransformed || data.ctdCode,
         data.ctdTitleTransformed || data.ctdTitle,
         data.id,
         data.activeFlag,
         new Date(data.createdDate).toLocaleDateString(),
         new Date(data.updatedDate).toLocaleDateString(),
         data.createdBy,
         data.modifiedBy
      );
   }

   public static changeActiveList(
      {
         activatedCodes,
         inActivatedCodes,
      }: {
         activatedCodes: CtdTitleInterface[];
         inActivatedCodes: CtdTitleInterface[];
      },
      ctdTitles: CtdTitle[]
   ) {
      return ctdTitles.map((title: CtdTitle) => {
         const isActivated = activatedCodes.find(
            (item: CtdTitleInterface) => item.id === title.id
         );
         const isInActiveted = inActivatedCodes.find(
            (item: CtdTitleInterface) => item.id === title.id
         );
         if (isActivated) {
            title.activeFlag = true;
         }
         if (isInActiveted) {
            title.activeFlag = false;
         }
         return title;
      });
   }
}
