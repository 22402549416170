import {
   GeneratedDocument,
   UpdateGeneratedDocument,
   GeneratedDocumentInterface,
} from '../models/generatedDocument/generatedDocument';
import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class GeneratedDocumentApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static getGeneratedDocuments(
      page: number,
      size: number,
      filterQuery: string
   ) {
      return fetch(
         `${baseUrl}api/v1/generated_document?page=${page}&size=${size}&${filterQuery}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static deleteGeneratedDocument(documentId: number) {
      return fetch(`${baseUrl}api/v1/generated_document/${documentId}`, {
         credentials: 'include',
         method: 'DELETE',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            await handleResponse(response);
            return documentId;
         })
         .catch(handleError);
   }

   static updateGeneratedDocument(
      generatedDocument: UpdateGeneratedDocument,
      documentId: number,
      document: GeneratedDocumentInterface
   ) {
      return fetch(`${baseUrl}api/v1/generated_document/${documentId}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(generatedDocument),
      })
         .then(async (response) => {
            await handleResponse(response);
            return document;
         })
         .catch(handleError);
   }

   static transferGeneratedDocument(
      generatedDocument: GeneratedDocumentInterface
   ) {
      return fetch(`${baseUrl}api/v1/generated_document/transfer`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(generatedDocument),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getAssignedGeneratedDocuments() {
      return fetch(`${baseUrl}api/v1/generated_document/assigned`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static downloadGeneratedDocument(generatedDocument: GeneratedDocument) {
      const { driveId } = generatedDocument;
      return fetch(`${baseUrl}api/v1/generated_document/download/${driveId}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const dateObj = `${new Date().toLocaleTimeString()}_${new Date()
               .toLocaleDateString()
               .replace(/\s+/g, '_')}`;
            const blob = await response.blob();
            const newBlob = new Blob([blob]);
            const blobUrl = window.URL.createObjectURL(newBlob);
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute(
               'download',
               `${generatedDocument.title}_${dateObj}.docx`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode?.removeChild(link);
            window.URL.revokeObjectURL(blobUrl);
            return { downloaded: true };
         })
         .catch((error) => {
            handleError(error);
            return { downloaded: false };
         });
   }

   static archiveGeneratedDocument(
      documentId: number | null,
      archived: boolean
   ) {
      return fetch(`${baseUrl}api/v1/generated_document/archive`, {
         credentials: 'include',
         method: 'PATCH',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({
            documentId,
            archived,
         }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch((error) => {
            handleError(error);
         });
   }

   static getGeneratedDocumentFiltersData(query: string | null) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/generated_document/filterData?${query}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
