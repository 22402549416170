import { put, call, takeLatest, select } from 'redux-saga/effects';
import { ModelDocumentApi } from '../../core/api';
import { GlobalState } from '../../core/models/globalState';
import {
   ModelDocument,
   ModelDocumentCallback,
} from '../../core/models/modelDocument/modelDocument';
import { BaseTemplate } from '../../core/models/baseTemplate';
import { UserState } from '../../core/models/user/userState';
import {
   deleteModelDocumentSuccess,
   getModelDocumentByIdSuccess,
   getModelDocumentsSuccess,
   modelDocumentActionFailure,
   updateModelDocumentSuccess,
   updateModelDocumentModifierSuccess,
   archiveModelDocumentSuccess,
   archiveModelDocumentFailure,
   getModelDocumentTypesSuccess,
   getBaseTemplatesSuccess,
   getModelDocumentFiltersDataSuccess,
   getModelDocumentFiltersDataFailure,
   getModelDocumentVariablesSuccess,
   getModelDocumentStatusesSuccess,
} from '../actions/modelDocument';
import { modelDocTypes } from '../types/modelDocument';
import { ApiErrorHandler } from '../../core/api/apiUtils';
import { IModelDocumentType } from '../../core/models/modelDocument/modelDOcumentTypes';
import { DuplicateModelDoc } from '../../core/models/modelDocument/modelDocumentDuplication';

const getUser = (state: GlobalState) => state.user;

export function* getModelDocuments({
   payload,
   callback,
}: {
   type: string;
   payload: {
      page: number;
      pageSize: number;
      searchQuery?: string;
   };
   callback: ModelDocumentCallback;
}) {
   try {
      const result: {
         rows: ModelDocument[];
         count: number;
      } = yield call(() => ModelDocumentApi.getModelDocuments(payload));
      const modelDocuments = result.rows.map((document: ModelDocument) =>
         ModelDocument.createModelDocumentObject(document)
      );
      yield put(
         getModelDocumentsSuccess({ modelDocuments, count: result.count })
      );
      callback(null, modelDocuments, result.count);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getModelDocumentById({
   payload,
   callback,
}: {
   type: string;
   payload: number;
   callback: ModelDocumentCallback;
}) {
   try {
      const modelDocument: ModelDocument = yield call(() =>
         ModelDocumentApi.getModelDocumentById(payload)
      );
      yield put(getModelDocumentByIdSuccess(modelDocument));
      callback(null, ModelDocument.createModelDocumentObject(modelDocument));
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* addModelDocument({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: ModelDocumentCallback;
}) {
   try {
      const modelDocument: ModelDocument = yield call(() =>
         ModelDocumentApi.addModelDocument(payload)
      );
      if (Array.isArray(modelDocument)) {
         callback(null, null, undefined, modelDocument);
      } else {
         callback(null, modelDocument);
      }
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(modelDocumentActionFailure(error));
   }
}

function* updateModelDocument({
   payload,
   callback,
}: {
   type: string;
   payload: ModelDocument;
   callback: ModelDocumentCallback;
}) {
   try {
      const modelDocument: ModelDocument = yield call(() =>
         ModelDocumentApi.updateModelDocument(payload.id, payload)
      );
      if (Array.isArray(modelDocument)) {
         callback(null, null, undefined, modelDocument);
      } else {
         const user: UserState = yield select(getUser);
         yield put(
            // TODO: @Vigen, we should get the modifier from backend.
            updateModelDocumentSuccess({
               ...modelDocument,
               Modifier: user.currentUser,
            })
         );
         callback(null, modelDocument);
      }
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* updateModelDocumentModifier({
   payload,
   callback,
}: {
   type: string;
   payload: number;
   callback: ModelDocumentCallback;
}) {
   try {
      const result: ModelDocument | string = yield call(() =>
         ModelDocumentApi.updateModelDocumentModifier(payload)
      );
      const modelDocument = ModelDocument.createModelDocumentObject(result);
      const user: UserState = yield select(getUser);
      yield put(
         updateModelDocumentModifierSuccess({
            ...modelDocument,
            modifier: user.currentUser,
         })
      );
      callback(null, modelDocument);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(modelDocumentActionFailure(error));
   }
}

export function* archiveModelDocument(action: {
   type: string;
   payload: { documentId: number; archived: boolean };
   callback: ModelDocumentCallback;
}) {
   try {
      const { documentId, archived } = action.payload;
      const response: {
         code: number;
         message: string;
         data: ModelDocument;
      } = yield call(() =>
         ModelDocumentApi.archiveModelDocument(documentId, archived)
      );
      const modelDocument = response.data;
      yield put(archiveModelDocumentSuccess(modelDocument.id));
      action.callback(null, modelDocument);
   } catch (error) {
      action.callback(JSON.parse(error.message), null);
      yield put(archiveModelDocumentFailure(error));
   }
}

export function* deleteTemplate({
   payload,
   callback,
}: {
   type: string;
   payload: string;
   callback: Function;
}) {
   try {
      const data: {} = yield call(() =>
         ModelDocumentApi.deleteTemplate(payload)
      );
      // TODO: @ Vigen take the template from the response. Not the payload.
      yield put(deleteModelDocumentSuccess(payload));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getModelDocumentTypes({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const result: IModelDocumentType[] = yield call(() =>
         ModelDocumentApi.getModelDocumentTypes()
      );
      yield put(getModelDocumentTypesSuccess(result));
      callback();
   } catch (error) {
      console.log(error);
      callback(JSON.parse(error.message), null);
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getBaseTemplates({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const baseTemplates: BaseTemplate[] = yield call(() =>
         ModelDocumentApi.getBaseTemplate()
      );
      yield put(getBaseTemplatesSuccess(baseTemplates));
      callback(null, baseTemplates);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getModelDocumentVariables({
   payload,
   callback,
}: {
   type: string;
   payload: object | any;
   callback: ModelDocumentCallback;
}) {
   try {
      const modelDocumentVariables = yield call(() =>
         ModelDocumentApi.getModelDocumentVariables(payload)
      );
      yield put(getModelDocumentVariablesSuccess(modelDocumentVariables));
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getModelDocumentFiltersData(action: {
   type: string;
   payload: string | null;
   callback: Function;
}) {
   try {
      const modelDocumentFilters = yield call(() =>
         ModelDocumentApi.getModelDocumentFiltersData(action.payload)
      );
      yield put(getModelDocumentFiltersDataSuccess(modelDocumentFilters));
      action.callback(null, modelDocumentFilters);
   } catch (error) {
      action.callback(JSON.parse(error.message), null);
      yield put(getModelDocumentFiltersDataFailure());
   }
}

export function* duplicateModelDocument(action: {
   type: string;
   payload: DuplicateModelDoc;
   callback: Function;
}) {
   try {
      const duplicatedModelDocument = yield call(() =>
         ModelDocumentApi.duplicateModelDocument(action.payload)
      );
      action.callback(null, duplicatedModelDocument);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* publishModelDocument(action: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const { templateId, confirm } = action.payload;
      const publishedModelDocument = yield call(() =>
         ModelDocumentApi.publishModelDocument(templateId, confirm)
      );
      action.callback(null, publishedModelDocument);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* withdrawModelDocument(action: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const { templateId } = action.payload;
      const withdrawedModelDocument = yield call(() =>
         ModelDocumentApi.withdrawModelDocument(templateId)
      );
      action.callback(null, withdrawedModelDocument);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getModelDocumentStatuses(action: {
   type: string;
   callback: Function;
}) {
   try {
      const modelDocumentStatuses = yield call(() =>
         ModelDocumentApi.getModelDocumentStatuses()
      );
      yield put(getModelDocumentStatusesSuccess(modelDocumentStatuses));
      action.callback(null, modelDocumentStatuses);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(
         modelDocumentActionFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export default function* modelDocumentWatcher() {
   yield takeLatest(modelDocTypes.GET_MODEL_DOCUMENTS, getModelDocuments);
   yield takeLatest(modelDocTypes.ADD_MODEL_DOCUMENT, addModelDocument);
   yield takeLatest(modelDocTypes.UPDATE_MODEL_DOCUMENT, updateModelDocument);
   yield takeLatest(
      modelDocTypes.UPDATE_MODEL_DOCUMENT_MODIFIER,
      updateModelDocumentModifier
   );
   yield takeLatest(
      modelDocTypes.GET_MODEL_DOCUMENT_BY_ID,
      getModelDocumentById
   );
   yield takeLatest(modelDocTypes.ARCHIVE_MODEL_DOCUMENT, archiveModelDocument);
   yield takeLatest(modelDocTypes.DELETE_MODEL_DOCUMENT, deleteTemplate);
   yield takeLatest(
      modelDocTypes.GET_MODEL_DOCUMENT_TYPES,
      getModelDocumentTypes
   );
   yield takeLatest(modelDocTypes.GET_BASE_TEMPLATE, getBaseTemplates);
   yield takeLatest(
      modelDocTypes.GET_MODEL_DOCUMENT_FILTERS_DATA,
      getModelDocumentFiltersData
   );
   yield takeLatest(
      modelDocTypes.GET_MODEL_DOCUMENT_VARIABLES,
      getModelDocumentVariables
   );
   yield takeLatest(
      modelDocTypes.DUPLICATE_MODEL_DOCUMENT,
      duplicateModelDocument
   );
   yield takeLatest(modelDocTypes.PUBLISH_MODEL_DOCUMENT, publishModelDocument);
   yield takeLatest(
      modelDocTypes.WITHDRAW_MODEL_DOCUMENT,
      withdrawModelDocument
   );
   yield takeLatest(
      modelDocTypes.GET_MODEL_DOCUMENT_STATUSES,
      getModelDocumentStatuses
   );
}
