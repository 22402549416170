import { BBCode } from '../../core/models/bbCodes';
import { BreezeMolecules } from '../../core/models/breezeMolecule/breezeMolecule';
import { ChromoSteps } from '../../core/models/chromoSteps/chromoSteps';
import { Molecule } from '../../core/models/molecule/molecule';
import { Scopes } from '../../core/models/scopes';
import { StageInterface } from '../../core/models/stage';
import { SystemTypes } from '../../core/models/systemType/systemType';
import { globalsTypes } from '../types/globals';
import { OperationsList } from '../../core/models/operationsList/operationsList';

export const getSystem = (
   projectType: string,
   eventName: string,
   callback: Function = () => {}
) => ({
   type: globalsTypes.GET_SYSTEM,
   payload: { projectType, eventName },
   callback,
});

export const getSystemSuccess = (types: {}) => ({
   type: globalsTypes.GET_SYSTEM_SUCCESS,
   payload: types,
});

export const getScopes = (callback: Function = () => {}) => ({
   type: globalsTypes.GET_SCOPES,
   callback,
});

export const getScopesSuccess = (payload: Scopes[]) => ({
   type: globalsTypes.GET_SCOPES_SUCCESS,
   payload,
});

export const getSystemTypes = (callback: Function = () => {}) => ({
   type: globalsTypes.GET_SYSTEM_TYPES,
   callback,
});

export const getSystemNumbers = (
   payload: string,
   callback: Function = () => {}
) => ({
   type: globalsTypes.GET_SYSTEM_NUMBERS,
   payload,
   callback,
});

export const getSystemNubersSuccess = (payload: []) => ({
   type: globalsTypes.GET_SYSTEM_NUMBERS_SUCCESS,
   payload,
});

export const getSystemTypesSuccess = (payload: SystemTypes[]) => ({
   type: globalsTypes.GET_SYSTEM_TYPES_SUCCESS,
   payload,
});

export const getMolecules = (callback: Function = () => {}) => ({
   type: globalsTypes.GET_MOLECULES,
   callback,
});

export const getMoleculesSuccess = (payload: Molecule[]) => ({
   type: globalsTypes.GET_MOlECULES_SUCCESS,
   payload,
});

export const getBigQueryTablePreviewOfSelections = (callback: Function) => ({
   type: globalsTypes.GET_BIG_QUERY_TABLE_PREVIEW_OF_SELECTIONS,
   callback,
});

export const getBreezeMolecules = (callback: Function = () => {}) => ({
   type: globalsTypes.GET_BREEZE_MOLECULES,
   callback,
});

export const getBreezeMoleculesSuccess = (payload: BreezeMolecules[]) => ({
   type: globalsTypes.GET_BREEZE_MOLECULES_SUCCESS,
   payload,
});

export const getBreezeChromoSteps = (callback: Function = () => {}) => ({
   type: globalsTypes.GET_CHROMO_STEPS,
   callback,
});

export const getBreezeChromoStepsSuccess = (payload: ChromoSteps[]) => ({
   type: globalsTypes.GET_CHROMO_STEPS_SUCCESS,
   payload,
});

export const getBreezeChromoStepsDetails = (callback: Function = () => {}) => ({
   type: globalsTypes.GET_OPERATIONS_LIST,
   callback,
});

export const getBreezeChromoStepsDetailsSuccess = (
   payload: OperationsList[]
) => ({
   type: globalsTypes.GET_OPERATIONS_LIST_SUCCESS,
   payload,
});

export const updateBreezeFormSelectionSuccess = (payload: any) => ({
   type: globalsTypes.GET_SELECTION_SUCCESS,
   payload,
});

export const updateBreezeMoleculeSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_MOLECULE_SUCCESS,
   payload,
});

export const updateStepSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_STEP_SUCCESS,
   payload,
});

export const updateRunsSelection = (callback: Function = () => {}) => ({
   type: globalsTypes.UPDATE_SELECTED_RUNS,
   callback,
});

export const updateRunsSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_RUNS_SUCCESS,
   payload,
});

export const updateInputSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_INPUT_SUCCESS,
   payload,
});

export const updateInputComputerSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_INPUT_COMPUTER_SUCCESS,
   payload,
});

export const updateKpiSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_KPI_SUCCESS,
   payload,
});

export const updateKpiComputerSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_KPI_COMPUTER_SUCCESS,
   payload,
});

export const updateKpiMetadataSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_KPI_METADATA_SUCCESS,
   payload,
});
export const updateQaSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_QA_SUCCESS,
   payload,
});

export const updateQaComputerSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_QA_COMPUTER_SUCCESS,
   payload,
});

export const updateQaMetadataSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_QA_METADATA_SUCCESS,
   payload,
});
export const updateOutputsMetadataSelectionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_OUTPUTS_METADATA_SUCCESS,
   payload,
});

export const updateWorkingDocumentDescriptionSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_DOCUMENT_DESCRIPTION_SUCCESS,
   payload,
});

export const updateDocumentStageSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_DOCUMENT_STAGE_SUCCESS,
   payload,
});

export const updateModelDocumentIdSelectedSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_MODEL_DOCUMENT_ID_SUCCESS,
   payload,
});

export const updateDocumentNameSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_DOCUMENT_NAME_SUCCESS,
   payload,
});

export const updateProjectIdSuccess = (payload: any) => ({
   type: globalsTypes.UPDATE_SELECTED_PROJECT_ID_SUCCESS,
   payload,
});

export const getWorkingDocumentDriveIdSuccess = (payload: any) => ({
   type: globalsTypes.GET_WORKING_DOCUMENT_DRIVE_ID_SUCCESS,
   payload,
});

export const setToast = (payload: any) => ({
   type: globalsTypes.SET_TOAST,
   payload,
});

export const getBBCodes = (callback: Function = () => {}) => ({
   type: globalsTypes.GET_BB_CODES,
   callback,
});

export const getBBCodesSuccess = (payload: BBCode[]) => ({
   type: globalsTypes.GET_BB_CODES_SUCCESS,
   payload,
});

export const getStages = (callback: Function = () => {}) => ({
   type: globalsTypes.GET_STAGES,
   callback,
});

export const getStagesSuccess = (payload: StageInterface[]) => ({
   type: globalsTypes.GET_STAGES_SUCCESS,
   payload,
});

export const getFailure = (payload: any) => ({
   type: globalsTypes.GET_FAILURE,
   payload,
});

export const getGuidance = () => ({
   type: globalsTypes.GET_GUIDANCE,
});

export const getGuidanceSuccess = (payload: any) => ({
   type: globalsTypes.GET_GUIDANCE_SUCCESS,
   payload,
});

export const getRoNumbers = (
   requestFrom = 'workingDocument',
   callback: Function = () => {}
) => ({
   type: globalsTypes.GET_RO_NUMBERS,
   requestFrom,
   callback,
});

export const getRoNumbersSuccess = (payload: {
   isAll: boolean;
   data: { roNumber: string; productFamilyName: string }[];
}) => ({
   type: globalsTypes.GET_RO_NUMBERS_SUCCESS,
   payload,
});

export const getgetRoNumbersFailure = () => ({
   type: globalsTypes.GET_RO_NUMBERS_FAILURE,
});

export const getRoNumber = (
   payload: string,
   callback: Function = () => {}
) => ({
   type: globalsTypes.GET_RO_NUMBER,
   payload,
   callback,
});

export const getRoNumberSuccess = (payload: any) => ({
   type: globalsTypes.GET_RO_NUMBER_SUCCESS,
   payload,
});

export const getRoNumberFailure = (error: any) => ({
   type: globalsTypes.GET_RO_NUMBER_FAILURE,
   payload: error,
});

export const updateCaches = (callback: Function, errorCallback: Function) => ({
   type: globalsTypes.UPDATE_CACHES,
   callback,
   errorCallback,
});

export const updateCachesSuccess = () => ({
   type: globalsTypes.UPDATE_CACHES_SUCCESS,
});

export const updateCachesFailure = (error: any) => ({
   type: globalsTypes.UPDATE_CACHES_FAILURE,
   payload: error,
});

export const ACTIVATE_APP = () => ({
   type: globalsTypes.ACTIVATE_APP,
   payload: { isLoggedIn: true },
});

export const DEACTIVATE_APP = () => ({
   type: globalsTypes.DEACTIVATE_APP,
   payload: { isLoggedIn: false },
});

export const CLEAR_GLOBAL_STATE = () => ({
   type: globalsTypes.CLEAR_GLOBAL_STATE,
});
export const findRoNumber = (payload: { code: string; from: string }) => ({
   type: globalsTypes.FIND_RO_NUMBER,
   payload,
});
