import { ProjectInterface } from '../../core/models/project/projectInterface';
import { WorkingDocument } from '../../core/models/workingDocument/workingDocument';
import { projectTypes } from '../types/project';
import { COMPONENT_ERROR } from '../../shared/CONSTANTS';

export const getProjectById = (
   data: { projectId: number; queryText: string },
   callback: Function = () => {}
) => ({
   type: projectTypes.GET_CURRENT_PROJECT,
   payload: { projectId: data.projectId, queryText: data.queryText },
   callback,
});

export const getProjectByIdSuccess = (project: ProjectInterface) => ({
   type: projectTypes.GET_CURRENT_PROJECT_SUCCESS,
   payload: project,
});

export const getProjectByIdFailure = (err: any) => ({
   type: projectTypes.GET_CURRENT_PROJECT_FAILURE,
   payload: err,
});

export const createProject = (
   project: ProjectInterface,
   callback: Function
) => ({
   type: projectTypes.ADD_PROJECT,
   payload: project,
   callback,
});

export const addProjectSuccess = (project: ProjectInterface) => ({
   type: projectTypes.ADD_PROJECT_SUCCESS,
   payload: project,
});

export const addProjectFailure = (err: COMPONENT_ERROR) => ({
   type: projectTypes.ADD_PROJECT_SUCCESS,
   payload: err,
});

export const getAllProjects = (
   page: number,
   size: number,
   callback: Function | boolean = false,
   searchQuery: string = '',
   includeWorkingDocuments: boolean = false
) => ({
   type: projectTypes.GET_ALL_PROJECTS,
   payload: { page, size, searchQuery, includeWorkingDocuments },
   callback,
});

export const updateProjectFavoriteSuccess = (
   id: number,
   isFavorite: boolean,
   callback: Function | boolean = false
) => ({
   type: projectTypes.UPDATE_PROJECT_FAVORITE_SUCCESS,
   payload: { id, isFavorite },
   callback,
});

export const getAllProjectsSuccess = (project: {
   rows: ProjectInterface[];
   includeWorkingDocuments: boolean;
   count: number;
}) => ({
   type: projectTypes.GET_ALL_PROJECTS_SUCCESS,
   payload: project,
});
export const getAllProjectsFailure = (err: any) => ({
   type: projectTypes.GET_ALL_PROJECTS_FAILURE,
   payload: err,
});
export const getAssignedProjects = (
   page: number,
   size: number,
   includeWorkingDocuments: boolean = false,
   callback: Function = () => {}
) => ({
   type: projectTypes.GET_ALL_ASSIGNED_PROJECTS,
   payload: { page, size, includeWorkingDocuments },
   callback,
});

export const getAssignedProjectsSuccess = (project: ProjectInterface[]) => ({
   type: projectTypes.GET_ALL_ASSIGNED_PROJECTS_SUCCESS,
   payload: project,
});

export const updateProject = (
   project: ProjectInterface,
   callback: Function
) => ({
   type: projectTypes.UPDATE_PROJECT,
   payload: project,
   callback,
});

export const updateProjectSuccess = (project: ProjectInterface) => ({
   type: projectTypes.UPDATE_PROJECT_SUCCESS,
   payload: project,
});

export const updateProjectFailure = (err: any) => ({
   type: projectTypes.UPDATE_PROJECT_FAILURE,
   payload: err,
});

export const deleteProject = (
   projectId: number,
   callback: Function = () => {}
) => ({
   type: projectTypes.DELETE_PROJECT,
   payload: projectId,
   callback,
});

export const deleteProjectSuccess = (projectId: number) => ({
   type: projectTypes.DELETE_PROJECT_SUCCESS,
   payload: projectId,
});

export const deleteProjectFailure = (err: any) => ({
   type: projectTypes.DELETE_PROJECT_FAILURE,
   payload: err,
});

export const assignDocumentToProjects = (
   data: any,
   callback: Function = () => {}
) => ({
   type: projectTypes.ASSIGN_DOCUMENTS_TO_PROJECT,
   payload: {
      documentList: data.documentsToBeAssigned,
      projectId: data.currentProjectId,
      callback: data.callback,
      error: data.error,
   },
   callback,
});

export const assignDocumentsSuccess = (project: ProjectInterface) => ({
   type: projectTypes.ASSIGN_DOCUMENTS_TO_PROJECT_SUCCESS,
   payload: project,
});

export const assignDocumentsFailure = (err: any) => ({
   type: projectTypes.ASSIGN_DOCUMENTS_TO_PROJECT_FAILURE,
   payload: err,
});

export const deleteDocumentFromProject = (
   documentId: number,
   projectId: number,
   callback: Function
) => ({
   type: projectTypes.DELETE_DOCUMENT_FROM_PROJECT,
   payload: { documentId, projectId, callback },
});

export const deleteDocumentFromProjectSuccess = (
   projectId: number,
   documentId: number
) => ({
   type: projectTypes.DELETE_DOCUMENT_IN_PROJECT_SUCCESS,
   payload: { projectId, documentId },
});
export const createDocumentInProjectSuccess = (payload: {
   projectId: string;
   document: WorkingDocument;
}) => ({
   type: projectTypes.CREATE_DOCUMENT_IN_PROJECT_SUCCESS,
   payload,
});

export const archiveProject = (
   projectId: number,
   archived: boolean,
   isDocumentArchived: boolean,
   callback: Function = () => {}
) => ({
   type: projectTypes.ARCHIVE_PROJECT,
   payload: { projectId, archived, isDocumentArchived },
   callback,
});

export const archiveProjectSuccess = (
   projectId: number,
   archived: boolean,
   data: Object
) => ({
   type: projectTypes.ARCHIVE_PROJECT_SUCCESS,
   payload: { projectId, archived, data },
});

export const archiveProjectFailure = (err: any) => ({
   type: projectTypes.ARCHIVE_PROJECT_FAILURE,
   payload: err,
});

export const CLEAR_PROJECT_STATE = () => ({
   type: projectTypes.CLEAR_PROJECT_STATE,
});

export const getDivisions = (callback: Function = () => {}) => ({
   type: projectTypes.GET_DIVISIONS,
   callback,
});

export const getDivisionsSuccess = (payload: []) => ({
   type: projectTypes.GET_DIVISIONS_SUCCESS,
   payload,
});

export const getDivisionsFailure = () => ({
   type: projectTypes.GET_DIVISIONS_FAILURE,
});

export const clearDocumentState = () => ({
   type: projectTypes.CLEAR_DOCUMENTS_STATE,
});

export const unassignDocument = (
   payload: {
      documentId: number;
      projectId: number;
   },
   callback: Function
) => ({
   type: projectTypes.UNASSIGN_DOCUMENT,
   payload,
   callback,
});

export const unassignDocumentSuccess = (payload: {
   documentId: number;
   projectId: number;
}) => ({
   type: projectTypes.UNASSIGN_DOCUMENT_SUCCESS,
   payload,
});

export const getProjectFilterData = (
   query: string | null,
   callback: Function = () => {}
) => ({
   type: projectTypes.GET_PROJECT_FILTER_DATA,
   payload: query,
   callback,
});

export const getProjectFilterDataSuccess = (payload: []) => ({
   type: projectTypes.GET_PROJECT_FILTER_DATA_SUCCESS,
   payload,
});

export const getProjectFilterDataFailure = () => ({
   type: projectTypes.GET_PROJECT_FILTER_DATA_FAILURE,
});
