import { Permission } from './permissionItem';
import { LandingOptions } from './landingoptions';
import { FontoOptions } from './fontooptions';
import { LookerOptions } from './lookeroptions';

export interface PermissionResponseInterface {
   LANDING_PERMISSIONS: LandingOptions;
   FONTO_PERMISSIONS: FontoOptions;
   LOOKER_PERMISSIONS: LookerOptions;
}

export type PermissionListItem = LandingOptions | FontoOptions | LookerOptions;

export interface PermissionListInterface {
   landingOptions: LandingOptions;
   fontoOptions: FontoOptions;
   lookerOptions: LookerOptions;
}

export class PermissionList implements PermissionListInterface {
   constructor(
      public landingOptions: LandingOptions = new LandingOptions(),
      public fontoOptions: FontoOptions = new FontoOptions(),
      public lookerOptions: LookerOptions = new LookerOptions()
   ) {}

   static createPermissionListobject(data: {
      permissions: PermissionResponseInterface;
   }) {
      const result = data.permissions;
      const landing = PermissionList.createOptions(
         result.LANDING_PERMISSIONS,
         'landing'
      );
      const fonto = PermissionList.createOptions(
         result.FONTO_PERMISSIONS,
         'fonto'
      );
      const looker = PermissionList.createOptions(
         result.LOOKER_PERMISSIONS,
         'looker'
      );
      if (
         landing instanceof LandingOptions &&
         fonto instanceof FontoOptions &&
         looker instanceof LookerOptions
      ) {
         return new PermissionList(landing, fonto, looker);
      }
      return new PermissionList();
   }

   static createOptions(categoryResult: PermissionListItem, type: string) {
      let optionList: LandingOptions | FontoOptions | LookerOptions;

      if (type === 'landing') {
         optionList = new LandingOptions();
      } else if (type === 'fonto') {
         optionList = new FontoOptions();
      } else {
         optionList = new LookerOptions();
      }

      Object.entries(categoryResult).forEach((data: any) => {
         const categoryKey = data[0];
         const categoryValue = data[1];

         switch (categoryKey) {
            case 'generated_document':
               {
                  const generatedDocumentOptions: Permission[] = PermissionList.createPermissions(
                     categoryValue
                  );
                  if ('generated_document' in optionList) {
                     optionList.generated_document = [
                        ...generatedDocumentOptions,
                     ];
                  }
               }
               break;
            case 'template':
               {
                  const modelDocumentOptions: Permission[] = PermissionList.createPermissions(
                     categoryValue
                  );
                  if ('template' in optionList) {
                     optionList.template = [...modelDocumentOptions];
                  }
               }
               break;
            case 'project':
               {
                  const projectOptions: Permission[] = PermissionList.createPermissions(
                     categoryValue
                  );
                  if ('project' in optionList) {
                     optionList.project = [...projectOptions];
                  }
               }
               break;
            case 'working_document':
               {
                  const workingDocumentOptions: Permission[] = PermissionList.createPermissions(
                     categoryValue
                  );
                  if ('working_document' in optionList) {
                     optionList.working_document = [...workingDocumentOptions];
                  }
               }
               break;
            case 'query':
               {
                  const queryOptions: Permission[] = PermissionList.createPermissions(
                     categoryValue
                  );
                  if ('query' in optionList) {
                     optionList.query = [...queryOptions];
                  }
               }
               break;
            default:
               break;
         }
      });

      return optionList;
   }

   static createPermissions(data: {}) {
      const permissionList: Permission[] = [];
      Object.entries(data).forEach((permissionResponse: any) => {
         const permissionKey = permissionResponse[0];
         const permissionValue = permissionResponse[1];
         const permissionItem = new Permission(
            permissionValue.name || permissionKey,
            permissionValue.description || '',
            permissionValue.status || false,
            permissionKey
         );
         permissionList.push(permissionItem);
      });

      return permissionList;
   }
}
