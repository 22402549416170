// TBD: Call an error logging service.
import { dev } from '../../_dev/_utils';

export function handleError(error: any) {
   console.error(`ERROR: API call failed`, error);
   if (typeof error === 'object') {
      throw new Error(
         JSON.stringify({
            status: error.status || error.code || 500,
            message: error.message,
            exception: true,
         })
      );
   } else {
      throw new Error(
         String({
            status: 500,
            message: error,
            exception: true,
         })
      );
   }
}

export interface componentReadableError {
   message: string;
   code: number;
   extraInformation: string | null;
   status?: number;
   stack?: string;
   exception?: boolean;
}

type errorMessage = { name?: string } | string;

export interface apiReadableError {
   code?: number;
   status?: number;
   message?: errorMessage;
   error?:
      | string
      | boolean
      | {
           message?: string;
           meta?: {
              message?: string;
           };
           code?: number;
           status?: number;
        };
   metadata?: { message?: string };
   meta?: { message?: string };
   statusCode?: number;
   timestamp: Date;
   url?: string;
   stack?: string;
}

export class ApiErrorHandler {
   public readonly message: string;

   public readonly code: number;

   public readonly extraInformation: string = '';

   public readonly timestamp:
      | Date
      | String = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;

   public readonly url: string = '';

   constructor(apiErrorObject: {
      message: string;
      code: number;
      extraInformation?: null | string;
      timestamp?: Date | String;
      url?: string | undefined;
   }) {
      this.message = apiErrorObject.message;
      this.code = apiErrorObject.code;
      this.extraInformation = apiErrorObject.extraInformation || '';
      this.timestamp =
         apiErrorObject.timestamp ||
         `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
      this.url = apiErrorObject.url || '';
   }

   static extractMessage(errorResponse: any): string {
      if (typeof errorResponse === 'string') {
         return errorResponse;
      }
      return (
         errorResponse.message?.name ||
         errorResponse.metadata?.message ||
         errorResponse.meta?.message ||
         errorResponse.message ||
         JSON.stringify(errorResponse)
      );
   }

   static extractCode(errorResponse: any): number {
      return (
         errorResponse.error?.code ||
         errorResponse.error?.status ||
         errorResponse.statusCode ||
         errorResponse.code ||
         errorResponse.status ||
         500
      );
   }

   static extractExtraInformation(
      errorResponse: apiReadableError
   ): string | null {
      switch (typeof errorResponse.metadata) {
         case 'string':
            return errorResponse.metadata || errorResponse.stack;
         case 'object':
            return JSON.stringify(errorResponse.metadata);
         default:
            if (typeof errorResponse.stack === 'string') {
               return errorResponse.stack;
            }
            return null;
      }
   }

   static extractTime(result: apiReadableError): Date | String {
      return result.timestamp || new Date().toLocaleDateString();
   }

   static extractUrl(result: apiReadableError): string {
      return result.url || '';
   }

   static createErrorObject(result: apiReadableError): ApiErrorHandler {
      const errorMessage = ApiErrorHandler.extractMessage(result);
      const errorCode = ApiErrorHandler.extractCode(result);
      const errorExtraInformation = ApiErrorHandler.extractExtraInformation(
         result
      );
      const errorTimestamp = ApiErrorHandler.extractTime(result);
      const errorUrl = ApiErrorHandler.extractUrl(result);
      const ApiErrorHandlerObject = {
         message: errorMessage,
         code: errorCode,
         extraInformation: errorExtraInformation,
         timestamp: errorTimestamp,
         url: errorUrl,
      };
      return new ApiErrorHandler(ApiErrorHandlerObject);
   }
}

export async function handleResponse(response: any) {
   try {
      const result = await response.json();
      result.timestamp = `${new Date().toLocaleDateString()} ${new Date().toLocaleTimeString()}`;
      result.url = response.url;
      result.code =
         result.code || response.code || response.status || response.statusCode;
      if (result.code === 401) {
         localStorage.removeItem('isLoggedIn');
      }
      if (
         parseInt(result.code, 10) >= 300 ||
         parseInt(result.status, 10) >= 300 ||
         parseInt(result.statusCode, 10) >= 300 ||
         result.error === true
      ) {
         dev.log('Raw Errorneous result', result);
         const newErrorObject = ApiErrorHandler.createErrorObject(result);
         throw new Error(JSON.stringify(newErrorObject));
      }
      return result;
   } catch (error) {
      // Error.message is the apiErrorObject.
      throw new Error(error.message);
   }
}
