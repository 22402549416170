import { put, call, takeLatest, select } from 'redux-saga/effects';
import { CtdTitleApi } from '../../core/api';
import { ApiErrorHandler } from '../../core/api/apiUtils';
import {
   CtdTitle,
   CtdTitleInterface,
} from '../../core/models/ctdTitle/ctdTitles';
import { CtdTitleState } from '../../core/models/ctdTitle/ctdTitlesState';
import { GlobalState } from '../../core/models/globalState';
import {
   activateCtdTitleSuccess,
   ctdTitleFailure,
   getActiveCtdTitlesSuccess,
   getCtdTitleByCodeSuccess,
   getCtdTitlesSuccess,
} from '../actions/ctdTitles';
import { ctdTitleTypes } from '../types/ctdTitles';

const getCtdTitle = (state: GlobalState) => state.ctdTitle;

function* getCtdTitlesList(action: { payload: string; callback: Function }) {
   const { payload, callback } = action;
   try {
      const result: CtdTitleInterface[] = yield call(() =>
         CtdTitleApi.getCtdTitlesList(payload)
      );
      const ctdTitles: CtdTitle[] = result.map((ctdTitle: CtdTitleInterface) =>
         CtdTitle.createCtdTitleObjectg(ctdTitle)
      );
      yield put(getCtdTitlesSuccess(ctdTitles));
      yield put(
         getActiveCtdTitlesSuccess(
            ctdTitles.filter((title) => title.activeFlag)
         )
      );
      callback(null, ctdTitles);
   } catch (error) {
      yield put(ctdTitleFailure(ApiErrorHandler.createErrorObject(error)));
      callback(JSON.parse(error.message), null);
   }
}

function* getActiveCtdTitlesList({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const result: CtdTitleInterface[] = yield call(() =>
         CtdTitleApi.getActiveCtdTitlesList()
      );
      const activeCtdTitles: CtdTitle[] = result.map(
         (ctdTitle: CtdTitleInterface) =>
            CtdTitle.createCtdTitleObjectg(ctdTitle)
      );
      yield put(getActiveCtdTitlesSuccess(activeCtdTitles));
      callback(null, activeCtdTitles);
   } catch (error) {
      yield put(ctdTitleFailure(ApiErrorHandler.createErrorObject(error)));
      callback(JSON.parse(error.message), null);
   }
}

function* getCtdTitlesListByCode({
   payload,
   callback,
}: {
   payload: string;
   type: string;
   callback: Function;
}) {
   try {
      const result: CtdTitleInterface[] = yield call(() =>
         CtdTitleApi.getCtdTitlesListByCode(payload)
      );
      const selectedCtdTitles: CtdTitle[] = result.map(
         (ctdTitle: CtdTitleInterface) =>
            CtdTitle.createCtdTitleObjectg(ctdTitle)
      );
      yield put(getCtdTitleByCodeSuccess(selectedCtdTitles));
      callback(null, selectedCtdTitles);
   } catch (error) {
      yield put(ctdTitleFailure(ApiErrorHandler.createErrorObject(error)));
      callback(JSON.parse(error.message), null);
   }
}

function* activateCtdTitle({
   payload,
   callback,
}: {
   payload: {
      activeCodes: number[];
      inActiveCodes: number[];
   };
   type: string;
   callback: Function;
}) {
   try {
      const { ctdTitles }: CtdTitleState = yield select(getCtdTitle);
      const data = yield call(() => CtdTitleApi.activateCtdTitle(payload));
      const newCtdTitleList = CtdTitle.changeActiveList(data, ctdTitles);
      yield put(
         activateCtdTitleSuccess({
            activeCtdTitles: newCtdTitleList.filter(
               (title) => title.activeFlag
            ),
            ctdTitles: newCtdTitleList,
         })
      );
      callback(null);
   } catch (error) {
      yield put(ctdTitleFailure(ApiErrorHandler.createErrorObject(error)));
      callback(JSON.parse(error.message), null);
   }
}

export default function* ctdTitleWatcher() {
   // @ts-ignore
   yield takeLatest(ctdTitleTypes.GET_CTD_TITLES, getCtdTitlesList);
   yield takeLatest(ctdTitleTypes.GET_ACTIVE_CTD_TITLE, getActiveCtdTitlesList);
   yield takeLatest(
      ctdTitleTypes.GET_CTD_TITLE_BY_CODE,
      getCtdTitlesListByCode
   );
   yield takeLatest(ctdTitleTypes.ACTIVATE_CTD_TITLE, activateCtdTitle);
}
