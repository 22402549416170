import { handleError, handleResponse } from './apiUtils';

const baseUrl = process.env.REACT_APP_API_URL;

export class FavoriteApi {
   static getAllFavorites() {
      return fetch(`${baseUrl}api/v1/favorites`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static changeProjectFavoriteStatus(id: number, favorite: boolean) {
      return fetch(`${baseUrl}api/v1/favorites/project`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ projectId: id, favorite }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static changeTemplateFavoriteStatus(id: number, favorite: boolean) {
      return fetch(`${baseUrl}api/v1/favorites/modelDocument`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ modelDocumentId: id, favorite }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static changeDocumentFavoriteStatus(id: number, favorite: boolean) {
      return fetch(`${baseUrl}api/v1/favorites/workingDocument`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ workingDocumentId: id, favorite }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }
}
