import { put, call, takeLatest } from 'redux-saga/effects';
import { ConversionApi } from '../../core/api';
import { ConversionStyle } from '../../core/models/conversionStyle/conversionStyle';
import {
   getAllConversionsFailure,
   getAllConversionsSuccess,
   updateConversionsFailure,
   updateConversionsSuccess,
} from '../actions/conversionStyles';
import { conversionTypes } from '../types/conversionStyles';

function* getAllConversions({
   callback,
}: {
   type: string;
   payload: number;
   callback: Function;
}) {
   try {
      const { data }: { data: ConversionStyle[] } = yield call(() =>
         ConversionApi.getAllConverions()
      );
      const result = data.map((style) =>
         ConversionStyle.createConversionStyleObject(style)
      );
      yield put(getAllConversionsSuccess(result));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(getAllConversionsFailure(error));
   }
}

function* updateConversion({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const data: number | boolean = yield call(() =>
         ConversionApi.updateConversion(payload.id, payload.data)
      );
      yield put(updateConversionsSuccess(payload));
      callback(null, data);
   } catch (error) {
      console.log(error);
      callback(JSON.parse(error.message));
      yield put(updateConversionsFailure(error));
   }
}

export default function* conversionStyleWatcher() {
   yield takeLatest(conversionTypes.GET_CONVERSIONS_START, getAllConversions);
   yield takeLatest(conversionTypes.UPDATE_CONVERSION_START, updateConversion);
}
