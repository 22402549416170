import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './assets/css/master.css';
import './assets/css/style.css';
import './assets/scss/global.scss';
import './assets/scss/responsive.scss';
import 'react-tagsinput/react-tagsinput.css';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './stores/store';

const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
   const [resource, config] = args;

   // request interceptor starts
   if (config && config?.headers) {
      (config.headers as Record<string, string>).Authorization =
         localStorage.getItem('access-token') ?? '';
   }
   // console.log('config:', config);
   // request interceptor ends

   // response interceptor here
   return originalFetch(resource, config);
};

ReactDOM.render(
   <Provider store={store}>
      <App />
   </Provider>,
   document.getElementById('root')
);
