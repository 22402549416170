import { Configuration } from '../../core/models/configuration/configuration';
import { configurationTypes } from '../types/configuration';
import { COMPONENT_ERROR } from '../../shared/CONSTANTS';

export const setSessionTimeInterval = (
   payload: { timeout: boolean; interval: number },
   callback: Function
) => ({
   type: configurationTypes.SET_SESSION_TIME_OUT,
   payload,
   callback,
});

export const setSessionTimeIntervalSuccess = (payload: {
   timeout: boolean;
   interval: number;
}) => ({
   type: configurationTypes.SET_SESSION_TIME_OUT_SUCCESS,
   payload,
});

export const setSessionTimeIntervalFailure = (error: COMPONENT_ERROR) => ({
   type: configurationTypes.SET_SESSION_TIME_OUT_FAILURE,
   error,
});

export const getSessionData = (callback: Function = () => {}) => ({
   type: configurationTypes.GET_SESSION_DATA,
   callback,
});

export const getSessionDataSuccess = (payload: Configuration[]) => ({
   type: configurationTypes.GET_SESSION_DATA_SUCCESS,
   payload,
});

export const getSessionDataFailure = (error: COMPONENT_ERROR) => ({
   type: configurationTypes.GET_SESSION_DATA_FAILURE,
   error,
});
