import { handleError, handleResponse } from './apiUtils';

export function getBBCodes() {
   const baseUrl = process.env.REACT_APP_API_URL;

   return fetch(`${baseUrl}api/v1/bbCode`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
   })
      .then(async (response) => {
         const result = await handleResponse(response);
         const bbCodes = result.data;
         return bbCodes;
      })
      .catch(handleError);
}
