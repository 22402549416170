import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class ConversionApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static getAllConverions() {
      return fetch(`${baseUrl}api/v1/conversionStyles`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static updateConversion(
      conversionId: number,
      data: { styles: Record<string, string> }
   ) {
      return fetch(`${baseUrl}api/v1/conversionStyles/${conversionId}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
