import React, { useEffect, useState } from 'react';
import {
   MenuItem,
   ListItemIcon,
   makeStyles,
   Typography,
   Link,
} from '@material-ui/core';
import './styles.scss';
import List from '@material-ui/core/List';
import { Help as HelpIcon } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReactTooltip from 'react-tooltip';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { SideMenuClickedProps } from '../SideMenuClickedPropsInterface';
import menuItems from '../../dataModules/menuItems';
import { GlobalState } from '../../core/models/globalState';
// eslint-disable-next-line import/no-cycle
import { isAdmin } from '../../core/helpers/protected.route';

const useStyles = makeStyles({
   listItemIconRoot: {
      minWidth: 15,
   },
});

/**
 * SideMenuList = left sidebar/menu
 * @param props
 * @constructor
 * @internal the vertical position is calculated and set in /src/index.tsx
 */
function SideMenuList(props: SideMenuClickedProps) {
   const [menuItemsFiltered, setMenuItemsFiltered] = useState<Array<{}>>([]);
   const { isUserReceived } = useSelector((state: GlobalState) => state.user);
   const classes = useStyles();
   const { location } = props;
   const { currentUser } = useSelector((state: GlobalState) => state.user);
   const alwaysAccessibleMenuItems = ['dashboard'];
   const userGuideMenuItem = {
      name: 'USER GUIDE',
      icon: HelpIcon,
      component: Link,
      className: 'user-guide-icon',
      link: '',
      clickHandler: () => props.toggleDrawer(true),
   };

   const isMenuItemActive = (menuItem: any) => {
      let isActive = false;
      if (menuItem.activeLinks) {
         const activeLink = menuItem.activeLinks.findIndex((link: string) =>
            location.pathname.startsWith(link)
         );
         isActive = activeLink !== -1;
      } else {
         isActive = location.pathname === menuItem.link;
      }

      return isActive;
   };

   const renderMenuitem = (item: any) => {
      const isLinkActive = isMenuItemActive(item);
      const IconComponent = item.icon;
      const isNameVisible =
         props.collapseState || props.location.pathname.includes('/edit');

      return (
         <div key={item.name}>
            <MenuItem
               component={item.component || null}
               to={item.link || null}
               style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}
               className={`menu__item ${isLinkActive && 'menu__item--active'} ${
                  item.className || ''
               }`}
               data-tip={item.name}
               onClick={item.clickHandler ? item.clickHandler() : null}
            >
               <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                  <IconComponent
                     style={item.iconStyle || {}}
                     fill={isLinkActive ? '#0066CC' : '#6d6d6d'}
                     fontSize="small"
                  />
               </ListItemIcon>
               <Typography
                  variant="caption"
                  style={{ display: isNameVisible ? 'none' : 'inline-block' }}
                  data-testid={item.name}
               >
                  {item.name}
               </Typography>
               <ArrowForwardIosIcon className="arrow" fontSize="small" />
            </MenuItem>
            {props.collapseState && <ReactTooltip />}
         </div>
      );
   };

   const hasAnyPermissionInArea = (menuItemArea: {
      permissionArea: boolean;
   }): boolean => {
      if (!menuItemArea) {
         return false;
      }

      return Object.values(menuItemArea).some(
         (permissionAttribute: boolean) => permissionAttribute
      );
   };

   useEffect(() => {
      if (currentUser?.role?.permissions) {
         const filteredMenuItems = menuItems.filter(
            (menuItem) =>
               alwaysAccessibleMenuItems.includes(menuItem.area as string) ||
               hasAnyPermissionInArea(
                  // @ts-ignore
                  currentUser.role.permissions.LANDING[menuItem.area]
               ) ||
               menuItem.roles?.includes(currentUser.role?.name.toLowerCase()) ||
               (menuItem.area === 'admin' && isAdmin(currentUser)) // TODO: add APP_ENV === 'local' once env file for SB and Dev have been amended.
         );
         setMenuItemsFiltered(filteredMenuItems);
      }
   }, [isUserReceived, currentUser]);
   return (
      <>
         {isUserReceived ? (
            <div className={`left-menu ${isUserReceived && 'empty'}`}>
               <List component="nav">
                  {menuItemsFiltered.map((item: any) => renderMenuitem(item))}
                  {renderMenuitem(userGuideMenuItem)}
                  {props.collapseState && <ReactTooltip />}
               </List>
            </div>
         ) : (
            <div className="align-loading">
               <CircularProgress value={30} size="40px" />
            </div>
         )}
         <div
            className={`collapse_sidebar ${!props.isShown ? 'd-none' : ''}`}
            onClick={() => props.onSideMenuClicked()}
            aria-hidden="true"
         >
            <List>
               <MenuItem className="menu__item">
                  <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
                     {props.collapseState ? (
                        <DoubleArrowIcon
                           style={{ color: '#0066cc' }}
                           fontSize="small"
                        />
                     ) : (
                        <i
                           className="fas fa-angle-double-left"
                           style={{ color: '#0066cc' }}
                        >
                           {' '}
                        </i>
                     )}
                  </ListItemIcon>
                  <Typography
                     variant="caption"
                     style={{
                        display: props.collapseState ? 'none' : 'initial',
                     }}
                  >
                     COLLAPSE SIDEBAR
                  </Typography>
               </MenuItem>
            </List>
         </div>
      </>
   );
}

export default withRouter(SideMenuList);
