import produce from 'immer';
import { FavoriteState } from '../../core/models/favorites/favoriteState';
import { favoriteTypes } from '../types/favorite';

const {
   GET_ALL_FAVORITES_SUCCESS,
   GET_ALL_FAVORITES_FAILURE,
   CHANGE_DOCUMENT_FAVORITE_SUCCESS,
   CHANGE_DOCUMENT_FAVORITE_FAILURE,
   CHANGE_PROJECT_FAVORITE_SUCCESS,
   CHANGE_PROJECT_FAVORITE_FAILURE,
   CHANGE_TEMPLATE_FAVORITE_SUCCESS,
   CHANGE_TEMPLATE_FAVORITE_FAILURE,
   CLEAR_SUCCESS_MESSAGE,
   CLEAR_ERROR_MESSAGE,
} = favoriteTypes;

const initialState: FavoriteState = {
   favoritesList: {},
   errorMessage: null,
   successMessage: null,
};

export const favorite = (
   state: FavoriteState = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case GET_ALL_FAVORITES_SUCCESS:
            draft.favoritesList = payload;
            draft.errorMessage = null;
            break;
         case GET_ALL_FAVORITES_FAILURE:
            draft.favoritesList = [];
            draft.errorMessage = payload;
            break;
         case CHANGE_DOCUMENT_FAVORITE_SUCCESS:
            draft.successMessage = payload;
            draft.errorMessage = null;
            break;
         case CHANGE_DOCUMENT_FAVORITE_FAILURE:
            draft.successMessage = null;
            draft.errorMessage = payload;
            break;
         case CHANGE_PROJECT_FAVORITE_SUCCESS:
            draft.successMessage = payload;
            draft.errorMessage = null;
            break;
         case CHANGE_PROJECT_FAVORITE_FAILURE:
            draft.errorMessage = payload;
            draft.successMessage = null;
            break;
         case CHANGE_TEMPLATE_FAVORITE_SUCCESS:
            draft.successMessage = payload;
            draft.errorMessage = null;
            break;
         case CHANGE_TEMPLATE_FAVORITE_FAILURE:
            draft.errorMessage = payload;
            draft.successMessage = null;
            break;
         case CLEAR_SUCCESS_MESSAGE:
            draft.successMessage = null;
            break;
         case CLEAR_ERROR_MESSAGE:
            draft.errorMessage = null;
            break;
         default:
            return draft;
      }
      return draft;
   });
