export const DEV_ONLY_UI_TEXT_ALERT =
   'This is shown in development environment only';

export const localUrlWhitelist = [
   'http://localhost:8000/',
   'http://localhost:3000/',
   'http://localhost:8080/',
];

export const dev = {
   UI_TEXT_ALERT: 'This is shown in development environment only',
   isInLocalEnvironment: localUrlWhitelist.includes(
      process.env.REACT_APP_API_URL
   ),
   log: (...msg) => {
      if (dev.isInLocalEnvironment) {
         console.log(...msg);
      }
   },
   warn: (...msg) => {
      if (dev.isInLocalEnvironment) {
         console.warn(...msg);
      }
   },
   dir: (...msg) => {
      if (dev.isInLocalEnvironment) {
         console.dir(...msg);
      }
   },
};
