import { handleError, handleResponse } from './apiUtils';

export class SystemApi {
   public static getSystemTypes() {
      return fetch(`${process.env.REACT_APP_API_URL}api/v1/system`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   public static getSystem(type: string, number: string) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/system/${type}/${number}`,
         {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   public static getSystemNumbers(type: string) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/system/numbers/${type}?systemType=${type}`,
         {
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   public static getAllMolecules() {
      return fetch(`${process.env.REACT_APP_API_URL}api/v1/molecule`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (molecules) => {
            const result = await handleResponse(molecules);
            return result.data;
         })
         .catch(handleError);
   }

   public static getScopes() {
      return fetch(`${process.env.REACT_APP_API_URL}api/v1/scope`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      }).then(async (response) => {
         const result = await handleResponse(response);
         return result.data;
      });
   }

   public static retriveRoNumbers(requestFrom = 'workingDocument') {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/roNumbers?requestFrom=${requestFrom}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   public static getRoNumber(number: string) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/roNumbers/${number}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   public static updateCache() {
      return fetch(`${process.env.REACT_APP_API_URL}api/v1/updateCache`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }
}
