import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { sendSSOCode } from '../../redux/actions/user';
// import './style.css';

function SSOauth() {
   const { search } = useLocation();
   const dispatch = useDispatch();
   const routerHistory = useHistory();
   const [loading, setLoading] = useState(true);
   const code = new URLSearchParams(search).get('code');

   useEffect(() => {
      if (search) {
         dispatch(
            sendSSOCode(code || '', (token: string) => {
               setLoading(false);
               if (token.length) {
                  localStorage.setItem('access-token', token);
               }
               localStorage.setItem('isLoggedIn', String(!!token));
               routerHistory.push('/');
            })
         );
      }
   }, []);

   return (
      <div className="align-loading full-page-loading">
         {loading ? <CircularProgress value={60} size="100px" /> : null}
      </div>
   );
}

export default SSOauth;
