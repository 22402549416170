import { FileInterface } from '../models/fileInfo';
import { ModelDocument } from '../models/modelDocument/modelDocument';
import { DuplicateModelDoc } from '../models/modelDocument/modelDocumentDuplication';
import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class ModelDocumentApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static getModelDocuments({
      page,
      pageSize,
      searchQuery,
   }: {
      page: number;
      pageSize: number;
      searchQuery?: string;
   }) {
      return fetch(
         `${baseUrl}api/v1/template?page=${page}&size=${pageSize}&searchQuery=${searchQuery?.replaceAll(
            'DP & DS',
            'DPandDS'
         )}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (modelDocs) => {
            const result = await handleResponse(modelDocs);
            return result.data;
         })
         .catch(handleError);
   }

   static addModelDocument(data: FileInterface) {
      return fetch(`${baseUrl}api/v1/template`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static updateModelDocument(templateId: number | null, data: ModelDocument) {
      return fetch(`${baseUrl}api/v1/template/${templateId}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getModelDocumentById(id: number) {
      return fetch(`${baseUrl}api/v1/template/${id}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      }).then(async (response) => {
         const result = await handleResponse(response);
         return result.data;
      });
   }

   static archiveModelDocument(documentId: number | null, archived: boolean) {
      return fetch(`${baseUrl}api/v1/template/archive`, {
         credentials: 'include',
         method: 'PATCH',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({
            documentId,
            archived,
         }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static deleteTemplate(templateId: string) {
      return fetch(`${baseUrl}api/v1/template/${templateId}`, {
         credentials: 'include',
         method: 'DELETE',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static updateModelDocumentModifier(templateId: number) {
      return fetch(`${baseUrl}api/v1/template/lastModifiedBy`, {
         credentials: 'include',
         method: 'PATCH',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ id: templateId }),
      }).then(async (response) => {
         const result = await handleResponse(response);
         return result.data;
      });
   }

   static getModelDocumentTypes() {
      return fetch(`${baseUrl}api/v1/template/type`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      }).then(async (response) => {
         const result = await handleResponse(response);
         return result.data;
      });
   }

   static getBaseTemplate() {
      return fetch(`${baseUrl}api/v1/baseTemplate`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (modelDocs) => {
            const result = await handleResponse(modelDocs);
            return result.data;
         })
         .catch(handleError);
   }

   static getModelDocumentFiltersData(query: string | null) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/template/filterData?${query}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getModelDocumentVariables(data: {
      report: string;
      stage: number;
      productArea: number;
      title: string;
      bbCodeType: string;
   }) {
      return fetch(
         `${baseUrl}api/v1/template/variable?report=${data.report}&stage=${
            data.stage
         }&productArea=${data.productArea}&title=${
            data.title
         }&bbCode=${data.bbCodeType.replaceAll('DP & DS', 'DPandDS')}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      ).then(async (response) => {
         const result = await handleResponse(response);
         return result.data;
      });
   }

   static duplicateModelDocument(data: DuplicateModelDoc) {
      return fetch(`${baseUrl}api/v1/template/duplicate/${data.templateId}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ ...data, templateId: undefined }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static publishModelDocument(templateId: number, confirmed: boolean = false) {
      return fetch(`${baseUrl}api/v1/template/publish/`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ templateId, confirmed: !!confirmed }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static withdrawModelDocument(templateId: number) {
      return fetch(`${baseUrl}api/v1/template/withdraw/`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ templateId }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static getModelDocumentStatuses() {
      return fetch(`${baseUrl}api/v1/template/status`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
