export const internalReportTypes = {
   GET_INTERNAL_REPORTS: 'GET_INTERNAL_REPORTS',
   GET_INTERNAL_REPORTS_SUCCESS: 'GET_INTERNAL_REPORTS_SUCCESS',
   GET_ACTIVE_INTERNAL_REPORTS: 'GET_ACTIVE_INTERNAL_REPORTS',
   GET_ACTIVE_INTERNAL_REPORTS_SUCCESS: 'GET_ACTIVE_INTERNAL_REPORTS_SUCCESS',
   CREATE_INTERNAL_REPORTS: 'CREATE_INTERNAL_REPORTS',
   CREATE_INTERNAL_REPORTS_SUCCESS: 'CREATE_INTERNAL_REPORTS_SUCCESS',
   UPDATE_INTERNAL_REPORT: 'UPDATE_INTERNAL_REPORT',
   UPDATE_INTERNAL_REPORT_SUCCESS: 'UPDATE_INTERNAL_REPORT_SUCCESS',
   ACTIVATE_INTERNAL_REPORT: 'ACTIVATE_INTERNAL_REPORT',
   ACTIVATE_INTERNAL_REPORT_SUCCESS: 'ACTIVATE_INTERNAL_REPORT_SUCCESS',
   UPDATE_INTERNAL_REPORT_TITLE: 'UPDATE_INTERNAL_REPORT_TITLE',
   UPDATE_INTERNAL_REPORT_TITLE_SUCCESS: 'UPDATE_INTERNAL_REPORT_TITLE_SUCCESS',
   INTERNAL_REPORT_FAILURE: 'INTERNAL_REPORT_FAILURE',
};
