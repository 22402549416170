import { put, call, takeLatest, select } from 'redux-saga/effects';
import { RoleApi } from '../../core/api';
import { GlobalState } from '../../core/models/globalState';
import { ProjectRoleInterface } from '../../core/models/role/projectRole';
import { Role } from '../../core/models/role/role';
import { RoleInterface } from '../../core/models/role/roleInterface';
import { RoleState } from '../../core/models/role/roleState';
import {
   loadRolesSuccess,
   loadRolesFailure,
   updateUserRoleFailure,
   createUserRoleSuccess,
   createUserRoleFailure,
   updateRolePermissionsSuccess,
   updateRolePermissionsFailure,
   getRoleByIdSuccess,
   getRoleByIdFailure,
   getPermissionsSuccess,
   getPermissionsFailure,
   LOAD_PROJECT_ROLES_SUCCESS,
} from '../actions/role';
import { updateUserSuccess } from '../actions/user';
import { roleTypes } from '../types/role';
import {
   PermissionList,
   PermissionResponseInterface,
} from '../../core/models/permissions/permission';
import { User } from '../../core/models/user/user';
import { UserInterface } from '../../core/models/user/userInterface';
import { COMPONENT_ERROR } from '../../shared/CONSTANTS';

export type RoleCallback = (
   error: COMPONENT_ERROR,
   role: Role | Role[] | null
) => void;

const getRole = (state: GlobalState) => state.role;

export function* loadRoles({
   callback,
}: {
   type: string;
   callback: RoleCallback;
}) {
   try {
      const result: Role[] = yield call(RoleApi.loadRoles);
      const roles = result.map((role) => Role.createRolesObject(role));
      yield put(loadRolesSuccess(roles));
      callback(null, roles);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(loadRolesFailure(error));
   }
}

export function* loadProjectRoles({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const data: ProjectRoleInterface[] = yield call(RoleApi.loadProjectRoles);
      yield put(LOAD_PROJECT_ROLES_SUCCESS(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(loadRolesFailure(error));
   }
}

function* updateRole({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const result: User = yield call(
         RoleApi.updateUserRole,
         payload.userId,
         payload.body
      );
      const updatedUser = User.createUserObject(result);
      // yield put(updateUserRoleSuccess(updatedUser));
      const roleState: RoleState = yield select(getRole);
      const newRole =
         roleState.roles.find(
            (role: RoleInterface) =>
               Number(role.id) === Number(updatedUser.role.id)
         ) || Role.createRolesObject(updatedUser.role || {});
      const user: UserInterface = {
         ...updatedUser,
         role: newRole,
      };
      yield put(updateUserSuccess(user));
      callback(null, user);
   } catch (error) {
      yield put(updateUserRoleFailure({ message: 'Role not updated' }));
      callback(JSON.parse(error.message), null);
      yield put(updateUserRoleFailure(error.response.data));
   }
}

export function* createNewRole({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const { data }: { data: Role } = yield call(
         RoleApi.createNewRole,
         payload
      );
      yield put(createUserRoleSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(createUserRoleFailure(error));
   }
}

function* updateRolePermissions({ payload, callback }: any) {
   try {
      const response: { code: number; data: RoleInterface } = yield call(
         RoleApi.changeRolePermissions,
         payload
      );
      if (response && response.code === 200) {
         yield put(updateRolePermissionsSuccess(response.data));
         callback(null, response.data);
      } else {
         yield put(
            updateRolePermissionsFailure({ message: 'Role not updated' })
         );
      }
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(updateRolePermissionsFailure(error));
   }
}

export function* getRoleById({
   payload,
   callback,
}: {
   type: string;
   payload: number;
   callback: Function;
}) {
   try {
      const response: { code: number; data: RoleInterface } = yield call(
         RoleApi.getRoleById,
         payload
      );
      yield put(getRoleByIdSuccess(response.data));
      callback(null, response.data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getRoleByIdFailure(error));
   }
}

function* getPermissions({ callback }: { type: string; callback: Function }) {
   try {
      const {
         data,
      }: {
         data: { permissions: PermissionResponseInterface }[];
      } = yield call(() => RoleApi.getPermissions());
      const permissions = PermissionList.createPermissionListobject(data[0]);
      yield put(getPermissionsSuccess(permissions));
      callback(null, permissions);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getPermissionsFailure(error));
   }
}

export default function* roleWatcher() {
   yield takeLatest(roleTypes.LOAD_ROLES, loadRoles);
   yield takeLatest(roleTypes.UPDATE_USER_ROLE, updateRole);
   yield takeLatest(roleTypes.CREATE_USER_ROLE, createNewRole);
   yield takeLatest(roleTypes.UPDATE_ROLE_PERMISSIONS, updateRolePermissions);
   yield takeLatest(roleTypes.GET_ROLE_BY_ID, getRoleById);
   yield takeLatest(roleTypes.LOAD_PROJECT_ROLES, loadProjectRoles);
   yield takeLatest(roleTypes.LOAD_PERMISSIONS, getPermissions);
}
