import { put, call, takeLatest } from 'redux-saga/effects';
import { UserApi } from '../../core/api';
import { ApiErrorHandler } from '../../core/api/apiUtils';
import { User } from '../../core/models/user/user';
import { UserInterface } from '../../core/models/user/userInterface';
import {
   getUserFailure,
   getCurrentUserSuccess,
   addUserSuccess,
   getAllUsersSuccess,
   getCurrentUser as getCurrentUserAction,
   updateUserSuccess,
   LOG_OUT_USER_ACTION_FAILURE,
   LOG_OUT_USER_ACTION_SUCCESS,
} from '../actions/user';
import { userTypes } from '../types/user';

function* sendSSOCode(action: {
   payload: string;
   type: string;
   callback: Function;
}) {
   try {
      const result: { data: string } = yield call(
         UserApi.sendSSOCode,
         action.payload
      );
      action.callback(result.data);
   } catch (error) {
      console.log(error);
      action.callback();
   }
}

function* getCurrentUser(action: any) {
   try {
      const currentUser: UserInterface = yield call(UserApi.getUserInfo);
      yield put(getCurrentUserSuccess(currentUser));
      action.callback(null, currentUser);
   } catch (error) {
      yield put(
         getUserFailure(ApiErrorHandler.createErrorObject(error).message)
      );
      action.callback(JSON.parse(error.message), null);
   }
}

function* addUser({
   payload,
   callback,
}: {
   payload: UserInterface;
   type: string;
   callback: Function;
}) {
   try {
      const newUser: UserInterface = yield call(() => UserApi.add(payload));
      yield put(getCurrentUserAction());
      yield put(addUserSuccess(newUser));
      callback(null, newUser);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(getUserFailure(error));
   }
}

function* loginUser({
   payload,
   callback,
}: {
   payload: { emailAddress: string; password: string };
   type: string;
   callback: Function;
}) {
   try {
      const result: { data: UserInterface } = yield call(() =>
         UserApi.login(payload)
      );
      callback(null, result.data);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(getUserFailure(error));
   }
}

function* getAllUsers({ callback }: { type: string; callback: Function }) {
   try {
      const result: UserInterface[] = yield call(() => UserApi.getAllUsers());
      const users = result.map((user: UserInterface) =>
         User.createUserObject(user)
      );
      yield put(getAllUsersSuccess(users));
      callback(null, users);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(getUserFailure(error));
   }
}

function* updateUser({
   payload,
   callback,
}: {
   type: string;
   payload: UserInterface;
   callback: Function;
}) {
   try {
      const updatedUser: UserInterface = yield call(() =>
         UserApi.updateUser(payload)
      );
      yield put(updateUserSuccess(updatedUser));
      callback();
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(getUserFailure(error));
   }
}

function* logoutUser({
   payload,
   callback,
}: {
   type: string;
   payload: UserInterface;
   callback: Function;
}) {
   try {
      const data: UserInterface = yield call(() => UserApi.logout(payload));
      yield put(LOG_OUT_USER_ACTION_SUCCESS(payload));
      callback(null, data);
   } catch (error) {
      yield put(LOG_OUT_USER_ACTION_FAILURE(error));
      callback(JSON.parse(error.message));
   }
}

export default function* userWatcher() {
   yield takeLatest(userTypes.GET_CURRENT_USER, getCurrentUser);
   yield takeLatest(userTypes.ADD_USER, addUser);
   yield takeLatest(userTypes.LOGIN_USER, loginUser);
   yield takeLatest(userTypes.GET_ALL_USERS, getAllUsers);
   yield takeLatest(userTypes.UPDATE_USER, updateUser);
   yield takeLatest(userTypes.LOG_OUT_USER, logoutUser);
   yield takeLatest(userTypes.SEND_SSO_CODE, sendSSOCode);
}
