import produce from 'immer';
import { Role } from '../../core/models/role/role';
import { RoleState } from '../../core/models/role/roleState';
import { roleTypes } from '../types/role';
import { PermissionList } from '../../core/models/permissions/permission';

const initialState: RoleState = {
   roles: [],
   isUpdateRoleSuccess: false,
   isUpdateRoleFailure: false,
   updatedRole: {},
   isCreateNewRoleSuccess: false,
   isCreateNewRleFailure: false,
   createdRole: {},
   isUpdateRolePermissionsSuccess: false,
   isUpdateRolePermissionsFailure: false,
   updatedRolePermissions: {},
   isGetRoleByIdSuccess: false,
   isGetRoleByIdFailure: false,
   selectedRole: {
      id: '',
      title: '',
      name: '',
      description: '',
      permissionsCount: 0,
      createdAt: '',
      createdDate: '',
      modifiedDate: '',
      permissions: {
         FONTO: {
            template: {
               create: false,
               edit: false,
               remove: false,
               view: false,
               download: false,
               transfer: false,
            },
            working_document: {
               create: false,
               edit: false,
               remove: false,
               view: false,
               download: false,
               transfer: false,
            },
         },
         LANDING: {
            generated_document: {
               create: false,
               edit: false,
               remove: false,
               view: false,
               download: false,
               transfer: false,
            },
            template: {
               create: false,
               edit: false,
               remove: false,
               view: false,
               download: false,
               transfer: false,
            },
            project: {
               create: false,
               edit: false,
               remove: false,
               view: false,
               download: false,
               transfer: false,
            },
            working_document: {
               create: false,
               edit: false,
               remove: false,
               view: false,
               download: false,
               transfer: false,
            },
         },
         LOOKER: {
            query: {
               create: false,
               edit: false,
               remove: false,
               view: false,
               download: false,
               transfer: false,
            },
         },
      },
   },
   getRoleByIdData: new Role(),
   projectRoles: [],
   permissionsList: new PermissionList(),
};

const {
   LOAD_ROLES,
   LOAD_ROLES_SUCCESS,
   LOAD_ROLES_FAILURE,
   UPDATE_USER_ROLE,
   UPDATE_USER_ROLE_SUCCESS,
   UPDATE_USER_ROLE_FAILURE,
   CREATE_USER_ROLE,
   CREATE_USER_ROLE_SUCCESS,
   CREATE_USER_ROLE_FAILURE,
   UPDATE_ROLE_PERMISSIONS,
   UPDATE_ROLE_PERMISSIONS_SUCCESS,
   UPDATE_ROLE_PERMISSIONS_FAILURE,
   GET_ROLE_BY_ID,
   GET_ROLE_BY_ID_SUCCESS,
   GET_ROLE_BY_ID_FAILURE,
} = roleTypes;

export const role = (
   state: RoleState = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case LOAD_ROLES:
            draft.roles = payload;
            break;
         case LOAD_ROLES_SUCCESS:
            draft.roles = payload;
            break;
         case LOAD_ROLES_FAILURE:
            draft.roles = [];
            break;
         case UPDATE_USER_ROLE:
            draft.isUpdateRoleSuccess = false;
            draft.isUpdateRoleFailure = false;
            break;
         case UPDATE_USER_ROLE_SUCCESS:
            draft.isUpdateRoleSuccess = true;
            draft.isUpdateRoleFailure = false;
            draft.updatedRole = payload;
            break;
         case UPDATE_USER_ROLE_FAILURE:
            draft.isUpdateRoleSuccess = false;
            draft.isUpdateRoleFailure = true;
            draft.updatedRole = payload;
            break;
         case CREATE_USER_ROLE:
            draft.isCreateNewRoleSuccess = false;
            draft.isCreateNewRleFailure = false;
            break;
         case CREATE_USER_ROLE_SUCCESS:
            draft.isCreateNewRoleSuccess = true;
            draft.isCreateNewRleFailure = false;
            draft.createdRole = payload;
            draft.roles.push(payload);
            break;
         case CREATE_USER_ROLE_FAILURE:
            draft.isCreateNewRoleSuccess = false;
            draft.isCreateNewRleFailure = true;
            draft.createdRole = payload;
            break;
         case UPDATE_ROLE_PERMISSIONS:
            draft.isUpdateRolePermissionsSuccess = false;
            draft.isUpdateRolePermissionsFailure = false;
            break;
         case UPDATE_ROLE_PERMISSIONS_SUCCESS:
            {
               draft.roles = state.roles;
               draft.isUpdateRolePermissionsSuccess = true;
               draft.isUpdateRolePermissionsFailure = false;
               draft.updatedRolePermissions = payload;
               const updatedRoleIndex = state.roles.findIndex(
                  (role) => role.id === payload.data?.id
               );
               draft.roles = [...state.roles];
               draft.roles[updatedRoleIndex] = payload;
            }
            break;
         case UPDATE_ROLE_PERMISSIONS_FAILURE:
            draft.isUpdateRolePermissionsSuccess = false;
            draft.isUpdateRolePermissionsFailure = true;
            draft.updatedRolePermissions = payload;
            break;
         case GET_ROLE_BY_ID:
            draft.isGetRoleByIdSuccess = false;
            draft.isGetRoleByIdFailure = false;
            break;
         case GET_ROLE_BY_ID_SUCCESS:
            draft.isGetRoleByIdSuccess = true;
            draft.isGetRoleByIdFailure = false;
            draft.getRoleByIdData = payload;
            draft.selectedRole = payload;
            break;
         case GET_ROLE_BY_ID_FAILURE:
            draft.isGetRoleByIdSuccess = false;
            draft.isGetRoleByIdFailure = true;
            draft.getRoleByIdData = payload;
            break;
         case roleTypes.CLEAR_ROLE_STATE:
            draft = { ...initialState };
            break;
         case roleTypes.LOAD_PROJECT_ROLES_SUCCESS:
            draft.projectRoles = payload;
            break;
         case roleTypes.LOAD_PERMISSIONS_SUCCESS:
            draft.permissionsList = payload;
            break;
         default:
            return draft;
      }
      return draft;
   });
