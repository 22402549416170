import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DashboardIcon(props: any) {
   return (
      <SvgIcon {...props} viewBox="0 0 443.7 426.5">
         <path d="M394.9,426.3H49c-26,0-47.1-21.1-47.1-47.1L2,47.4c0-26,21.1-47.1,47.1-47.1H395c26,0,47.1,21.1,47.1,47.1v331.9C442,405.3,420.9,426.3,394.9,426.3z M416,77.3H27v285h389V77.3z" />
         <path d="M175,147.3H53c-5.5,0-10-4.5-10-10v-33c0-5.5,4.5-10,10-10h122c5.5,0,10,4.5,10,10v33C185,142.9,180.5,147.3,175,147.3z" />
         <path d="M175,247.3H53c-5.5,0-10-4.5-10-10v-33c0-5.5,4.5-10,10-10h122c5.5,0,10,4.5,10,10v33C185,242.9,180.5,247.3,175,247.3z" />
         <path d="M175,344.3H53c-5.5,0-10-4.5-10-10v-33c0-5.5,4.5-10,10-10h122c5.5,0,10,4.5,10,10v33C185,339.9,180.5,344.3,175,344.3z" />
         <path d="M390,147.3H216c-5.5,0-10-4.5-10-10v-33c0-5.5,4.5-10,10-10h174c5.5,0,10,4.5,10,10v33C400,142.9,395.5,147.3,390,147.3z" />
         <path d="M390,247.3H216c-5.5,0-10-4.5-10-10v-33c0-5.5,4.5-10,10-10h174c5.5,0,10,4.5,10,10v33C400,242.9,395.5,247.3,390,247.3z" />
         <path d="M390,344.3H216c-5.5,0-10-4.5-10-10v-33c0-5.5,4.5-10,10-10h174c5.5,0,10,4.5,10,10v33C400,339.9,395.5,344.3,390,344.3z" />
      </SvgIcon>
   );
}
