import { ComponentType, lazy } from 'react';

const lazyRetry = (componentImport: any): Promise<any> => {
   return new Promise((resolve, reject) => {
      const hasRefreshed = JSON.parse(
         window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
      );
      // try to import the component
      componentImport()
         .then((component: any) => {
            resolve(component);
         })
         .catch((error: any) => {
            if (!hasRefreshed) {
               // not been refreshed yet
               window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
               return window.location.reload(); // refresh the page
            }
            reject(error); // there was an error
         });
   });
};

const Dashboard = lazy(() =>
   lazyRetry(() => import('./pages/Dashboard/Dashboard'))
);
const Editor = lazy(() => lazyRetry(() => import('./pages/FontoView/editor')));
const DocumentView = lazy(() =>
   lazyRetry(() => import('./pages/DocumentView/DocumentView'))
);
const AuthorDocument = lazy(() =>
   lazyRetry(() => import('./pages/Author Document/AuthorDocuments'))
);
const TemplateView = lazy(() => import('./pages/TemplateView/TemplateView'));
const ManageTemplates = lazy(() =>
   lazyRetry(() => import('./pages/Managetemplate/Managetemplate'))
);
const Template = lazy(() =>
   lazyRetry(() => import('./pages/NewTemplate/Template'))
);
const Document = lazy(() =>
   lazyRetry(() => import('./pages/NewDocument/Document'))
);
const Project = lazy(() => lazyRetry(() => import('./pages/Project/Project')));
const NewProject = lazy(() =>
   lazyRetry(() => import('./pages/NewProject/NewProject'))
);
const AllProjects = lazy(() =>
   lazyRetry(() => import('./pages/AllProjects/AllProjects'))
);
const GeneratedMetadataTransfer = lazy(() =>
   lazyRetry(() =>
      import('./pages/GeneratedMetadataTransfer/GeneratedMetadataTransfer')
   )
);
const GeneratedOutputView = lazy(() =>
   lazyRetry(() => import('./pages/GeneratedOutputView/GeneratedOutputView'))
);
const Administration = lazy(() =>
   lazyRetry(() => import('./pages/Administration/Administrations'))
);
const ManageRoles = lazy(() =>
   lazyRetry(() => import('./pages/Administration/manageRoles/ManageRoles'))
);
const Permissions = lazy(() =>
   lazyRetry(() => import('./pages/Administration/permissions/Permission'))
);
const ManageCTDs = lazy(() =>
   lazyRetry(() => import('./pages/Administration/manageCTDs/ManageCTDs'))
);
const FontoGlobalSettings = lazy(() =>
   lazyRetry(() =>
      import('./pages/Administration/fontoGlobalSettings/FontoGlobalSettings')
   )
);
const StyleMapping = lazy(() =>
   lazyRetry(() => import('./pages/Administration/styleMapping/StyleMapping'))
);
const NewStyleMapping = lazy(() =>
   lazyRetry(() =>
      import('./pages/Administration/newStyleMapping/newStyleMapping')
   )
);
const InternalReport = lazy(() =>
   lazyRetry(() =>
      import('./pages/Administration/internalReport/InternalReport')
   )
);
const VariablesManagement = lazy(() =>
   lazyRetry(() => import('./pages/VariablesManagement/VariablesManagement'))
);
const NewVariable = lazy(() =>
   lazyRetry(() => import('./pages/VariablesManagement/NewVariable'))
);

interface RouteType {
   id: number;
   path: string;
   component: ComponentType<any>;
   name: string;
   adminOnly?: boolean;
   adminOrModelDocManager?: boolean;
   exact?: boolean;
   permission?: string;
}

const routes: RouteType[] = [
   {
      id: 1,
      path: '/layout/dashboard',
      component: Dashboard,
      name: 'Dashboard',
   },
   {
      id: 2,
      path: '/layout/authordocument/edit/:id',
      component: Editor,
      name: 'Document Editor',
      exact: true,
      permission: 'LANDING.working_document.edit',
   },
   {
      id: 3,
      path: '/layout/authordocument/:id',
      component: DocumentView,
      name: 'Document View',
      exact: true,
      permission: 'LANDING.working_document.view',
   },
   {
      id: 4,
      path: '/layout/authordocument',
      component: AuthorDocument,
      name: 'Author Document',
      exact: true,
      permission: 'LANDING.working_document.view',
   },
   {
      id: 5,
      path: '/layout/managetemplate/:id',
      component: TemplateView,
      name: 'Template View',
      permission: 'LANDING.template.view',
   },
   {
      id: 6,
      path: '/layout/managetemplate',
      component: ManageTemplates,
      name: 'Manage Templates',
      permission: 'LANDING.template.view',
   },
   {
      id: 7,
      path: '/layout/new_template',
      component: Template,
      name: 'New Template',
      permission: 'LANDING.template.create',
   },
   {
      id: 8,
      path: '/layout/new_document',
      component: Document,
      name: 'New Document',
      permission: 'LANDING.working_document.create',
   },
   {
      id: 9,
      path: '/layout/update_document/:id',
      component: Document,
      name: 'Update Document',
      permission: 'LANDING.working_document.edit',
   },
   {
      id: 10,
      path: '/layout/project_edit/:id',
      component: NewProject,
      name: 'Edit Project',
      permission: 'LANDING.project.edit',
   },
   {
      id: 11,
      path: '/layout/project',
      component: Project,
      name: 'Project',
      permission: 'LANDING.project.view',
   },
   {
      id: 12,
      path: '/layout/new_project',
      component: NewProject,
      name: 'New Project',
      permission: 'LANDING.project.create',
   },
   // {
   //     id: 14,
   //     path: 'add-tutorial',
   //     component: AddTutorial,
   //     name: 'Add Tutorial',
   //     isShownInTutorials: false,
   // },
   {
      id: 15,
      path: '/layout/projects',
      component: AllProjects,
      name: 'Projects',
      permission: 'LANDING.project.view',
   },
   // {
   //     id: 16,
   //     path: 'tutorials',
   //     component: ManageTutorials,
   //     name: 'Tutorials'
   // }
   {
      id: 17,
      path: '/layout/generated-output',
      component: GeneratedOutputView,
      name: 'Generated Document',
      permission: 'LANDING.generated_document.view',
   },
   {
      id: 18,
      path: '/layout/generated-transfer/:id',
      component: GeneratedMetadataTransfer,
      name: 'Generated Document Transfer',
      permission: 'LANDING.generated_document.edit',
   },
   {
      id: 19,
      path: '/administration/manage-roles',
      component: ManageRoles,
      name: 'Administration',
      adminOnly: true,
   },
   {
      id: 24,
      path: '/administration/permissions',
      component: Permissions,
      name: 'Manage Permissions',
      adminOnly: true,
   },
   {
      id: 25,
      path: '/administration/change-permissions/:id',
      component: Permissions,
      name: 'Change Permissions',
      adminOnly: true,
   },
   {
      id: 23,
      path: '/administration/manage-ctd',
      component: ManageCTDs,
      name: 'Manage CTDs',
      adminOnly: true,
   },
   {
      id: 26,
      path: '/administration/configurations',
      component: FontoGlobalSettings,
      name: 'Set configurations',
      adminOnly: true,
   },
   {
      id: 27,
      path: '/administration/style-mapping',
      component: StyleMapping,
      name: 'Style mapping',
      adminOnly: true,
   },
   {
      id: 28,
      path: '/administration/new-style-mapping',
      component: NewStyleMapping,
      name: 'New style mapping',
      adminOnly: true,
      exact: true,
   },
   {
      id: 29,
      path: '/administration/new-style-mapping/:id',
      component: NewStyleMapping,
      name: 'Edit style mapping',
      adminOnly: true,
   },
   {
      id: 30,
      path: '/administration/internal-report',
      component: InternalReport,
      name: 'Set configurations',
      adminOnly: true,
   },
   {
      id: 20,
      path: '/administration',
      component: Administration,
      name: 'Administration',
      adminOnly: true,
      exact: true,
   },
   {
      id: 21,
      path: '/layout/update_template/:id',
      component: Template,
      name: 'Update Template',
      permission: 'LANDING.template.edit',
   },
   {
      id: 22,
      path: '/layout/modeldocument/edit/:id',
      component: Editor,
      name: 'Document Editor',
      permission: 'LANDING.template.edit',
   },
   {
      id: 31,
      path: '/layout/manage-variables',
      component: VariablesManagement,
      name: 'Variables Management',
      adminOrModelDocManager: true,
      exact: true,
   },
   {
      id: 32,
      path: '/layout/new-variable',
      component: NewVariable,
      name: 'New Variable',
      adminOrModelDocManager: true,
      exact: true,
   },
   {
      id: 33,
      path: '/layout/update-variable/:id',
      component: NewVariable,
      name: 'Update Variable',
      adminOrModelDocManager: true,
   },
];

export default routes;
