import produce from 'immer';
import { Project } from '../../core/models/project/project';
import { ProjectState } from '../../core/models/project/projectState';
import { projectTypes } from '../types/project';

const initialState: ProjectState = {
   projects: [],
   assignedProjects: [],
   projectsWithWorkingDocs: [],
   project: new Project(),
   projectsCount: 0,
   isFullyLoaded: false,
   error: null,
   divisions: [],
   reusedDocuments: [],
   projectFilters: [],
   areProjectsLoadedSuccessfully: false,
   isProjectDeletedSuccessfully: false,
};

export const project = (
   state = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case projectTypes.GET_ALL_PROJECTS:
            draft.areProjectsLoadedSuccessfully = false;
            return draft;
         case projectTypes.GET_ALL_PROJECTS_SUCCESS:
            if (payload.includeWorkingDocuments) {
               draft.projectsWithWorkingDocs = payload.rows.map(
                  (newProjectItem: Project) =>
                     Project.createProjectObject(newProjectItem)
               );
            } else {
               draft.projects = payload.rows.map((newProjectItem: Project) =>
                  Project.createProjectObject(newProjectItem)
               );
            }

            draft.projectsCount = payload.count;
            draft.areProjectsLoadedSuccessfully = true;
            draft.isProjectDeletedSuccessfully = false;
            return draft;
         case projectTypes.GET_CURRENT_PROJECT_SUCCESS:
            draft.project = payload;
            return draft;

         case projectTypes.GET_ALL_ASSIGNED_PROJECTS_SUCCESS:
            draft.assignedProjects = payload.map((projectItem: Project) =>
               Project.createProjectObject(projectItem)
            );
            draft.isFullyLoaded = true;
            return draft;

         case projectTypes.ADD_PROJECT_SUCCESS: {
            const projectItem = Project.createProjectObject(payload);
            draft.projects.unshift(projectItem);
            draft.assignedProjects.unshift(projectItem);
            draft.projectsWithWorkingDocs.unshift(projectItem);
            return draft;
         }
         case projectTypes.UPDATE_PROJECT_SUCCESS: {
            const index = draft.projects.findIndex(
               (projectItem) => projectItem.id === payload.id
            );
            const assignedIndex = draft.assignedProjects.findIndex(
               (projectItem) => projectItem.id === payload.id
            );
            const newProject = Project.createProjectObject(payload);

            if (index !== -1) {
               draft.projects[index] = { ...newProject };
               draft.projects.sort(
                  (project: Project, nextProject: Project) =>
                     new Date(`${nextProject.updatedAt}`).getTime() -
                     new Date(`${project.updatedAt}`).getTime()
               );
            }

            if (assignedIndex !== -1) {
               draft.assignedProjects[assignedIndex] = { ...newProject };
               draft.assignedProjects.sort(
                  (project: Project, nextProject: Project) =>
                     new Date(`${nextProject.updatedAt}`).getTime() -
                     new Date(`${project.updatedAt}`).getTime()
               );
            }
            draft.project = { ...newProject };
            return draft;
         }
         case projectTypes.UPDATE_PROJECT_FAVORITE_SUCCESS: {
            const index = draft.projects.findIndex(
               (projectItem) => projectItem.id === payload.id
            );
            const assignedIndex = draft.assignedProjects.findIndex(
               (projectItem) => projectItem.id === payload.id
            );
            const newProject = {
               ...draft.projects[index],
               isFavorite: payload.isFavorite,
            };

            if (index !== -1) {
               draft.projects[index] = { ...newProject };
            }

            if (assignedIndex !== -1) {
               draft.assignedProjects[assignedIndex] = { ...newProject };
            }
            return draft;
         }
         case projectTypes.DELETE_PROJECT_SUCCESS: {
            draft.projects = draft.projects.filter(
               (projectItem) => projectItem.id !== payload
            );
            draft.assignedProjects = draft.assignedProjects.filter(
               (projectItem) => projectItem.id !== payload
            );
            return draft;
         }
         case projectTypes.CREATE_DOCUMENT_IN_PROJECT_SUCCESS: {
            const index = draft.projectsWithWorkingDocs.findIndex(
               (projectItem) => projectItem.id === Number(payload.projectId)
            );
            const assignedIndex = draft.assignedProjects.findIndex(
               (projectItem) => projectItem.id === Number(payload.projectId)
            );
            if (
               draft.projectsWithWorkingDocs.length > 0 &&
               draft.projectsWithWorkingDocs[index]
            ) {
               draft.projectsWithWorkingDocs[index].workingDocuments.push(
                  payload.document
               );
            }
            if (
               draft.assignedProjects.length > 0 &&
               draft.assignedProjects[assignedIndex]
            ) {
               draft.assignedProjects[assignedIndex].workingDocuments.push(
                  payload.document
               );
            }

            if (draft.project?.id === Number(payload.projectId)) {
               draft.project.workingDocuments.push(payload.document);
            }
            return draft;
         }
         case projectTypes.ARCHIVE_PROJECT_SUCCESS: {
            const { projectId, data } = payload;
            draft.projects = draft.projects.filter(
               (projectItem) => projectItem.id !== projectId
            );
            draft.assignedProjects = draft.assignedProjects.filter(
               (projectItem) => projectItem.id !== projectId
            );
            draft.projectsWithWorkingDocs = draft.projectsWithWorkingDocs.filter(
               (projectItem) => projectItem.id !== projectId
            );

            const reusedDocuments =
               data.archiveDocumentsResponse?.documentNotAllowedToArchive;

            if (reusedDocuments?.length > 0) {
               const documentProjects = reusedDocuments
                  .map((document: any) => document.projects)
                  .flat();

               draft.reusedDocuments = documentProjects.filter(
                  (value: any, index: number, self: any) =>
                     index ===
                     self.findIndex((item: any) => item.id === value.id)
               );
            }

            draft.projectsCount -= 1;
            draft.isProjectDeletedSuccessfully = true;
            return draft;
         }
         case projectTypes.DELETE_DOCUMENT_IN_PROJECT_SUCCESS: {
            const { projectId, documentId } = payload;

            const index = draft.projectsWithWorkingDocs.findIndex(
               (projectItem) => projectItem.id === Number(projectId)
            );

            draft.project.workingDocuments = draft.project.workingDocuments.filter(
               (document) => document.id !== documentId
            );

            if (index !== -1) {
               draft.projectsWithWorkingDocs[
                  index
               ].workingDocuments = draft.projectsWithWorkingDocs[
                  index
               ].workingDocuments.filter(
                  (document) => document.id !== documentId
               );
            }
            const assignedIndex = draft.assignedProjects.findIndex(
               (projectItem) => projectItem.id === Number(projectId)
            );
            if (assignedIndex !== -1) {
               draft.assignedProjects[
                  assignedIndex
               ].workingDocuments = draft.assignedProjects[
                  assignedIndex
               ].workingDocuments.filter(
                  (document) => document.id !== documentId
               );
            }
            return draft;
         }
         case projectTypes.UNASSIGN_DOCUMENT_SUCCESS: {
            draft.project.workingDocuments = draft.project.workingDocuments.filter(
               (wd) => wd.id !== payload.documentId
            );

            const projectIndex = draft.projects.findIndex(
               (project) => project.id === payload.projectId
            );
            if (projectIndex > -1) {
               draft.projects[
                  projectIndex
               ].workingDocuments = draft.project.workingDocuments.filter(
                  (wd) => wd.id !== payload.documentId
               );
            }

            return draft;
         }
         case projectTypes.CLEAR_PROJECT_STATE:
            draft = { ...initialState };
            return draft;
         case projectTypes.GET_DIVISIONS_SUCCESS:
            draft.divisions = payload;
            return draft;
         case projectTypes.GET_DIVISIONS_FAILURE:
            draft.divisions = [];
            return draft;
         case projectTypes.CLEAR_DOCUMENTS_STATE:
            draft.reusedDocuments = [];
            return draft;
         case projectTypes.GET_PROJECT_FILTER_DATA_SUCCESS:
            draft.projectFilters = payload;
            return draft;
         case projectTypes.GET_PROJECT_FILTER_DATA_FAILURE:
            draft.projectFilters = [];
            return draft;
         default:
            return draft;
      }
   });
