import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function BolderClose(props: any) {
   return (
      <SvgIcon {...props} viewBox="0 0 20 20">
         <line
            x1="4"
            y1="4"
            x2="16"
            y2="16"
            strokeLinecap="round"
            style={{ strokeWidth: 5 }}
         />
         <line
            x1="16"
            y1="4"
            x2="4"
            y2="16"
            strokeLinecap="round"
            style={{ strokeWidth: 5 }}
         />
      </SvgIcon>
   );
}
