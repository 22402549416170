import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { WorkingDocumentApi } from '../../core/api';
import { DocumentMetadata } from '../../core/models/documentMetadata';
import {
   WorkingDocument,
   WorkingDocumentCallback,
} from '../../core/models/workingDocument/workingDocument';
import {
   workingDocumentFailure,
   getPublicWorkingDocumentSuccess,
   getAssignWorkingDocumentSuccess,
   deleteWorkingDocumentSuccess,
   clearWorkingDocumentPlaceHolders,
   getBreezeMetadataSuccess,
   updateDocumentSuccess,
   updateWorkingDocumentSuccess,
   archiveWorkingDocumentSuccess,
   archiveWorkingDocumentFailure,
   getWorkingDocumentSuccess,
   getWorkingDocumentByIdSuccess,
   getCountriesSuccess,
   getDocumentFilterDataSuccess,
   getDocumentFilterDataFailure,
   getVariableValuesSuccess,
   getVariableValuesFailure,
   getAutogeneratedVariablesValuesSuccess,
   getDocumentByDriveIdSuccess,
} from '../actions/workingDocument';
import { workingDocumentTypes } from '../types/workingDocument';
import { createDocumentInProjectSuccess } from '../actions/project';
import * as CONSTANTS from '../../shared/CONSTANTS';
import { ApiErrorHandler } from '../../core/api/apiUtils';
import { isJson } from '../../shared/helpers';
import { BreezeWorkingDocument } from '../../core/models/workingDocument/breezeWorkingDocument';
import {
   getDocument,
   triggerBreezeWithUserSelections,
   updateBqTablePlotMetadataData,
} from '../../core/api/breeze.api';
import { BreezeMetadata } from '../../core/models/workingDocument/breezeMetadata/breezeMetadata';
import { IBreezeMetadata } from '../../core/models/workingDocument/breezeMetadata/breezeMetadataInterface';
import { ModelDocumentCallback } from '../../core/models/modelDocument/modelDocument';

function* getPublicWorkingDocuments({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const { page, size, searchQuery } = payload;
      const result: {
         count: number;
         rows: WorkingDocument[];
      } = yield call(() =>
         WorkingDocumentApi.getPublicDocuments(page, size, searchQuery)
      );
      const workingDocuments = result.rows.map((document: WorkingDocument) =>
         WorkingDocument.createWorkingDocumentObject(document)
      );
      yield put(
         getPublicWorkingDocumentSuccess({
            workingDocuments,
            count: result.count,
         })
      );
      callback(null, workingDocuments, result.count);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* getAssignDocuments({
   payload,
   callback,
}: {
   type: string;
   payload: { page: number; size: number };
   callback: Function;
}) {
   try {
      const { page, size } = payload;
      const result: {
         count: number;
         rows: WorkingDocument[];
      } = yield call(() => WorkingDocumentApi.getAssignDocuments(page, size));
      const workingDocuments = result.rows.map((document: WorkingDocument) =>
         WorkingDocument.createWorkingDocumentObject(document)
      );
      yield put(getAssignWorkingDocumentSuccess(workingDocuments));
      callback(null, workingDocuments, result.count);
   } catch (error) {
      if (isJson(error.message)) {
         callback(JSON.parse(error.message));
      }
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* getWorkingDocuments({
   payload,
   callback,
}: {
   type: string;
   payload: { page: number; size: number; searchQuery: string };
   callback: Function;
}) {
   try {
      const { page, size, searchQuery } = payload;
      const result: {
         count: number;
         rows: WorkingDocument[];
      } = yield call(() =>
         WorkingDocumentApi.getWorkingDocuments(page, size, searchQuery)
      );

      const workingDocuments = result.rows.map((document: WorkingDocument) =>
         WorkingDocument.createWorkingDocumentObject(document)
      );
      yield put(
         getWorkingDocumentSuccess({ workingDocuments, count: result.count })
      );
      callback(null, workingDocuments, result.count);
   } catch (error) {
      if (isJson(error.message)) {
         callback(JSON.parse(error.message));
      }
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* addNewDocument({
   payload,
   callback,
}: {
   type: string;
   payload: DocumentMetadata | BreezeWorkingDocument;
   callback: Function;
}) {
   try {
      const newWorkingDocument: WorkingDocument = yield call(() =>
         WorkingDocumentApi.addDocument(payload)
      );

      if (payload.projectId) {
         yield put(
            createDocumentInProjectSuccess({
               projectId: payload.projectId,
               document: newWorkingDocument,
            })
         );
         yield put({
            type: workingDocumentTypes.GET_ASSIGN_WORKING_DOCUMENTS,
            payload: {
               page: 1,
               size: CONSTANTS.DEFAULT_WORKING_DOCUMENT_SIZE,
            },
            callback: () => {},
         });
      } else {
         yield put({
            type: workingDocumentTypes.GET_PUBLIC_WORKING_DOCUMENTS,
            payload: { page: 1, size: CONSTANTS.DEFAULT_WORKING_DOCUMENT_SIZE },
            callback: () => {},
         });
      }
      callback(null, newWorkingDocument);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* updateDocument({
   payload,
   callback,
}: {
   type: string;
   payload: WorkingDocument;
   callback: WorkingDocumentCallback;
}) {
   try {
      const workingDocument: WorkingDocument = yield call(() =>
         WorkingDocumentApi.updateDocument(payload.id, payload)
      );
      yield put(
         updateDocumentSuccess({
            ...workingDocument,
         })
      );
      callback(null, workingDocument);
   } catch (error) {
      callback(JSON.parse(error.message), null);
   }
}

function* updateWorkingDocument({
   payload,
   callback,
}: {
   type: string;
   payload: WorkingDocument;
   callback: WorkingDocumentCallback;
}) {
   try {
      const workingDocument: WorkingDocument = yield call(() =>
         WorkingDocumentApi.updateDocument(payload.id, payload)
      );
      yield put(
         updateWorkingDocumentSuccess({
            ...workingDocument,
         })
      );
      callback(null, workingDocument);
   } catch (error) {
      callback(JSON.parse(error.message), null);
   }
}

function* archiveDocument(action: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const { documentId, projectId, archived } = action.payload;
      const response: {
         code: number;
         message: string;
         data: Object;
      } = yield call(() =>
         WorkingDocumentApi.archiveWorkingDocument(
            documentId,
            projectId,
            archived
         )
      );
      const workingDocument = response.data as WorkingDocument;
      yield put(archiveWorkingDocumentSuccess(documentId, archived));
      action.callback(null, workingDocument);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(
         archiveWorkingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* addNewPlaceholderDocument({
   payload,
   callback,
}: {
   type: string;
   payload: DocumentMetadata;
   callback: Function;
}) {
   try {
      const newBreezeWorkingDocument: BreezeWorkingDocument = yield call(
         async () =>
            BreezeWorkingDocument.createWorkingDocumentObject(
               await WorkingDocumentApi.addPlaceholderDocument(payload)
            )
      );

      if (payload.projectId) {
         yield put(
            createDocumentInProjectSuccess({
               projectId: payload.projectId,
               document: newBreezeWorkingDocument,
            })
         );
         yield put({
            type: workingDocumentTypes.GET_ASSIGN_WORKING_DOCUMENTS,
            payload: {
               page: 1,
               size: CONSTANTS.DEFAULT_WORKING_DOCUMENT_SIZE,
            },
            callback: () => {},
         });
      } else {
         yield put({
            type: workingDocumentTypes.GET_PUBLIC_WORKING_DOCUMENTS,
            payload: { page: 1, size: CONSTANTS.DEFAULT_WORKING_DOCUMENT_SIZE },
            callback: () => {},
         });
      }
      yield put({
         type: workingDocumentTypes.ADD_PLACEHOLDER_WORKING_DOCUMENT_SUCCESS,
         payload: newBreezeWorkingDocument.driveId,
      });
      callback(null, newBreezeWorkingDocument);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* mergeBreezeImagesInDocument({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const imageTriggerResult: String = yield call(async () =>
         WorkingDocumentApi.mergeBreezeImagesInDocument(payload)
      );
      yield put(clearWorkingDocumentPlaceHolders());
      callback(null, imageTriggerResult);
   } catch (error) {
      callback(JSON.parse(error.message));
   }
}

function* deleteDocument({
   payload,
   callback,
}: {
   type: string;
   payload: number;
   callback: Function;
}) {
   try {
      const data: number = yield call(() =>
         WorkingDocumentApi.deleteDocument(payload)
      );
      // TODO: Use the deleted working document
      yield put(deleteWorkingDocumentSuccess(payload));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* triggerBreeze({
   payload,
   callback,
}: {
   type: string;
   payload: {};
   callback: (error: Error | null, response?: any) => void;
}) {
   try {
      const breezeTriggerResult: {} = yield call(() =>
         triggerBreezeWithUserSelections(payload)
      );
      callback(null, breezeTriggerResult);
   } catch (error) {
      callback(JSON.parse(error.message));
   }
}

function* updateBigQueryTableWithSelections({
   payload,
   callback,
}: {
   type: string;
   payload: {};
   callback: (error: Error | null, response?: any) => void;
}) {
   try {
      const breezeTriggerResult: {} = yield call(() =>
         updateBqTablePlotMetadataData(payload)
      );
      callback(null, breezeTriggerResult);
   } catch (error) {
      callback(JSON.parse(error.message));
   }
}

export function* getBreezeMetadata({
   payload,
   callback,
}: {
   type: string;
   payload: string;
   callback: Function;
}) {
   try {
      const breezeMetadata: IBreezeMetadata = yield call(() =>
         getDocument(payload)
      );
      yield put(getBreezeMetadataSuccess(breezeMetadata));
      callback(
         null,
         BreezeMetadata.createBreezeMetadatasObject(breezeMetadata)
      );
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getWorkingDocumentById({
   payload,
   callback,
}: {
   type: string;
   payload: number;
   callback: ModelDocumentCallback;
}) {
   try {
      const workingDocument: WorkingDocument = yield call(() =>
         WorkingDocumentApi.getWorkingDocumentById(payload)
      );
      yield put(getWorkingDocumentByIdSuccess(workingDocument));
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getCountries({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const countries: [] = yield call(() => WorkingDocumentApi.getCountries());
      yield put(getCountriesSuccess(countries));
      callback(null, countries);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* getDocumentFilterData(action: {
   type: string;
   payload: string | null;
   callback: Function;
}) {
   try {
      const documentFilterData = yield call(() =>
         WorkingDocumentApi.getDocumentFiltersData(action.payload)
      );
      yield put(getDocumentFilterDataSuccess(documentFilterData));
      action.callback(null, documentFilterData);
   } catch (error) {
      action.callback(JSON.parse(error.message), null);
      yield put(getDocumentFilterDataFailure());
   }
}

function* getVariablesValuesData({
   payload,
   callback,
}: {
   type: string;
   payload: { id: number; query: string; roNumber: string };
   callback: Function;
}) {
   try {
      const { id, query, roNumber } = payload;
      const result = yield call(() =>
         WorkingDocumentApi.getVariableValues(id, query, roNumber)
      );
      yield put(getVariableValuesSuccess(result));
   } catch (error) {
      if (isJson(error.message)) {
         callback(JSON.parse(error.message));
      }
      yield put(
         getVariableValuesFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* getAutogeneratedVariablesValues({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const reportMetadata = yield call(() =>
         WorkingDocumentApi.getAutopopulatedVariablesValues(payload)
      );

      yield put(getAutogeneratedVariablesValuesSuccess(reportMetadata));
      callback(null, reportMetadata);
   } catch (error) {
      yield put(
         workingDocumentFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

function* getDocumentByDriveId({
   payload,
   callback,
}: {
   type: string;
   payload: string;
   callback: Function;
}) {
   try {
      const result = yield call(() =>
         WorkingDocumentApi.getDocumentWithDriveId(payload)
      );
      yield put(getDocumentByDriveIdSuccess(result));
   } catch (error) {
      if (isJson(error.message)) {
         callback(JSON.parse(error.message));
      }
      yield put(
         getVariableValuesFailure(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export default function* workingDocumentWatcher() {
   yield takeLatest(
      workingDocumentTypes.GET_ASSIGN_WORKING_DOCUMENTS,
      getAssignDocuments
   );
   yield takeLatest(
      workingDocumentTypes.GET_PUBLIC_WORKING_DOCUMENTS,
      getPublicWorkingDocuments
   );
   yield takeLatest(workingDocumentTypes.ADD_WORKING_DOCUMENT, addNewDocument);
   yield takeLatest(
      workingDocumentTypes.ADD_PLACEHOLDER_WORKING_DOCUMENT,
      addNewPlaceholderDocument
   );
   yield takeLatest(
      workingDocumentTypes.TRIGGER_BREEZE_IMAGE_GENERATION,
      triggerBreeze
   );
   yield takeLatest(
      workingDocumentTypes.UPDATE_BIG_QUERY_TABLE_WITH_SELECTION_DATA,
      updateBigQueryTableWithSelections
   );
   yield takeLatest(
      workingDocumentTypes.IMAGE_MERGE_BREEZE_DOCUMENT,
      mergeBreezeImagesInDocument
   );
   yield takeLatest(
      workingDocumentTypes.DELETE_WORKING_DOCUMENT,
      deleteDocument
   );

   yield takeLatest(
      workingDocumentTypes.GET_BREEZE_METADATA,
      getBreezeMetadata
   );

   yield takeLatest(workingDocumentTypes.UPDATE_DOCUMENT, updateDocument);

   yield takeLatest(
      workingDocumentTypes.UPDATE_WORKING_DOCUMENT,
      updateWorkingDocument
   );

   yield takeLatest(
      workingDocumentTypes.ARCHIVE_WORKING_DOCUMENT,
      archiveDocument
   );
   yield takeLatest(
      workingDocumentTypes.GET_WORKING_DOCUMENTS,
      getWorkingDocuments
   );
   yield takeLatest(
      workingDocumentTypes.GET_WORKING_DOCUMENT_BY_ID,
      getWorkingDocumentById
   );
   yield takeLatest(workingDocumentTypes.GET_COUNTRIES, getCountries);
   yield takeLatest(
      workingDocumentTypes.GET_DOCUMENT_FILTER_DATA,
      getDocumentFilterData
   );
   yield takeEvery(
      workingDocumentTypes.GET_VARIABLE_VALUES,
      getVariablesValuesData
   );
   yield takeLatest(
      workingDocumentTypes.GET_AUTOGENERATED_VARIABLES_VALUES,
      getAutogeneratedVariablesValues
   );
   yield takeLatest(
      workingDocumentTypes.GET_DOCUMENT_BY_DRIVE_ID,
      getDocumentByDriveId
   );
}
