import { put, call, takeLatest } from 'redux-saga/effects';
import { VariablesApi } from '../../core/api/variables.api';
import { variablesAndMetadataTypes } from '../types/variablesAndMetadata';
import {
   getAllVariablesSuccess,
   getVariableIdentifiersSuccess,
   createVariableSuccess,
   createVariableFailure,
   getVariableByIdSuccess,
   updateVariableSuccess,
   updateVariableFailure,
   deleteVariableSuccess,
   deleteVariableFailure,
   addReportsToVariableSuccess,
   getFilteredVariableIdentifiersSuccess,
   getReportIdentifiersSuccess,
   getReportIdentifiersFailure,
   getVariableDatasetsSuccess,
   getVariableDatasetsFailure,
   getVariableDataTablesSuccess,
   getVariableDataTablesFailure,
   updateVariableIdentifiersSuccess,
   updateVariableIdentifiersFailure,
   getVariableBQNamesSuccess,
   getVariableBQNamesFailure,
   getVariableTGNamesSuccess,
   getVariableTGNamesFailure,
   getExistingUINamesSuccess,
   getExistingUINamesFailure,
} from '../actions/variables';

export function* getAllVariables({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const variables: [] = yield call(() =>
         VariablesApi.getAllVariables(payload.filterQuery)
      );
      yield put(getAllVariablesSuccess(variables));
      callback(null, variables);
   } catch (error) {
      callback(JSON.parse(error.message), null);
   }
}

export function* getVariableById({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const variableResponse: any[] = yield call(() =>
         VariablesApi.getVariableById(payload.id)
      );
      yield put(
         getVariableByIdSuccess({
            ...variableResponse[0],
            MdIdentifiers: undefined,
            identifiers: variableResponse[0].MdIdentifiers,
         })
      );
      callback(null, variableResponse[0]);
   } catch (error) {
      callback(JSON.parse(error.message), null);
   }
}

export function* addReportsToVariable({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const variableResponse: any = yield call(() =>
         VariablesApi.addReportsToVariable(payload.id, payload.identifiers)
      );
      yield put(addReportsToVariableSuccess(variableResponse.data.identifiers));
      callback(null, variableResponse.data.identifiers);
   } catch (error) {
      console.log('error', error);
      callback(JSON.parse(error.message), null);
   }
}

export function* getVariableIdentifiers({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const variables: [] = yield call(() =>
         VariablesApi.getVariableIdentifiers(payload.filterQuery)
      );
      if (!payload.filterQuery) {
         yield put(getVariableIdentifiersSuccess(variables));
      } else {
         yield put(getFilteredVariableIdentifiersSuccess(variables));
      }
      callback(null, variables);
   } catch (error) {
      callback(JSON.parse(error.message), null);
   }
}

export function* createVariable({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const newVariable = yield call(() =>
         VariablesApi.createVariable(payload)
      );
      yield put(createVariableSuccess(newVariable));
      callback(null, newVariable);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(createVariableFailure(error));
   }
}

export function* deleteVariable({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const response = yield call(() =>
         VariablesApi.deleteVariable(payload.id)
      );
      yield put(deleteVariableSuccess(response));
      callback(null, response);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(deleteVariableFailure(error));
   }
}

export function* updateVariable({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const response = yield call(() => VariablesApi.updateVariable(payload));
      yield put(updateVariableSuccess(response));
      callback(null, response);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(updateVariableFailure(error));
   }
}

export function* getReportIdentifiers({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const reportIdentifiers: [] = yield call(() =>
         VariablesApi.getReportIdentifiers(payload)
      );
      yield put(getReportIdentifiersSuccess(reportIdentifiers));
      callback(null, reportIdentifiers);
   } catch (error) {
      yield put(getReportIdentifiersFailure(error.message));
      callback(JSON.parse(error.message), null);
   }
}

export function* getVariableDatasets({
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const datasets: [] = yield call(() => VariablesApi.getVariableDatasets());
      yield put(getVariableDatasetsSuccess(datasets));
      callback(null, datasets);
   } catch (error) {
      yield put(getVariableDatasetsFailure(error.message));
      callback(JSON.parse(error.message), null);
   }
}

export function* getVariableDataTables({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const dataTables: [] = yield call(() =>
         VariablesApi.getVariableDataTables(payload)
      );
      yield put(getVariableDataTablesSuccess(dataTables));
      callback(null, dataTables);
   } catch (error) {
      yield put(getVariableDataTablesFailure(error.message));
      callback(JSON.parse(error.message), null);
   }
}

export function* updateVariableIdentifiers({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const variableData: [] = yield call(() =>
         VariablesApi.updateVariableIdentifiers(payload)
      );
      yield put(updateVariableIdentifiersSuccess(variableData));
      callback(null, variableData);
   } catch (error) {
      yield put(updateVariableIdentifiersFailure(error.message));
      callback(JSON.parse(error.message), null);
   }
}

export function* getVariableBQNames({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const gigQueryNames: [] = yield call(() =>
         VariablesApi.getVariableBQNames(payload.dataset, payload.table)
      );
      yield put(getVariableBQNamesSuccess(gigQueryNames));
      callback(null, gigQueryNames);
   } catch (error) {
      yield put(getVariableBQNamesFailure(error.message));
      callback(JSON.parse(error.message), null);
   }
}

export function* getVariableTGNames({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const tableGeneratorNames: [] = yield call(() =>
         VariablesApi.getVariableTGNames(payload)
      );
      yield put(getVariableTGNamesSuccess(tableGeneratorNames));
      callback(null, tableGeneratorNames);
   } catch (error) {
      yield put(getVariableTGNamesFailure(error.message));
      callback(JSON.parse(error.message), null);
   }
}

export function* getExistingUINames({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const variables: [] = yield call(() =>
         VariablesApi.getExistingUINames(payload.uiName)
      );
      yield put(getExistingUINamesSuccess(variables));
      callback(null, variables);
   } catch (error) {
      yield put(getExistingUINamesFailure(error.message));
      callback(JSON.parse(error.message), null);
   }
}

export default function* variablesWatcher() {
   yield takeLatest(
      variablesAndMetadataTypes.GET_ALL_VARIABLES,
      getAllVariables
   );
   yield takeLatest(
      variablesAndMetadataTypes.GET_VARIABLE_BY_ID,
      getVariableById
   );
   yield takeLatest(
      variablesAndMetadataTypes.ADD_REPORTS_TO_VARIABLE,
      addReportsToVariable
   );
   yield takeLatest(
      variablesAndMetadataTypes.GET_ALL_REPORTS,
      getVariableIdentifiers
   );
   yield takeLatest(variablesAndMetadataTypes.UPDATE_VARIABLE, updateVariable);
   yield takeLatest(variablesAndMetadataTypes.CREATE_VARIABLE, createVariable);
   yield takeLatest(variablesAndMetadataTypes.DELETE_VARIABLE, deleteVariable);
   yield takeLatest(
      variablesAndMetadataTypes.GET_REPORT_IDENTIFIERS,
      getReportIdentifiers
   );
   yield takeLatest(
      variablesAndMetadataTypes.GET_VARIABLE_DATASETS,
      getVariableDatasets
   );
   yield takeLatest(
      variablesAndMetadataTypes.GET_VARIABLE_TABLES,
      getVariableDataTables
   );
   yield takeLatest(
      variablesAndMetadataTypes.GET_VARIABLE_TABLES,
      getVariableDataTables
   );
   yield takeLatest(
      variablesAndMetadataTypes.UPDATE_VARIABLE_IDENTIFIERS,
      updateVariableIdentifiers
   );
   yield takeLatest(
      variablesAndMetadataTypes.GET_VARIABLE_BIG_QUERY_NAMES,
      getVariableBQNames
   );
   yield takeLatest(
      variablesAndMetadataTypes.GET_TABLE_GENERATOR_NAMES,
      getVariableTGNames
   );
   yield takeLatest(
      variablesAndMetadataTypes.GET_EXISTING_UI_NAMES,
      getExistingUINames
   );
}
