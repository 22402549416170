import { put, takeLatest, call } from 'redux-saga/effects';
import { StatusApi } from '../../core/api/statusApi';
import { Status } from '../../core/models/status/status';
import { StatusInterface } from '../../core/models/status/statusInterface';
import { loadStatusesFailure, loadStatusesSuccess } from '../actions/status';
import { statusTypes } from '../types/status';

export function* getStatuses({
   callback,
}: {
   type: string;
   callback: Function;
}) {
   try {
      const data: StatusInterface[] = yield call(() =>
         StatusApi.loadStatuses()
      );
      const status = data.map(Status.createStatusObject);
      yield put(loadStatusesSuccess(status));
      callback(null, status);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(loadStatusesFailure(error));
   }
}

export default function* statusWatcher() {
   yield takeLatest(statusTypes.LOAD_STATUSES, getStatuses);
}
