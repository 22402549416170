export const workingDocumentTypes = {
   ADD_WORKING_DOCUMENT: 'ADD_WORKING_DOCUMENT',
   ADD_WORKING_DOCUMENT_SUCCESS: 'ADD_WORKING_DOCUMENT_SUCCESS',
   ADD_PLACEHOLDER_WORKING_DOCUMENT: 'ADD_PLACEHOLDER_WORKING_DOCUMENT',
   ADD_PLACEHOLDER_WORKING_DOCUMENT_SUCCESS:
      'ADD_PLACEHOLDER_WORKING_DOCUMENT_SUCCESS',
   ADD_DRIVE_ID_SUCCESS: 'ADD_DRIVE_ID_SUCCESS',

   ARCHIVE_WORKING_DOCUMENT: 'ARCHIVE_WORKING_DOCUMENT',
   ARCHIVE_WORKING_DOCUMENT_SUCCESS: 'ARCHIVE_WORKING_DOCUMENT_SUCCESS',
   ARCHIVE_WORKING_DOCUMENT_FAILURE: 'ARCHIVE_WORKING_DOCUMENT_FAILURE',

   CLEAR_WORKING_DOCUMENT_STATE: 'CLEAR_WORKING_DOCUMENT_STATE',
   CLEAR_WORKING_DOCUMENT_PLACEHOLDERS: 'CLEAR_WORKING_DOCUMENT_PLACEHOLDERS',
   CLEAR_WORKING_DOCUMENT_FORM: 'CLEAR_WORKING_DOCUMENT_FORM',

   DELETE_PROJECT_WORKING_DOCUMENT: 'DELETE_PROJECT_WORKING_DOCUMENT',
   DELETE_WORKING_DOCUMENT: 'DELETE_WORKING_DOCUMENT',
   DELETE_WORKING_DOCUMENT_SUCCESS: 'DELETE_WORKING_DOCUMENT_SUCCESS',

   GET_ALL_WORKING_DOCUMENTS: 'GET_ALL_WORKING_DOCUMENTS',
   GET_ALL_WORKING_DOCUMENTS_SUCCESS: 'GET_ALL_WORKING_DOCUMENTS_SUCCESS',
   GET_ASSIGN_WORKING_DOCUMENTS: 'GET_ASSIGN_WORKING_DOCUMENTS',
   GET_ASSIGN_WORKING_DOCUMENTS_SUCCESS: 'GET_ASSIGN_WORKING_DOCUMENTS_SUCCESS',
   GET_BREEZE_METADATA: 'GET_BREEZE_METADATA',
   GET_BREEZE_METADATA_SUCCESS: 'GET_BREEZE_METADATA_SUCCESS',
   GET_PUBLIC_WORKING_DOCUMENTS: 'GET_PUBLIC_WORKING_DOCUMENTS',
   GET_PUBLIC_WORKING_DOCUMENTS_SUCCESS: 'GET_PUBLIC_WORKING_DOCUMENTS_SUCCESS',
   GET_WORKING_DOCUMENTS: 'GET_WORKING_DOCUMENTS',
   GET_WORKING_DOCUMENTS_SUCCESS: 'GET_WORKING_DOCUMENTS_SUCCESS',
   GET_WORKING_DOCUMENT_BY_ID: 'GET_WORKING_DOCUMENT_BY_ID',
   GET_WORKING_DOCUMENT_BY_ID_SUCCESS: 'GET_WORKING_DOCUMENT_BY_ID_SUCCESS',

   IMAGE_MERGE_BREEZE_DOCUMENT: 'IMAGE_MERGE_BREEZE_DOCUMENT',

   SETUP_BREEZE_WORKING_DOCUMENT_PLACEHOLDER:
      'SETUP_BREEZE_WORKING_DOCUMENT_PLACEHOLDER',

   TRIGGER_BREEZE_IMAGE_GENERATION: 'TRIGGER_BREEZE_IMAGE_GENERATION',

   UPDATE_BREEZE_METADATA_SUCCESS: 'UPDATE_BREEZE_METADATA_SUCCESS',
   UPDATE_BIG_QUERY_TABLE_WITH_SELECTION_DATA:
      'UPDATE_BIG_QUERY_TABLE_WITH_SELECTION_DATA',
   UPDATE_SELECTED_MOLECULE_SUCCESS: 'UPDATE_SELECTED_MOLECULE_SUCCESS',
   UPDATE_WORKING_DOCUMENT_DESCRIPTION_SUCCESS:
      'UPDATE_WORKING_DOCUMENT_DESCRIPTION_SUCCESS',
   UPDATE_WORKING_DOCUMENT_TEMPLATE_ID_SUCCESS:
      'UPDATE_WORKING_DOCUMENT_TEMPLATE_ID_SUCCESS',
   UPDATE_WORKING_DOCUMENT_PROJECT_SUCCESS:
      'UPDATE_WORKING_DOCUMENT_PROJECT_SUCCESS',
   UPDATE_SELECTED_RUNS_SUCCESS: 'UPDATE_SELECTED_RUNS_SUCCESS',
   UPDATE_SELECTED_INPUTS_SUCCESS: 'UPDATE_SELECTED_INPUTS_SUCCESS',
   UPDATE_SELECTED_INPUTS_COMPUTER_SUCCESS:
      'UPDATE_SELECTED_INPUTS_COMPUTER_SUCCESS',
   UPDATE_SELECTED_FACTOR_STUDY_SUCCESS: 'UPDATE_SELECTED_FACTOR_STUDY_SUCCESS',
   UPDATE_SELECTED_KPI_SUCCESS: 'UPDATE_SELECTED_KPI_SUCCESS',
   UPDATE_SELECTED_KPI_COMPUTER_SUCCESS: 'UPDATE_SELECTED_KPI_COMPUTER_SUCCESS',
   UPDATE_SELECTED_KPI_METADATA_SUCCESS: 'UPDATE_SELECTED_KPI_METADATA_SUCCESS',
   UPDATE_SELECTED_LINEAR_REGRESSION_SUCCESS:
      'UPDATE_SELECTED_LINEAR_REGRESSION_SUCCESS',
   UPDATE_SELECTED_QA_SUCCESS: 'UPDATE_SELECTED_QA_SUCCESS',
   UPDATE_SELECTED_QA_COMPUTER_SUCCESS: 'UPDATE_SELECTED_QA_COMPUTER_SUCCESS',
   UPDATE_SELECTED_QA_METADATA_SUCCESS: 'UPDATE_SELECTED_QA_METADATA_SUCCESS',
   UPDATE_QUADRATIC_EFFECT_LIST_SUCCESS: 'UPDATE_QUADRATIC_EFFECT_LIST_SUCCESS',
   UPDATE_QUADRATIC_EFFECT_SELECTED_SUCCESS:
      'UPDATE_QUADRATIC_EFFECT_SELECTED_SUCCESS',
   UPDATE_TWO_WAY_EFFECT_LIST_SUCCESS: 'UPDATE_TWO_WAY_EFFECT_LIST_SUCCESS',
   UPDATE_TWO_WAY_EFFECT_SELECTED_SUCCESS:
      'UPDATE_TWO_WAY_EFFECT_SELECTED_SUCCESS',
   UPDATE_BLOCK_SELECTED_SUCCESS: 'UPDATE_BLOCK_SELECTED_SUCCESS',
   UPDATE_SELECTED_OUTPUTS_METADATA_SUCCESS:
      'UPDATE_SELECTED_OUTPUTS_METADATA_SUCCESS',
   UPDATE_BREEZE_METADATA_DRIVE_ID_SUCCESS:
      'UPDATE_BREEZE_METADATA_DRIVE_ID_SUCCESS',
   UPDATE_SELECTED_CHROMO_STEP_SUCCESS: 'UPDATE_SELECTED_CHROMO_STEP_SUCCESS',
   UPDATE_WORKING_DOCUMENT_NAME_SUCCESS: 'UPDATE_WORKING_DOCUMENT_NAME_SUCCESS',
   UPDATE_WORKING_DOCUMENT: 'UPDATE_WORKING_DOCUMENT',
   UPDATE_WORKING_DOCUMENT_SUCCESS: 'UPDATE_WORKING_DOCUMENT_SUCCESS',
   UPDATE_WORKING_DOCUMENT_FAVORITE_SUCCESS:
      'UPDATE_WORKING_DOCUMENT_FAVORITE_SUCCESS',
   UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
   UPDATE_DOCUMENT_SUCCESS: 'UPDATE_DOCUMENT_SUCCESS',

   WORKING_DOCUMENT_ACTION_FAILURE: 'WORKING_DOCUMENT_ACTION_FAILURE',
   GET_COUNTRIES: 'GET_COUNTRIES',
   GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
   GET_COUNTRIES_FAILURE: 'GET_COUNTRIES_FAILURE',
   GET_DOCUMENT_FILTER_DATA: 'GET_DOCUMENT_FILTER_DATA',
   GET_DOCUMENT_FILTER_DATA_SUCCESS: 'GET_DOCUMENT_FILTER_DATA_SUCCESS',
   GET_DOCUMENT_FILTER_DATA_FAILURE: 'GET_DOCUMENT_FILTER_DATA_FAILURE',
   GET_VARIABLE_VALUES: 'GET_VARIABLE_VALUES',
   GET_VARIABLE_VALUES_SUCCESS: 'GET_VARIABLE_VALUES_SUCCESS',
   GET_VARIABLE_VALUES_FAILURE: 'GET_VARIABLE_VALUES_FAILURE',
   GET_AUTOGENERATED_VARIABLES_VALUES: 'GET_AUTOGENERATED_VARIABLES_VALUES',
   GET_AUTOGENERATED_VARIABLES_VALUES_SUCCESS:
      'GET_AUTOGENERATED_VARIABLES_VALUES_SUCCESS',
   GET_AUTOGENERATED_VARIABLES_VALUES_FAILURE:
      'GET_AUTOGENERATED_VARIABLES_VALUES_FAILURE',
   GET_DOCUMENT_BY_DRIVE_ID: 'GET_DOCUMENT_BY_DRIVE_ID',
   GET_DOCUMENT_BY_DRIVE_ID_SUCCESS: 'GET_DOCUMENT_BY_DRIVE_ID_SUCCESS',
   GET_DOCUMENT_BY_DRIVE_ID_FAILURE: 'GET_DOCUMENT_BY_DRIVE_ID_FAILURE',
};
