export const projectTypes = {
   GET_CURRENT_PROJECT: 'GET_CURRENT_PROJECT',
   GET_CURRENT_PROJECT_SUCCESS: 'GET_CURRENT_PROJECT_SUCCESS',
   GET_CURRENT_PROJECT_FAILURE: 'GET_CURRENT_PROJECT_FAILURE',
   ADD_PROJECT: 'ADD_PROJECT',
   ADD_PROJECT_SUCCESS: 'ADD_PROJECT_SUCCESS',
   ADD_PROJECT_FAILURE: 'ADD_PROJECT_FAILURE',
   GET_ALL_PROJECTS: 'GET_ALL_PROJECTS',
   GET_ALL_PROJECTS_SUCCESS: 'GET_ALL_PROJECTS_SUCCESS',
   GET_ALL_PROJECTS_FAILURE: 'GET_ALL_PROJECTS_FAILURE',
   GET_ALL_ASSIGNED_PROJECTS: 'GET_ALL_ASSIGNED_PROJECTS',
   GET_ALL_ASSIGNED_PROJECTS_SUCCESS: 'GET_ALL_ASSIGNED_PROJECTS_SUCCESS',
   GET_ALL_ASSIGNED_PROJECTS_FAILURE: 'GET_ALL_ASSIGNED_PROJECTS_FAILURE',
   UPDATE_PROJECT: 'UPDATE_PROJECT',
   UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
   UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',
   UPDATE_PROJECT_FAVORITE_SUCCESS: 'UPDATE_PROJECT_FAVORITE_SUCCESS',
   DELETE_PROJECT: 'DELETE_PROJECT',
   DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
   DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_FAILURE',
   ASSIGN_DOCUMENTS_TO_PROJECT: 'ASSIGN_DOCUMENTS_TO_PROJECT',
   ASSIGN_DOCUMENTS_TO_PROJECT_SUCCESS: 'ASSIGN_DOCUMENTS_TO_PROJECT_SUCCESS',
   ASSIGN_DOCUMENTS_TO_PROJECT_FAILURE: 'ASSIGN_DOCUMENTS_TO_PROJECT_FAILURE',
   DELETE_DOCUMENT_FROM_PROJECT: 'DELETE_DOCUMENT_FROM_PROJECT',
   CREATE_DOCUMENT_IN_PROJECT_SUCCESS: 'CREATE_DOCUMENT_IN_PROJECT_SUCCESS',
   ARCHIVE_PROJECT: 'ARCHIVE_PROJECT',
   ARCHIVE_PROJECT_SUCCESS: 'ARCHIVE_PROJECT_SUCCESS',
   ARCHIVE_PROJECT_FAILURE: 'ARCHIVE_PROJECT_FAILURE',
   DELETE_DOCUMENT_IN_PROJECT_SUCCESS: 'DELETE_DOCUMENT_IN_PROJECT_SUCCESS',
   CLEAR_PROJECT_STATE: 'CLEAR_PROJECT_STATE',
   GET_DIVISIONS: 'GET_DIVISIONS',
   GET_DIVISIONS_SUCCESS: 'GET_DIVISIONS_SUCCESS',
   GET_DIVISIONS_FAILURE: 'GET_DIVISIONS_FAILURE',
   CLEAR_DOCUMENTS_STATE: 'CLEAR_DOCUMENTS_STATE',
   UNASSIGN_DOCUMENT: 'UNASSIGN_DOCUMENT',
   UNASSIGN_DOCUMENT_SUCCESS: 'UNASSIGN_DOCUMENT_SUCCESS',
   GET_PROJECT_FILTER_DATA: 'GET_PROJECT_FILTER_DATA',
   GET_PROJECT_FILTER_DATA_SUCCESS: 'GET_PROJECT_FILTER_DATA_SUCCESS',
   GET_PROJECT_FILTER_DATA_FAILURE: 'GET_PROJECT_FILTER_DATA_FAILURE',
};
