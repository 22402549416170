export interface ModelDocumentMetadataInterface {
   ctdCode?: string;
   ctdTitle?: string;
   ctdId?: string;
   countryGroupName: string;
   countries: string[];
   productArea: string;
   stage: string;
   stageType?: string;
   bbCode: string;
   bbCodeType: string;
   reportGroup?: string;
   reportTitle?: string;
}

export class ModelDocumentMetadata implements ModelDocumentMetadataInterface {
   constructor(
      public countryGroupName: string = '',
      public countries: string[] = [],
      public productArea: string = '',
      public stage: string = '',
      public stageType?: string,
      public bbCode: string = '',
      public bbCodeType: string = '',
      public ctdCode?: string,
      public ctdTitle?: string,
      public ctdId?: string,
      public reportGroup?: string,
      public reportTitle?: string
   ) {}
}
