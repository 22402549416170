import { conversionTypes } from '../types/conversionStyles';

export const getAllConversions = (callback: Function = () => {}) => ({
   type: conversionTypes.GET_CONVERSIONS_START,
   callback,
});

export const getAllConversionsSuccess = (payload: any) => ({
   type: conversionTypes.GET_CONVERSIONS_SUCCESS,
   payload,
});

export const getAllConversionsFailure = (payload: any) => ({
   type: conversionTypes.GET_CONVERSIONS_FAILURE,
   payload,
});

export const updateConversions = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: conversionTypes.UPDATE_CONVERSION_START,
   payload,
   callback,
});

export const updateConversionsSuccess = (payload: any) => ({
   type: conversionTypes.UPDATE_CONVERSION_SUCCESS,
   payload,
});

export const updateConversionsFailure = (payload: any) => ({
   type: conversionTypes.UPDATE_CONVERSION_FAILURE,
   payload,
});

export const selectConversionStyleSuccess = (payload: any) => ({
   type: conversionTypes.SELECT_CONVERSION_SUCCESS,
   payload,
});
