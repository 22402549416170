import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ButtonContext from './ButtonPopupContext';
import { routePopupSteps } from '../../dataModules';

const ButtonPopupProvider: ({
   children,
}: {
   children: object;
}) => JSX.Element = ({ children }) => {
   const location = useLocation();
   const routeForStep = routePopupSteps.find(
      (item) =>
         (!item.isWithParameters && item.path === location.pathname) ||
         (item.isWithParameters &&
            item.path !== location.pathname &&
            location.pathname.includes(item.path))
   );

   const [data, setData] = useState({
      showPopups: false,
      allSteps: routeForStep?.steps || 0,
      activeStep: 1,
      resetTutorials: false,
      defaultLang: 'en',
   });

   useEffect(() => {
      const routeStep = routePopupSteps.find(
         (item) =>
            (!item.isWithParameters && item.path === location.pathname) ||
            (item.isWithParameters &&
               item.path !== location.pathname &&
               location.pathname.includes(item.path))
      );
      setData(() => ({
         ...data,
         allSteps: routeStep?.steps || 0,
      }));
   }, [location]);

   const changeActiveStep = (step: number) => {
      setData((prevState) => ({
         ...prevState,
         activeStep: step,
      }));
   };

   const changeBrowserLang = (lang: string) => {
      localStorage.setItem('lang', lang);
      setData((prevState) => ({
         ...prevState,
         defaultLang: lang,
      }));
   };

   const togglePopups = (show: boolean) => {
      setData((prevState) => ({
         ...prevState,
         showPopups: show,
         activeStep: !show ? 1 : prevState.activeStep,
      }));
   };

   const resetTutorialsFunction = (isReset = true) => {
      setData((prevState) => ({
         ...prevState,
         resetTutorials: isReset,
      }));
   };

   const providerValue = {
      ...data,
      changeActiveStep,
      togglePopups,
      changeBrowserLang,
      resetTutorialsFunction,
   };

   return (
      <ButtonContext.Provider value={providerValue}>
         {children}
      </ButtonContext.Provider>
   );
};

export default ButtonPopupProvider;
