export interface Metadatainterface {
   mainRoNumber: string;
   productFamilyName: string;
   productFamilyCode: string;
   innCalculated: string;
   drugProducts: string[];
   drugSubstances: string[];
   ctdCode?: string;
   ctdTitle?: string;
   moleculeType?: string;
   moleculeName?: string;
   ctdTitleId?: string;
   countryGroupName?: string;
   countries?: string[];
   productArea?: string;
   stage?: string;
   stageType?: string;
   bbCode?: string;
   reportGroup?: string;
   reportTitle?: string;
}

export class Metadata implements Metadatainterface {
   constructor(
      public mainRoNumber: string = '',
      public productFamilyName: string = '',
      public productFamilyCode: string = '',
      public innCalculated: string = '',
      public drugProducts: string[] = [],
      public drugSubstances: string[] = [],
      public ctdCode?: string,
      public ctdTitle?: string,
      public moleculeType?: string,
      public moleculeName?: string,
      public ctdTitleId?: string,
      public countryGroupName?: string,
      public countries?: string[],
      public productArea?: string,
      public stage?: string,
      public stageType?: string,
      public bbCode?: string,
      public reportGroup?: string,
      public reportTitle?: string
   ) {}
}
