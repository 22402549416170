import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import LogoFinal from '../../assets/images/logo-blue.svg';
// eslint-disable-next-line import/no-cycle
import SideMenuList from '../Menu/SideMenuList';
import './styles.scss';
import Sidebar from '../../shared/components/Guidance/Sidebar';
import { Navbar } from '../../shared/components/NavBar/Navbar';
import { SubNavBar } from '../../shared/components/SubNavBar/SubNavBar';
import { GlobalState } from '../../core/models/globalState';
import { getGuidance } from '../../redux/actions/globals';

import '../../assets/css/helpers.css';
import { getCurrentUser, setIsUserReceived } from '../../redux/actions/user';
import { componentReadableError } from '../../core/api/apiUtils';
import { UserInterface } from '../../core/models/user/userInterface';

const guideDrawerWidth = 300;

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -guideDrawerWidth,
      display: 'flex',
   },
   contentShift: {
      transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.easeOut,
         duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
   },
}));

export const Layout = (props: any) => {
   const dispatch = useDispatch();
   const [isdeMenuCollapsed, setIsdeMenuCollapsed] = useState(false);
   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
   const [collapseState, setCollapseState] = useState(
      isdeMenuCollapsed || props.location.pathname.includes('/edit')
   );
   const [windowSize, setWindowSize] = useState(window.innerWidth);
   const classes = useStyles(collapseState);
   const isEditPage = props.location.pathname.includes('/edit');
   const { guidance } = useSelector((state: GlobalState) => state.globals);
   const { currentUser } = useSelector((state: GlobalState) => state.user);
   const [, , removeCookie] = useCookies([
      'id_token',
      'access_token',
      'ia_session',
   ]);

   useEffect(() => {
      let unmounted = false;
      if (!guidance.length && !unmounted) {
         dispatch(getGuidance());
      }
      return () => {
         unmounted = true;
      };
   }, []);

   const history = useHistory();

   useEffect(() => {
      const accessToken = localStorage.getItem('access-token');
      if (!currentUser?.id || !accessToken) {
         dispatch(
            getCurrentUser((_: componentReadableError, user: UserInterface) => {
               if (!user) {
                  removeCookie('id_token', { path: '/' });
                  removeCookie('access_token', { path: '/' });
                  removeCookie('ia_session', { path: '/' });
                  localStorage.removeItem('isLoggedIn');
                  history.push('/');
               }
            })
         );
      }
      if (currentUser?.id) {
         dispatch(setIsUserReceived(true));
      }
   }, []);

   useEffect(() => {
      const handleResize = () => {
         setWindowSize(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
   }, []);

   useEffect(() => {
      setIsdeMenuCollapsed(windowSize < 992);
   }, [windowSize]);

   const toggleDrawer = (open: boolean) => (event: any) => {
      if (
         event.type === 'keydown' &&
         (event.key === 'Tab' || event.key === 'Shift')
      ) {
         return;
      }

      if (open && !isdeMenuCollapsed && !isSidebarOpen) {
         setIsdeMenuCollapsed(true);
      }

      setIsSidebarOpen(open);
   };

   useEffect(() => {
      let unmounted = false;
      if (!unmounted) {
         setCollapseState(
            isdeMenuCollapsed || props.location.pathname.includes('/edit')
         );
      }
      return () => {
         unmounted = true;
      };
   }, [isdeMenuCollapsed, props.location.pathname]);

   function onSideMenuClickedHandler() {
      setIsdeMenuCollapsed(!isdeMenuCollapsed);
   }

   const getAppSidebarClassName = () =>
      `app__sidebar ${
         isdeMenuCollapsed || props.location.pathname.includes('/edit')
            ? 'minimized'
            : 'maximized'
      } ${
         props.location.pathname.includes('/edit')
            ? 'justify-content-center'
            : ''
      }`;

   return (
      <>
         <Navbar />
         <SubNavBar />
         <div>
            <div
               className={clsx('app', {
                  'app--withoutSubNav': isEditPage,
                  'app--minimized': isdeMenuCollapsed || isEditPage,
                  'app--guideOpen': isSidebarOpen,
               })}
            >
               <Sidebar
                  guidanceData={guidance}
                  isSidebarOpen={isSidebarOpen}
                  toggleDrawer={toggleDrawer}
               />

               <div
                  className={clsx(classes.content, {
                     [classes.contentShift]: isSidebarOpen,
                  })}
                  style={
                     props.location.pathname.includes('/layout/dashboard')
                        ? { flexDirection: 'column' }
                        : { flexDirection: 'row' }
                  }
               >
                  <div
                     className={getAppSidebarClassName()}
                     style={
                        props.location.pathname.includes('/edit')
                           ? {}
                           : { height: 'calc(100vh - 76px)' }
                     }
                  >
                     <div
                        className="appLogo"
                        style={{
                           display: props.location.pathname.includes('/edit')
                              ? 'none'
                              : 'initial',
                        }}
                     >
                        <Link to="/layout/dashboard">
                           <img src={LogoFinal} width={100} alt="Logo" />
                        </Link>
                     </div>
                     <SideMenuList
                        isShown={!props.location.pathname.includes('/edit')}
                        collapseState={isdeMenuCollapsed}
                        onSideMenuClicked={onSideMenuClickedHandler}
                        toggleDrawer={toggleDrawer}
                     />
                  </div>
                  <main
                     className="app__content"
                     style={{
                        width: '100%',
                        height: 'calc(100vh - 76px)',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                     }}
                  >
                     {props.children}
                  </main>
               </div>
            </div>
         </div>
      </>
   );
};
