import { ApiErrorHandler, componentReadableError } from '../core/api/apiUtils';
import { SystemTypes } from '../core/models/systemType/systemType';

export const DEFAULT_PROJECT_SIZE = 10;
export const DEFAULT_MODEL_DOCUMENT_SIZE = 50;
export const DEFAULT_GENERATED_DOCUMENT_SIZE = 50;
export const DEFAULT_WORKING_DOCUMENT_SIZE = 50;
export const DEFAULT_GET_ALL_MODEL_DOCUMENTS_PAYLOAD = {
   page: 1,
   pageSize: 300,
   searchQuery: '',
};

export const CTD_CODES: { title: string; id: number }[] = [
   { title: 'P.5.4', id: 1 },
   { title: 'S.4.4', id: 2 },
   { title: 'P.8.3', id: 3 },
   { title: 'S.7.3', id: 4 },
   { title: 'S.4.1', id: 5 },
   { title: 'P.5.1', id: 6 },
   { title: 'S.2.5.4', id: 7 },
   { title: 'S.2.5', id: 8 },
   { title: 'non-CTD', id: 9 },
];

export type PermissionsObject = {
   FONTO: {
      template: {
         create: boolean;
         edit: boolean;
         remove: boolean;
         view: boolean;
         download: boolean;
         transfer: boolean;
      };
      working_document: {
         create: boolean;
         edit: boolean;
         remove: boolean;
         view: boolean;
         download: boolean;
         transfer: boolean;
      };
   };
   LANDING: {
      generated_document: {
         create: boolean;
         edit: boolean;
         remove: boolean;
         view: boolean;
         download: boolean;
         transfer: boolean;
      };
      template: {
         create: boolean;
         edit: boolean;
         remove: boolean;
         view: boolean;
         download: boolean;
         transfer: boolean;
      };
      project: {
         create: boolean;
         edit: boolean;
         remove: boolean;
         view: boolean;
         download: boolean;
         transfer: boolean;
      };
      working_document: {
         create: boolean;
         edit: boolean;
         remove: boolean;
         view: boolean;
         download: boolean;
         transfer: boolean;
      };
   };
   LOOKER: {
      query: {
         create: boolean;
         edit: boolean;
         remove: boolean;
         view: boolean;
         download: boolean;
         transfer: boolean;
      };
   };
};

export const DEFAULT_PERMISSIONS_OBJECT = {
   FONTO: {
      template: {
         create: false,
         edit: false,
         remove: false,
         view: false,
         download: false,
         transfer: false,
      },
      working_document: {
         create: false,
         edit: false,
         remove: false,
         view: false,
         download: false,
         transfer: false,
      },
   },
   LANDING: {
      generated_document: {
         create: false,
         edit: false,
         remove: false,
         view: false,
         download: false,
         transfer: false,
      },
      template: {
         create: false,
         edit: false,
         remove: false,
         view: false,
         download: false,
         transfer: false,
      },
      project: {
         create: false,
         edit: false,
         remove: false,
         view: false,
         download: false,
         transfer: false,
      },
      working_document: {
         create: false,
         edit: false,
         remove: false,
         view: false,
         download: false,
         transfer: false,
      },
   },
   LOOKER: {
      query: {
         create: false,
         edit: false,
         remove: false,
         view: false,
         download: false,
         transfer: false,
      },
   },
};

export const NULL_ERROR_OBJECT = new ApiErrorHandler({
   message: '',
   code: 0,
   extraInformation: null,
}) as componentReadableError;

export type COMPONENT_ERROR = componentReadableError | null;

// Temporary solution, before getting clarification about the template types
export const MODEL_DOCUMENT_TYPES: SystemTypes[] = [
   {
      id: 1,
      name: 'submissionExpert',
      description: 'Submission Model',
      numberPrefix: 'GPM',
      modelDocumentDisplayType: 'Submission Model',
   },
   {
      id: 2,
      name: 'internalReport',
      description: 'Internal Reports',
      numberPrefix: 'PTD',
      modelDocumentDisplayType: 'Internal Report Model',
   },
   {
      id: 5,
      name: '',
      description: 'Internal Reports',
      modelDocumentDisplayType: 'Breeze Output Model',
      numberPrefix: '',
   },
   {
      id: 6,
      name: '',
      description: 'Internal Reports',
      modelDocumentDisplayType: 'Accummulus Model',
      numberPrefix: '',
   },
];

// quick

export interface IErrorProps {
   setError: Function;
}

const origin = window.location.origin;

export const PV_REPORTS_BACKEND_BASE_URL = !process.env.NODE_ENV.match(/prod/gi)
   ? 'http://localhost:3010/'
   : `${origin}/pvreports-backend/`;

const PRODUCTION_ENVIRONMENT_URL =
   'https://intelligent-authoring-qa.roche.com/';
export const allowCredentialsLogin = !window.location.href.includes(
   PRODUCTION_ENVIRONMENT_URL
);

export const styleKeys: Record<string, string[]> = {
   figureStyle: [
      'table-title',
      'table-description',
      'table-footnote',
      'table-header',
      'table-data',
      'table-title-cont',
      'image-title',
      'image-description',
      'image-footnote',
      'image-caption',
   ],
   elementStyle: [
      'default-text',
      'default-font',
      'default-table',
      'custom-table',
      'ordered-list',
      'unordered-list',
      'footnote-superscript',
      'footnote-reference',
      'footnote-index',
      'block-element',
      'inline-element',
      'link-text',
      'space-after-paragraph',
   ],
   titleStyle: [
      'outline-title',
      'heading-1',
      'heading-2',
      'heading-3',
      'heading-4',
      'heading-5',
      'heading-6',
      'heading-7',
      'heading-8',
      'heading-9',
   ],
};

export const maxLengths = {
   title: 200,
   description: 500,
   standardInput: 150,
};

export const docNameRegexp = /^[^_]+$/;

export const MODEL_DOCUMENT_STATUSES = ['DRAFT', 'PUBLISHED', 'WITHDRAWN'];
