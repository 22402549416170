import { handleError, handleResponse } from './apiUtils';
import { getCookie } from '../../shared/helpers';
import { PV_REPORTS_BACKEND_BASE_URL } from '../../shared/CONSTANTS';
import { dev } from '../../_dev/_utils';

export function getBreezeMolecules() {
   return fetch(`${PV_REPORTS_BACKEND_BASE_URL}molecule/`, {
      credentials: 'include',
      method: 'GET',
      headers: {
         'content-type': 'application/json',
         Authorization: getCookie('cmsAuth'),
      },
   })
      .then(async (response) => {
         const result = await handleResponse(response);
         return result.molecules;
      })
      .catch(handleError);
}

export function getBreezeChromoSteps(molecule: string) {
   return fetch(`${PV_REPORTS_BACKEND_BASE_URL}molecule/${molecule}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
         'content-type': 'application/json',
         Authorization: getCookie('cmsAuth'),
      },
   })
      .then((response) => response.json())
      .then((data) => {
         return data.steps;
      })
      .catch(handleError);
}

export function getBreezeChromoStepsDetails(molecule: string, step: string) {
   return fetch(`${PV_REPORTS_BACKEND_BASE_URL}molecule/${molecule}/${step}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
         'content-type': 'application/json',
         Authorization: getCookie('cmsAuth'),
      },
   })
      .then((response) => {
         const result = handleResponse(response);
         return result;
      })
      .catch(handleError);
}

export function updateBqTablePlotMetadataData(data: any) {
   return fetch(`${PV_REPORTS_BACKEND_BASE_URL}gFunction/metadata`, {
      credentials: 'include',
      method: 'POST',
      headers: {
         'content-type': 'application/json',
         Authorization: getCookie('cmsAuth'),
      },
      body: JSON.stringify(data),
   })
      .then(async (response) => {
         const result = await handleResponse(response);
         return result;
      })
      .catch(handleError);
}
export function triggerBreezeWithUserSelections(data: any) {
   return fetch(`${PV_REPORTS_BACKEND_BASE_URL}gFunction/triggerBreeze`, {
      credentials: 'include',
      method: 'POST',
      headers: {
         'content-type': 'application/json',
         Authorization: getCookie('cmsAuth'),
      },
      body: JSON.stringify(data),
   })
      .then(async (response) => {
         const result = await handleResponse(response);
         return result;
      })
      .catch(handleError);
}
export function saveDocument(data: any) {
   return fetch(`${PV_REPORTS_BACKEND_BASE_URL}document/breezeSelections`, {
      credentials: 'include',
      method: 'POST',
      headers: {
         'content-type': 'application/json',
         Authorization: getCookie('cmsAuth'),
      },
      body: JSON.stringify(data),
   })
      .then(async (response) => {
         const result = await handleResponse(response);
         return result;
      })
      .catch(handleError);
}

export function getDocument(driverId: string) {
   return fetch(`${PV_REPORTS_BACKEND_BASE_URL}document/${driverId}`, {
      credentials: 'include',
      method: 'GET',
      headers: {
         'content-type': 'application/json',
         Authorization: getCookie('cmsAuth'),
      },
   })
      .then(async (response) => {
         const result = await handleResponse(response);
         const data = result.data;
         return data.uiCompleteJson;
      })
      .catch(handleError);
}

export function getTablePreview(data: any) {
   return fetch(`${PV_REPORTS_BACKEND_BASE_URL}gFunction/preview `, {
      credentials: 'include',
      method: 'POST',
      headers: {
         'content-type': 'application/json',
         Authorization: getCookie('cmsAuth'),
      },
      body: JSON.stringify(data),
   })
      .then(async (response) => {
         const result = await handleResponse(response);
         const rows = result.data.rows;
         rows.forEach((dataSet: any, index: number) => {
            Object.keys(dataSet).forEach((key: string) => {
               dev.log(`Checking ${key}`, rows[index][key]);
               if (
                  rows[index][key] === null ||
                  rows[index][key] === undefined
               ) {
                  rows[index][key] = 'null';
               }
            });
         });
         const suggestions = result.data.suggestedRuns;
         const dataAndSuggestions = { rows, suggestions };
         dev.log('Data,', dataAndSuggestions);
         return dataAndSuggestions;
      })
      .catch(handleError);
}
