import { StatusInterface } from '../../core/models/status/statusInterface';
import { statusTypes } from '../types/status';

const {
   LOAD_STATUSES,
   LOAD_STATUSES_FAILURE,
   LOAD_STATUSES_SUCCESS,
} = statusTypes;

export const loadStatuses = (callback: Function = () => {}) => ({
   type: LOAD_STATUSES,
   callback,
});

export const loadStatusesSuccess = (payload: StatusInterface[]) => ({
   type: LOAD_STATUSES_SUCCESS,
   payload,
});

export const loadStatusesFailure = (payload: StatusInterface[]) => ({
   type: LOAD_STATUSES_FAILURE,
   payload,
});
