import { put, call, takeLatest } from 'redux-saga/effects';
import { GeneratedDocumentApi } from '../../core/api';
import {
   GeneratedDocument,
   GeneratedDocumentInterface,
   GeneratedDocumentCallback,
} from '../../core/models/generatedDocument/generatedDocument';
import {
   getGeneratedDocumentsSuccess,
   updateGeneratedDocumentSuccess,
   deleteGeneratedDocumentSuccess,
   transferGeneratedDocumentsSuccess,
   generatedDocumentActionsFail,
   getAssignedGeneratedDocumentsSuccess,
   archiveGeneratedDocumentSuccess,
   getGeneratedDocumentFiltersDataSuccess,
   getGeneratedDocumentFiltersDataFailure,
} from '../actions/generatedDocument';
import { generatedDocumentTypes } from '../types/generatedDocument';
import { ApiErrorHandler } from '../../core/api/apiUtils';

export function* getGeneratedDocuments({
   payload,
   callback,
}: {
   type: string;
   payload: { page: number; size: number; filterQuery: string };
   callback: GeneratedDocumentCallback;
}) {
   try {
      const { page, size, filterQuery } = payload;
      const result: GeneratedDocumentInterface[] = yield call(() =>
         GeneratedDocumentApi.getGeneratedDocuments(page, size, filterQuery)
      );
      const data = result.map((generatedDocument: any) =>
         GeneratedDocument.createGeneratedDocumentObject(generatedDocument)
      );
      yield put(getGeneratedDocumentsSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         generatedDocumentActionsFail(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* updateGeneratedDocument({
   payload,
   callback,
}: {
   type: string;
   payload: any;
   callback: GeneratedDocumentCallback;
}) {
   try {
      const { updatedData, documentId, document } = payload;
      const data: GeneratedDocumentInterface = yield call(() =>
         GeneratedDocumentApi.updateGeneratedDocument(
            updatedData,
            documentId,
            document
         )
      );
      yield put(updateGeneratedDocumentSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         generatedDocumentActionsFail(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* transferGeneratedDocument({
   payload,
   callback,
}: {
   type: string;
   payload: GeneratedDocumentInterface;
   callback: GeneratedDocumentCallback;
}) {
   try {
      const data: GeneratedDocument = yield call(() =>
         GeneratedDocumentApi.transferGeneratedDocument(payload)
      );
      yield put(transferGeneratedDocumentsSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         generatedDocumentActionsFail(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* deleteGeneratedDocument({
   payload,
   callback,
}: {
   type: string;
   payload: number;
   callback: GeneratedDocumentCallback;
}) {
   try {
      const data: number = yield call(() =>
         GeneratedDocumentApi.deleteGeneratedDocument(payload)
      );
      yield put(deleteGeneratedDocumentSuccess(data));
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         generatedDocumentActionsFail(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getAssignedGeneratedDocuments({
   callback,
}: {
   type: string;
   callback: GeneratedDocumentCallback;
}) {
   try {
      const result: GeneratedDocumentInterface[] = yield call(() =>
         GeneratedDocumentApi.getAssignedGeneratedDocuments()
      );
      const data = result.map((generatedDocument: any) =>
         GeneratedDocument.createGeneratedDocumentObject(generatedDocument)
      );
      yield put(getAssignedGeneratedDocumentsSuccess(data));
      callback(null, data);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         generatedDocumentActionsFail(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* archiveGeneratedDocument({
   payload,
   callback,
}: {
   type: string;
   payload: { documentId: number; archived: boolean };
   callback: GeneratedDocumentCallback;
}) {
   try {
      const { documentId, archived } = payload;
      const response: {
         code: number;
         message: string;
         data: GeneratedDocument;
      } = yield call(() =>
         GeneratedDocumentApi.archiveGeneratedDocument(documentId, archived)
      );
      const generatedDocument = response.data;
      yield put(archiveGeneratedDocumentSuccess(generatedDocument.id));
      callback(null, generatedDocument);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(
         generatedDocumentActionsFail(ApiErrorHandler.createErrorObject(error))
      );
   }
}

export function* getGeneratedDocumentFiltersData(action: {
   type: string;
   payload: string | null;
   callback: Function;
}) {
   try {
      const generatedDocumentFiltersData = yield call(() =>
         GeneratedDocumentApi.getGeneratedDocumentFiltersData(action.payload)
      );
      yield put(
         getGeneratedDocumentFiltersDataSuccess(generatedDocumentFiltersData)
      );
      action.callback(null, generatedDocumentFiltersData);
   } catch (error) {
      action.callback(JSON.parse(error.message), null);
      yield put(getGeneratedDocumentFiltersDataFailure());
   }
}

export default function* generatedDocumentWatcher() {
   yield takeLatest(
      generatedDocumentTypes.GET_GENERATED_DOCUMENTS,
      getGeneratedDocuments
   );
   yield takeLatest(
      generatedDocumentTypes.UPDATE_GENERATED_DOCUMENT,
      updateGeneratedDocument
   );
   yield takeLatest(
      generatedDocumentTypes.TRANSFER_GENERATED_DOCUMENT,
      transferGeneratedDocument
   );
   yield takeLatest(
      generatedDocumentTypes.DELETE_GENERATED_DOCUMENT,
      deleteGeneratedDocument
   );
   yield takeLatest(
      generatedDocumentTypes.GET_ASSIGNED_GENERATED_DOCUMENTS,
      getAssignedGeneratedDocuments
   );
   yield takeLatest(
      generatedDocumentTypes.ARCHIVE_GENERATED_DOCUMENT,
      archiveGeneratedDocument
   );
   yield takeLatest(
      generatedDocumentTypes.GET_GENERATED_DOCUMENT_FILTER_DATA,
      getGeneratedDocumentFiltersData
   );
}
