import { createContext } from 'react';

const initialData = {
   showPopups: false,
   allSteps: 0,
   activeStep: 0,
   resetTutorials: false,
   defaultLang: localStorage.getItem('lang') || 'en',
   changeActiveStep: (step: number) => {
      console.log(step);
   },
   togglePopups: (show: boolean) => {
      console.log(show);
   },
   changeBrowserLang: (lang: string) => {
      localStorage.setItem('lang', lang);
      initialData.defaultLang = lang;
   },
   resetTutorialsFunction: (isReset: boolean) => {
      initialData.resetTutorials = isReset;
   },
};

export default createContext(initialData);
