import { DocumentModifier } from '../fileInfo';
import { Metadata, Metadatainterface } from '../metadata';
import { BreezeMetadata } from './breezeMetadata/breezeMetadata';
import { IBreezeMetadata } from './breezeMetadata/breezeMetadataInterface';
import { WorkingDocument } from './workingDocument';

export class BreezeWorkingDocument extends WorkingDocument {
   public isBreezeDocument = true;

   constructor(
      public breezeMetadata: IBreezeMetadata = new BreezeMetadata(),
      public driveId: string = '',
      public id: number | null = 0,
      public section: string | undefined = '',
      public moleculeType: string | undefined = '',
      public modifiedDate: string | number | Date | undefined = '',
      public createdDate: string | number | Date | undefined = '',
      public name: string = '',
      public owner: any = '',
      public modifier: DocumentModifier = new DocumentModifier(),
      public fullName: string = '',
      public metadata: Metadatainterface = new Metadata(),
      public description: string = '',
      public projects: any[] = [],
      public templateId: string = '',
      public projectId: string = '',
      public archived: boolean = false
   ) {
      super();
   }
}
