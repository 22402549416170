export interface PermissionInterface {
   name: string;
   description: string;
   status: boolean;
   role: string;
}

export class Permission implements PermissionInterface {
   constructor(
      public name: string = '',
      public description: string = '',
      public status: boolean = false,
      public role: string = ''
   ) {}
}
