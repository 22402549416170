import io from 'socket.io-client';
import { UserInterface } from '../models/user/userInterface';

const SOCKET_API_ENDPOINT: string = new URL(
   process.env.REACT_APP_API_URL as string
).origin;
const SOCKET_API_PATH: string = process.env.REACT_APP_SOCKET_PATH as string;
console.log(`Using socket path: `, SOCKET_API_ENDPOINT, SOCKET_API_PATH);

export class SocketService {
   private static socketServiceSocket: any;

   public static listeners: {
      [key: string]: () => void;
   } = {};

   static createSocket({ currentUser }: { currentUser: UserInterface }) {
      if (
         !SocketService.socketServiceSocket ||
         !SocketService.socketServiceSocket.connected
      ) {
         if (SocketService.socketServiceSocket) {
            console.log('Different identity: Destroying socket');
            SocketService.socketServiceSocket.disconnect();
         }
         const token = localStorage.getItem('access-token');
         SocketService.socketServiceSocket = io(SOCKET_API_ENDPOINT, {
            transports: ['websocket'],
            auth: { token },
            reconnectionAttempts: 100,
            path: SOCKET_API_PATH,
            withCredentials: true,
         });
      }
      SocketService.socketServiceSocket.on('disconnect', (reason: string) => {
         if (reason === 'io server disconnect') {
            // the disconnection was initiated by the server, you need to reconnect manually
            SocketService.socketServiceSocket.connect();
         }
         // else the socket will automatically try to reconnect
      });

      SocketService.socketServiceSocket.emit('login', { currentUser });
      return SocketService.socketServiceSocket;
   }

   static get socket() {
      return SocketService.socketServiceSocket;
   }

   static addListener(listener: string, callback: () => void): void {
      SocketService.listeners[listener] = callback;
   }

   static getlisteners() {
      return SocketService.listeners;
   }

   static updateCurrentUserOnSocket(user: UserInterface) {
      const currentUser = user;
      SocketService.socketServiceSocket.emit(
         'server:updateCurrentUserOnSocket',
         { currentUser }
      );
      return SocketService.socket;
   }

   static emitEvent(event: string, data: any = null) {
      return SocketService.socket.emit(event, data);
   }

   static loginSocket() {
      SocketService.emitEvent('login');
      return SocketService.socket;
   }

   static logoutSocket() {
      SocketService.emitEvent('logout');
   }
}
