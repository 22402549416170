import { TutorialItemFromDBInterface } from '../../core/models/tutorial/tutorials';
import { tutorialTypes } from '../types/tutorial';

export const getAllTutorials = (
   payload: number,
   callback: Function = () => {}
) => ({
   type: tutorialTypes.GET_ALL_TUTORIALS,
   payload,
   callback,
});

export const getAllTutorialsSuccess = (
   payload: TutorialItemFromDBInterface[]
) => ({
   type: tutorialTypes.GET_ALL_TUTORIALS_SUCCESS,
   payload,
});

export const deleteTutorial = (payload: number, callBack: Function) => ({
   type: tutorialTypes.DELETE_TUTORIAL,
   payload,
   callBack,
});

export const deleteTutorialSuccess = (payload: number | boolean) => ({
   type: tutorialTypes.DELETE_TUTORIAL_SUCCESS,
   payload,
});

export const tutorialActionsFail = (error: any) => ({
   type: tutorialTypes.TUTORIAL_ACTIONS_FAIL,
   error,
});
