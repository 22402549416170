import produce from 'immer';
import { CtdTitleState } from '../../core/models/ctdTitle/ctdTitlesState';
import { ctdTitleTypes } from '../types/ctdTitles';

const initialState: CtdTitleState = {
   ctdTitles: [],
   activeCtdTitles: [],
   selectedCtdTitles: [],
   error: null,
};

export const ctdTitle = (
   state: CtdTitleState = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case ctdTitleTypes.GET_CTD_TITLES_SUCCESS: {
            draft.ctdTitles = payload;
            draft.error = null;
            return draft;
         }
         case ctdTitleTypes.GET_CTD_TITLE_BY_CODE_SUCCESS: {
            draft.selectedCtdTitles = payload;
            draft.error = null;
            return draft;
         }
         case ctdTitleTypes.GET_ACTIVE_CTD_TITLE_SUCCESS: {
            draft.activeCtdTitles = payload;
            draft.error = null;
            return draft;
         }
         case ctdTitleTypes.ACTIVATE_CTD_TITLE_SUCCESS: {
            draft.activeCtdTitles = payload.activeCtdTitles;
            draft.ctdTitles = payload.ctdTitles;
            draft.error = null;
            return draft;
         }
         case ctdTitleTypes.ACTIVATE_CTD_TITLE_FAILURE: {
            draft.error = payload;
            return draft;
         }
         case ctdTitleTypes.CTD_TITLE_FAILURE: {
            draft.error = payload;
            return draft;
         }
         default:
            return draft;
      }
   });
