import { DocumentModifier } from '../fileInfo';
import { UserInterface } from '../user/userInterface';
import Members from './members';
import { COMPONENT_ERROR } from '../../../shared/CONSTANTS';
import { Country } from '../country';
import { ModelDocumentVariables } from './modelDocumentVariables';
import {
   ModelDocumentMetadata,
   ModelDocumentMetadataInterface,
} from './modelDocumentMetadata';

export function getRole(file: any, type: string) {
   let data: number[] = [-1];
   if (file.ModelDocumentRoles) {
      Object.values(file.ModelDocumentRoles).forEach((role: any) => {
         if (type === role.name) {
            data = role?.Users.map((member: UserInterface) => member.id);
         }
      });
   }

   return data;
}

export type ModelDocumentCallback = (
   error: COMPONENT_ERROR,
   modelDocument: ModelDocument[] | ModelDocument | null,
   count?: number,
   info?: any
) => void;

export class ModelDocument {
   constructor(
      public driveId: string = '',
      public id: number | null = 0,
      public modifiedDate: string | number | Date | undefined = '',
      public createdDate: string | number | Date | undefined = '',
      public name: string = '',
      public title: string = '',
      public modifier: DocumentModifier = new DocumentModifier(),
      public metadata: ModelDocumentMetadataInterface = new ModelDocumentMetadata(),
      public variables: ModelDocumentVariables[] = [],
      public members: Members = new Members(),
      public description: string = '',
      public moleculeId: string = '',
      public archived: boolean = false,
      public effectiveDate: string = new Date().toString(),
      public effectiveVersion: string = '1.0',
      public type: number = 0,
      public countries: Country[] = [],
      public isFavorite: boolean = false,
      public status: string = ''
   ) {}

   public static createModelDocumentObject(data: any) {
      return new ModelDocument(
         data.driveId,
         data.id,
         new Date(data.updatedAt).toLocaleString(),
         new Date(data.createdAt).toLocaleString(),
         data.name,
         data.title,
         new DocumentModifier(
            data.Modifier?.firstName,
            data.Modifier?.lastName
         ),
         data.metadata,
         data.variables,
         {
            owner: getRole(data, 'owner'),
            manager: getRole(data, 'manager'),
            viewer: getRole(data, 'viewer'),
         },
         data.description,
         data.moleculeId,
         data.archived,
         data.effectiveDate,
         data?.effectiveVersion,
         data.type,
         data.metadata.countries?.map((country: any) => ({
            name: country,
            label: country,
         })),
         data.isFavorite,
         data.status
      );
   }
}
