export const userTypes = {
   GET_CURRENT_USER: 'GET_CURRENT_USER',
   GET_CURRENT_USER_SUCCESS: 'GET_CURRENT_USER_SUCCESS',
   GET_USER_FAILURE: 'GET_USER_FAILURE',
   ADD_USER: 'ADD_USER',
   ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
   LOGIN_USER: 'LOGIN_USER',
   UPDATE_USER: 'UPDATE_USER',
   UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
   GET_ALL_USERS: 'GET_ALL_USERS',
   GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
   SET_ONLINE_USERS: 'SET_ONLINE_USERS',
   SET_IS_USER_RECEIVED: 'SET_IS_USER_RECEIVED',
   LOG_OUT_USER: 'LOG_OUT_USER',
   LOG_OUT_USER_SUCCESS: 'LOG_OUT_USER_SUCCESS',
   LOG_OUT_USER_FAILURE: 'LOG_OUT_USER_FAILURE',
   SEND_SSO_CODE: 'SEND_SSO_CODE',
};
