import { RoleInterface } from './roleInterface';
import { PermissionsInterface, getPermissionsCount } from '../rolesModel';

export class Role implements RoleInterface {
   constructor(
      public id: number = 0,
      public name: string = '',
      public description: string = '',
      public permissions: PermissionsInterface = new PermissionsInterface(),
      public permissionsCount: number = 0,
      public createdDate: string = '',
      public modifiedDate: string = '',
      public title: string = '',
      public createdAt: string = ''
   ) {}

   public static createRolesObject(data: any) {
      return new Role(
         data.id,
         data.name,
         data.description,
         data.permissions,
         getPermissionsCount(data.permissions),
         `${new Date(data.createdAt).toLocaleDateString()} ${new Date(
            data.createdAt
         ).toLocaleTimeString()}`,
         `${new Date(data.modifiedAt).toLocaleDateString()} ${new Date(
            data.modifiedAt
         ).toLocaleTimeString()}`,
         data.name,
         new Date(data.createdAt).toLocaleString()
      );
   }
}
