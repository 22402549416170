import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import { RemoveCircle } from '@material-ui/icons';
import { COMPONENT_ERROR } from '../CONSTANTS';
import { isJson } from '../helpers';

const useStyles = makeStyles({
   title: {
      display: 'flex',
      alignItems: 'center',

      '& div': {
         textAlign: 'left',
      },
   },
   errorTitle: {
      color: 'black',
   },
   errorText: {
      fontSize: 15,
      fontWeight: 700,
      marginLeft: 5,
      color: 'black',
   },
   statusCode: {
      fontSize: 15,
      fontWeight: 700,
      marginLeft: 5,
      color: '#e44939',
   },
   errorExtraInformation: {
      fontSize: 12,
   },
   dateStyles: {
      paddingTop: 5,
      fontSize: 9,
      textAlign: 'left',
   },
   errorDialogContent: {
      textAlign: 'left',
   },
   dialogTitle: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 16,
   },
   errorMessage: {
      '& > div': {
         color: '#ADB5BD',
         fontWeight: 400,
         paddingLeft: 34,
         marginBottom: 10,
      },
   },
});

interface propsValue {
   onClose: Function;
   errorInfo: COMPONENT_ERROR;
   open: boolean;
   openDuration?: number;
}
export default function ErrorInfoDialog(props: propsValue) {
   const { onClose, errorInfo, open, openDuration } = props;
   const classes = useStyles();

   const createErrorDialog = () => {
      if (!errorInfo) {
         return 'EXCEPTION: NULL ERROR';
      }
      // in case the message passed is not a json string, try...catch will prevent app breaking
      const error = isJson(errorInfo?.message)
         ? JSON.parse(errorInfo?.message)
         : errorInfo?.message;
      return (
         <div className="error-modal">
            <div className="d-flex mb-1">
               <span className={classes.errorTitle}>Error: </span>
               <div className={classes.errorText}>
                  {error?.message ||
                     (typeof errorInfo?.message === 'string' &&
                        errorInfo?.message) ||
                     (typeof error === 'string' && error) ||
                     'UNKNOWN ERROR'}
               </div>
            </div>
            <div className="d-flex">
               <span className={classes.errorTitle}>Status Code: </span>
               <div className={`${classes.errorText} ${classes.statusCode}`}>
                  {error?.code ||
                     (typeof errorInfo?.code === 'number' && errorInfo?.code) ||
                     (typeof errorInfo?.status === 'number' &&
                        errorInfo?.status) ||
                     'Unknown'}
                  <div className={classes.errorExtraInformation}>
                     {error?.extraInformation &&
                        `Metadata: ${error.extraInformation}`}
                  </div>
               </div>
            </div>
            <div className={classes.dateStyles}>
               <div>Timestamp: {error?.timestamp}</div>
               <div>Src: {error.url}</div>
            </div>
         </div>
      );
   };

   const handleClose = () => {
      onClose();
   };
   return (
      <>
         <Snackbar
            open={open}
            className="error-snackbar error-dialog"
            autoHideDuration={openDuration || 6000}
            onClose={handleClose}
            message={
               <div className={classes.errorDialogContent}>
                  <div className={classes.dialogTitle}>
                     <RemoveCircle />
                     <span>Sorry, an error has occurred!</span>
                  </div>
                  <div
                     className={`${classes.errorMessage} error-message-content`}
                  >
                     <div>Contact support if this error persists</div>
                     <div>{createErrorDialog()}</div>
                  </div>
               </div>
            }
         />
         <div className="backdrop" />
      </>
   );
}

ErrorInfoDialog.propTypes = {
   onClose: PropTypes.func.isRequired,
   open: PropTypes.bool.isRequired,
};
