export const favoriteTypes = {
   GET_ALL_FAVORITES: 'GET_ALL_FAVORITES',
   GET_ALL_FAVORITES_SUCCESS: 'GET_ALL_FAVORITES_SUCCESS',
   GET_ALL_FAVORITES_FAILURE: 'GET_ALL_FAVORITES_FAILURE',
   CHANGE_PROJECT_FAVORITE: 'CHANGE_PROJECT_FAVORITE',
   CHANGE_PROJECT_FAVORITE_SUCCESS: 'CHANGE_PROJECT_FAVORITE_SUCCESS',
   CHANGE_PROJECT_FAVORITE_FAILURE: 'CHANGE_PROJECT_FAVORITE_FAILURE',
   CHANGE_TEMPLATE_FAVORITE: 'CHANGE_TEMPLATE_FAVORITE',
   CHANGE_TEMPLATE_FAVORITE_SUCCESS: 'CHANGE_TEMPLATE_FAVORITE_SUCCESS',
   CHANGE_TEMPLATE_FAVORITE_FAILURE: 'CHANGE_TEMPLATE_FAVORITE_FAILURE',
   CHANGE_DOCUMENT_FAVORITE: 'CHANGE_DOCUMENT_FAVORITE',
   CHANGE_DOCUMENT_FAVORITE_SUCCESS: 'CHANGE_DOCUMENT_FAVORITE_SUCCESS',
   CHANGE_DOCUMENT_FAVORITE_FAILURE: 'CHANGE_DOCUMENT_FAVORITE_FAILURE',
   CLEAR_SUCCESS_MESSAGE: 'FAVORITES_CLEAR_SUCCESS_MESSAGE',
   CLEAR_ERROR_MESSAGE: 'FAVORITES_CLEAR_ERROR_MESSAGE',
};
