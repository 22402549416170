import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/no-cycle
import { Home } from './pages/Home/Home';
import ButtonPopupProvider from './shared/context/ButtonPopupProvider';
import routes from './routes';
import { render } from './core/helpers/protected.route';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import IACustomizedToast from './shared/components/IACustomToast/IACustomToast';
import SSOauth from './pages/SSOauth/SSOauth';
import { Layout } from './pages/Layout/Layout';
import { GlobalState } from './core/models/globalState';
import { Loading } from './shared/components/Loading/Loading';

const App: React.FC = () => {
   const [cookies] = useCookies(['id_token', 'access_token']);
   const environment = process.env.REACT_APP_APPLICATION_ENV;
   const { currentUser, isUserReceived } = useSelector(
      (state: GlobalState) => state.user
   );

   // This is Main Component, which provide router i.e. layout ,Home. its content is inside fluid container.
   return (
      <HelmetProvider>
         <Helmet>
            <title>Intelligent Authoring - {environment?.toUpperCase()}</title>
         </Helmet>
         <IACustomizedToast />
         <BrowserRouter>
            <ButtonPopupProvider>
               <div>
                  <React.Suspense fallback={<Loading />}>
                     <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/ssoauth" component={SSOauth} />
                        <Route path="/not-found" component={PageNotFound} />
                        <Layout>
                           <React.Suspense fallback={<Loading />}>
                              <Switch>
                                 {isUserReceived ? (
                                    routes.map((route) => (
                                       <Route
                                          key={route.id}
                                          {...route}
                                          component={undefined}
                                          render={(props) =>
                                             render(
                                                {
                                                   ...props,
                                                   cookies,
                                                   component: route.component,
                                                   permissionsToOpen:
                                                      route.permission,
                                                   adminOnly: route.adminOnly,
                                                },
                                                currentUser
                                             )
                                          }
                                       />
                                    ))
                                 ) : (
                                    <Loading />
                                 )}
                                 <Redirect to={{ pathname: '/not-found' }} />
                              </Switch>
                           </React.Suspense>
                        </Layout>
                        <Redirect to={{ pathname: '/not-found' }} />
                     </Switch>
                  </React.Suspense>
               </div>
            </ButtonPopupProvider>
         </BrowserRouter>
      </HelmetProvider>
   );
};

// @ts-ignore
if (window.Cypress) {
   // @ts-ignore
   window.store = store;
}

export default App;
