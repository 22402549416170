import { CtdTitle } from '../../core/models/ctdTitle/ctdTitles';
import { ctdTitleTypes } from '../types/ctdTitles';
import { COMPONENT_ERROR } from '../../shared/CONSTANTS';

export const getCtdTitles = (
   searchQuery: string,
   callback: Function = () => {}
) => ({
   type: ctdTitleTypes.GET_CTD_TITLES,
   payload: searchQuery,
   callback,
});

export const getCtdTitlesSuccess = (payload: CtdTitle[]) => ({
   type: ctdTitleTypes.GET_CTD_TITLES_SUCCESS,
   payload,
});

export const getActiveCtdTitles = (callback: Function = () => {}) => ({
   type: ctdTitleTypes.GET_ACTIVE_CTD_TITLE,
   callback,
});

export const getActiveCtdTitlesSuccess = (payload: CtdTitle[]) => ({
   type: ctdTitleTypes.GET_ACTIVE_CTD_TITLE_SUCCESS,
   payload,
});

export const getCtdTitleByCode = (
   payload: string,
   callback: Function = () => {}
) => ({
   type: ctdTitleTypes.GET_CTD_TITLE_BY_CODE,
   payload,
   callback,
});

export const getCtdTitleByCodeSuccess = (payload: CtdTitle[]) => ({
   type: ctdTitleTypes.GET_CTD_TITLE_BY_CODE_SUCCESS,
   payload,
});

export const activateCtdTitle = (
   payload: { activeCodes: number[]; inActiveCodes: number[] },
   callback: Function
) => ({
   type: ctdTitleTypes.ACTIVATE_CTD_TITLE,
   payload,
   callback,
});

export const activateCtdTitleSuccess = (payload: {
   activeCtdTitles: CtdTitle[];
   ctdTitles: CtdTitle[];
}) => ({
   type: ctdTitleTypes.ACTIVATE_CTD_TITLE_SUCCESS,
   payload,
});

export const activateCtdTitleFailure = (error: COMPONENT_ERROR) => ({
   type: ctdTitleTypes.ACTIVATE_CTD_TITLE_FAILURE,
   error,
});

export const ctdTitleFailure = (error: COMPONENT_ERROR) => ({
   type: ctdTitleTypes.CTD_TITLE_FAILURE,
   error,
});
