import produce from 'immer';
import { GeneratedDocumentState } from '../../core/models/generatedDocument/generatedDocumentState';
import { generatedDocumentTypes } from '../types/generatedDocument';
import {
   GeneratedDocument,
   GeneratedDocumentInterface,
} from '../../core/models/generatedDocument/generatedDocument';
import * as CONSTANTS from '../../shared/CONSTANTS';

const initialState: GeneratedDocumentState = {
   generatedDocuments: [],
   assignedGeneratedDocuments: [],
   generatedDocumentFilters: [],
   isFullyLoaded: false,
   error: null,
};

export const generatedDocument = (
   state = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;

      switch (type) {
         case generatedDocumentTypes.GET_GENERATED_DOCUMENTS_SUCCESS: {
            if (
               payload.length < CONSTANTS.DEFAULT_GENERATED_DOCUMENT_SIZE ||
               payload.length % CONSTANTS.DEFAULT_GENERATED_DOCUMENT_SIZE !== 0
            ) {
               draft.isFullyLoaded = true;
            }
            draft.generatedDocuments = payload;
            draft.generatedDocuments.sort(
               (document: GeneratedDocument, nextDocument: GeneratedDocument) =>
                  nextDocument.modifiedDate - document.modifiedDate
            );
            draft.error = null;
            return draft;
         }
         case generatedDocumentTypes.UPDATE_GENERATED_DOCUMENT_SUCCESS: {
            if (payload) {
               const index: number = draft.generatedDocuments.findIndex(
                  (document: GeneratedDocumentInterface) =>
                     document.id === payload.id
               );
               if (index !== -1) {
                  draft.generatedDocuments[index] = payload;
                  draft.generatedDocuments.sort(
                     (
                        document: GeneratedDocument,
                        nextDocument: GeneratedDocument
                     ) => nextDocument.modifiedDate - document.modifiedDate
                  );
               }
            }
            draft.error = null;
            return draft;
         }
         case generatedDocumentTypes.TRANSFER_GENERATED_DOCUMENT_SUCCESS: {
            const index: number = draft.generatedDocuments.findIndex(
               (document: GeneratedDocumentInterface) =>
                  document.id === payload.data?.id
            );
            if (payload && index !== -1) {
               draft.generatedDocuments[index].status = 2;
               draft.generatedDocuments[index].statusDescription =
                  'transferred';
            }
            draft.error = null;
            return draft;
         }
         case generatedDocumentTypes.DELETE_GENERATED_DOCUMENT_SUCCESS: {
            const documentIndex: number = draft.generatedDocuments.findIndex(
               (document: GeneratedDocumentInterface) => document.id === payload
            );
            if (payload && documentIndex !== -1) {
               draft.generatedDocuments.splice(documentIndex, 1);
            }
            draft.error = null;
            return draft;
         }
         case generatedDocumentTypes.GENERATED_DOCUMENT_FAILURE: {
            draft.error = payload;
            return draft;
         }
         case generatedDocumentTypes.GET_ASSIGNED_GENERATED_DOCUMENTS_SUCCESS: {
            draft.assignedGeneratedDocuments = payload;
            draft.error = null;
            return draft;
         }
         case generatedDocumentTypes.CLEAR_GENERATED_DOCUMENT_STATE:
            draft = { ...initialState };
            return draft;
         case generatedDocumentTypes.ARCHIVE_GENERATED_DOCUMENT_SUCCESS: {
            const { documentId } = payload;
            if (documentId) {
               draft.generatedDocuments = draft.generatedDocuments.filter(
                  (document) => document.id !== documentId
               );
               draft.assignedGeneratedDocuments = draft.assignedGeneratedDocuments.filter(
                  (document) => document.id !== documentId
               );
            }
            return draft;
         }
         case generatedDocumentTypes.GET_GENERATED_DOCUMENT_FILTER_DATA_SUCCESS:
            draft.generatedDocumentFilters = payload;
            return draft;
         case generatedDocumentTypes.GET_GENERATED_DOCUMENT_FILTER_DATA_FAILURE:
            draft.generatedDocumentFilters = [];
            return draft;
         default:
            return draft;
      }
   });
