import { variablesAndMetadataTypes } from '../types/variablesAndMetadata';

export const getAllVariables = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_ALL_VARIABLES,
   payload,
   callback,
});

export const getAllVariablesSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_ALL_VARIABLES_SUCCESS,
   payload,
});

export const getVariableById = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_BY_ID,
   payload,
   callback,
});

export const getVariableByIdSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_BY_ID_SUCCESS,
   payload,
});

export const addReportsToVariable = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.ADD_REPORTS_TO_VARIABLE,
   payload,
   callback,
});

export const addReportsToVariableSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.ADD_REPORTS_TO_VARIABLE_SUCCESS,
   payload,
});

export const getVariableIdentifiers = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_ALL_REPORTS,
   payload,
   callback,
});

export const getVariableIdentifiersSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_ALL_REPORTS_SUCCESS,
   payload,
});

export const getFilteredVariableIdentifiersSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_FILTERED_REPORTS_SUCCESS,
   payload,
});

export const createVariable = (payload: any, callback: Function) => ({
   type: variablesAndMetadataTypes.CREATE_VARIABLE,
   payload,
   callback,
});

export const createVariableSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.CREATE_VARIABLE_SUCCESS,
   payload,
});

export const createVariableFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.CREATE_VARIABLE_FAILURE,
   payload,
});

export const updateVariable = (payload: any, callback: Function) => ({
   type: variablesAndMetadataTypes.UPDATE_VARIABLE,
   payload,
   callback,
});

export const updateVariableSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.UPDATE_VARIABLE_SUCCESS,
   payload,
});

export const updateVariableFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.UPDATE_VARIABLE_FAILURE,
   payload,
});

export const deleteVariable = (payload: any, callback: Function) => ({
   type: variablesAndMetadataTypes.DELETE_VARIABLE,
   payload,
   callback,
});

export const deleteVariableSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.DELETE_VARIABLE_SUCCESS,
   payload,
});

export const deleteVariableFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.DELETE_VARIABLE_FAILURE,
   payload,
});

export const getReportIdentifiers = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_REPORT_IDENTIFIERS,
   payload,
   callback,
});

export const getReportIdentifiersSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_REPORT_IDENTIFIERS_SUCCESS,
   payload,
});

export const getReportIdentifiersFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_REPORT_IDENTIFIERS_FAILURE,
   payload,
});

export const getVariableDatasets = (callback: Function = () => {}) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_DATASETS,
   callback,
});

export const getVariableDatasetsSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_DATASETS_SUCCESS,
   payload,
});
export const getVariableDatasetsFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_DATASETS_FAILURE,
   payload,
});

export const getVariableDataTables = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_TABLES,
   payload,
   callback,
});

export const getVariableDataTablesSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_TABLES_SUCCESS,
   payload,
});

export const getVariableDataTablesFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_TABLES_FAILURE,
   payload,
});

export const updateVariableIdentifiers = (
   payload: any,
   callback: Function
) => ({
   type: variablesAndMetadataTypes.UPDATE_VARIABLE_IDENTIFIERS,
   payload,
   callback,
});

export const updateVariableIdentifiersSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.UPDATE_VARIABLE_IDENTIFIERS_SUCCESS,
   payload,
});

export const updateVariableIdentifiersFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.UPDATE_VARIABLE_IDENTIFIERS_FAILURE,
   payload,
});

export const getVariableBQNames = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_BIG_QUERY_NAMES,
   payload,
   callback,
});

export const getVariableBQNamesSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_BIG_QUERY_NAMES_SUCCESS,
   payload,
});

export const getVariableBQNamesFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_VARIABLE_BIG_QUERY_NAMES_FAILURE,
   payload,
});

export const getVariableTGNames = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_TABLE_GENERATOR_NAMES,
   payload,
   callback,
});

export const getVariableTGNamesSuccess = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_TABLE_GENERATOR_NAMES_SUCCESS,
   payload,
});

export const getVariableTGNamesFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_TABLE_GENERATOR_NAMES_FAILURE,
   payload,
});

export const getExistingUINames = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_EXISTING_UI_NAMES,
   payload,
   callback,
});

export const getExistingUINamesSuccess = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: variablesAndMetadataTypes.GET_EXISTING_UI_NAMES_SUCCESS,
   payload,
   callback,
});

export const getExistingUINamesFailure = (payload: any) => ({
   type: variablesAndMetadataTypes.GET_EXISTING_UI_NAMES_FAILURE,
   payload,
});
