import { combineReducers } from 'redux';
import { modelDocument } from './modelDocument';
import { user } from './user';
import { project } from './project';
import { globals } from './globals';
import { role } from './role';
import { generatedDocument } from './generatedDocument';
import { workingDocument } from './workingDocument';
import { tutorial } from './tutorial';
import { ctdTitle } from './ctdTitles';
import { configuration } from './configuration';
import { conversions } from './conversionStyles';
import { internalReports } from './internalReport';
import { favorite } from './favorite';
import { status } from './status';
import { variables } from './variables';

const reducers = combineReducers({
   user,
   modelDocument,
   project,
   globals,
   role,
   workingDocument,
   generatedDocument,
   tutorial,
   ctdTitle,
   configuration,
   conversions,
   internalReports,
   favorite,
   status,
   variables,
});

export default reducers;
