import { COMPONENT_ERROR } from '../../../shared/CONSTANTS';
import { DocumentModifier, FileInterface } from '../fileInfo';
import { Metadata, Metadatainterface } from '../metadata';
import { BreezeMetadata } from './breezeMetadata/breezeMetadata';
import { IBreezeMetadata } from './breezeMetadata/breezeMetadataInterface';

export type WorkingDocumentCallback = (
   error: COMPONENT_ERROR,
   workingDocument: WorkingDocument[] | WorkingDocument | null
) => void;
export class WorkingDocument implements Partial<FileInterface> {
   constructor(
      public breezeMetadata: IBreezeMetadata = new BreezeMetadata(),
      public driveId: string = '',
      public id: number | null = 0,
      public section: string | undefined = '',
      public moleculeType: string | undefined = '',
      public modifiedDate: string | number | Date | undefined = '',
      public createdDate: string | number | Date | undefined = '',
      public name: string = '',
      public owner: any = '',
      public modifier: DocumentModifier = new DocumentModifier(),
      public fullName: string = '',
      public metadata: Metadatainterface = new Metadata(),
      public reportMetadata: object = {},
      public reportAutomation: boolean = true,
      public additionalMetadata: any[] = [],
      public description: string = '',
      public projects: any[] = [],
      public templateId: string = '',
      public projectId: string = '',
      public archived: boolean = false,
      public moleculeId: string = '',
      public mainRoNumber: string = '',
      public Projects: any[] = [],
      public variables: any[] = [],
      public template: {
         id: number | string;
         name: string;
         metadata: object;
      } = {
         id: '',
         name: '',
         metadata: {},
      },
      public isFavorite: boolean = false
   ) {}

   public static createWorkingDocumentObject(data: any): WorkingDocument {
      return new WorkingDocument(
         data.breezeMetadata,
         data.driveId,
         data.id,
         data?.metadata?.sectionCode,
         data?.Molecule?.title || data?.metadata?.productArea,
         new Date(data.updatedAt).toLocaleString(),
         new Date(data.createdAt).toLocaleString(),
         data.name,
         data.Creator?.username,
         new DocumentModifier(
            data.Modifier?.firstName,
            data.Modifier?.lastName
         ),
         `${data.Creator?.firstName} ${data.Creator?.lastName}`,
         data.metadata,
         data.reportMetadata,
         data.reportAutomation,
         data.additionalMetadata,
         data.description,
         data.Projects || [],
         data.templateId || '',
         data.projectId || '',
         data.archived || false,
         data.moleculeId || '',
         data.mainRoNumber || '',
         data.Projects,
         data.variables,
         data.template,
         data.isFavorite
      );
   }
}
