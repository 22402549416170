import { PasswordAuthenticator } from '../models/loginInterface';
import { UserInterface } from '../models/user/userInterface';
import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class UserApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static getUserInfo() {
      return fetch(`${baseUrl}api/v1/user/me`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static add(user: UserInterface) {
      return fetch(`${baseUrl}api/v1/user`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(user),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            localStorage.setItem('access-token', result.data.accessToken);
            return result.data;
         })
         .catch(handleError);
   }

   static login(credentials: PasswordAuthenticator) {
      return fetch(`${baseUrl}api/v1/user/login`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(credentials),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            localStorage.setItem('access-token', result.data.accessToken);
            return result;
         })
         .catch(handleError);
   }

   static getAllUsers() {
      return fetch(`${baseUrl}api/v1/user`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (users) => {
            const result = await handleResponse(users);
            return result.data;
         })
         .catch(handleError);
   }

   static updateUser(user: UserInterface) {
      return fetch(`${baseUrl}api/v1/user/${user.id}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(user),
      })
         .then(async (response) => {
            // TODO: Take user response from backend. We need to stop trusting the front-end. It can never be trusted.
            await handleResponse(response);
            return user;
         })
         .catch(handleError);
   }

   static unlockUserDocuments(driveId: string = '', documentType: string = '') {
      return fetch(`${baseUrl}api/v1/user/unlock`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ driveId, documentType }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static logout(user: UserInterface) {
      return fetch(`${baseUrl}api/v1/user/logout`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(user),
      })
         .then(async (response) => {
            return handleResponse(response);
         })
         .catch(handleError);
   }

   static sendSSOCode(code: string) {
      return fetch(`${baseUrl}api/v1/user/ssoauth`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ code }),
      })
         .then(async (response) => {
            return handleResponse(response);
         })
         .catch(handleError);
   }
}
