export const roleTypes = {
   LOAD_ROLES: 'LOAD_ROLES',
   LOAD_ROLES_SUCCESS: 'LOAD_ROLES_SUCCESS',
   LOAD_ROLES_FAILURE: 'LOAD_ROLES_FAILURE',
   UPDATE_USER_ROLE: 'UPDATE_USER_ROLE',
   UPDATE_USER_ROLE_SUCCESS: 'UPDATE_USER_ROLE_SUCCESS',
   UPDATE_USER_ROLE_FAILURE: 'UPDATE_USER_ROLE_FAILURE',
   CREATE_USER_ROLE: 'CREATE_USER_ROLE',
   CREATE_USER_ROLE_SUCCESS: 'CREATE_USER_ROLE_SUCCESS',
   CREATE_USER_ROLE_FAILURE: 'CREATE_USER_ROLE_FAILURE',
   UPDATE_ROLE_PERMISSIONS: 'UPDATE_ROLE_PERMISSIONS',
   UPDATE_ROLE_PERMISSIONS_SUCCESS: 'UPDATE_ROLE_PERMISSIONS_SUCCESS',
   UPDATE_ROLE_PERMISSIONS_FAILURE: 'UPDATE_ROLE_PERMISSIONS_FAILURE',
   GET_ROLE_BY_ID: 'GET_ROLE_BY_ID',
   GET_ROLE_BY_ID_SUCCESS: 'GET_ROLE_BY_ID_SUCCESS',
   GET_ROLE_BY_ID_FAILURE: 'GET_ROLE_BY_ID_FAILURE',
   CLEAR_ROLE_STATE: 'CLEAR_ROLE_STATE',
   LOAD_PROJECT_ROLES: 'LOAD_PROJECT_ROLES',
   LOAD_PROJECT_ROLES_SUCCESS: 'LOAD_PROJECT_ROLES_SUCCESS',
   LOAD_PERMISSIONS: 'LOAD_PERMISSIONS',
   LOAD_PERMISSIONS_SUCCESS: 'LOAD_PERMISSIONS_SUCCESS',
   LOAD_PERMISSIONS_FAILURE: 'LOAD_PERMISSIONS_FAILURE',
};
