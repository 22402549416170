import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserInterface } from '../models/user/userInterface';

export function isAdmin(currentUser: UserInterface): boolean {
   const adminRoleId = 1;
   const adminRoleName = 'Admin';
   const authUser = currentUser;

   return (
      authUser.role?.name.toLowerCase() === adminRoleName.toLowerCase() ||
      authUser.role?.id === adminRoleId
   );
}

export function isModelDocManager(currentUser: UserInterface): boolean {
   const roleName = 'Template Manager';
   const authUser = currentUser;

   return authUser.role?.name.toLowerCase() === roleName.toLowerCase();
}

export function haveUserPermission(
   currentUser: UserInterface,
   permissionToCheck: string
): boolean {
   const permissionPath = permissionToCheck.split('.');
   return !!(currentUser.role.permissions as Record<string, any>)[
      permissionPath[0]
   ][permissionPath[1]][permissionPath[2]];
}

export const render = (
   props: any,
   currentUser: UserInterface
): React.ReactNode => {
   if (currentUser.id) {
      if (
         (props.permissionsToOpen &&
            !haveUserPermission(currentUser, props.permissionsToOpen)) ||
         (props.adminOnly && !isAdmin(currentUser)) ||
         (props.adminOrModelDocManager &&
            !isAdmin(currentUser) &&
            !isModelDocManager(currentUser))
      ) {
         return <Redirect to={{ pathname: '/layout/dashboard' }} />;
      }
      const Component = props.component;

      return <Component {...props} />;
   }
   return <Redirect to={{ pathname: '/' }} />;
};
