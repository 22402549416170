import produce from 'immer';
import { globalsTypes } from '../types/globals';

const initialState = {
   systemTypes: [],
   molecules: [],
   breezeMolecules: [],
   chromoSteps: [],
   operationsList: {
      runs: [],
      input: [],
      inputComputer: [],
      kpi: [],
      kpiComputer: [],
      qa: [],
      qaComputer: [],
   },
   bbCodes: [],
   stages: [],
   systemNumbers: [],
   scopes: [],
   cacheLoading: false,
   systemData: {
      countries: [],
      products: [],
      productManufacturers: [],
      substances: [],
      substanceManufacturers: [],
   },
   guidance: [],
   breezeFormSelection: {
      breezeMetadata: [],
      description: '',
      documentStage: 0,
      id: 0,
      input: [],
      inputComputer: [],
      kpi: [],
      kpiComputer: [],
      kpi_metadata: [],
      modelDocumentId: 0,
      templateId: 0,
      molecule: '',
      name: '',
      outputsMetadata: [],
      qa: [],
      qaComputer: [],
      qa_metadata: [],
      project: '',
      projectId: '',
      runs: [],
      unit_ops: '',
      workingDocumentDriveId: '',
   },
   customizedToast: {
      toastMessage: 'Success!',
      toastType: 'success',
      toastOpen: false,
   },
   isAppActive: false,
   roNumbers: [],
   allRoNumbers: [],
   roNumber: {
      substances: [],
      products: [],
   },
   isRoNumberLoadedSuccess: false,
   isRoNumberLoadedFailure: false,
   selectedRoNumber: '',
   isSystemDataLoadedSuccess: false,
};

export const globals = (
   state = initialState,
   action: { type: string; payload: any; isAll?: boolean }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case globalsTypes.GET_SYSTEM_TYPES_SUCCESS:
            draft.systemTypes = payload;
            return draft;
         case globalsTypes.GET_SCOPES_SUCCESS:
            draft.scopes = payload;
            return draft;
         case globalsTypes.GET_MOlECULES_SUCCESS:
            draft.molecules = payload;
            return draft;
         case globalsTypes.GET_BREEZE_MOLECULES_SUCCESS:
            draft.breezeMolecules = payload;
            return draft;
         case globalsTypes.GET_CHROMO_STEPS_SUCCESS:
            draft.chromoSteps = payload;
            return draft;
         case globalsTypes.GET_OPERATIONS_LIST_SUCCESS:
            draft.operationsList = payload;
            return draft;
         case globalsTypes.GET_SELECTION_SUCCESS:
            draft.breezeFormSelection = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_DOCUMENT_DESCRIPTION_SUCCESS:
            draft.breezeFormSelection.description = payload;
            return draft;
         case globalsTypes.UPDATE_DOCUMENT_STAGE_SUCCESS:
            draft.breezeFormSelection.documentStage = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_PROJECT_ID_SUCCESS:
            draft.breezeFormSelection.projectId = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_DOCUMENT_NAME_SUCCESS:
            draft.breezeFormSelection.name = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_MODEL_DOCUMENT_ID_SUCCESS:
            draft.breezeFormSelection.modelDocumentId = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_MOLECULE_SUCCESS:
            draft.breezeFormSelection.molecule = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_STEP_SUCCESS:
            draft.breezeFormSelection.unit_ops = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_RUNS_SUCCESS:
            draft.breezeFormSelection.runs = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_INPUT_SUCCESS:
            draft.breezeFormSelection.input = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_INPUT_COMPUTER_SUCCESS:
            draft.breezeFormSelection.inputComputer = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_KPI_SUCCESS:
            draft.breezeFormSelection.kpi = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_KPI_COMPUTER_SUCCESS:
            draft.breezeFormSelection.kpiComputer = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_KPI_METADATA_SUCCESS:
            draft.breezeFormSelection.kpi_metadata = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_QA_SUCCESS:
            draft.breezeFormSelection.qa = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_QA_COMPUTER_SUCCESS:
            draft.breezeFormSelection.qaComputer = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_QA_METADATA_SUCCESS:
            draft.breezeFormSelection.qa_metadata = payload;
            return draft;
         case globalsTypes.UPDATE_SELECTED_OUTPUTS_METADATA_SUCCESS:
            draft.breezeFormSelection.outputsMetadata = payload;
            return draft;
         case globalsTypes.GET_WORKING_DOCUMENT_DRIVE_ID_SUCCESS:
            draft.breezeFormSelection.workingDocumentDriveId = payload;
            return draft;
         case globalsTypes.GET_BB_CODES_SUCCESS:
            draft.bbCodes = payload;
            return draft;
         case globalsTypes.GET_STAGES_SUCCESS:
            draft.stages = payload;
            return draft;
         case globalsTypes.GET_FAILURE:
            console.log(payload);
            return draft;
         case globalsTypes.GET_SYSTEM_NUMBERS_SUCCESS:
            draft.systemNumbers = payload;
            return draft;
         case globalsTypes.GET_SYSTEM:
            draft.isSystemDataLoadedSuccess = false;
            draft.systemData = payload;
            return draft;
         case globalsTypes.GET_SYSTEM_SUCCESS:
            draft.isSystemDataLoadedSuccess = true;
            draft.systemData = payload.result;
            return draft;
         case globalsTypes.GET_GUIDANCE_SUCCESS:
            draft.guidance = payload;
            return draft;
         case globalsTypes.GET_RO_NUMBERS_SUCCESS:
            if (payload.isAll) {
               draft.allRoNumbers = payload.data;
            } else {
               draft.roNumbers = payload.data;
            }
            return draft;
         case globalsTypes.GET_RO_NUMBERS_FAILURE:
            draft.roNumbers = [];
            return draft;
         case globalsTypes.GET_RO_NUMBER_SUCCESS:
            draft.isRoNumberLoadedSuccess = true;
            draft.isRoNumberLoadedFailure = false;
            draft.roNumber = payload.result;
            draft.selectedRoNumber = payload.number;
            return draft;
         case globalsTypes.UPDATE_CACHES:
            draft.cacheLoading = true;
            return draft;
         case globalsTypes.UPDATE_CACHES_SUCCESS:
            draft.cacheLoading = false;
            return draft;
         case globalsTypes.UPDATE_CACHES_FAILURE:
            draft.cacheLoading = false;
            return draft;
         case globalsTypes.FIND_RO_NUMBER:
            draft.isRoNumberLoadedSuccess = true;
            draft.isRoNumberLoadedFailure = false;
            if (!payload.code) {
               draft.roNumber = {
                  substances: [],
                  products: [],
               };
               draft.selectedRoNumber = '';
               return draft;
            }
            if (payload.from === 'project') {
               draft.roNumber = draft.roNumbers.find(
                  (ro: any) => ro.productFamilyCode === payload.code
               ) || {
                  substances: [],
                  products: [],
               };
            } else {
               draft.roNumber = draft.allRoNumbers.find(
                  (ro: any) => ro.productFamilyCode === payload.code
               ) || {
                  substances: [],
                  products: [],
               };
            }
            draft.selectedRoNumber = payload.code;
            return draft;
         case globalsTypes.GET_RO_NUMBER_FAILURE:
            draft.isRoNumberLoadedSuccess = false;
            draft.isRoNumberLoadedFailure = true;
            return draft;
         case globalsTypes.SET_TOAST:
            draft.customizedToast = payload;
            return draft;
         case globalsTypes.ACTIVATE_APP:
            draft.isAppActive = payload.isLoggedIn;
            return draft;
         case globalsTypes.DEACTIVATE_APP:
            draft.isAppActive = payload.isLoggedIn;
            return draft;
         case globalsTypes.CLEAR_GLOBAL_STATE:
            draft = { ...initialState };
            return draft;
         default:
            return draft;
      }
   });
