import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { GlobalState } from '../../../core/models/globalState';
import { setToast } from '../../../redux/actions/globals';

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      '& > * + *': {
         marginTop: theme.spacing(2),
      },
   },
}));

const IACustomizedToast = () => {
   const classes = useStyles();
   const dispatch = useDispatch();
   let autoHideDuration = 2000;

   const { toastOpen, toastType, toastMessage } = useSelector(
      (state: GlobalState) => state.globals.customizedToast
   );

   if (toastType !== 'success') {
      autoHideDuration = 6000;
   }

   const handleClose = (
      event: React.SyntheticEvent<Element, Event> | undefined
   ) => {
      if (event) {
         return;
      }
      dispatch(setToast({ toastOpen: false, toastType, toastMessage }));
   };

   return (
      <div className={classes.root}>
         <Snackbar
            open={toastOpen}
            autoHideDuration={autoHideDuration}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
         >
            <Alert elevation={6} variant="filled" color={toastType}>
               {toastMessage}
            </Alert>
         </Snackbar>
      </div>
   );
};

export default IACustomizedToast;
