import { Permission } from './permissionItem';

export class LandingOptions {
   constructor(
      public project: Permission[] = [],
      public template: Permission[] = [],
      public working_document: Permission[] = [],
      public generated_document: Permission[] = []
   ) {}
}
