export class Scopes {
   id: number;

   name: string;
}

export function createScopeObject(data: any) {
   const scope = new Scopes();

   scope.id = data.id;
   scope.name = data.type;

   return scope;
}
