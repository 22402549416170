import { User } from '../user/user';

export interface ConversionStyleInterface {
   isDefault: boolean;
   lastModifiedBy: string;
   name: string;
   id: number;
   styles: Record<string, string>;
   Modifier?: User;
}

export class ConversionStyle implements ConversionStyleInterface {
   constructor(
      public isDefault = false,
      public lastModifiedBy = '',
      public name = '',
      public id = 0,
      public styles = {}
   ) {}

   public static createConversionStyleObject(data: ConversionStyleInterface) {
      return new ConversionStyle(
         data.isDefault,
         data.Modifier
            ? `${data.Modifier.firstName} ${data.Modifier.lastName}`
            : data.lastModifiedBy,
         data.name,
         data.id,
         data.styles
      );
   }
}
