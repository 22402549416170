import { handleError, handleResponse } from './apiUtils';
import { TutorialsInterface } from '../models/tutorial/tutorials';

const baseUrl = process.env.REACT_APP_API_URL;

export class TutorialApi {
   static createNewTutorial(tutorial: any) {
      return fetch(`${baseUrl}api/v1/tutorial/createTutorial`, {
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(tutorial),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static translateTutorialText(data: TutorialsInterface) {
      return fetch(`${baseUrl}api/v1/tutorial/translateTutorialText`, {
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static loadTutorials(pageSize: number) {
      return fetch(`${baseUrl}api/v1/tutorial/getTutorials?page=${pageSize}`, {
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data || [];
         })
         .catch(handleError);
   }

   static deleteTutorial(tutorialId: number) {
      return fetch(`${baseUrl}api/v1/tutorial/removeTutorialById`, {
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ tutorial_id: tutorialId }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }
}
