export const globalsTypes = {
   GET_SYSTEM: 'GET_SYSTEM',
   GET_SYSTEM_SUCCESS: 'GET_SYSTEM_SUCCESS',
   GET_SCOPES: 'GET_SCOPES',
   GET_SCOPES_SUCCESS: 'GET_SCOPES_SUCCESS',
   GET_SYSTEM_TYPES: 'GET_SYSTEM_TYPES',
   GET_SYSTEM_TYPES_SUCCESS: 'GET_SYSTEM_TYPES_SUCCESS',
   GET_MOLECULES: 'GET_MOLECULES',
   GET_MOlECULES_SUCCESS: 'GET_MOLECULES_SUCCESS',
   GET_BREEZE_MOLECULES: 'GET_BREEZE_MOLECULES',
   GET_BREEZE_MOLECULES_SUCCESS: 'GET_BREEZE_MOLECULES_SUCCESS',
   GET_CHROMO_STEPS: 'GET_CHROMO_STEPS',
   GET_CHROMO_STEPS_SUCCESS: 'GET_CHROMO_STEPS_SUCCESS',
   GET_OPERATIONS_LIST: 'GET_OPERATIONS_LIST',
   GET_OPERATIONS_LIST_SUCCESS: 'GET_OPERATIONS_LIST_SUCCESS',
   GET_SELECTION: 'GET_SELECTION',
   GET_SELECTION_SUCCESS: 'GET_SELECTION_SUCCESS',
   UPDATE_SELECTED_DOCUMENT_DESCRIPTION_SUCCESS:
      'UPDATE_SELECTED_DOCUMENT_DESCRIPTION_SUCCESS',
   UPDATE_DOCUMENT_STAGE_SUCCESS: 'UPDATE_DOCUMENT_STAGE_SUCCESS',
   UPDATE_SELECTED_MODEL_DOCUMENT_ID_SUCCESS:
      'UPDATE_SELECTED_MODEL_DOCUMENT_ID_SUCCESS',
   UPDATE_SELECTED_DOCUMENT_NAME_SUCCESS:
      'UPDATE_SELECTED_DOCUMENT_NAME_SUCCESS',
   UPDATE_SELECTED_PROJECT_ID_SUCCESS: 'UPDATE_SELECTED_PROJECT_ID_SUCCESS',
   UPDATE_SELECTED_MOLECULE: 'UPDATE_SELECTED_MOLECULE',
   UPDATE_SELECTED_MOLECULE_SUCCESS: 'UPDATE_SELECTED_MOLECULE_SUCCESS',
   UPDATE_SELECTED_STEP: 'UPDATE_SELECTED_STEP',
   UPDATE_SELECTED_STEP_SUCCESS: 'UPDATE_SELECTED_STEP_SUCCESS',
   UPDATE_SELECTED_RUNS: 'UPDATE_SELECTED_RUNS',
   UPDATE_SELECTED_RUNS_SUCCESS: 'UPDATE_SELECTED_RUNS_SUCCESS',
   UPDATE_SELECTED_INPUT_SUCCESS: 'UPDATE_SELECTED_INPUT_SUCCESS',
   UPDATE_SELECTED_INPUT_COMPUTER_SUCCESS:
      'UPDATE_SELECTED_INPUT_COMPUTER_SUCCESS',
   UPDATE_SELECTED_KPI_SUCCESS: 'UPDATE_SELECTED_KPI_SUCCESS',
   UPDATE_SELECTED_KPI_COMPUTER_SUCCESS: 'UPDATE_SELECTED_KPI_COMPUTER_SUCCESS',
   UPDATE_SELECTED_KPI_METADATA_SUCCESS: 'UPDATE_SELECTED_KPI_METADATA_SUCCESS',
   UPDATE_SELECTED_QA_SUCCESS: 'UPDATE_SELECTED_QA_SUCCESS',
   UPDATE_SELECTED_QA_COMPUTER_SUCCESS: 'UPDATE_SELECTED_QA_COMPUTER_SUCCESS',
   UPDATE_SELECTED_QA_METADATA: 'UPDATE_SELECTED_QA_METADATA',
   UPDATE_SELECTED_QA_METADATA_SUCCESS: 'UPDATE_SELECTED_QA_METADATA_SUCCESS',
   UPDATE_SELECTED_OUTPUTS_METADATA_SUCCESS:
      'UPDATE_SELECTED_OUTPUTS_METADATA_SUCCESS',
   GET_WORKING_DOCUMENT_DRIVE_ID: 'GET_WORKING_DOCUMENT_DRIVE_ID',
   GET_WORKING_DOCUMENT_DRIVE_ID_SUCCESS:
      'GET_WORKING_DOCUMENT_DRIVE_ID_SUCCESS',
   UPDATE_BREEZE_TRIGGER_VALUES_SUCCESS: 'UPDATE_BREEZE_TRIGGER_VALUES_SUCCESS',
   GET_FAILURE: 'GET_FAILURE',
   GET_BB_CODES: 'GET_BB_CODES',
   GET_BB_CODES_SUCCESS: 'GET_BB_CODES_SUCCESS',
   GET_STAGES: 'GET_STAGES',
   GET_STAGES_SUCCESS: 'GET_STAGES_SUCCESS',
   GET_SYSTEM_NUMBERS: 'GET_SYSTEM_NUMBERS',
   GET_SYSTEM_NUMBERS_SUCCESS: 'GET_SYSTEM_NUMBERS_SUCCESS',
   GET_GUIDANCE: 'GET_GUIDANCE',
   GET_GUIDANCE_SUCCESS: 'GET_GUIDANCE_SUCCESS',
   SET_TOAST: 'SET_TOAST',
   ACTIVATE_APP: 'ACTIVATE_APP',
   DEACTIVATE_APP: 'DEACTIVATE_APP',
   CLEAR_GLOBAL_STATE: 'CLEAR_APPLICATION_STATE',
   GET_BIG_QUERY_TABLE_PREVIEW_OF_SELECTIONS:
      'GET_BIG_QUERY_TABLE_PREVIEW_OF_SELECTIONS',
   GET_RO_NUMBERS: 'GET_RO_NUMBERS',
   GET_RO_NUMBERS_SUCCESS: 'GET_RO_NUMBERS_SUCCESS',
   GET_RO_NUMBERS_FAILURE: 'GET_RO_NUMBERS_FAILURE',
   GET_RO_NUMBER: 'GET_RO_NUMBER',
   GET_RO_NUMBER_SUCCESS: 'GET_RO_NUMBER_SUCCESS',
   GET_RO_NUMBER_FAILURE: 'GET_RO_NUMBER_FAILURE',
   UPDATE_CACHES: 'UPDATE_CACHES',
   UPDATE_CACHES_SUCCESS: 'UPDATE_CACHES_SUCCESS',
   UPDATE_CACHES_FAILURE: 'UPDATE_CACHES_FAILURE',
   FIND_RO_NUMBER: 'FIND_RO_NUMBER',
};
