import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
   Drawer,
   Button,
   ListItem,
   Collapse,
   Typography,
   CardContent,
   ListItemIcon,
   ListItemText,
   MobileStepper,
   IconButton,
} from '@material-ui/core';
import {
   ExpandLess,
   ExpandMore,
   KeyboardArrowLeft,
   KeyboardArrowRight,
   CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonContext from '../../context/ButtonPopupContext';
import BolderClose from '../../icons/BolderClose';

const guideDrawerWidth = 300;

const useStyles = makeStyles((theme) => ({
   list: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
   },
   drawerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      padding: '8px 5px',
      backgroundColor: '#0066cc',
      color: '#ffffff',
   },
   drawer: {
      width: guideDrawerWidth,
      flexShrink: 0,
   },
   drawerPaper: {
      width: guideDrawerWidth,
      backgroundColor: '#f4f5f7',
      borderRight: (isSidebarOpen) =>
         isSidebarOpen ? '1px solid rgba(0, 0, 0, 0.12)' : 'none',
   },
   drawerRoot: {
      width: guideDrawerWidth,
      position: 'fixed',
   },
   fullList: {
      width: 'auto',
   },
   toolbar: {
      ...theme.mixins.toolbar,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
   },
   root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
   },
   nested: {
      paddingLeft: theme.spacing(4),
   },
   helpButton: {
      position: 'fixed',
      bottom: '0',
      right: (props: any) => (props.direction === 'left' ? 'unset' : 0),
      left: (props: any) => (props.direction === 'left' ? 0 : 'unset'),
      margin: 40,
   },
   doneCircleIcon: {
      fill: '#36b17e',
   },
   notDoneCircleIcon: {
      fill: '#dfe1e6',
   },
   listItem: {
      borderTop: '6px solid #36b17e',
      backgroundColor: 'rgb(255, 255, 255)',
      margin: '10px',
      borderRadius: '4px',
      lineHeight: '20px',
      boxShadow:
         'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.31) 0px 0px 1px 0px',
      transition: 'height 2000ms ease 0s',
      cursor: 'pointer',
      '&:hover, &:focus': {
         backgroundColor: 'rgb(255, 255, 255)',
      },
   },
   collapse: {
      width: '100%',
      borderRadius: '4px',
      backgroundColor: 'rgb(255, 255, 255)',
   },
   sidebarTitle: {
      fontSize: '24px',
      color: '#fff',
      paddingLeft: '20px',
   },
   listWrapper: {
      overflowY: 'auto',
      padding: '0 18px',
      width: '100%',
   },
   closeBtn: {
      marginRight: 'unset',
   },
   loading: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
   },
}));

function Sidebar({
   guidanceData,
   isSidebarOpen,
   toggleDrawer,
   testingPage,
}: any) {
   const buttonPopupData = useContext(ButtonContext);
   const { defaultLang } = buttonPopupData;
   guidanceData =
      guidanceData === undefined ? { userTutorialResult: [] } : guidanceData;

   const location = useLocation();
   const classes = useStyles(isSidebarOpen);

   const [pageTutorials, setPageTutorials] = useState([]);
   const [openDropdownIndex, setOpenDropdownIndex] = useState(null);

   const languages = ['en', 'de'];
   const defaultLanguage = 'en';
   const browserLanguage = window.navigator.language;
   const existingBrowserLanguage = languages.find((language) =>
      browserLanguage.startsWith(language)
   );

   const [selectedLanguage, setSelectedLanguage] = React.useState(
      existingBrowserLanguage || defaultLanguage
   );

   useEffect(() => {
      setSelectedLanguage(defaultLang);
   }, [defaultLang]);

   const findSidebarDataByPage = () => {
      const sidebarData = guidanceData.userTutorialResult;
      const pageKeys = sidebarData.map((item: any) => {
         return item.page;
      });
      const currentPageKey = pageKeys.find((item: any) => {
         const data = sidebarData.filter((s: any) => {
            if (item === s.page) {
               return s;
            }
         });
         const params = data[0].page.includes(':');
         if (!params) {
            return item.includes(location.pathname);
         }
         const paramsStart = item.indexOf(':');
         const path = item.slice(0, paramsStart);
         return location.pathname.startsWith(path);
      });
      return sidebarData.filter((item: any) => {
         if (item.page === currentPageKey || item.page === testingPage) {
            return item;
         }
      });
   };

   const getModifiedTutorialsData = (data: any) => {
      const getSteps = (tutorial: any) => {
         const steps = [];
         for (let i = 0; i <= tutorial.number_of_steps - 1; i++) {
            steps.push({
               title: tutorial.tutorial_title,
               description: tutorial.steps[i].description,
            });
         }
         return steps;
      };

      if (data && data.tutorials) {
         return data.tutorials.map((item: any) => {
            const {
               tutorial_id: id,
               tutorial_title: title,
               number_of_steps: allSteps,
            } = item;
            const steps = getSteps(item);

            return {
               id,
               activeStep: item.current_step - 1,
               allSteps,
               title,
               steps,
            };
         });
      }

      return [];
   };

   useEffect(() => {
      if (guidanceData.length !== 0) {
         const data = findSidebarDataByPage();
         const tutorials = getModifiedTutorialsData(data[0]);
         setPageTutorials(tutorials);
      }
   }, [guidanceData, location]);

   const toggleDropdownItem = (index: number) => {
      const newValue: any = index === openDropdownIndex ? null : index;
      setOpenDropdownIndex(newValue);
   };

   const handleStepChange = (type: string, tutorialIndex: number) => {
      const tutorialsCopy: any = [...pageTutorials];
      const { activeStep: prevActiveStep } = tutorialsCopy[tutorialIndex];
      tutorialsCopy[tutorialIndex].activeStep =
         type === 'back' ? prevActiveStep - 1 : prevActiveStep + 1;
      setPageTutorials(tutorialsCopy);
   };

   const getButton = (type: string, tutorial: any, index: number) => {
      const disabled =
         type === 'back'
            ? tutorial.activeStep === 0
            : tutorial.allSteps - 1 === tutorial.activeStep;

      return (
         <Button
            size="small"
            disabled={disabled}
            onClick={() => handleStepChange(type, index)}
            startIcon={type === 'back' ? <KeyboardArrowLeft /> : null}
            endIcon={type !== 'back' ? <KeyboardArrowRight /> : null}
         >
            {type === 'back' ? 'Back' : 'Next'}
         </Button>
      );
   };

   const handleThickClick = (event: any, index: number) => {
      event.stopPropagation();
      const tutorialsCopy: any = [...pageTutorials];
      const isTutorialDone =
         tutorialsCopy[index].activeStep === tutorialsCopy[index].allSteps - 1;
      if (isTutorialDone) {
         tutorialsCopy[index].activeStep = 0;
      } else {
         tutorialsCopy[index].activeStep = tutorialsCopy[index].allSteps - 1;
      }
      setPageTutorials(tutorialsCopy);
   };

   const list = (
      <div
         className={clsx(classes.list)}
         role="presentation"
         onKeyDown={toggleDrawer(false)}
      >
         {pageTutorials &&
            pageTutorials.map((tutorial: any, index) => (
               <React.Fragment key={`${tutorial.id} ${index}`}>
                  <div className={`${classes.listItem} user-guidance-item`}>
                     <ListItem
                        button
                        onClick={() => toggleDropdownItem(index)}
                        disableRipple
                     >
                        <ListItemIcon>
                           <CheckCircleIcon
                              onClick={(event) =>
                                 handleThickClick(event, index)
                              }
                              className={`${
                                 tutorial.activeStep === tutorial.allSteps - 1
                                    ? classes.doneCircleIcon
                                    : classes.notDoneCircleIcon
                              } check-icon`}
                           />
                        </ListItemIcon>
                        <ListItemText
                           style={{ marginRight: '5px' }}
                           primary={tutorial.title[selectedLanguage]}
                        />
                        {openDropdownIndex === index ? (
                           <ExpandMore />
                        ) : (
                           <ExpandLess />
                        )}
                     </ListItem>
                     <Collapse
                        in={openDropdownIndex === index}
                        timeout="auto"
                        unmountOnExit
                        className={classes.collapse}
                     >
                        <CardContent>
                           <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                           >
                              {
                                 tutorial.steps[tutorial.activeStep]
                                    .description[selectedLanguage]
                              }
                           </Typography>
                        </CardContent>
                        <MobileStepper
                           steps={tutorial.allSteps}
                           activeStep={tutorial.activeStep}
                           position="static"
                           variant="text"
                           nextButton={getButton('next', tutorial, index)}
                           backButton={getButton('back', tutorial, index)}
                        />
                     </Collapse>
                  </div>
               </React.Fragment>
            ))}
      </div>
   );

   return (
      <Drawer
         className={classes.drawer}
         id="user-guidance"
         variant="persistent"
         open={isSidebarOpen}
         onClose={toggleDrawer(false)}
         classes={{
            paper: classes.drawerPaper,
         }}
      >
         <div className={classes.toolbar}>
            <div className={classes.drawerHeader}>
               <Typography
                  className={classes.sidebarTitle}
                  variant="body2"
                  color="textPrimary"
                  component="h3"
               >
                  User Guide
               </Typography>

               <IconButton
                  size="small"
                  color="inherit"
                  id="close-guidance"
                  onClick={toggleDrawer(false)}
               >
                  <BolderClose
                     className={classes.closeBtn}
                     style={{
                        width: '15px',
                        height: '15px',
                        fill: '#fff',
                        stroke: '#fff',
                     }}
                  />
               </IconButton>
            </div>
            {guidanceData.length === 0 ? (
               <div className={classes.loading}>
                  <CircularProgress value={30} size="50px" />
               </div>
            ) : (
               <div className={classes.listWrapper}>{list}</div>
            )}
         </div>
      </Drawer>
   );
}

export default Sidebar;
