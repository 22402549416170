import { put, call, takeLatest } from 'redux-saga/effects';
import { ProjectApi } from '../../core/api';
import { Project } from '../../core/models/project/project';
import { ProjectInterface } from '../../core/models/project/projectInterface';
import {
   addProjectSuccess,
   addProjectFailure,
   getProjectByIdSuccess,
   getProjectByIdFailure,
   getAllProjectsSuccess,
   getAllProjectsFailure,
   updateProjectSuccess,
   updateProjectFailure,
   getAssignedProjectsSuccess,
   deleteProjectSuccess,
   deleteProjectFailure,
   archiveProjectSuccess,
   archiveProjectFailure,
   deleteDocumentFromProjectSuccess,
   getDivisionsSuccess,
   getDivisionsFailure,
   unassignDocumentSuccess,
   getProjectFilterDataSuccess,
   getProjectFilterDataFailure,
} from '../actions/project';
import { projectTypes } from '../types/project';
import { workingDocumentTypes } from '../types/workingDocument';
import * as CONSTANTS from '../../shared/CONSTANTS';

export function* getCurrentProject(action: {
   type: string;
   payload: { projectId: number; queryText: string };
   callback: Function;
}) {
   try {
      const { projectId, queryText } = action.payload;
      const result: ProjectInterface = yield call(() =>
         ProjectApi.getProjectById(projectId, queryText)
      );
      const data = Project.createProjectObject(result);
      yield put(getProjectByIdSuccess(data));
      action.callback(null, data);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(getProjectByIdFailure(error));
   }
}

export function* getAllProjects(action: {
   type: string;
   payload: any;
   callback: Function | boolean;
}) {
   try {
      const {
         page,
         size,
         searchQuery,
         includeWorkingDocuments,
      } = action.payload;
      const response: {
         rows: ProjectInterface[];
         count: number;
      } = yield call(() =>
         ProjectApi.loadProjects(
            page,
            size,
            searchQuery,
            includeWorkingDocuments
         )
      );
      yield put(
         getAllProjectsSuccess({ ...response, includeWorkingDocuments })
      );
      if (action.callback instanceof Function)
         action.callback(null, response.rows);
   } catch (error) {
      if (action.callback instanceof Function) {
         action.callback(JSON.parse(error.message));
      }
      yield put(getAllProjectsFailure(error));
   }
}

export function* getAssignedProjects(action: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const { page, size, includeWorkingDocuments } = action.payload;
      const assignedProjects: ProjectInterface[] = yield call(() =>
         ProjectApi.loadAssignedProjects(page, size, includeWorkingDocuments)
      );
      yield put(getAssignedProjectsSuccess(assignedProjects));
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(getAllProjectsFailure(error));
   }
}

export function* addProject(action: {
   type: string;
   payload: ProjectInterface;
   callback: Function;
}) {
   try {
      const projectData = action.payload;
      const data: ProjectInterface = yield call(() =>
         ProjectApi.createNewProject(projectData)
      );
      yield put(addProjectSuccess(data));
      action.callback(null);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(addProjectFailure(error));
   }
}

export function* updateProject(action: {
   type: string;
   payload: ProjectInterface;
   callback: Function;
}) {
   try {
      const projectData = action.payload;
      const updatedProject: ProjectInterface = yield call(() =>
         ProjectApi.updateProject(projectData)
      );
      yield put(updateProjectSuccess(updatedProject));

      action.callback(null);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(updateProjectFailure(error));
   }
}

function* deleteProject(action: {
   type: string;
   payload: number;
   callback: Function;
}) {
   try {
      const projectId = action.payload;
      // TODO: Use projectId from response
      yield call(() => ProjectApi.deleteProject(projectId));
      yield put(deleteProjectSuccess(projectId));
      action.callback(null);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(deleteProjectFailure(error));
   }
}

function* assignDocuments(action: { type: string; payload: any }) {
   const { documentList, projectId, callback } = action.payload;
   try {
      yield call(() =>
         ProjectApi.assignDocumentToProject(documentList, projectId)
      );
      yield put({
         type: workingDocumentTypes.GET_ASSIGN_WORKING_DOCUMENTS,
         payload: {
            page: 1,
            size: CONSTANTS.DEFAULT_WORKING_DOCUMENT_SIZE,
         },
      });
      yield callback(null);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield deleteProjectFailure(error);
   }
}

function* deleteDocumentFromProject(action: { type: string; payload: any }) {
   const { documentId, projectId, callback } = action.payload;
   try {
      const response: {
         code: number;
         message: string;
         data: Object;
      } = yield call(() =>
         ProjectApi.deleteDocumentFromProject(projectId, documentId)
      );
      if (response.code === 200) {
         yield put(deleteDocumentFromProjectSuccess(projectId, documentId));
      } else {
         yield deleteProjectFailure(response);
      }
      callback(null, response.data);
   } catch (error) {
      callback(JSON.parse(error.message));
      yield deleteProjectFailure(error);
   }
}

export function* archiveProject(action: {
   type: string;
   payload: any;
   callback: Function;
}) {
   try {
      const { projectId, archived, isDocumentArchived } = action.payload;
      const response: {
         code: number;
         message: string;
         data: Object;
      } = yield call(() =>
         ProjectApi.archiveProject(projectId, archived, isDocumentArchived)
      );
      if (response.code === 200) {
         yield put(archiveProjectSuccess(projectId, archived, response.data));
      }

      action.callback(null);
   } catch (error) {
      action.callback(JSON.parse(error.message));
      yield put(archiveProjectFailure(error));
   }
}

function* getDivisions({ callback }: { type: string; callback: Function }) {
   try {
      const divisions = yield call(() => ProjectApi.getDivisions());
      yield put(getDivisionsSuccess(divisions));
      callback(null, divisions);
   } catch (error) {
      callback(JSON.parse(error.message), null);
      yield put(getDivisionsFailure());
   }
}

function* unassignDocument(action: {
   type: string;
   payload: { documentId: number; projectId: number };
   callback: Function;
}) {
   try {
      const response: {
         code: number;
         message: string;
         data: Object;
      } = yield call(() => ProjectApi.unassignDocument(action.payload));
      if (response.code === 200) {
         yield put(unassignDocumentSuccess(action.payload));
      }

      action.callback(null);
   } catch (error) {
      console.log(error);
      action.callback(JSON.parse(error.message), null);
   }
}

function* getProjectFilterData(action: {
   type: string;
   payload: string | null;
   callback: Function;
}) {
   try {
      const projectFilterData = yield call(() =>
         ProjectApi.getProjectFiltersData(action.payload)
      );
      yield put(getProjectFilterDataSuccess(projectFilterData));
      action.callback(null, projectFilterData);
   } catch (error) {
      action.callback(JSON.parse(error.message), null);
      yield put(getProjectFilterDataFailure());
   }
}

export default function* projectWatcher() {
   yield takeLatest(projectTypes.GET_CURRENT_PROJECT, getCurrentProject);
   yield takeLatest(projectTypes.GET_ALL_PROJECTS, getAllProjects);
   yield takeLatest(
      projectTypes.GET_ALL_ASSIGNED_PROJECTS,
      getAssignedProjects
   );
   yield takeLatest(projectTypes.ADD_PROJECT, addProject);
   yield takeLatest(projectTypes.UPDATE_PROJECT, updateProject);
   yield takeLatest(projectTypes.DELETE_PROJECT, deleteProject);
   yield takeLatest(projectTypes.ASSIGN_DOCUMENTS_TO_PROJECT, assignDocuments);
   yield takeLatest(
      projectTypes.DELETE_DOCUMENT_FROM_PROJECT,
      deleteDocumentFromProject
   );
   yield takeLatest(projectTypes.ARCHIVE_PROJECT, archiveProject);
   yield takeLatest(projectTypes.GET_DIVISIONS, getDivisions);
   yield takeLatest(projectTypes.UNASSIGN_DOCUMENT, unassignDocument);
   yield takeLatest(projectTypes.GET_PROJECT_FILTER_DATA, getProjectFilterData);
}
