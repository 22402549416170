import React, {
   useEffect,
   useState,
   KeyboardEvent,
   useRef,
   useContext,
} from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Menu, Box, Snackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useCookies } from 'react-cookie';
import ReactTooltip from 'react-tooltip';
import LanguageDialog from '../Guidance/LanguageDialog';
import ButtonContext from '../../context/ButtonPopupContext';
import {
   setOnlineUsers,
   LOG_OUT_USER_ACTION,
   updateUserSuccess,
} from '../../../redux/actions/user';
import { GlobalState } from '../../../core/models/globalState';
import { UserApi, RevisionApi } from '../../../core/api';
import { SocketService } from '../../../core/services/socket';
import { UserInterface } from '../../../core/models/user/userInterface';
import {
   ACTIVATE_APP,
   CLEAR_GLOBAL_STATE,
   DEACTIVATE_APP,
   getBreezeChromoStepsDetailsSuccess,
} from '../../../redux/actions/globals';
import {
   CLEAR_ROLE_STATE,
   loadRoles as loadUserRoles,
} from '../../../redux/actions/role';
import { dev } from '../../../_dev/_utils';
import { CLEAR_PROJECT_STATE } from '../../../redux/actions/project';
import {
   getDocumentByDriveId,
   clearWorkingDocumentForm,
   clearWorkingDocumentPlaceHolders,
   CLEAR_WORKING_DOCUMENT_STATE,
} from '../../../redux/actions/workingDocument';
import { CLEAR_MODEL_DOCUMENT_STATE } from '../../../redux/actions/modelDocument';
import { CLEAR_GENERATED_DOCUMENT_STATE } from '../../../redux/actions/generatedDocument';
import ErrorInfoDialog from '../ErrorInfoDialog';
import { COMPONENT_ERROR } from '../../CONSTANTS';
import WarningIcon from '../../icons/WarningIcon';

export const Navbar: React.FC = () => {
   const dispatch = useDispatch();
   const { currentUser } = useSelector((state: GlobalState) => state.user);
   const { documentData } = useSelector(
      (state: GlobalState) => state.workingDocument
   );
   const { isAppActive } = useSelector(
      (state: GlobalState) => state.globals,
      shallowEqual
   );
   const buttonPopupData = useContext(ButtonContext);
   const { defaultLang, resetTutorialsFunction } = buttonPopupData;
   const location = useLocation();
   const history = useHistory();
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [cookies, setCookie, removeCookie] = useCookies([
      'id_token',
      'access_token',
   ]);
   const [open, setOpen] = useState(false);
   const [openLanguageDialog, setOpenLanguageDialog] = useState(false);
   const [socket, setSocket] = useState(SocketService.socket);
   const [openedDocumentName, setOpenedDocumentName] = useState<any>('');
   const anchorRef = useRef<HTMLButtonElement>(null);
   const languages = ['en', 'de'];
   const defaultLanguage = 'en';
   const browserLanguage = window.navigator.language;
   const existingBrowserLanguage = languages.find((language) =>
      browserLanguage.startsWith(language)
   );
   const [selectedLanguage, setSelectedLanguage] = useState(
      existingBrowserLanguage || defaultLanguage
   );
   const [error, setError] = useState<COMPONENT_ERROR>(null);
   const [successText, setSuccessText] = useState<string>('');
   const menuContainer = useRef<HTMLDivElement>(null);

   const isEdit = history.location.pathname.includes('update_document');
   const creatingNewDocument = history.location.pathname.includes(
      'new_document'
   );
   const isEditorOpen =
      history.location.pathname.includes('authordocument/edit/') ||
      history.location.pathname.includes('modeldocument/edit/');
   const driveIdFromUrl = /[^/]*$/.exec(location.pathname)?.[0];

   const environment = process.env.REACT_APP_APPLICATION_ENV;

   useEffect(() => {
      if (isEditorOpen) {
         dispatch(
            getDocumentByDriveId(
               // @ts-ignore
               location.state?.driveId || driveIdFromUrl,
               (responseError: COMPONENT_ERROR) => {
                  if (responseError) {
                     setError(responseError);
                  }
               }
            )
         );
      }
   }, [isEditorOpen]);

   useEffect(() => {
      let name: string;
      const { document } = documentData;
      if (document?.Projects?.length) {
         const project = documentData?.document?.Projects.map(
            (item: any) => item.name
         );
         name = `${project.join(', ')} > ${documentData?.document?.name}`;
      } else {
         name = documentData?.document?.name;
      }
      const docInfo = documentData?.document?.archived
         ? 'Archived'
         : documentData?.document?.status
         ? documentData?.document?.status === 'DRAFT'
            ? documentData?.document?.status
            : `${documentData?.document?.status} - view only`
         : '';
      name = `${name} ${docInfo ? `(${docInfo})` : ''}`;
      setOpenedDocumentName(name);
      if (documentData?.inform) {
         setSuccessText(documentData?.message);
      }
   }, [documentData]);

   useEffect(() => {
      if (!isEdit) {
         dispatch(clearWorkingDocumentForm());
      }
   }, [isEdit]);

   useEffect(() => {
      if (!creatingNewDocument && !isEdit) {
         dispatch(clearWorkingDocumentPlaceHolders());
         dispatch(
            getBreezeChromoStepsDetailsSuccess([
               {
                  qa: [],
                  runs: [],
                  kpi: [],
                  input: [],
                  inputComputer: [],
                  kpiComputer: [],
                  qaComputer: [],
               },
            ])
         );
      }
   }, [creatingNewDocument]);

   const clickAwayEvent = () => {
      setOpen(false);
   };

   useEffect(() => {
      let unmounted = false;
      if (!unmounted) {
         resetTutorialsFunction(false);
         menuContainer?.current?.addEventListener('click', clickAwayEvent);
      }
      return () => {
         unmounted = true;
      };
   }, []);

   function logoutSocket(user: UserInterface): void {
      socket.emit('logout', { user });
      socket.disconnect();
   }

   const clearApplication = () => {
      dispatch(CLEAR_GLOBAL_STATE());
      dispatch(CLEAR_PROJECT_STATE());
      dispatch(CLEAR_WORKING_DOCUMENT_STATE());
      dispatch(CLEAR_MODEL_DOCUMENT_STATE());
      dispatch(CLEAR_ROLE_STATE());
      dispatch(CLEAR_GENERATED_DOCUMENT_STATE());
   };

   async function logout(): Promise<boolean> {
      if (!currentUser?.emailAddress) {
         return false;
      }
      await UserApi.unlockUserDocuments();
      await RevisionApi.createRevision();
      const user = { ...currentUser, status: 'offline' };
      logoutSocket(user);
      dispatch(
         LOG_OUT_USER_ACTION(user, async () => {
            localStorage.removeItem('isLoggedIn');
            localStorage.removeItem('filterQuery');
            localStorage.removeItem('filterData');
            localStorage.removeItem('access-token');
            clearApplication();
            removeCookie('ia_session', { path: '/' });
            removeCookie('ia_pvreports_session', { path: '/' });
            removeCookie('cmsAuth', { path: '/' });
            history.push('/');
         })
      );
      // dispatch(logoutAction(() => {}));
      dispatch(DEACTIVATE_APP());
      return true;
   }

   useEffect(() => {
      let unmounted = false;
      if (!unmounted) {
         resetTutorialsFunction(false);
      }
      return () => {
         unmounted = true;
      };
   }, []);

   useEffect(() => {
      if (currentUser?.id !== 0 && !isAppActive) {
         // User is logged in. This was probably a page refresh. Set App to active.
         localStorage.removeItem('currentUserSocketCreated');
         dispatch(ACTIVATE_APP());
      }
   }, [currentUser]);

   useEffect(() => {
      if (isAppActive && currentUser?.id && currentUser?.id !== 0) {
         setSocket(SocketService.createSocket({ currentUser }));
         localStorage.currentUserSocketCreated = true;
      }
   }, [isAppActive, currentUser]);

   useEffect(() => {
      let unmounted = false;
      if (!unmounted && socket) {
         socket.on('client:getOnlineUsers', (res: any) => {
            dispatch(setOnlineUsers(res.onlineUsers));
         });
         socket.on(
            'client:userHasBeenUpdated',
            ({ currentUser: user }: { currentUser: UserInterface }) => {
               SocketService.updateCurrentUserOnSocket(user); // one way update
               dispatch(updateUserSuccess(user));
            }
         );

         socket.on('client:roleHasBeenUpdated', ({ role }: { role: any }) => {
            dispatch(
               loadUserRoles((responseError: COMPONENT_ERROR) => {
                  if (responseError) {
                     setError(responseError);
                  }
               })
            );
            dev.log('Role has been updated...', role);
         });

         socket.on(
            'client:updateMyCurrentUser',
            ({ currentUser }: { currentUser: UserInterface }) => {
               dev.log('Update user coming from backend server', currentUser);
               dispatch(updateUserSuccess(currentUser));
            }
         );

         socket.emit('server:getOnlineUsers');
      }
      return () => {
         if (socket) {
            socket.off('client:userHasBeenUpdated');
            socket.off('client:updateMyCurrentUser');
            socket.off('client:getOnlineUsers');
            socket.off('client:roleHasBeenUpdated');
         }
         unmounted = true;
      };
   }, [currentUser, isAppActive, socket]);

   const logoutModalToggle = () => {
      setOpen(!open);
   };

   const handleListKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === 9 || event.keyCode === 27) {
         event.preventDefault();
         setOpen(false);
      }
   };

   const handleClose = (value: any) => {
      setOpenLanguageDialog(false);
      setSelectedLanguage(value);
   };

   const resetTutorials = () => {
      localStorage.removeItem('first_time_visit_author_documents');
      localStorage.removeItem('first_time_visit_dashboard');
      localStorage.removeItem('first_time_visit_manage_templates');
      localStorage.removeItem('first_time_visit_new_template');
      localStorage.removeItem('first_time_visit_new_document');
      localStorage.removeItem('first_time_visit_projects');
      localStorage.removeItem('first_time_visit_new_project');
      resetTutorialsFunction(true);
      setTimeout(() => {
         resetTutorialsFunction(false);
      }, 1000);
      setOpen(false);
   };

   return location.pathname !== '/' ? (
      <header
         className={`main-header ${
            environment === 'dev'
               ? 'dev-env'
               : environment === 'sb'
               ? 'sb-env'
               : ''
         }`}
      >
         <Link to="/layout/dashboard">
            <div className="logolink" />
         </Link>
         {isEditorOpen ? (
            <div className="align-left navbar-left">
               <span>{openedDocumentName}</span>
            </div>
         ) : null}
         <div className="align-right">
            <div className="environment-title">
               {environment?.toUpperCase()} Environment
            </div>
            <div data-tip="" data-for="userinfoNavbar">
               <span
                  className="user_name"
                  style={{ cursor: 'pointer', fontWeight: 'bold' }}
               >
                  {currentUser?.firstName} {currentUser.lastName}
               </span>
               {currentUser?.img ? (
                  <img
                     alt="the login user"
                     className="user_photo"
                     src={currentUser?.img}
                  />
               ) : (
                  ''
               )}
            </div>
            <ReactTooltip
               id="userinfoNavbar"
               className="user-info__tooltip"
               data-offset="{'top': 10, 'left': 10}"
               type="light"
               place="left"
               backgroundColor="#707070"
            >
               <span>{currentUser?.role?.name}</span>{' '}
               <span>{currentUser?.emailAddress}</span>
            </ReactTooltip>
            <IconButton
               aria-label="user"
               className="logout-modal-btn"
               ref={anchorRef}
               size="medium"
               onClick={() => logoutModalToggle()}
            >
               <MoreVertIcon style={{ fill: '#fff' }} />
            </IconButton>
            <div ref={menuContainer} id="menuContainer">
               <Menu
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  disablePortal
                  className="logout-modal"
               >
                  <MenuList
                     autoFocusItem={open}
                     id="menu-list-grow"
                     onKeyDown={handleListKeyDown}
                  >
                     <MenuItem
                        onClick={() => resetTutorials()}
                        data-testid="reset-guide"
                     >
                        Reset guide popups
                     </MenuItem>
                     <MenuItem
                        onClick={() => setOpenLanguageDialog(true)}
                        data-testid="change-language"
                     >
                        <Box
                           display="flex"
                           justifyContent="space-between"
                           className="lang-content"
                        >
                           <div>Change tutorial language</div>
                           <div>({defaultLang})</div>
                        </Box>
                     </MenuItem>
                     <MenuItem onClick={() => logout()} data-testid="logout">
                        Logout
                     </MenuItem>
                  </MenuList>
               </Menu>
            </div>
         </div>
         <LanguageDialog
            open={openLanguageDialog}
            selectedValue={selectedLanguage}
            onClose={handleClose}
         />
         <ErrorInfoDialog
            open={error !== null}
            errorInfo={error}
            onClose={() => setError(null)}
         />
         <Snackbar
            open={!!successText}
            autoHideDuration={5000}
            onClose={() => setSuccessText('')}
            className="document-snackbar"
            color="#F4A261"
            message={
               <div>
                  <WarningIcon fill="#F4A261" />
                  <span>{successText}</span>
               </div>
            }
         />
      </header>
   ) : (
      <header
         className={`main-header ${
            environment === 'dev'
               ? 'dev-env'
               : environment === 'sb'
               ? 'sb-env'
               : ''
         }`}
      >
         <Link to="/layout/dashboard">
            <div className="logolink" />
         </Link>
         <ErrorInfoDialog
            open={error !== null}
            errorInfo={error}
            onClose={() => setError(null)}
         />
      </header>
   );
};

export default Navbar;
