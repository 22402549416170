import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../shared/components/Footer/Footer';

import './styles.scss';

function PageNotFound() {
   return (
      <div className="pageNotFound">
         <div className="pageNotFound__container">
            <h1>404</h1>
            <h2>The page you are looking for is not found!</h2>
            <Link to="/layout/dashboard">Redirect to homepage</Link>
         </div>
         <Footer />
      </div>
   );
}

export default PageNotFound;
