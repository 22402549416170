import produce from 'immer';
import { InternalReportState } from '../../core/models/internalReports/internalReportState';
import { internalReportTypes } from '../types/internalReports';

const initialState: InternalReportState = {
   internalReports: [],
   activeInternalReports: [],
   error: null,
};

export const internalReports = (
   state = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case internalReportTypes.GET_ACTIVE_INTERNAL_REPORTS_SUCCESS: {
            draft.activeInternalReports = payload;
            draft.error = null;
            return draft;
         }
         case internalReportTypes.CREATE_INTERNAL_REPORTS_SUCCESS: {
            draft.error = null;
            return draft;
         }
         case internalReportTypes.ACTIVATE_INTERNAL_REPORT_SUCCESS: {
            payload.activatedTitles.forEach(
               (title: { id: number; reportGroupId: number }) => {
                  const updatedReportIndex = draft.internalReports.findIndex(
                     (report) => report.id === title.reportGroupId
                  );
                  const updatedTitleIndex = draft.internalReports[
                     updatedReportIndex
                  ].reportTitles.findIndex(
                     (reportTitle) => reportTitle.id === title.id
                  );
                  draft.internalReports[updatedReportIndex].reportTitles[
                     updatedTitleIndex
                  ].activeFlag = true;
               }
            );
            payload.inActivatedTitles.forEach(
               (title: { id: number; reportGroupId: number }) => {
                  const updatedReportIndex = draft.internalReports.findIndex(
                     (report) => report.id === title.reportGroupId
                  );
                  const updatedTitleIndex = draft.internalReports[
                     updatedReportIndex
                  ].reportTitles.findIndex(
                     (reportTitle) => reportTitle.id === title.id
                  );
                  draft.internalReports[updatedReportIndex].reportTitles[
                     updatedTitleIndex
                  ].activeFlag = false;
               }
            );
            return draft;
         }
         case internalReportTypes.UPDATE_INTERNAL_REPORT_SUCCESS: {
            draft.error = null;
            return draft;
         }
         case internalReportTypes.GET_INTERNAL_REPORTS_SUCCESS: {
            draft.internalReports = payload;
            draft.error = null;
            return draft;
         }
         case internalReportTypes.UPDATE_INTERNAL_REPORT_TITLE_SUCCESS: {
            const { reportTitle, groupName } = payload;
            const reportIndex = draft.internalReports.findIndex(
               (report) => report.id === reportTitle.reportGroupId
            );
            if (reportIndex !== -1) {
               draft.internalReports[reportIndex].groupName = groupName;
               const titleIndex = draft.internalReports[
                  reportIndex
               ].reportTitles.findIndex(
                  (report) => report.id === reportTitle.id
               );
               if (titleIndex !== -1) {
                  draft.internalReports[reportIndex].reportTitles[
                     titleIndex
                  ] = reportTitle;
               }
            }
            return draft;
         }
         case internalReportTypes.INTERNAL_REPORT_FAILURE: {
            draft.error = payload;
            return draft;
         }
         default:
            return draft;
      }
   });
