import {
   InternalReportCreateForm,
   InternalReportsActivationForm,
   InternalReportUpdateForm,
   InternalReportUpdateTitleForm,
} from '../../core/models/internalReports/internalReportForm';
import { InternalReportInterface } from '../../core/models/internalReports/internalReports';
import { internalReportTypes } from '../types/internalReports';

export const getInternalReports = (callback: Function) => ({
   type: internalReportTypes.GET_INTERNAL_REPORTS,
   callback,
});

export const getInternalReportsSuccess = (
   payload: InternalReportInterface[]
) => ({
   type: internalReportTypes.GET_INTERNAL_REPORTS_SUCCESS,
   payload,
});
export const getActiveInternalReports = (callback: Function) => ({
   type: internalReportTypes.GET_ACTIVE_INTERNAL_REPORTS,
   callback,
});

export const getActiveInternalReportsSuccess = (
   payload: InternalReportInterface[]
) => ({
   type: internalReportTypes.GET_ACTIVE_INTERNAL_REPORTS_SUCCESS,
   payload,
});

export const createInternalReports = (
   payload: InternalReportCreateForm,
   callback: Function
) => ({
   type: internalReportTypes.CREATE_INTERNAL_REPORTS,
   payload,
   callback,
});

export const createInternalReportsSuccess = (
   payload: InternalReportInterface[]
) => ({
   type: internalReportTypes.CREATE_INTERNAL_REPORTS_SUCCESS,
   payload,
});
export const updateInternalReports = (
   payload: InternalReportUpdateForm,
   callback: Function
) => ({
   type: internalReportTypes.UPDATE_INTERNAL_REPORT,
   payload,
   callback,
});

export const updateInternalReportsSuccess = (
   payload: InternalReportInterface[]
) => ({
   type: internalReportTypes.UPDATE_INTERNAL_REPORT_SUCCESS,
   payload,
});

export const activateInternalReports = (
   payload: InternalReportsActivationForm,
   callback: Function
) => ({
   type: internalReportTypes.ACTIVATE_INTERNAL_REPORT,
   payload,
   callback,
});
export const updateInternalReportTitle = (
   payload: InternalReportUpdateTitleForm,
   callback: Function
) => ({
   type: internalReportTypes.UPDATE_INTERNAL_REPORT_TITLE,
   payload,
   callback,
});

export const activateInternalReportsSuccess = (payload: {
   activatedTitles: { id: number; reportGroupId: number }[];
   inActivatedTitles: { id: number; reportGroupId: number }[];
}) => ({
   type: internalReportTypes.ACTIVATE_INTERNAL_REPORT_SUCCESS,
   payload,
});
export const updateInternalReportTitleSuccess = (payload: {
   reportTitle: InternalReportInterface;
   groupName: string;
}) => ({
   type: internalReportTypes.UPDATE_INTERNAL_REPORT_TITLE_SUCCESS,
   payload,
});

export const internalReportFalure = (payload: any) => ({
   type: internalReportTypes.INTERNAL_REPORT_FAILURE,
   payload,
});
