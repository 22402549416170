import produce from 'immer';
import { variablesAndMetadataTypes } from '../types/variablesAndMetadata';
import { VariablesState } from '../../core/models/variables';

const {
   GET_FILTERED_REPORTS_SUCCESS,
   GET_VARIABLE_BY_ID_SUCCESS,
   GET_ALL_VARIABLES_SUCCESS,
   GET_ALL_REPORTS_SUCCESS,
   CREATE_VARIABLE,
   CREATE_VARIABLE_SUCCESS,
   GET_REPORT_IDENTIFIERS_SUCCESS,
   GET_VARIABLE_DATASETS_SUCCESS,
   GET_VARIABLE_TABLES_SUCCESS,
   // UPDATE_VARIABLE_IDENTIFIERS_SUCCESS,
   GET_TABLE_GENERATOR_NAMES_SUCCESS,
   GET_VARIABLE_BIG_QUERY_NAMES_SUCCESS,
   GET_EXISTING_UI_NAMES_SUCCESS,
} = variablesAndMetadataTypes;

const initialState: VariablesState = {
   allVariables: [],
   allIdentifiers: [],
   variable: undefined,
   isVariableCreatedSuccessfully: false,
   reportIdentifiers: {},
   variableDatasets: [],
   variableDataTables: [],
   variableTableGeneratorNames: [],
   variableBigQueryNames: [],
   existingUINames: [],
};

export const variables = (
   state = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case GET_ALL_VARIABLES_SUCCESS:
            draft.allVariables = payload;
            return draft;
         case GET_VARIABLE_BY_ID_SUCCESS:
            draft.variable = payload;
            return draft;
         case GET_ALL_REPORTS_SUCCESS:
            draft.allIdentifiers = payload;
            return draft;
         case GET_FILTERED_REPORTS_SUCCESS:
            draft.fitleredIdentifiers = payload;
            return draft;
         case CREATE_VARIABLE:
            draft.isVariableCreatedSuccessfully = false;
            return draft;
         case CREATE_VARIABLE_SUCCESS:
            draft.variable = payload;
            draft.isVariableCreatedSuccessfully = true;
            return draft;
         case GET_REPORT_IDENTIFIERS_SUCCESS:
            draft.reportIdentifiers = payload;
            return draft;
         case GET_VARIABLE_DATASETS_SUCCESS:
            draft.variableDatasets = payload;
            return draft;
         case GET_VARIABLE_TABLES_SUCCESS:
            draft.variableDataTables = payload;
            return draft;
         case GET_TABLE_GENERATOR_NAMES_SUCCESS:
            draft.variableTableGeneratorNames = payload;
            return draft;
         case GET_VARIABLE_BIG_QUERY_NAMES_SUCCESS:
            draft.variableBigQueryNames = payload;
            return draft;
         case GET_EXISTING_UI_NAMES_SUCCESS:
            draft.existingUINames = payload;
            return draft;
         default:
            return draft;
      }
   });
