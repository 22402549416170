import produce from 'immer';
import { TutorialState } from '../../core/models/tutorial/tutorialState';
import { tutorialTypes } from '../types/tutorial';

const initialState: TutorialState = {
   tutorials: [],
   error: null,
};

export const tutorial = (
   state: TutorialState = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case tutorialTypes.GET_ALL_TUTORIALS_SUCCESS:
            draft.tutorials = payload;
            return draft;
         case tutorialTypes.DELETE_TUTORIAL_SUCCESS:
            if (payload) {
               const index = draft.tutorials.findIndex(
                  (tutorialItem) => tutorialItem.id === payload
               );
               draft.tutorials.splice(index, 1);
            }
            return draft;
         default:
            return draft;
      }
   });
