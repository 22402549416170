// eslint-disable-next-line max-classes-per-file
import { User } from '../user/user';
import { Project } from '../project/project';
import { ProjectInterface } from '../project/projectInterface';
import { WorkingDocument } from '../workingDocument/workingDocument';
import { COMPONENT_ERROR } from '../../../shared/CONSTANTS';
import { Substance } from '../substance';
import { Product } from '../product';

export class DocumentMetadata {
   countries: any[] = [];

   products: Product[] = [];

   substances: Substance[] = [];
}

export interface ProductSubstanceInterface {
   name: string;
   manufacturer: string;
}

export class ProductSubstance implements ProductSubstanceInterface {
   constructor(public name: string = '', public manufacturer: string = '') {}
}

export type GeneratedDocumentCallback = (
   error: COMPONENT_ERROR,
   generatedDocument: GeneratedDocument[] | GeneratedDocument | null
) => void;

export class UpdateGeneratedDocument {
   name: string = '';

   productFamily: string = '';

   gpmNumber: string = '';

   sectionCode: string = '';

   sectionTitle: string = '';

   scopes: string = '';

   documentMetadata: any = new DocumentMetadata();

   type: string = '';
}

export interface GeneratedDocumentInterface {
   id: number;
   creator: User;
   project: ProjectInterface;
   workingDocument: WorkingDocument;
   createdAt: string;
   driveId: string;
   gpmNumber: string;
   modifier: number;
   modifiedAt: string;
   modifiedDate: number;
   originalDocumentId: number;
   productFamily: string;
   projectId: number;
   revisionId: string;
   scopes: string;
   sectionCode: string;
   sectionTitle: string;
   status: number;
   statusDescription: string;
   type: string;
   name: string;
   title: string;
   username: string;
   documentMetadata: DocumentMetadata;
   currentUserEmail?: string;
   ctd?: string;
   extension?: string;
   content?: string;
   archived: boolean;
}

export class GeneratedDocument implements GeneratedDocumentInterface {
   constructor(
      public id: number = 0,
      public creator: User = new User(),
      public project: ProjectInterface = new Project(),
      public workingDocument = new WorkingDocument(),
      public createdAt: string = '',
      public driveId: string = '',
      public gpmNumber: string = '',
      public modifier: number = 0,
      public modifiedAt: string = '',
      public modifiedDate: number = 0,
      public originalDocumentId: number = 0,
      public productFamily: string = '',
      public projectId: number = 0,
      public revisionId: string = '',
      public scopes: string = '',
      public sectionCode: string = '',
      public sectionTitle: string = '',
      public status: number = 0,
      public statusDescription: string = '',
      public type: string = '',
      public name: string = '',
      public title: string = '',
      public username: string = '',
      public documentMetadata: DocumentMetadata = new DocumentMetadata(),
      public archived: boolean = false,
      public currentUserEmail?: string,
      public ctd?: string,
      public extension?: string,
      public content?: string
   ) {}

   public static createGeneratedDocumentObject(document: any) {
      if (
         document.metadata.countries &&
         document.metadata.countries[0] instanceof Array
      ) {
         document.metadata.countries = document.metadata.countries[0];
      }

      return new GeneratedDocument(
         document.id,
         document.Creator || {},
         document.project,
         document.WorkingDocument,
         new Date(document.createdAt).toLocaleString(),
         document.driveId,
         document.gpmNumber,
         document.lastModifiedBy,
         new Date(document.updatedAt).toLocaleString(),
         new Date(document.updatedAt).getTime(),
         document.originalDocumentId,
         document.productFamily,
         document.projectId,
         document.revisionId,
         document.scopes,
         document.sectionCode,
         document.sectionTitle,
         document.status,
         document.statusDescription,
         document.type,
         document.name,
         document.title,
         document.Creator?.username,
         document.metadata,
         document.archived
      );
   }
}
