import React from 'react';
import { useLocation } from 'react-router-dom';
import './styles.scss';

export const SubNavBar: React.FC = () => {
   const location = useLocation();

   return location.pathname !== '/' &&
      !(location.pathname.indexOf('/edit') > -1) ? (
      <header className="sub-header ">
         <div className="sub-nav" />
      </header>
   ) : (
      <header
         className={
            location.pathname === '/' || location.pathname.indexOf('/edit') > -1
               ? 'sub-header-display-none'
               : 'sub-header'
         }
      />
   );
};

export default SubNavBar;
