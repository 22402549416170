export const modelDocTypes = {
   ADD_MODEL_DOCUMENT: 'ADD_MODEL_DOCUMENT',
   ADD_MODEL_DOCUMENT_SUCCESS: 'ADD_MODEL_DOCUMENT_SUCCESS',
   GET_MODEL_DOCUMENTS: 'GET_MODEL_DOCUMENTS',
   GET_MODEL_DOCUMENTS_SUCCESS: 'GET_MODEL_DOCUMENTS_SUCCESS',
   UPDATE_MODEL_DOCUMENT: 'UPDATE_MODEL_DOCUMENT',
   UPDATE_MODEL_DOCUMENT_SUCCESS: 'UPDATE_MODEL_DOCUMENT_SUCCESS',
   UPDATE_MODEL_DOCUMENT_FAILURE: 'UPDATE_MODEL_DOCUMENT_FAILURE',
   UPDATE_MODEL_DOCUMENT_FAVORITE_SUCCESS:
      'UPDATE_MODEL_DOCUMENT_FAVORITE_SUCCESS',
   UPDATE_MODEL_DOCUMENT_MODIFIER: 'UPDATE_MODEL_DOCUMENT_MODIFIER',
   UPDATE_MODEL_DOCUMENT_MODIFIER_SUCCESS:
      'UPDATE_MODEL_DOCUMENT_MODIFIER_SUCCESS',
   UPDATE_MODEL_DOCUMENT_MODIFIER_FAILURE:
      'UPDATE_MODEL_DOCUMENT_MODIFIER_FAILURE',
   GET_MODEL_DOCUMENT_BY_ID: 'GET_MODEL_DOCUMENT_BY_ID',
   GET_MODEL_DOCUMENT_BY_ID_SUCCESS: 'GET_MODEL_DOCUMENT_BY_ID_SUCCESS',
   DELETE_MODEL_DOCUMENT: 'DELETE_MODEL_DOCUMENT',
   DELETE_MODEL_DOCUMENT_SUCCESS: 'DELETE_MODEL_DOCUMENT_SUCCESS',
   MODEL_DOCUMENT_ACTION_FAILURE: 'MODEL_DOCUMENT_ACTION_FAILURE',
   CLEAR_MODEL_DOCUMENT_STATE: 'CLEAR_MODEL_DOCUMENT_STATE',
   ARCHIVE_MODEL_DOCUMENT: 'ARCHIVE_MODEL_DOCUMENT',
   ARCHIVE_MODEL_DOCUMENT_SUCCESS: 'ARCHIVE_MODEL_DOCUMENT_SUCCESS',
   ARCHIVE_MODEL_DOCUMENT_FAILURE: 'ARCHIVE_MODEL_DOCUMENT_FAILURE',
   GET_MODEL_DOCUMENT_TYPES: 'GET_MODEL_DOCUMENT_TYPES',
   GET_MODEL_DOCUMENT_TYPES_SUCCESS: 'GET_MODEL_DOCUMENT_TYPES_SUCCESS',
   GET_BASE_TEMPLATE: 'GET_BASE_TEMPLATE',
   GET_BASE_TEMPLATE_SUCCESS: 'GET_BASE_TEMPLATE_SUCCESS',
   GET_MODEL_DOCUMENT_FILTERS_DATA: 'GET_MODEL_DOCUMENT_FILTERS_DATA',
   GET_MODEL_DOCUMENT_FILTERS_DATA_SUCCESS:
      'GET_MODEL_DOCUMENT_FILTERS_DATA_SUCCESS',
   GET_MODEL_DOCUMENT_FILTERS_DATA_FAILURE:
      'GET_MODEL_DOCUMENT_FILTERS_DATA_FAILURE',
   GET_MODEL_DOCUMENT_VARIABLES: 'GET_MODEL_DOCUMENT_VARIABLES',
   GET_MODEL_DOCUMENT_VARIABLES_SUCCESS: 'GET_MODEL_DOCUMENT_VARIABLES_SUCCESS',
   GET_MODEL_DOCUMENT_VARIABLES_FAILURE: 'GET_MODEL_DOCUMENT_VARIABLES_FAILURE',
   DUPLICATE_MODEL_DOCUMENT: 'DUPLICATE_MODEL_DOCUMENT',
   PUBLISH_MODEL_DOCUMENT: 'PUBLISH_MODEL_DOCUMENT',
   WITHDRAW_MODEL_DOCUMENT: 'WITHDRAW_MODEL_DOCUMENT',
   GET_MODEL_DOCUMENT_STATUSES: 'GET_MODEL_DOCUMENT_STATUSES',
   GET_MODEL_DOCUMENT_STATUSES_SUCCESS: 'GET_MODEL_DOCUMENT_STATUSES_SUCCESS',
};
