import { Link } from 'react-router-dom';
import AuthorDocumentIcon from '@material-ui/icons/Edit';
import ManageTemplateIcon from '@material-ui/icons/Dashboard';
import DashboardIcon from '../shared/icons/Dashboard';
import Folders from '../shared/icons/Folder';
import TransferIcon from '../shared/icons/Transfer';
import AdminIcon from '../shared/icons/Admin';
import VariableManagement from '../shared/icons/VariableManagement';

export default [
   {
      id: 1,
      name: 'DASHBOARD',
      component: Link,
      link: '/layout/dashboard',
      icon: DashboardIcon,
      iconStyle: { width: 15, height: 15 },
      area: 'dashboard',
   },
   {
      id: 2,
      name: 'MANAGE PROJECTS',
      component: Link,
      link: '/layout/projects',
      activeLinks: [
         '/layout/project_edit',
         '/layout/project',
         '/layout/new_project',
      ],
      icon: Folders,
      area: 'project',
   },
   {
      id: 3,
      name: 'WORKING DOCUMENTS',
      component: Link,
      link: '/layout/authordocument',
      activeLinks: [
         '/layout/authordocument',
         '/layout/new_document',
         '/layout/update_document',
      ],
      icon: AuthorDocumentIcon,
      iconFontSize: 'small',
      area: 'working_document',
   },
   {
      id: 4,
      name: 'GENERATED DOCUMENTS',
      component: Link,
      link: '/layout/generated-output',
      activeLinks: ['/layout/generated-output', '/layout/generated-transfer'],
      icon: TransferIcon,
      iconFontSize: 'small',
      area: 'generated_document',
   },
   {
      id: 5,
      name: 'TEMPLATES',
      component: Link,
      link: '/layout/managetemplate',
      activeLinks: [
         '/layout/managetemplate',
         '/layout/new_template',
         '/layout/update_template/',
         '/layout/modeldocument/edit',
      ],
      icon: ManageTemplateIcon,
      iconFontSize: 'small',
      area: 'template',
   },
   {
      id: 6,
      name: 'VARIABLES MANAGEMENT',
      roles: ['admin', 'template manager'],
      component: Link,
      link: '/layout/manage-variables',
      activeLinks: [
         '/layout/manage-variables',
         '/layout/new-variable',
         '/layout/update-variable/',
      ],
      icon: VariableManagement,
      iconFontSize: 'small',
      area: 'admin',
   },
   {
      id: 7,
      name: 'ADMIN PANEL',
      roles: ['admin'],
      component: Link,
      link: '/administration',
      activeLinks: [
         '/administration',
         '/administration/manage-roles',
         '/administration/permissions',
      ],
      icon: AdminIcon,
      iconFontSize: 'small',
      area: 'admin',
   },
];
