import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class RevisionApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static createRevision(driveId: string = '', documentType: string = '') {
      return fetch(`${baseUrl}api/v1/revision`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ driveId, documentType }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static createRevisionOnSessionExpire(
      driveId: string = '',
      documentType: string = ''
   ) {
      return fetch(`${baseUrl}api/v1/revision/documentSession`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({ driveId, documentType }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }
}
