import React, { useState, useEffect, ChangeEvent } from 'react';
import { GoogleLogin } from 'react-google-login';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Box } from '@material-ui/core';
import Logofinallogin from '../../assets/images/logo-final-login.png';
import { User } from '../../core/models/user/user';
import { UserInterface } from '../../core/models/user/userInterface';
import { Navbar } from '../../shared/components/NavBar/Navbar';
import Footer from '../../shared/components/Footer/Footer';
import { addUser, loginUser } from '../../redux/actions/user';
import { GlobalState } from '../../core/models/globalState';
import { ACTIVATE_APP } from '../../redux/actions/globals';
import { SocketService } from '../../core/services/socket';
import { dev } from '../../_dev/_utils';
import ErrorInfoDialog from '../../shared/components/ErrorInfoDialog';
import { ApiErrorHandler } from '../../core/api/apiUtils';
import { COMPONENT_ERROR, allowCredentialsLogin } from '../../shared/CONSTANTS';
import { PasswordAuthenticator } from '../../core/models/loginInterface';
import { store } from '../../stores/store';
import { userTypes } from '../../redux/types/user';
import RocheIcon from '../../shared/icons/RocheIcon';

export const Home: React.FC = () => {
   const { isAppActive } = useSelector((state: GlobalState) => state.globals);
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [socket, setSocket] = useState(SocketService.socket);
   const dispatch = useDispatch();
   const [error, setError] = useState<COMPONENT_ERROR>(null);
   const [type, setType] = useState<string>('google');
   const [authenticationCredentials, setAuthenticationCredentials] = useState<
      PasswordAuthenticator
   >(new PasswordAuthenticator());
   const [errorMessages, setErrorMessages] = useState<PasswordAuthenticator>(
      new PasswordAuthenticator()
   );
   const history = useHistory();
   // const SCOPES_STRING = 'https://www.googleapis.com/auth/drive  https://www.googleapis.com/auth/drive.activity.readonly';

   useEffect(() => {
      if (localStorage.getItem('isLoggedIn') === 'true') {
         history.push('/layout/dashboard');
      }
   }, []);

   useEffect(() => {
      if (!socket) return;
      /*
       If there is a socket, it will always have a user attached to the socket object from creation.
       When a User logs out, the application will still have a connected socket in memory and state.
       The socket will already have listeners created in the Navbar.
       The server's login listener will emit an event, captured in client, which will inclusively update all connected sockets.
      */
      dev.log('Is App Active ==>', isAppActive);
      if (isAppActive && socket.auth) {
         // Login with currentUser if the app is active
         socket.emit('login', { currentUser: socket.auth });
      }
   }, [socket, isAppActive]);

   function ErrorLogin() {
      if (error?.message !== '') {
         return <div className="error">{error?.message}</div>;
      }
      return <div />;
   }

   function isEmailWhitelisted(emailAddress: string): boolean {
      return (
         emailAddress.indexOf('@contractors.roche.com') > -1 ||
         emailAddress.indexOf('@businesspartner.roche.com') > -1 ||
         emailAddress.indexOf('@gene.com') > -1 ||
         emailAddress.indexOf('@sentium-consulting.com') > -1 ||
         emailAddress.indexOf('@roche.com') > -1 ||
         emailAddress.indexOf('@science.roche.com') > -1
      );
   }

   // https://developers.google.com/identity/sign-in/web/reference
   // gmail.com & sentium-consulting.com are added as temporary solution for testing
   async function onSuccessResponse(response: any) {
      const isValidEmail = isEmailWhitelisted(response.profileObj.email);
      console.log('Google response', response);
      if (!isValidEmail) {
         sessionStorage.removeItem('userProfile');
         const isNotValidEmailError = {
            message:
               'Sorry, only users with valid roche email address are allowed access the application.',
            code: 403,
            extraInformation: null,
         };
         setError(new ApiErrorHandler(isNotValidEmailError));
         return;
      }

      const authUser: User = { ...response.profileObj };
      store.dispatch({
         type: userTypes.GET_CURRENT_USER_SUCCESS,
         payload: authUser,
      });
      localStorage.setItem('isLoggedIn', 'true');
      const user: UserInterface = new User();

      user.email = response.profileObj.email;
      user.firstName = response.profileObj.givenName;
      user.lastName = response.profileObj.familyName;
      user.lastLogin = new Date().toISOString();
      user.status = 'online';
      user.img = response.profileObj.imageUrl;
      user.res = response;
      dispatch(
         addUser(user, (responseError: COMPONENT_ERROR) => {
            if (responseError) {
               return setError(responseError);
            }
            history.push('/layout/dashboard');
         })
      );
      dispatch(ACTIVATE_APP());
   }

   function onFailureResponse(errorObj: any): void {
      let errorString = 'Error in Login..';
      if (errorObj && errorObj.details) {
         errorString = `${errorString} ${errorObj.details}`;
      }
      if (errorObj && errorObj.error) {
         errorString = `${errorString} ${errorObj.error}`;
      }
      const errorObject = {
         code: 400,
         message: errorString,
      };
      setError(new ApiErrorHandler(errorObject));
      sessionStorage.clear();
      console.dir(error);
   }

   const toggleLoginType = (): void => {
      setType(type === 'google' ? 'credentials' : 'google');
   };

   const changeCredentials = (event: ChangeEvent<HTMLInputElement>): void => {
      const { name, value } = event.target;
      setAuthenticationCredentials({
         ...authenticationCredentials,
         [name]: value,
      });
   };

   const handleSignIn = () => {
      const emailErrorMessage = authenticationCredentials.emailAddress
         ? ''
         : 'Username is required';
      const passwordErrorMessage = authenticationCredentials.password
         ? ''
         : 'Password is required';

      setErrorMessages(
         new PasswordAuthenticator(emailErrorMessage, passwordErrorMessage)
      );

      if (!emailErrorMessage && !passwordErrorMessage) {
         dispatch(
            loginUser(
               authenticationCredentials,
               (responseError: COMPONENT_ERROR) => {
                  if (responseError) {
                     setError(responseError);
                     return;
                  }
                  dispatch(ACTIVATE_APP());
                  history.push('/layout/dashboard');
               }
            )
         );
      }
   };

   const handleSSOClick = () => {
      window.location.href = `${process.env.REACT_APP_SSO_AUTH_URL}?client_id=${process.env.REACT_APP_SSO_CLIENT}&response_type=code&scope=edit&redirect_uri=${window.location.origin}/ssoauth`;
   };

   return (
      <>
         <Navbar />
         <div>
            <div className="wrapper">
               <div className="main">
                  <img className="logo" src={Logofinallogin} alt="Login logo" />
                  <Card className="card">
                     <div className="login">
                        <Box mb=".75rem">
                           <p className="welcome">
                              Welcome to Intelligent Authoring platform to
                              better collaborate and manage the authoring of
                              Documents and Templates
                           </p>
                        </Box>
                        {type === 'google' ? (
                           <div className="loginSSOorGoogle">
                              <div
                                 className="loginSSOContent"
                                 aria-hidden="true"
                                 onClick={handleSSOClick}
                              >
                                 <RocheIcon />
                                 <span className="loginSSO">
                                    Login with SSO
                                 </span>
                              </div>
                              {process.env.REACT_APP_APPLICATION_ENV !==
                                 'qa' && (
                                 <GoogleLogin
                                    className="google-button"
                                    clientId={
                                       process.env
                                          .REACT_APP_AUTH_CLIENT_ID as string
                                    }
                                    buttonText="Sign in with Google"
                                    onSuccess={onSuccessResponse}
                                    onFailure={onFailureResponse}
                                    accessType="online"
                                 />
                              )}
                           </div>
                        ) : (
                           <div className="credentials-container">
                              {errorMessages.emailAddress && (
                                 <span className="error-messsage">
                                    {errorMessages.emailAddress}
                                 </span>
                              )}
                              <input
                                 className={`login-input ${
                                    errorMessages.emailAddress
                                       ? 'error-input'
                                       : ''
                                 }`}
                                 value={authenticationCredentials.emailAddress}
                                 name="emailAddress"
                                 placeholder="Username"
                                 onChange={(event) => changeCredentials(event)}
                                 type="text"
                              />
                              {errorMessages.password && (
                                 <span className="error-messsage">
                                    {errorMessages.password}
                                 </span>
                              )}
                              <input
                                 className={`password-input ${
                                    errorMessages.password ? 'error-input' : ''
                                 }`}
                                 value={authenticationCredentials.password}
                                 name="password"
                                 placeholder="Password"
                                 onChange={(event) => changeCredentials(event)}
                                 type="password"
                              />
                              <button
                                 className="login-button"
                                 type="button"
                                 onClick={() => handleSignIn()}
                              >
                                 Login
                              </button>
                           </div>
                        )}
                        <ErrorLogin />
                        {allowCredentialsLogin && (
                           <span
                              onClick={() => toggleLoginType()}
                              className="use-password"
                              aria-hidden="true"
                           >
                              {type === 'google'
                                 ? 'Login with Username and Password'
                                 : 'Login with Google Authentication'}
                           </span>
                        )}
                     </div>
                  </Card>
               </div>
               <Footer />
            </div>
         </div>
         <ErrorInfoDialog
            open={error !== null}
            errorInfo={error}
            onClose={() => setError(null)}
         />
      </>
   );
};

export default Home;
