import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function WarningIcon({ fill, ...rest }: any) {
   return (
      <SvgIcon {...rest} viewBox="0 0 16 16">
         <path
            fill={fill}
            d="
				M8.03573 10.0673C8.25205 10.0673 8.45951 9.98162 8.61247 9.82903C8.76542 9.67644 8.85136 9.46949
				8.85136 9.25369V5.48584C8.85136 5.27005 8.76542 5.06309 8.61247 4.9105C8.45951 4.75791 8.25205
				4.67218 8.03573 4.67218C7.81942 4.67218 7.61196 4.75791 7.459 4.9105C7.30604 5.06309 7.22011
				5.27005 7.22011 5.48584V9.24117C7.21845 9.34907 7.23832 9.45623 7.27856 9.55639C7.3188 9.65656
				7.37862 9.74774 7.45452 9.82463C7.53042 9.90151 7.6209 9.96257 7.72068 10.0042C7.82047 10.0459
				7.92756 10.0674 8.03573 10.0673Z"
         />
         <path
            fill={fill}
            d="
				M8.00427 12.8527C8.52402 12.8527 8.94537 12.4324 8.94537 11.9139C8.94537 11.3954 8.52402
				10.9751 8.00427 10.9751C7.48451 10.9751 7.06317 11.3954 7.06317 11.9139C7.06317 12.4324
				7.48451 12.8527 8.00427 12.8527Z"
         />
         <path
            fill={fill}
            d="M15.7724 12.7085L9.66152 1.48005C9.49974 1.18351 9.2608 0.935975 8.96986 0.763502C8.67892
				0.591028 8.34675 0.5 8.00832 0.5C7.66989 0.5 7.33772 0.591028 7.04678 0.763502C6.75584 0.935975
				6.5169 1.18351 6.35512 1.48005L0.237955 12.7085C0.0778456 12.9952 -0.00414334 13.3187 0.000161166
				13.6469C0.00446567 13.9751 0.0949131 14.2964 0.262488 14.5788C0.430063 14.8612 0.668912 15.0949
				0.955227 15.2565C1.24154 15.4181 1.56532 15.5021 1.89429 15.5H14.1161C14.4423 15.5003 14.7631 15.4159
				15.0469 15.2553C15.3306 15.0946 15.5676 14.8631 15.7347 14.5835C15.9017 14.3039 15.993 13.9858 15.9996
				13.6604C16.0062 13.335 15.9279 13.0134 15.7724 12.7273V12.7085ZM14.6556 13.9227C14.6001 14.0162 14.5212
				14.0936 14.4266 14.1473C14.332 14.2011 14.225 14.2294 14.1161 14.2294H1.89429C1.78524 14.2297 1.67799
				14.2016 1.58312 14.148C1.48825 14.0943 1.40904 14.0169 1.35331 13.9234C1.29757 13.8299 1.26723 13.7235
				1.26528 13.6147C1.26333 13.5059 1.28983 13.3985 1.34218 13.3031L7.45307 2.07464C7.50683 1.97528 7.58653
				1.89228 7.68373 1.83443C7.78093 1.77658 7.892 1.74604 8.00518 1.74604C8.11837 1.74604 8.22944 1.77658
				8.32664 1.83443C8.42384 1.89228 8.50354 1.97528 8.5573 2.07464L14.6682 13.3031C14.7202 13.3986 14.7464
				13.5059 14.7442 13.6146C14.742 13.7232 14.7115 13.8294 14.6556 13.9227Z"
         />
      </SvgIcon>
   );
}
