import produce from 'immer';
import { WorkingDocumentState } from '../../core/models/workingDocument/workingDocumentState';
import { workingDocumentTypes } from '../types/workingDocument';
import { WorkingDocument } from '../../core/models/workingDocument/workingDocument';
import * as CONSTANTS from '../../shared/CONSTANTS';

const initialState: WorkingDocumentState = {
   allWorkingDocuments: [],
   publicWorkingDocuments: [],
   assignWorkingDocuments: [],
   workingDocumentCount: 0,
   newDocument: new WorkingDocument(),
   isFullyLoaded: false,
   workingDocumentError: null,
   workingDocumentPlaceholderDriveId: '',
   countries: [],
   documentFilters: [],
   variableValues: [],
   isVariableValuesGetSuccess: false,
   isLoading: false,
   autoGeneratedVariablesValues: {},
   reportMetadata: {},
   isDocumentUpdateSuccess: false,
   documentData: {},
   isDeleteWorkingDocumentSuccess: false,
};

export const workingDocument = (
   state = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case workingDocumentTypes.GET_PUBLIC_WORKING_DOCUMENTS_SUCCESS:
            if (
               payload.workingDocuments.length <
                  CONSTANTS.DEFAULT_WORKING_DOCUMENT_SIZE ||
               payload.workingDocuments.length %
                  CONSTANTS.DEFAULT_WORKING_DOCUMENT_SIZE !==
                  0
            ) {
               draft.isFullyLoaded = true;
            }
            draft.publicWorkingDocuments = payload.workingDocuments;
            draft.workingDocumentCount = payload.count;
            draft.workingDocumentError = null;
            draft.isDeleteWorkingDocumentSuccess = false;
            return draft;

         case workingDocumentTypes.GET_ASSIGN_WORKING_DOCUMENTS_SUCCESS:
            draft.assignWorkingDocuments = payload;
            draft.isDeleteWorkingDocumentSuccess = false;
            draft.workingDocumentError = null;
            return draft;

         case workingDocumentTypes.GET_WORKING_DOCUMENTS_SUCCESS:
            draft.allWorkingDocuments = payload.workingDocuments;
            draft.workingDocumentCount = payload.count;
            draft.isDeleteWorkingDocumentSuccess = false;
            draft.workingDocumentError = null;
            return draft;

         case workingDocumentTypes.DELETE_WORKING_DOCUMENT_SUCCESS:
            if (payload) {
               draft.assignWorkingDocuments = draft.assignWorkingDocuments.filter(
                  (document) => document.id !== Number(payload)
               );
               draft.allWorkingDocuments = draft.allWorkingDocuments.filter(
                  (document) => document.id !== Number(payload)
               );
            }
            draft.workingDocumentError = null;
            return draft;

         case workingDocumentTypes.WORKING_DOCUMENT_ACTION_FAILURE:
            draft.workingDocumentError = payload;
            draft.isDocumentUpdateSuccess = false;
            draft.isDeleteWorkingDocumentSuccess = false;
            return draft;
         case workingDocumentTypes.ARCHIVE_WORKING_DOCUMENT_SUCCESS:
            {
               const { documentId, archived } = payload;

               if (archived) {
                  draft.allWorkingDocuments = draft.allWorkingDocuments.filter(
                     (document) => document.id !== documentId
                  );
                  draft.assignWorkingDocuments = draft.assignWorkingDocuments.filter(
                     (document) => document.id !== documentId
                  );
                  draft.workingDocumentCount -= 1;
               }
            }
            draft.workingDocumentError = null;
            draft.isDeleteWorkingDocumentSuccess = true;
            return draft;

         case workingDocumentTypes.DELETE_PROJECT_WORKING_DOCUMENT:
            draft.assignWorkingDocuments = draft.assignWorkingDocuments.filter(
               (workingDocument) => workingDocument.id !== Number(payload)
            );
            draft.workingDocumentError = null;
            return draft;

         case workingDocumentTypes.UPDATE_WORKING_DOCUMENT_FAVORITE_SUCCESS: {
            const index = draft.allWorkingDocuments.findIndex(
               (projectItem) => projectItem.id === payload.id
            );
            const assignedIndex = draft.assignWorkingDocuments.findIndex(
               (projectItem) => projectItem.id === payload.id
            );
            const publicIndex = draft.publicWorkingDocuments.findIndex(
               (workingDocument) => workingDocument.id === payload.id
            );
            const newProject = {
               ...draft.allWorkingDocuments[index],
               isFavorite: payload.isFavorite,
            };

            if (index !== -1) {
               draft.allWorkingDocuments[index] = { ...newProject };
            }

            if (assignedIndex !== -1) {
               draft.assignWorkingDocuments[assignedIndex] = { ...newProject };
            }

            if (publicIndex !== -1) {
               draft.publicWorkingDocuments[publicIndex] = { ...newProject };
            }
            return draft;
         }
         case workingDocumentTypes.ADD_PLACEHOLDER_WORKING_DOCUMENT_SUCCESS:
            draft.workingDocumentPlaceholderDriveId = payload;
            return draft;

         case workingDocumentTypes.CLEAR_WORKING_DOCUMENT_STATE:
            draft = { ...initialState };
            return draft;

         case workingDocumentTypes.CLEAR_WORKING_DOCUMENT_FORM:
            draft.newDocument = new WorkingDocument();
            return draft;

         case workingDocumentTypes.CLEAR_WORKING_DOCUMENT_PLACEHOLDERS:
            draft.workingDocumentPlaceholderDriveId =
               initialState.workingDocumentPlaceholderDriveId;
            return draft;

         case workingDocumentTypes.TRIGGER_BREEZE_IMAGE_GENERATION:
            // TODO: Add state stage where image_generation = true
            return draft;
         case workingDocumentTypes.UPDATE_BIG_QUERY_TABLE_WITH_SELECTION_DATA:
            // TODO: Add state stage where big query table update = true
            return draft;

         case workingDocumentTypes.GET_WORKING_DOCUMENT_BY_ID_SUCCESS:
            draft.newDocument = WorkingDocument.createWorkingDocumentObject(
               payload
            );
            draft.reportMetadata = payload?.reportMetadata;
            draft.workingDocumentError = null;
            return draft;

         case workingDocumentTypes.GET_BREEZE_METADATA_SUCCESS:
            draft.newDocument.breezeMetadata = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_MOLECULE_SUCCESS:
            draft.newDocument.breezeMetadata.molecule = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_RUNS_SUCCESS:
            draft.newDocument.breezeMetadata.runs = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_INPUTS_SUCCESS:
            draft.newDocument.breezeMetadata.input = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_INPUTS_COMPUTER_SUCCESS:
            draft.newDocument.breezeMetadata.inputComputer = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_KPI_SUCCESS:
            draft.newDocument.breezeMetadata.kpi = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_KPI_COMPUTER_SUCCESS:
            draft.newDocument.breezeMetadata.kpiComputer = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_KPI_METADATA_SUCCESS:
            draft.newDocument.breezeMetadata.kpi_metadata = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_QA_SUCCESS:
            draft.newDocument.breezeMetadata.qa = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_QA_COMPUTER_SUCCESS:
            draft.newDocument.breezeMetadata.qaComputer = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_QA_METADATA_SUCCESS:
            draft.newDocument.breezeMetadata.qa_metadata = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_OUTPUTS_METADATA_SUCCESS:
            draft.newDocument.breezeMetadata.outputsMetadata = payload;
            return draft;

         case workingDocumentTypes.UPDATE_BREEZE_METADATA_SUCCESS:
            draft.newDocument.breezeMetadata = payload;
            return draft;

         case workingDocumentTypes.UPDATE_BREEZE_METADATA_DRIVE_ID_SUCCESS:
            draft.newDocument.breezeMetadata.workingDocumentDriveId = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_CHROMO_STEP_SUCCESS:
            draft.newDocument.breezeMetadata.unit_ops = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_LINEAR_REGRESSION_SUCCESS:
            draft.newDocument.breezeMetadata.linearRegresion = payload;
            return draft;

         case workingDocumentTypes.UPDATE_SELECTED_FACTOR_STUDY_SUCCESS:
            draft.newDocument.breezeMetadata.factorStudy = payload;
            return draft;

         case workingDocumentTypes.UPDATE_QUADRATIC_EFFECT_LIST_SUCCESS:
            draft.newDocument.breezeMetadata.quadraticEffectList = payload;
            return draft;

         case workingDocumentTypes.UPDATE_QUADRATIC_EFFECT_SELECTED_SUCCESS:
            draft.newDocument.breezeMetadata.quadraticEffectSelected = payload;
            return draft;

         case workingDocumentTypes.UPDATE_TWO_WAY_EFFECT_LIST_SUCCESS:
            draft.newDocument.breezeMetadata.twoWayInteractionEffectList = payload;
            return draft;

         case workingDocumentTypes.UPDATE_TWO_WAY_EFFECT_SELECTED_SUCCESS:
            draft.newDocument.breezeMetadata.twoWayEffectSelected = payload;
            return draft;

         case workingDocumentTypes.UPDATE_BLOCK_SELECTED_SUCCESS:
            draft.newDocument.breezeMetadata.blockSelected = payload;
            return draft;

         case workingDocumentTypes.UPDATE_WORKING_DOCUMENT_DESCRIPTION_SUCCESS:
            draft.newDocument.description = payload;
            return draft;

         case workingDocumentTypes.UPDATE_WORKING_DOCUMENT_TEMPLATE_ID_SUCCESS:
            draft.newDocument.templateId = payload;
            return draft;

         case workingDocumentTypes.UPDATE_WORKING_DOCUMENT_PROJECT_SUCCESS:
            draft.newDocument.projectId = payload;
            return draft;

         case workingDocumentTypes.UPDATE_WORKING_DOCUMENT_NAME_SUCCESS:
            draft.newDocument.name = payload;
            return draft;

         case workingDocumentTypes.UPDATE_WORKING_DOCUMENT_SUCCESS: {
            const index = draft.publicWorkingDocuments.findIndex(
               (workingDocument) => workingDocument.id === payload.id
            );
            if (index !== -1) {
               const newWorkingDocument = WorkingDocument.createWorkingDocumentObject(
                  payload
               );
               draft.publicWorkingDocuments[index] = newWorkingDocument;
               draft.publicWorkingDocuments.sort(
                  (document: WorkingDocument, nextDocument: WorkingDocument) =>
                     new Date(`${nextDocument.modifiedDate}`).getTime() -
                     new Date(`${document.modifiedDate}`).getTime()
               );
               draft.newDocument = newWorkingDocument;
            }
            draft.workingDocumentError = null;
            draft.isDocumentUpdateSuccess = true;
            draft.reportMetadata = payload?.reportMetadata;
            draft.newDocument = WorkingDocument.createWorkingDocumentObject(
               payload
            );
            return draft;
         }

         case workingDocumentTypes.UPDATE_DOCUMENT_SUCCESS: {
            const index = draft.publicWorkingDocuments.findIndex(
               (workingDocument) => workingDocument.id === payload.id
            );
            if (index !== -1) {
               const newWorkingDocument = WorkingDocument.createWorkingDocumentObject(
                  payload
               );
               draft.publicWorkingDocuments[index] = newWorkingDocument;
               draft.publicWorkingDocuments.sort(
                  (document: WorkingDocument, nextDocument: WorkingDocument) =>
                     new Date(`${nextDocument.modifiedDate}`).getTime() -
                     new Date(`${document.modifiedDate}`).getTime()
               );
               draft.newDocument = newWorkingDocument;
            }
            draft.workingDocumentError = null;
            return draft;
         }

         case workingDocumentTypes.GET_COUNTRIES_SUCCESS:
            draft.countries = payload;
            return draft;

         case workingDocumentTypes.GET_DOCUMENT_FILTER_DATA_SUCCESS:
            draft.documentFilters = payload;
            return draft;

         case workingDocumentTypes.GET_DOCUMENT_FILTER_DATA_FAILURE:
            draft.documentFilters = [];
            return draft;

         case workingDocumentTypes.GET_VARIABLE_VALUES:
            draft.isLoading = true;
            return draft;
         case workingDocumentTypes.GET_VARIABLE_VALUES_SUCCESS:
            draft.variableValues = payload;
            draft.isVariableValuesGetSuccess = true;
            console.log('draft', draft);
            draft.isLoading = false;
            return draft;
         case workingDocumentTypes.GET_VARIABLE_VALUES_FAILURE:
            draft.variableValues = [];
            draft.isVariableValuesGetSuccess = false;
            draft.isLoading = false;
            console.log('draft 2', draft);
            return draft;
         case workingDocumentTypes.GET_AUTOGENERATED_VARIABLES_VALUES_SUCCESS:
            draft.autoGeneratedVariablesValues = payload;
            return draft;
         case workingDocumentTypes.GET_AUTOGENERATED_VARIABLES_VALUES_FAILURE:
            draft.autoGeneratedVariablesValues = {};
            return draft;
         case workingDocumentTypes.GET_DOCUMENT_BY_DRIVE_ID_SUCCESS:
            draft.documentData = payload;
            return draft;
         default:
            return draft;
      }
   });
