import { handleError, handleResponse } from './apiUtils';

export function getAllStages() {
   const baseUrl = process.env.REACT_APP_API_URL;

   return fetch(`${baseUrl}api/v1/stage`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
   })
      .then(async (response) => {
         const result = await handleResponse(response);
         const stages = result.data;
         return stages;
      })
      .catch(handleError);
}
