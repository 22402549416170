import { WorkingDocument } from '../../core/models/workingDocument/workingDocument';
import { workingDocumentTypes } from '../types/workingDocument';
import { projectTypes } from '../types/project';
import { BreezeWorkingDocument } from '../../core/models/workingDocument/breezeWorkingDocument';

export const addWorkingDocument = (
   payload: WorkingDocument,
   callback: Function
) => ({
   type: workingDocumentTypes.ADD_WORKING_DOCUMENT,
   payload,
   callback,
});

export const addWorkingDocumentSuccess = (payload: WorkingDocument) => ({
   type: workingDocumentTypes.ADD_WORKING_DOCUMENT_SUCCESS,
   payload,
});

export const addPlaceholderWorkingDocument = (
   payload: BreezeWorkingDocument,
   callback: Function
) => ({
   type: workingDocumentTypes.ADD_PLACEHOLDER_WORKING_DOCUMENT,
   payload,
   callback,
});

export const addPlaceholderWorkingDocumentSuccess = (payload: string) => ({
   type: workingDocumentTypes.ADD_PLACEHOLDER_WORKING_DOCUMENT_SUCCESS,
   payload,
});

export const addDriveIdSuccess = (payload: string) => ({
   type: workingDocumentTypes.ADD_DRIVE_ID_SUCCESS,
   payload,
});

export const archiveWorkingDocument = (
   documentId: number,
   projectId: number | null,
   archived: boolean,
   callback: Function
) => ({
   type: workingDocumentTypes.ARCHIVE_WORKING_DOCUMENT,
   payload: { documentId, projectId, archived },
   callback,
});

export const archiveWorkingDocumentFailure = (err: any) => ({
   type: workingDocumentTypes.ARCHIVE_WORKING_DOCUMENT_FAILURE,
   err,
});

export const archiveWorkingDocumentSuccess = (
   documentId: number,
   archived: boolean
) => ({
   type: workingDocumentTypes.ARCHIVE_WORKING_DOCUMENT_SUCCESS,
   payload: { documentId, archived },
});

export const clearWorkingDocumentPlaceHolders = () => ({
   type: workingDocumentTypes.CLEAR_WORKING_DOCUMENT_PLACEHOLDERS,
});

export const clearWorkingDocumentForm = () => ({
   type: workingDocumentTypes.CLEAR_WORKING_DOCUMENT_FORM,
});

export const deleteWorkingDocuments = (
   payload: boolean | number,
   callback: Function
) => ({
   type: workingDocumentTypes.DELETE_WORKING_DOCUMENT,
   payload,
   callback,
});

export const deleteWorkingDocumentSuccess = (payload: boolean | number) => ({
   type: workingDocumentTypes.DELETE_WORKING_DOCUMENT_SUCCESS,
   payload,
});

export const getPublicWorkingDocuments = (
   page: number,
   size: number,
   searchQuery?: string,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.GET_PUBLIC_WORKING_DOCUMENTS,
   payload: { page, size, searchQuery },
   callback,
});

export const getPublicWorkingDocumentSuccess = (payload: {
   workingDocuments: WorkingDocument[];
   count: number;
}) => ({
   type: workingDocumentTypes.GET_PUBLIC_WORKING_DOCUMENTS_SUCCESS,
   payload,
});

export const getAssignWorkingDocuments = (
   page: number,
   size: number,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.GET_ASSIGN_WORKING_DOCUMENTS,
   payload: { page, size },
   callback,
});

export const getAssignWorkingDocumentSuccess = (
   payload: WorkingDocument[]
) => ({
   type: workingDocumentTypes.GET_ASSIGN_WORKING_DOCUMENTS_SUCCESS,
   payload,
});

export const getWorkingDocuments = (
   page: number,
   size: number,
   searchQuery?: string,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.GET_WORKING_DOCUMENTS,
   payload: { page, size, searchQuery },
   callback,
});

export const getWorkingDocumentSuccess = (payload: {
   workingDocuments: WorkingDocument[];
   count: number;
}) => ({
   type: workingDocumentTypes.GET_WORKING_DOCUMENTS_SUCCESS,
   payload,
});

export const getWorkingDocumentById = (
   payload: string | null,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.GET_WORKING_DOCUMENT_BY_ID,
   payload,
   callback,
});

export const getWorkingDocumentByIdSuccess = (payload: any) => ({
   type: workingDocumentTypes.GET_WORKING_DOCUMENT_BY_ID_SUCCESS,
   payload,
});

export const getBreezeMetadata = (
   payload: string | null,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.GET_BREEZE_METADATA,
   payload,
   callback,
});

export const getBreezeMetadataSuccess = (payload: any) => ({
   type: workingDocumentTypes.GET_BREEZE_METADATA_SUCCESS,
   payload,
});

export const updateBreezeMetadataSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_BREEZE_METADATA_SUCCESS,
   payload,
});

export const updateDocumentFavoriteSuccess = (
   id: number,
   isFavorite: boolean,
   callback: Function | boolean = false
) => ({
   type: workingDocumentTypes.UPDATE_WORKING_DOCUMENT_FAVORITE_SUCCESS,
   payload: { id, isFavorite },
   callback,
});

export const triggerImageMergeForBreezeDocument = (
   payload: any,
   callback: Function
) => ({
   type: workingDocumentTypes.IMAGE_MERGE_BREEZE_DOCUMENT,
   payload,
   callback,
});

export const triggerBreezeWithUserSelections = (
   payload: any,
   callback: Function
) => ({
   type: workingDocumentTypes.TRIGGER_BREEZE_IMAGE_GENERATION,
   payload,
   callback,
});

export const updateBigQueryTableWithSelectionData = (
   payload: any,
   callback: Function
) => ({
   type: workingDocumentTypes.UPDATE_BIG_QUERY_TABLE_WITH_SELECTION_DATA,
   payload,
   callback,
});

export const updateSelectedMoleculeSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_MOLECULE_SUCCESS,
   payload,
});

export const updateSelectedChromoStepSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_CHROMO_STEP_SUCCESS,
   payload,
});

export const updateRunsSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_RUNS_SUCCESS,
   payload,
});

export const updateInputSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_INPUTS_SUCCESS,
   payload,
});

export const updateInputComputerSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_INPUTS_COMPUTER_SUCCESS,
   payload,
});

export const updateKpiSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_KPI_SUCCESS,
   payload,
});

export const updateKpiComputerSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_KPI_COMPUTER_SUCCESS,
   payload,
});

export const updateKpiMetadataSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_KPI_METADATA_SUCCESS,
   payload,
});

export const updateQaSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_QA_SUCCESS,
   payload,
});

export const updateQaComputerSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_QA_COMPUTER_SUCCESS,
   payload,
});

export const updateQaMetadataSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_QA_METADATA_SUCCESS,
   payload,
});

export const updateOutputsMetadataSelectionSuccessWrDoc = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_OUTPUTS_METADATA_SUCCESS,
   payload,
});

export const updateBreezeMetadataDriveIdSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_BREEZE_METADATA_DRIVE_ID_SUCCESS,
   payload,
});

export const updateWorkingDocumentNameSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_WORKING_DOCUMENT_NAME_SUCCESS,
   payload,
});

export const updateWorkingDocumentDescriptionSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_WORKING_DOCUMENT_DESCRIPTION_SUCCESS,
   payload,
});

export const updateWorkingDocumentTemplateIdSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_WORKING_DOCUMENT_TEMPLATE_ID_SUCCESS,
   payload,
});

export const updateWorkingDocumentProjectSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_WORKING_DOCUMENT_PROJECT_SUCCESS,
   payload,
});

export const updateWorkingDocumentLinearRegressionSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_LINEAR_REGRESSION_SUCCESS,
   payload,
});

export const updateFactorStudySuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_SELECTED_FACTOR_STUDY_SUCCESS,
   payload,
});

export const updateQuadraticEffectList = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_QUADRATIC_EFFECT_LIST_SUCCESS,
   payload,
});

export const updateQuadraticEffectSelected = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_QUADRATIC_EFFECT_SELECTED_SUCCESS,
   payload,
});

export const updateTwoWayInteractionEffectList = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_TWO_WAY_EFFECT_LIST_SUCCESS,
   payload,
});

export const updateTwoWayEffectSelected = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_TWO_WAY_EFFECT_SELECTED_SUCCESS,
   payload,
});

export const updateBlockSelected = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_BLOCK_SELECTED_SUCCESS,
   payload,
});

export const updateWorkingDocument = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.UPDATE_WORKING_DOCUMENT,
   payload,
   callback,
});

export const updateWorkingDocumentSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_WORKING_DOCUMENT_SUCCESS,
   payload,
});

export const updateDocument = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.UPDATE_DOCUMENT,
   payload,
   callback,
});

export const updateDocumentSuccess = (payload: any) => ({
   type: workingDocumentTypes.UPDATE_DOCUMENT_SUCCESS,
   payload,
});

export const workingDocumentFailure = (err: any) => ({
   type: workingDocumentTypes.WORKING_DOCUMENT_ACTION_FAILURE,
   err,
});

export const getCountries = (callback: Function = () => {}) => ({
   type: workingDocumentTypes.GET_COUNTRIES,
   callback,
});

export const getCountriesSuccess = (payload: any) => ({
   type: workingDocumentTypes.GET_COUNTRIES_SUCCESS,
   payload,
});

export const getDocumentFilterData = (
   query: string | null,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.GET_DOCUMENT_FILTER_DATA,
   payload: query,
   callback,
});

export const getDocumentFilterDataSuccess = (payload: any) => ({
   type: workingDocumentTypes.GET_DOCUMENT_FILTER_DATA_SUCCESS,
   payload,
});

export const getDocumentFilterDataFailure = () => ({
   type: workingDocumentTypes.GET_DOCUMENT_FILTER_DATA_FAILURE,
});

export const getVariableValues = (
   id: number | null,
   query: string | null,
   roNumber: string | null,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.GET_VARIABLE_VALUES,
   payload: { id, query, roNumber },
   callback,
});

export const getVariableValuesSuccess = (payload: []) => ({
   type: workingDocumentTypes.GET_VARIABLE_VALUES_SUCCESS,
   payload,
});

export const getVariableValuesFailure = (error: any) => ({
   type: workingDocumentTypes.GET_VARIABLE_VALUES_FAILURE,
   error,
});

export const getAutogeneratedVariablesValues = (
   payload: any,
   callback: Function
) => ({
   payload,
   callback,
   type: workingDocumentTypes.GET_AUTOGENERATED_VARIABLES_VALUES,
});

export const getAutogeneratedVariablesValuesSuccess = (payload: any) => ({
   payload,
   type: workingDocumentTypes.GET_AUTOGENERATED_VARIABLES_VALUES_SUCCESS,
});

export const getDocumentByDriveId = (
   driveId: string,
   callback: Function = () => {}
) => ({
   type: workingDocumentTypes.GET_DOCUMENT_BY_DRIVE_ID,
   payload: driveId,
   callback,
});

export const getDocumentByDriveIdSuccess = (payload: any) => ({
   type: workingDocumentTypes.GET_DOCUMENT_BY_DRIVE_ID_SUCCESS,
   payload,
});

export const getDocumentByDriveIdFailure = (error: any) => ({
   type: workingDocumentTypes.GET_DOCUMENT_BY_DRIVE_ID_FAILURE,
   error,
});

export const ARCHIVE_PROJECT_WORKING_DOCUMENT = (
   projectId: number,
   documentId: number
) => ({
   type: projectTypes.DELETE_DOCUMENT_IN_PROJECT_SUCCESS,
   payload: { projectId, documentId },
});

export const CLEAR_WORKING_DOCUMENT_STATE = () => ({
   type: workingDocumentTypes.CLEAR_WORKING_DOCUMENT_STATE,
});

export const DELETE_PROJECT_WORKING_DOCUMENT = (payload: number) => ({
   type: workingDocumentTypes.DELETE_PROJECT_WORKING_DOCUMENT,
   payload,
});
