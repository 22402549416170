export const variablesAndMetadataTypes = {
   GET_ALL_VARIABLES: 'GET_ALL_VARIABLES',
   GET_ALL_VARIABLES_SUCCESS: 'GET_ALL_VARIABLES_SUCCESS',
   GET_ALL_VARIABLES_FAILURE: 'GET_ALL_VARIABLES_FAILURE',
   GET_VARIABLE_BY_ID: 'GET_VARIABLE_BY_ID',
   GET_VARIABLE_BY_ID_SUCCESS: 'GET_VARIABLE_BY_ID_SUCCESS',
   GET_VARIABLE_BY_ID_FAILURE: 'GET_VARIABLE_BY_ID_FAILURE',
   ADD_REPORTS_TO_VARIABLE: 'ADD_REPORTS_TO_VARIABLE_ID',
   ADD_REPORTS_TO_VARIABLE_SUCCESS: 'ADD_REPORTS_TO_VARIABLE_SUCCESS',
   ADD_REPORTS_TO_VARIABLE_FAILURE: 'ADD_REPORTS_TO_VARIABLE_FAILURE',
   GET_ALL_REPORTS: 'GET_ALL_REPORTS',
   GET_ALL_REPORTS_SUCCESS: 'GET_ALL_REPORTS_SUCCESS',
   GET_ALL_REPORTS_FAILURE: 'GET_ALL_REPORTS_FAILURE',
   GET_FILTERED_REPORTS_SUCCESS: 'GET_FILTERED_REPORTS_SUCCESS',
   CREATE_VARIABLE: 'CREATE_VARIABLE',
   CREATE_VARIABLE_SUCCESS: 'CREATE_VARIABLE_SUCCESS',
   CREATE_VARIABLE_FAILURE: 'CREATE_VARIABLE_FAILURE',
   UPDATE_VARIABLE: 'UPDATE_VARIABLE',
   UPDATE_VARIABLE_SUCCESS: 'UPDATE_VARIABLE_SUCCESS',
   UPDATE_VARIABLE_FAILURE: 'UPDATE_VARIABLE_FAILURE',
   DELETE_VARIABLE: 'DELETE_VARIABLE',
   DELETE_VARIABLE_SUCCESS: 'DELETE_VARIABLE_SUCCESS',
   DELETE_VARIABLE_FAILURE: 'DELETE_VARIABLE_FAILURE',
   GET_REPORT_IDENTIFIERS: 'GET_REPORT_IDENTIFIERS',
   GET_REPORT_IDENTIFIERS_SUCCESS: 'GET_REPORT_IDENTIFIERS_SUCCESS',
   GET_REPORT_IDENTIFIERS_FAILURE: 'GET_REPORT_IDENTIFIERS_FAILURE',
   GET_VARIABLE_DATASETS: 'GET_VARIABLE_DATASETS',
   GET_VARIABLE_DATASETS_SUCCESS: 'GET_VARIABLE_DATASETS_SUCCESS',
   GET_VARIABLE_DATASETS_FAILURE: 'GET_VARIABLE_DATASETS_FAILURE',
   GET_VARIABLE_TABLES: 'GET_VARIABLE_TABLES',
   GET_VARIABLE_TABLES_SUCCESS: 'GET_VARIABLE_TABLES_SUCCESS',
   GET_VARIABLE_TABLES_FAILURE: 'GET_VARIABLE_TABLES_FAILURE',
   UPDATE_VARIABLE_IDENTIFIERS: 'UPDATE_VARIABLE_IDENTIFIERS',
   UPDATE_VARIABLE_IDENTIFIERS_SUCCESS: 'UPDATE_VARIABLE_IDENTIFIERS_SUCCESS',
   UPDATE_VARIABLE_IDENTIFIERS_FAILURE: 'UPDATE_VARIABLE_IDENTIFIERS_FAILURE',
   GET_TABLE_GENERATOR_NAMES: 'GET_TABLE_GENERATOR_NAMES',
   GET_TABLE_GENERATOR_NAMES_SUCCESS: 'GET_TABLE_GENERATOR_NAMES_SUCCESS',
   GET_TABLE_GENERATOR_NAMES_FAILURE: 'GET_TABLE_GENERATOR_NAMES_FAILURE',
   GET_VARIABLE_BIG_QUERY_NAMES: 'GET_VARIABLE_BIG_QUERY_NAMES',
   GET_VARIABLE_BIG_QUERY_NAMES_SUCCESS: 'GET_VARIABLE_BIG_QUERY_NAMES_SUCCESS',
   GET_VARIABLE_BIG_QUERY_NAMES_FAILURE: 'GET_VARIABLE_BIG_QUERY_NAMES_FAILURE',
   GET_EXISTING_UI_NAMES: 'GET_EXISTING_UI_NAMES',
   GET_EXISTING_UI_NAMES_SUCCESS: 'GET_EXISTING_UI_NAMES_SUCCESS',
   GET_EXISTING_UI_NAMES_FAILURE: 'GET_EXISTING_UI_NAMES_FAILURE',
};
