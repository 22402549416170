import { StatusInterface } from './statusInterface';

export class Status implements StatusInterface {
   constructor(
      public id: number,
      public type: string,
      public createdAt: string,
      public updatedAt: string
   ) {}

   public static createStatusObject(data: any) {
      return new Status(
         data.id,
         data.type,
         new Date(data.createdAt).toLocaleDateString(),
         new Date(data.updatedAt).toLocaleDateString()
      );
   }
}
