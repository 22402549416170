export const ctdTitleTypes = {
   GET_CTD_TITLES: 'GET_CTD_TITLES',
   GET_CTD_TITLES_SUCCESS: 'GET_CTD_TITLES_SUCCESS',
   GET_CTD_TITLE_BY_CODE: 'GET_CTD_TITLE_BY_CODE',
   GET_CTD_TITLE_BY_CODE_SUCCESS: 'GET_CTD_TITLE_BY_CODE_SUCCESS',
   GET_ACTIVE_CTD_TITLE: 'GET_ACTIVE_CTD_TITLE',
   GET_ACTIVE_CTD_TITLE_SUCCESS: 'GET_ACTIVE_CTD_TITLE_SUCCESS',
   ACTIVATE_CTD_TITLE: 'ACTIVATE_CTD_TITLE',
   ACTIVATE_CTD_TITLE_SUCCESS: 'ACTIVATE_CTD_TITLE_SUCCESS',
   ACTIVATE_CTD_TITLE_FAILURE: 'ACTIVATE_CTD_TITLE_FAILURE',
   CTD_TITLE_FAILURE: 'CTD_TITLE_FAILURE',
};
