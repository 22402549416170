import { dev } from '../_dev/_utils';

export const isJson = (jsonString: string) => {
   try {
      JSON.parse(jsonString);
      return true;
   } catch (error) {
      return false;
   }
};

export const getCookie = (cookieName: string) => {
   const cookie = {};
   const pair = document.cookie
      .split(';')
      .find((cookie) => cookie.trim().match(cookieName))
      ?.split('=');
   if (pair) {
      const value = pair[1].trim();
      // @ts-ignore
      cookie[pair[0].trim()] = value;
      dev.log('Get Cookie =>', cookie);
      return value;
   }

   return '';
};

export const sanitizeValue = (value: string | number, type: string) => {
   let str = String(value);
   const blacklistInput = ['<', '>', '"', "'"];
   const blacklistTextarea = ['<script>', '</script>', '<a>', '</a>'];
   const blacklist = type === 'input' ? blacklistInput : blacklistTextarea;
   blacklist.forEach((char) => {
      if (str.includes(char)) {
         str = str.replaceAll(char, '');
      }
   });
   return str.replace(/[^\x20-\x7E]/g, '');
};

export const retrieveProductModifiedData = (data: [] | any, type: string) => {
   const drugProductsData: any = [];
   const drugSubstanceData: any = [];
   const countriesData: any = [];
   let productsData: any = [];
   let substancesData: any = [];

   if (data.length) {
      data.forEach((metadata: any) => {
         if (metadata.countries?.length) {
            const countryData = metadata.countries?.map(
               (country: string, key: number) => {
                  return {
                     name: country,
                     id: key,
                  };
               }
            );
            countriesData.push(...countryData);
         }

         if (type === 'create' || type === 'project') {
            productsData = metadata.products;
            substancesData = metadata.substances;
         } else {
            productsData = metadata.drugProducts;
            substancesData = metadata.drugSubstances;
         }

         if (productsData?.length) {
            const productData = productsData?.map(
               (product: any, key: number) => {
                  if (type !== 'project') {
                     return {
                        ...product,
                        drugProduct: product.name,
                        manufacturers: product.manufacturers?.map(
                           (name: string, i: number) => ({
                              name,
                              id: i,
                           })
                        ),
                        selectedManufacturers: product.manufacturers?.map(
                           (name: string, i: number) => ({
                              name,
                              id: i,
                           })
                        ),
                     };
                  }

                  return {
                     name: product.name,
                     id: key,
                  };
               }
            );
            drugProductsData.push(...productData);
         }

         if (substancesData?.length) {
            const substanceData = substancesData?.map(
               (substance: any, key: number) => {
                  if (type !== 'project') {
                     return {
                        name: substance.name,
                        manufacturers: substance.manufacturers?.map(
                           (name: string, i: number) => ({
                              name,
                              id: i,
                           })
                        ),
                        selectedManufacturers: substance.manufacturers?.map(
                           (name: string, i: number) => ({
                              name,
                              id: i,
                           })
                        ),
                     };
                  }

                  return {
                     name: substance.name,
                     id: key,
                  };
               }
            );
            drugSubstanceData.push(...substanceData);
         }
      });
   }

   const uniqueProducts = drugProductsData.filter(
      (ele: any, ind: number) =>
         ind ===
         drugProductsData.findIndex(
            (elem: any) => elem.name === ele.name && elem.id === ele.id
         )
   );

   const uniqueSubstances = drugSubstanceData.filter(
      (ele: any, ind: number) =>
         ind ===
         drugSubstanceData.findIndex(
            (elem: any) => elem.name === ele.name && elem.id === ele.id
         )
   );

   return {
      drugProductsData,
      drugSubstanceData,
      countriesData,
      uniqueProducts,
      uniqueSubstances,
   };
};

export const retrieveRoNumberModifiedData = (data: [] | any, page: string) => {
   const {
      isEdit,
      roNumber,
      selectedRoNumberData,
      productListAll,
      substanceListAll,
      selectedRow,
      rowNumbersRef,
   } = data;

   const isProductsSubstanceDataAvailable =
      (page === 'workingDocument' &&
         (roNumber?.products?.length || roNumber?.substances?.length)) ||
      roNumber?.substances?.length;
   const productListAllCopy = [...productListAll];
   const substanceListAllCopy = [...substanceListAll];

   if (isEdit) {
      if (isProductsSubstanceDataAvailable) {
         const rows: any = rowNumbersRef.current || [];
         const metaKey: any = rows.find(
            (r: any) => r.row === selectedRoNumberData
         )?.key;
         const index = productListAllCopy.findIndex((p) => p.key === metaKey);

         if (index !== -1) {
            productListAllCopy[index].products = roNumber.products;
            substanceListAllCopy[index].substances = roNumber.substances;
         } else {
            substanceListAllCopy.push({
               key: metaKey,
               substances: roNumber.substances,
            });
            productListAllCopy.push({
               key: metaKey,
               products: roNumber.products,
            });
         }
      }
   } else {
      const key = selectedRow.current;
      if (isProductsSubstanceDataAvailable && key) {
         const index = productListAllCopy.findIndex((p) => p.key === key);

         if (index !== -1) {
            productListAllCopy[index].products = roNumber.products;
            substanceListAllCopy[index].substances = roNumber.substances;
         } else {
            substanceListAllCopy.push({
               key,
               substances: roNumber.substances,
            });
            productListAllCopy.push({ key, products: roNumber.products });
         }
      }
   }

   return { productListAllCopy, substanceListAllCopy };
};

export const generateTextForUseInDocument = (
   useInDoc: boolean,
   selection: string
) => {
   let text = '';
   const variableTypes = ['Free Text', 'Automatic', 'Automatic & Dropdown'];
   const metadataTypes = ['Dropdown', 'Binary'];
   if (useInDoc && variableTypes.indexOf(selection) > -1) {
      text = 'Variable used in content';
   } else if (useInDoc && metadataTypes.indexOf(selection) > -1) {
      text = 'Metadata used in content';
   } else if (!useInDoc && variableTypes.indexOf(selection) > -1) {
      text = 'Variable not used in content';
   } else if (!useInDoc && metadataTypes.indexOf(selection) > -1) {
      text = 'Metadata not used in content';
   }
   return text;
};

export const generateVariableFieldText = (selection: string) => {
   let text = '';
   if (selection === 'Dropdown') {
      text = 'User selection from provided list';
   } else if (selection === 'Automatic') {
      text = 'Auto Populated';
   } else if (selection === 'Automatic & Dropdown') {
      text = 'Automatic & Dropdown';
   } else {
      text = 'Free text';
   }

   return text;
};
