/* eslint-disable max-classes-per-file */
export class RoleInterface {
   id: string | number;

   title: string;

   permissionsCount: number;

   createdDate: string;

   modifiedDate: string;

   name: string;

   description: string;

   permissions: PermissionsInterface;

   createdAt: string;
}

export class PermissionsInterface {
   FONTO: FontoPermissions;

   LANDING: LandingPermissions;

   LOOKER: LookerPermissions;
}

export class FontoPermissions {
   template: PermissionActions;

   working_document: PermissionActions;
}

export class LandingPermissions {
   generated_document: PermissionActions;

   template: PermissionActions;

   project: PermissionActions;

   working_document: PermissionActions;
}

export class LookerPermissions {
   query: PermissionActions;
}

export class PermissionActions {
   create: boolean;

   download: boolean;

   edit: boolean;

   remove: boolean;

   transfer: boolean;

   view: boolean;
}

export const getPermissionsCount = (permissions: PermissionsInterface) => {
   let count = 0;

   Object.values(permissions).forEach((category: any) => {
      Object.values(category).forEach((types: any) => {
         Object.values(types).forEach((permission: any) => {
            if (permission === true) {
               count++;
            }
         });
      });
   });

   return count;
};

export const createRolesObject = (dbRole: any) => {
   const Role = new RoleInterface();

   Role.id = dbRole.id;
   Role.title = dbRole.name;
   Role.description = dbRole.description;
   Role.permissions = dbRole.permissions;
   Role.permissionsCount = getPermissionsCount(dbRole.permissions);
   Role.createdDate = `${new Date(
      dbRole.createdAt
   ).toLocaleDateString()} ${new Date(dbRole.createdAt).toLocaleTimeString()}`;
   Role.modifiedDate = `${new Date(
      dbRole.modifiedAt
   ).toLocaleDateString()} ${new Date(dbRole.modifiedAt).toLocaleTimeString()}`;

   return Role;
};
