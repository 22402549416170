import { IBreezeMetadata } from './breezeMetadataInterface';

export class BreezeMetadata implements IBreezeMetadata {
   constructor(
      public documentStage: number = 0,
      public factorStudy: string = '',
      public input: string[] = [],
      public inputComputer: string[] = [],
      public kpi: string[] = [],
      public kpiComputer: string[] = [],
      public kpi_metadata: any[] = [],
      public linearRegresion: string = '',
      public molecule: string = '',
      public outputsMetadata: any[] = [],
      public qa: string[] = [],
      public qaComputer: string[] = [],
      public qa_metadata: any[] = [],
      public quadraticEffectList: string[] = [],
      public quadraticEffectSelected: boolean = false,
      public runs: string[] = [],
      public twoWayInteractionEffectList: string[] = [],
      public twoWayEffectSelected: boolean = false,
      public blockSelected: boolean = false,
      public unit_ops: string = '',
      public workingDocumentDriveId: string = ''
   ) {}

   public static createBreezeMetadatasObject(data: any) {
      return new BreezeMetadata(
         data.documentStage,
         data.factorStudy,
         data.input,
         data.inputComputer,
         data.kpi,
         data.kpiComputer,
         data.kpi_metadata,
         data.linearRegresion,
         data.molecule,
         data.outputsMetadata,
         data.qa,
         data.qaComputer,
         data.qa_metadata,
         data.quadraticEffectList,
         data.quadraticEffectSelected,
         data.runs,
         data.twoWayInteractionEffectList,
         data.twoWayEffectSelected,
         data.blockSelected,
         data.unit_ops,
         data.workingDocumentDriveId
      );
   }
}
