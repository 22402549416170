import { put, call, takeLatest } from 'redux-saga/effects';
import { FavoriteApi } from '../../core/api/favorites.api';
import {
   changeDocumentFavoriteFailure,
   changeDocumentFavoriteSuccess,
   changeProjectFavoriteFailure,
   changeProjectFavoriteSuccess,
   changeTemplateFavoriteFailure,
   changeTemplateFavoriteSuccess,
   getAllFavoritesFailure,
   getAllFavoritesSuccess,
} from '../actions/favorite';
import { updateModelDocumentFavoriteSuccess } from '../actions/modelDocument';
import { updateProjectFavoriteSuccess } from '../actions/project';
import { updateDocumentFavoriteSuccess } from '../actions/workingDocument';
import { favoriteTypes } from '../types/favorite';

export function* getAllFavorites({
   callback,
}: {
   type: string;
   payload: number;
   callback: Function;
}) {
   try {
      const data = yield call(() => FavoriteApi.getAllFavorites());
      yield put(getAllFavoritesSuccess(data));
      if (callback) {
         callback(null, data);
      }
   } catch (error) {
      if (callback) {
         callback(JSON.parse(error.message));
      }
      yield put(getAllFavoritesFailure());
   }
}

export function* changeDocumentFavorite({
   payload,
   callback,
}: {
   type: string;
   payload: {
      id: number;
      isFavorite: boolean;
   };
   callback: Function;
}) {
   try {
      const data = yield call(() =>
         FavoriteApi.changeDocumentFavoriteStatus(
            payload.id,
            payload.isFavorite
         )
      );
      yield put(changeDocumentFavoriteSuccess(payload.isFavorite));
      yield put(updateDocumentFavoriteSuccess(payload.id, payload.isFavorite));
      if (callback) {
         callback(null, data);
      }
   } catch (error) {
      if (callback) {
         callback(JSON.parse(error.message));
      }
      yield put(changeDocumentFavoriteFailure(payload.isFavorite));
   }
}

export function* changeProjectFavorite({
   payload,
   callback,
}: {
   type: string;
   payload: {
      id: number;
      isFavorite: boolean;
   };
   callback: Function;
}) {
   try {
      const data = yield call(() =>
         FavoriteApi.changeProjectFavoriteStatus(payload.id, payload.isFavorite)
      );
      yield put(changeProjectFavoriteSuccess(payload.isFavorite));
      yield put(updateProjectFavoriteSuccess(payload.id, payload.isFavorite));
      if (callback) {
         callback(null, data);
      }
   } catch (error) {
      if (callback) {
         callback(JSON.parse(error.message));
      }
      yield put(changeProjectFavoriteFailure(payload.isFavorite));
   }
}

export function* changeTemplateFavorite({
   payload,
   callback,
}: {
   type: string;
   payload: {
      id: number;
      isFavorite: boolean;
   };
   callback: Function;
}) {
   try {
      const data = yield call(() =>
         FavoriteApi.changeTemplateFavoriteStatus(
            payload.id,
            payload.isFavorite
         )
      );
      yield put(changeTemplateFavoriteSuccess(payload.isFavorite));
      yield put(
         updateModelDocumentFavoriteSuccess(payload.id, payload.isFavorite)
      );
      if (callback) {
         callback(null, data);
      }
   } catch (error) {
      if (callback) {
         callback(JSON.parse(error.message));
      }
      yield put(changeTemplateFavoriteFailure(payload.isFavorite));
   }
}

export default function* favoriteWatcher() {
   yield takeLatest(favoriteTypes.GET_ALL_FAVORITES, getAllFavorites);
   yield takeLatest(
      favoriteTypes.CHANGE_DOCUMENT_FAVORITE,
      changeDocumentFavorite
   );
   yield takeLatest(
      favoriteTypes.CHANGE_PROJECT_FAVORITE,
      changeProjectFavorite
   );
   yield takeLatest(
      favoriteTypes.CHANGE_TEMPLATE_FAVORITE,
      changeTemplateFavorite
   );
}
