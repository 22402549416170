import { ProjectInterface } from '../models/project/projectInterface';
import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class ProjectApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static loadProjects(
      page: number,
      size: number,
      searchQuery: string,
      includeWorkingDocuments: boolean
   ) {
      return fetch(
         `${baseUrl}api/v1/project?page=${page}&size=${size}&searchQuery=${searchQuery}&includeWorkingDocuments=${includeWorkingDocuments}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static loadAssignedProjects(
      page: number,
      size: number,
      includeWorkingDocuments: boolean
   ) {
      return fetch(
         `${baseUrl}api/v1/project/assignedProjects?page=${page}&size=${size}&includeWorkingDocuments=${includeWorkingDocuments}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      ).then(async (response) => {
         const result = await handleResponse(response);
         return result.data.rows;
      });
   }

   static createNewProject(project: ProjectInterface) {
      return fetch(`${baseUrl}api/v1/project`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(project),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static updateProject(project: ProjectInterface) {
      return fetch(`${baseUrl}api/v1/project/${project.id}`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(project),
      }).then(async (response) => {
         const result = await handleResponse(response);
         return result.data;
      });
   }

   static getProjectById(projectId: number | null, queryText: string | null) {
      return fetch(`${baseUrl}api/v1/project/${projectId}?${queryText}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      }).then(async (response) => {
         const result = await handleResponse(response);
         return result.data;
      });
   }

   static deleteProject(projectId: number | null) {
      return fetch(`${baseUrl}api/v1/project/${projectId}`, {
         credentials: 'include',
         method: 'DELETE',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static assignDocumentToProject(documentList: [], projectId: number) {
      return fetch(`${baseUrl}api/v1/project/assignDocuments`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({
            documentList,
            projectId,
         }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static deleteDocumentFromProject(projectId: number, documentId: number) {
      return fetch(
         `${baseUrl}api/v1/project/${projectId}/document/${documentId}`,
         {
            credentials: 'include',
            method: 'DELETE',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static archiveProject(
      projectId: number | null,
      archived: boolean,
      isDocumentArchived: boolean = false
   ) {
      return fetch(`${baseUrl}api/v1/project/archive`, {
         credentials: 'include',
         method: 'PATCH',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({
            projectId,
            archived,
            isDocumentArchived,
         }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static getDivisions() {
      return fetch(`${process.env.REACT_APP_API_URL}api/v1/division`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static unassignDocument({
      documentId,
      projectId,
   }: {
      documentId: number;
      projectId: number;
   }) {
      return fetch(`${baseUrl}api/v1/project/unassignDocument`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({
            documentId,
            projectId,
         }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static getProjectFiltersData(query: string | null) {
      return fetch(
         `${process.env.REACT_APP_API_URL}api/v1/project/filterData?${query}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
