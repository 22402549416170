import { handleError, handleResponse } from './apiUtils';

const baseUrl = process.env.REACT_APP_API_URL;

export class ConfigurationApi {
   static setSessionTimeInterval(data: { timeout: boolean; interval: number }) {
      return fetch(`${baseUrl}api/v1/configuration`, {
         credentials: 'include',
         method: 'POST',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static getSessionInterval() {
      return fetch(`${baseUrl}api/v1/configuration`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
