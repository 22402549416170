import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TransferIcon = React.forwardRef(function TransferIcon(props: any, ref) {
   return (
      <SvgIcon {...props} ref={ref} viewBox="0 0 512 512">
         <path d="M325,115c0,2.8,2.2,5,5,5h114.3c-2.7-5.1-6.3-9.7-10.5-13.7L337.4,15c-3.7-3.5-7.9-6.5-12.4-8.8L325,115L325,115z" />
         <path d="M330,150c-19.3,0-35-15.7-35-35V0H116C85.7,0,61,24.7,61,55v402c0,30.3,24.7,55,55,55h280c30.3,0,55-24.7,55-55V150H330z M296.1,430.5v-61.6H98V251.1h198.1v-61.6L423,310L296.1,430.5z" />
      </SvgIcon>
   );
});
export default TransferIcon;
