import { modelDocTypes } from '../types/modelDocument';
import { ModelDocumentCallback } from '../../core/models/modelDocument/modelDocument';
import { COMPONENT_ERROR } from '../../shared/CONSTANTS';
import { IModelDocumentType } from '../../core/models/modelDocument/modelDOcumentTypes';
import { ModelDocumentVariables } from '../../core/models/modelDocument/modelDocumentVariables';
import { BaseTemplate } from '../../core/models/baseTemplate';
import { DuplicateModelDoc } from '../../core/models/modelDocument/modelDocumentDuplication';

export const getModelDocuments = (
   payload: { page: number; pageSize: number; searchQuery?: string },
   callback: ModelDocumentCallback = () => {}
) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENTS,
   payload,
   callback,
});

export const getModelDocumentsSuccess = (payload: any) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENTS_SUCCESS,
   payload,
});

export const getBaseTemplates = (callback: Function = () => {}) => ({
   type: modelDocTypes.GET_BASE_TEMPLATE,
   callback,
});

export const getBaseTemplatesSuccess = (payload: BaseTemplate[]) => ({
   type: modelDocTypes.GET_BASE_TEMPLATE_SUCCESS,
   payload,
});

export const getModelDocumentById = (
   payload: number | null,
   callback: Function = () => {}
) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_BY_ID,
   payload,
   callback,
});

export const getModelDocumentByIdSuccess = (payload: any) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_BY_ID_SUCCESS,
   payload,
});

export const addModelDocument = (payload: any, callback: Function) => ({
   type: modelDocTypes.ADD_MODEL_DOCUMENT,
   payload,
   callback,
});

export const addModelDocumentSuccess = (payload: any) => ({
   type: modelDocTypes.ADD_MODEL_DOCUMENT_SUCCESS,
   payload,
});

export const updateModelDocument = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: modelDocTypes.UPDATE_MODEL_DOCUMENT,
   payload,
   callback,
});

export const updateModelDocumentSuccess = (payload: any) => ({
   type: modelDocTypes.UPDATE_MODEL_DOCUMENT_SUCCESS,
   payload,
});

export const updateModelDocumentFavoriteSuccess = (
   id: number,
   isFavorite: boolean,
   callback: Function | boolean = false
) => ({
   type: modelDocTypes.UPDATE_MODEL_DOCUMENT_FAVORITE_SUCCESS,
   payload: { id, isFavorite },
   callback,
});

export const updateModelDocumentModifier = (
   payload: number | null,
   callback: Function = () => {}
) => ({
   type: modelDocTypes.UPDATE_MODEL_DOCUMENT_MODIFIER,
   payload,
   callback,
});

export const updateModelDocumentModifierSuccess = (payload: any) => ({
   type: modelDocTypes.UPDATE_MODEL_DOCUMENT_MODIFIER_SUCCESS,
   payload,
});

export const updateModelDocumentModifierFailure = (payload: any) => ({
   type: modelDocTypes.UPDATE_MODEL_DOCUMENT_MODIFIER_FAILURE,
   payload,
});

export const deleteModelDocument = (payload: any, callback: Function) => ({
   type: modelDocTypes.DELETE_MODEL_DOCUMENT,
   payload,
   callback,
});

export const deleteModelDocumentSuccess = (payload: any) => ({
   type: modelDocTypes.DELETE_MODEL_DOCUMENT_SUCCESS,
   payload,
});

export const modelDocumentActionFailure = (payload: COMPONENT_ERROR) => ({
   type: modelDocTypes.MODEL_DOCUMENT_ACTION_FAILURE,
   payload,
});
export const getFailure = (payload: any) => ({
   type: modelDocTypes.MODEL_DOCUMENT_ACTION_FAILURE,
   payload,
});

export const updateFail = (payload: any) => ({
   type: modelDocTypes.UPDATE_MODEL_DOCUMENT_FAILURE,
   payload,
});

export const archiveModelDocument = (
   documentId: number,
   archived: boolean,
   callback: Function
) => ({
   type: modelDocTypes.ARCHIVE_MODEL_DOCUMENT,
   payload: { documentId, archived },
   callback,
});

export const archiveModelDocumentSuccess = (documentId: number | null) => ({
   type: modelDocTypes.ARCHIVE_MODEL_DOCUMENT_SUCCESS,
   payload: { documentId },
});

export const archiveModelDocumentFailure = (err: any) => ({
   type: modelDocTypes.ARCHIVE_MODEL_DOCUMENT_FAILURE,
   err,
});

export const getModelDocumentTypes = (callback: Function) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_TYPES,
   callback,
});

export const getModelDocumentTypesSuccess = (
   payload: IModelDocumentType[]
) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_TYPES_SUCCESS,
   payload,
});

export const getModelDocumentFiltersData = (
   query: string | null,
   callback: Function
) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_FILTERS_DATA,
   payload: query,
   callback,
});

export const getModelDocumentFiltersDataSuccess = (payload: any) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_FILTERS_DATA_SUCCESS,
   payload,
});

export const getModelDocumentFiltersDataFailure = () => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_FILTERS_DATA_FAILURE,
});

export const getModelDocumentVariables = (
   payload: {
      report: string;
      stage: string;
      productArea: string;
      title: string;
      bbCodeType: string;
   },
   callback: Function
) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_VARIABLES,
   payload,
   callback,
});

export const getModelDocumentVariablesSuccess = (
   payload: ModelDocumentVariables[]
) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_VARIABLES_SUCCESS,
   payload,
});

export const getModelDocumentVariablesFailure = () => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_VARIABLES_FAILURE,
});

export const CLEAR_MODEL_DOCUMENT_STATE = () => ({
   type: modelDocTypes.CLEAR_MODEL_DOCUMENT_STATE,
});

export const duplicateModelDocument = (
   payload: DuplicateModelDoc,
   callback: Function = () => {}
) => ({
   type: modelDocTypes.DUPLICATE_MODEL_DOCUMENT,
   payload,
   callback,
});

export const publishModelDocument = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: modelDocTypes.PUBLISH_MODEL_DOCUMENT,
   payload,
   callback,
});

export const wthdrawModelDocument = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: modelDocTypes.WITHDRAW_MODEL_DOCUMENT,
   payload,
   callback,
});

export const getModelDocumentStatuses = (callback: Function = () => {}) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_STATUSES,
   callback,
});

export const getModelDocumentStatusesSuccess = (payload: []) => ({
   type: modelDocTypes.GET_MODEL_DOCUMENT_STATUSES_SUCCESS,
   payload,
});
