import { handleError, handleResponse } from './apiUtils';
import { getCookie } from '../../shared/helpers';

const baseUrl = process.env.REACT_APP_API_URL;

export class VariablesApi {
   static getAllVariables(filterText: string = '') {
      return fetch(`${baseUrl}api/v1/mdVariables?${filterText}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getVariableById(id: string = '') {
      return fetch(`${baseUrl}api/v1/mdVariables/${id}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getVariableIdentifiers(filterQuery: string) {
      return fetch(
         `${baseUrl}api/v1/mdVariables/identifiers?${filterQuery ?? ''}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static addReportsToVariable(
      id: number,
      identifiers: number[]
   ): Promise<any> {
      return fetch(`${baseUrl}api/v1/mdVariables/${id}/identifiers`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify({
            identifiers,
         }),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result;
         })
         .catch(handleError);
   }

   static createVariable(data: {}) {
      return fetch(`${baseUrl}api/v1/mdVariables`, {
         credentials: 'include',
         method: 'POST',
         headers: {
            'content-type': 'application/json',
            Authorization: getCookie('cmsAuth'),
            'x-id-token': getCookie('id_token'),
         },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static updateVariable(data: any) {
      return fetch(`${baseUrl}api/v1/mdVariables/${data.id}`, {
         credentials: 'include',
         method: 'PUT',
         headers: {
            'content-type': 'application/json',
            Authorization: getCookie('cmsAuth'),
            'x-id-token': getCookie('id_token'),
         },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static deleteVariable(id: number) {
      return fetch(`${baseUrl}api/v1/mdVariables/variableIdentifier/${id}`, {
         credentials: 'include',
         method: 'delete',
         headers: {
            'content-type': 'application/json',
            Authorization: getCookie('cmsAuth'),
            'x-id-token': getCookie('id_token'),
         },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getReportIdentifiers(id: any) {
      return fetch(`${baseUrl}api/v1/mdVariables/variableIdentifier/${id}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getVariableDatasets() {
      return fetch(`${baseUrl}api/v1/mdVariables/datasets`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getVariableDataTables(dataset: any) {
      return fetch(`${baseUrl}api/v1/mdVariables/datasets/${dataset}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static updateVariableIdentifiers(data: any) {
      return fetch(
         `${baseUrl}api/v1/mdVariables/variableIdentifier/${data.variableId}`,
         {
            credentials: 'include',
            method: 'PUT',
            headers: {
               'content-type': 'application/json',
               Authorization: getCookie('cmsAuth'),
               'x-id-token': getCookie('id_token'),
            },
            body: JSON.stringify(data),
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getVariableBQNames(dataset: any, table: any) {
      return fetch(
         `${baseUrl}api/v1/mdVariables/datasets/${dataset}/${table}/variableNames`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getVariableTGNames(report: string) {
      return fetch(
         `${baseUrl}api/v1/mdVariables/tgVariableNames?report=${report}`,
         {
            credentials: 'include',
            method: 'GET',
            headers: { 'content-type': 'application/json' },
         }
      )
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getExistingUINames(uiName: string) {
      return fetch(`${baseUrl}api/v1/mdVariables/existingVariables/${uiName}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
