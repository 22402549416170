import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class CtdTitleApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static getCtdTitlesList(searchQuery: string = '') {
      return fetch(`${baseUrl}api/v1/ctdCode?searchQuery=${searchQuery}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getActiveCtdTitlesList() {
      return fetch(`${baseUrl}api/v1/ctdCode/active`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static getCtdTitlesListByCode(ctdCode: string) {
      return fetch(`${baseUrl}api/v1/ctdCode/${ctdCode}`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }

   static activateCtdTitle(data: {
      activeCodes: number[];
      inActiveCodes: number[];
   }) {
      return fetch(`${baseUrl}api/v1/ctdCode`, {
         credentials: 'include',
         method: 'PUT',
         headers: { 'content-type': 'application/json' },
         body: JSON.stringify(data),
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
