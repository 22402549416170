import { all, spawn } from 'redux-saga/effects';
import modelDocumentWatcher from './modelDocument';
import userWatcher from './user';
import projectWatcher from './project';
import globalsWatcher from './globals';
import roleWatcher from './role';
import statusWatcher from './status';
import workingDocumentWatcher from './workingDocument';
import generatedDocumentWatcher from './generatedDocument';
import tutorialWatcher from './tutorial';
import ctdTitleWatcher from './ctdTitles';
import configurationWatcher from './configuration';
import conversionStyleWatcher from './conversionStyles';
import internalReportsWatcher from './internalReports';
import favoriteWatcher from './favorite';
import variablesWatcher from './variables';

export default function* rootSaga() {
   yield all([
      spawn(userWatcher),
      spawn(modelDocumentWatcher),
      spawn(projectWatcher),
      spawn(globalsWatcher),
      spawn(roleWatcher),
      spawn(statusWatcher),
      spawn(workingDocumentWatcher),
      spawn(generatedDocumentWatcher),
      spawn(tutorialWatcher),
      spawn(ctdTitleWatcher),
      spawn(configurationWatcher),
      spawn(conversionStyleWatcher),
      spawn(internalReportsWatcher),
      spawn(favoriteWatcher),
      spawn(variablesWatcher),
   ]);
}
