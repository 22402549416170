import { PermissionList } from '../../core/models/permissions/permission';
import { ProjectRoleInterface } from '../../core/models/role/projectRole';
import { RoleInterface } from '../../core/models/rolesModel';
import { roleTypes } from '../types/role';
import { ApiErrorHandler } from '../../core/api/apiUtils';

const {
   LOAD_ROLES,
   LOAD_ROLES_SUCCESS,
   LOAD_ROLES_FAILURE,
   UPDATE_USER_ROLE,
   UPDATE_USER_ROLE_SUCCESS,
   UPDATE_USER_ROLE_FAILURE,
   CREATE_USER_ROLE,
   CREATE_USER_ROLE_SUCCESS,
   CREATE_USER_ROLE_FAILURE,
   UPDATE_ROLE_PERMISSIONS,
   UPDATE_ROLE_PERMISSIONS_SUCCESS,
   UPDATE_ROLE_PERMISSIONS_FAILURE,
   GET_ROLE_BY_ID,
   GET_ROLE_BY_ID_SUCCESS,
   GET_ROLE_BY_ID_FAILURE,
} = roleTypes;

export const loadRoles = (callback: Function = () => {}) => ({
   type: LOAD_ROLES,
   callback,
});

export const loadRolesSuccess = (payload: RoleInterface[]) => ({
   type: LOAD_ROLES_SUCCESS,
   payload,
});

export const loadRolesFailure = (payload: RoleInterface[]) => ({
   type: LOAD_ROLES_FAILURE,
   payload,
});

export const updateUserRole = (payload: any, callback: Function) => ({
   type: UPDATE_USER_ROLE,
   payload,
   callback,
});

export const updateUserRoleSuccess = (role: RoleInterface) => ({
   type: UPDATE_USER_ROLE_SUCCESS,
   payload: role,
});

export const updateUserRoleFailure = (err: any) => ({
   type: UPDATE_USER_ROLE_FAILURE,
   payload: err,
});

export const createUserRole = (
   payload: any,
   callback: Function = () => {}
) => ({
   type: CREATE_USER_ROLE,
   payload,
   callback,
});

export const createUserRoleSuccess = (role: RoleInterface) => ({
   type: CREATE_USER_ROLE_SUCCESS,
   payload: role,
});

export const createUserRoleFailure = (err: any) => ({
   type: CREATE_USER_ROLE_FAILURE,
   payload: err,
});

export const updateRolePermissions = (payload: any, callback: Function) => ({
   type: UPDATE_ROLE_PERMISSIONS,
   payload,
   callback,
});

export const updateRolePermissionsSuccess = (role: RoleInterface) => ({
   type: UPDATE_ROLE_PERMISSIONS_SUCCESS,
   payload: role,
});

export const updateRolePermissionsFailure = (err: any) => ({
   type: UPDATE_ROLE_PERMISSIONS_FAILURE,
   payload: err,
});

export const getRoleById = (payload: number, callback: Function) => ({
   type: GET_ROLE_BY_ID,
   payload,
   callback,
});

export const getRoleByIdSuccess = (data: any) => ({
   type: GET_ROLE_BY_ID_SUCCESS,
   payload: data,
});

export const getRoleByIdFailure = (error: null | ApiErrorHandler = null) => ({
   type: GET_ROLE_BY_ID_FAILURE,
   payload: error,
});

export const getPermissions = (callback: Function = () => {}) => ({
   type: roleTypes.LOAD_PERMISSIONS,
   callback,
});

export const getPermissionsSuccess = (permissions: PermissionList) => ({
   type: roleTypes.LOAD_PERMISSIONS_SUCCESS,
   payload: permissions,
});

export const getPermissionsFailure = (error: {}) => ({
   type: roleTypes.LOAD_PERMISSIONS_FAILURE,
   payload: error,
});

export const CLEAR_ROLE_STATE = () => ({
   type: roleTypes.CLEAR_ROLE_STATE,
});

export const LOAD_PROJECT_ROLES = (callback: Function = () => {}) => ({
   type: roleTypes.LOAD_PROJECT_ROLES,
   callback,
});

export const LOAD_PROJECT_ROLES_SUCCESS = (
   payload: ProjectRoleInterface[]
) => ({
   type: roleTypes.LOAD_PROJECT_ROLES_SUCCESS,
   payload,
});
