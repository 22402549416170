import produce from 'immer';
import { ConfigurationState } from '../../core/models/configuration/configurationState';
import { configurationTypes } from '../types/configuration';

const initialState: ConfigurationState = {
   setSessionTimeInterval: [],
   sessionData: [],
   error: null,
};

export const configuration = (
   state = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case configurationTypes.SET_SESSION_TIME_OUT_SUCCESS:
            draft.setSessionTimeInterval = payload;
            draft.error = null;
            return draft;
         case configurationTypes.SET_SESSION_TIME_OUT_FAILURE:
            draft.setSessionTimeInterval = [];
            draft.error = payload;
            return draft;
         case configurationTypes.GET_SESSION_DATA_SUCCESS:
            draft.sessionData = payload;
            draft.error = null;
            return draft;
         case configurationTypes.GET_SESSION_DATA_FAILURE:
            draft.sessionData = [];
            draft.error = payload;
            return draft;
         default:
            return draft;
      }
   });
