import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import subscribeActionMiddleware from 'redux-subscribe-action';
import reducers from '../redux/reducers/index';
import rootSaga from '../redux/sagas';

const saga = createSagaMiddleware();

function configureStore() {
   let store;
   if (
      process.env.NODE_ENV === 'production' ||
      process.env.REACT_APP_APPLICATION_ENV === 'prod' ||
      process.env.REACT_APP_APPLICATION_ENV === 'qa'
   ) {
      store = createStore(
         reducers,
         compose(
            applyMiddleware(saga),
            applyMiddleware(subscribeActionMiddleware)
         )
      );
   } else {
      store = createStore(
         reducers,
         composeWithDevTools(
            applyMiddleware(saga),
            applyMiddleware(subscribeActionMiddleware)
         )
      );
   }
   saga.run(rootSaga);

   return store;
}

export const store = configureStore();
