export const weekDays = [
   'Sunday',
   'Monday',
   'Tuesday',
   'Wednesday',
   'Thursday',
   'Friday',
   'Saturday',
];

export const tutorialSteps = {
   dashboard: [
      {
         label:
            'Welcome to Intelligent Authoring! This brief guide will help you get started.',
      },
      {
         label:
            "IA brings report writing into the 21st century, and can save hours of your time. Let's show you around!",
      },
      {
         label: "First, let's create a document.",
      },
   ],
   authorDocument: [
      {
         label: 'Welcome to page where you can manage all Documents',
      },
      {
         label: 'Page contains list of all documents',
      },
      {
         label: 'It is possible to change or delete documents',
      },
      {
         label: 'To create a new document template might be helpful',
      },
   ],
   manageTemplate: [
      {
         label: 'Welcome to page where you can manage all templates',
      },
      {
         label: 'Page contains list of all templates',
      },
      {
         label: 'It is possible to change or delete templates',
      },
      {
         label: 'Templates help to create new documents',
      },
   ],
   newTemplate: [
      {
         label: 'Welcome to the page where you can create a new template',
      },
      {
         label: 'You can set all necessary parameters',
      },
      {
         label: 'Popup card contain detailed information',
      },
      {
         label: 'To save a new template click "Save"',
      },
   ],
   newDocument: [
      {
         label: 'Welcome to page where you can create new document',
      },
      {
         label: 'This is where all necessary parameters are set',
      },
      {
         label: 'Popup card containing detailed information',
      },
      {
         label:
            'Once the template is complete click "Save" to submit the new template',
      },
   ],
   editDocument: [
      {
         label:
            'Welcome to the document editor! It will feel very familiar to Word users, however it has a few added tricks up its sleeve.',
      },
      {
         label:
            'You can add variables, generate complex tables and insert paragraphs from other documents, all from within the editor.',
      },
      {
         label:
            'To find out how to use these features and more, open up the sidebar from the bottom left of your screen.',
      },
   ],
};

export const routePopupSteps = [
   {
      path: '/layout/dashboard',
      steps: 1,
      isWithParameters: false,
   },
   // {
   //   path: '/layout/authordocument/edit',
   //   steps: 5,
   //   isWithParameters: true,
   // },
   // {
   //   path: '/layout/authordocument',
   //   steps: 3,
   //   isWithParameters: true,
   // },
   {
      path: '/layout/authordocument',
      steps: 1,
      isWithParameters: false,
   },
   // {
   //   path: '/layout/managetemplate',
   //   steps: 3,
   //   isWithParameters: true,
   // },
   // {
   //   path: '/layout/managetemplate',
   //   steps: 3,
   //   isWithParameters: false,
   // },
   {
      path: '/layout/new_template',
      steps: 16,
      isWithParameters: false,
   },
   {
      path: '/layout/new_document',
      steps: 5,
      isWithParameters: false,
   },
   {
      path: '/layout/managetemplate',
      steps: 1,
      isWithParameters: false,
   },
   // {
   //   path: '/layout/user',
   //   steps: 1,
   //   isWithParameters: false,
   // },
   {
      path: '/layout/projects',
      steps: 1,
      isWithParameters: false,
   },
   {
      path: '/layout/new_project',
      steps: 3,
      isWithParameters: false,
   },
   {
      path: 'buttonPopup.spec.js',
      steps: 2,
      isWithParameters: true,
   },
];

export const documentAttributes =
   'id,' +
   'name,' +
   'description,' +
   'version,' +
   'webContentLink,' +
   'webViewLink,' +
   'createdTime,' +
   'modifiedTime,' +
   'modifiedByMeTime,' +
   'sharedWithMeTime,' +
   'sharingUser,' +
   'owners,' +
   'lastModifyingUser,' +
   'ownedByMe,' +
   'permissions,' +
   'originalFilename,' +
   'size,' +
   'exportLinks';
