import { ProjectInterface } from './projectInterface';
import { UserInterface } from '../user/userInterface';
import { MoleculeInterface } from '../molecule/moleculeInterface';
import { User } from '../user/user';
import { Role } from '../role/role';

export class Project implements ProjectInterface {
   constructor(
      public id: number = 0,
      public name: string = '',
      public title: string = '',
      public color: any = '',
      public updatedAt: string = '',
      public createdAt: string = '',
      public creatorName: string = '',
      public creator: Partial<UserInterface> = new User(),
      public creatorEmail: string = '',
      public workingDocuments: any[] = [],
      public modelDocuments: any[] = [],
      public members: Partial<UserInterface>[] = [],
      public molecule: Partial<MoleculeInterface> = {},
      public createdDate: string = '',
      public modifiedDate: string = '',
      public modifier: Partial<UserInterface> = {},
      // public dosageForm: any[] = [],
      // public dosageStrength: any[] = [],
      public products: any[] = [],
      // public productManufacturers: any[] = [],
      public substances: any[] = [],
      public countries: any[] = [],
      // public substanceManufacturers: any[] = [],
      public status: string = '',
      public productFamilyName: string = '',
      public productFamilyCode: string = '',
      public innCalculated: string = '',
      public systemNumber: string = '',
      public type: string = '',
      public moleculeId: number = 0,
      public archived: boolean = false,
      public driveId: string = '',
      public roNumber: string = '',
      public divisionsGroupId: number = 1,
      public divisionsGroupName: string = 'Pharma',
      public mainRoNumber: string = '',
      public additionalMetadata: any[] = [],
      public systemTypeId: number = 0,
      public systemTypeDescription: string = '',
      public isMember: boolean = false,
      public isFavorite: boolean = false,
      public workingDocumentsCount: number = 0,
      public documentList?: any[],
      public memberList?: object,
      public newAssignedMembers?: {
         [key: string]: number[];
      },
      public unAssignedMembers?: {
         [key: string]: number[];
      }
   ) {}

   public static createProjectObject(data: any) {
      return new Project(
         data.id,
         data.name,
         data.title,
         data.color,
         new Date(data.updatedAt).toLocaleString(),
         new Date(data.createdAt).toLocaleString(),
         `${data.Creator?.firstName} ${data.Creator?.lastName}`,
         {
            id: data.Creator?.id,
            firstName: data.Creator?.firstName,
            lastName: data.Creator?.lastName,
            role: new Role(data.Creator?.role?.id, data.Creator?.role?.name),
            status: data.Creator?.status,
            lastLogin: data.Creator?.lastLogin,
            email: data.Creator?.emailAddress,
            img: data.Creator?.img,
         },
         data.Creator?.emailAddress,
         data.WorkingDocuments || [],
         data.ModelDocuments || [],
         data.Users?.map((user: any) => {
            const { lastName, firstName, ...rest } = user;
            return { ...rest, lastName, firstName };
         }) || [],
         data.Molecule,
         new Date(data.createdAt).toLocaleDateString(),
         new Date(data.updatedAt).toLocaleDateString(),
         {
            ...data.Modifier,
            firstName: data.Modifier?.firstName || data.Creator?.firstName,
            lastName: data.Modifier?.lastName || data.Creator?.lastName,
         },
         // data.dosageForm,
         // data.dosageStrength,
         data.products,
         // data.productManufacturers,
         data.substances,
         data.countries,
         // data.substanceManufacturers,
         data.status,
         data.productFamilyName,
         data.productFamilyCode,
         data.innCalculated,
         data.systemNumber,
         data.type,
         data.moleculeId,
         data.archived,
         data.driveId,
         data.roNumber,
         data.divisionsGroupId,
         data.divisionsGroupName,
         data.mainRoNumber,
         data.additionalMetadata,
         data.systemTypeId,
         data.SystemType.description,
         data.isMember,
         data.isFavorite,
         data.workingDocumentsCount
      );
   }
}
