import produce from 'immer';
import { StatusState } from '../../core/models/status/statusState';
import { statusTypes } from '../types/status';

const initialState: StatusState = {
   statuses: [],
};

const { LOAD_STATUSES_SUCCESS, LOAD_STATUSES_FAILURE } = statusTypes;

export const status = (
   state: StatusState = initialState,
   action: { type: string; payload: any }
) =>
   produce(state, (draft) => {
      const { type, payload } = action;
      switch (type) {
         case LOAD_STATUSES_SUCCESS:
            draft.statuses = payload;
            break;
         case LOAD_STATUSES_FAILURE:
            draft.statuses = [];
            break;
         default:
            return draft;
      }
      return draft;
   });
