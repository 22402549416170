import { Role } from '../role/role';
import { RoleInterface } from '../role/roleInterface';
import { UserInterface } from './userInterface';

export class User implements UserInterface {
   constructor(
      public id: number = 0,
      public firstName: string = '',
      public lastName: string = '',
      public role: RoleInterface = new Role(),
      public status: 'offline' | 'online' = 'online',
      public lastLogin: string = '',
      public email: string = '',
      public img: string = '',
      public priority: string = '',
      public emailAddress: string = '',
      public createdAt: string = '',
      public modifiedAt: string = ''
   ) {}

   public static createUserObject(data: any) {
      return new User(
         data.id,
         data.firstName,
         data.lastName,
         new Role(data.role.id, data.role.name),
         data.status,
         data.lastLogin ? new Date(data.lastLogin).toLocaleString() : '',
         data.emailAddress,
         data.img,
         data.priority,
         data.emailAddress
      );
   }
}
