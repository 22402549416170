import React from 'react';
import footer from '../../../assets/images/footer.svg';

import './styles.scss';

const Footer = () => (
   <div className="footer">
      <img className="footer-img" src={footer} alt="Footer background" />
      <div className="footer-rectangle" />
   </div>
);

export default Footer;
