import { handleError, handleResponse } from './apiUtils';

let baseUrl = process.env.REACT_APP_API_URL;

export class StatusApi {
   static setBaseUrl() {
      baseUrl = process.env.REACT_APP_API_URL;
   }

   static loadStatuses() {
      return fetch(`${baseUrl}api/v1/status`, {
         credentials: 'include',
         method: 'GET',
         headers: { 'content-type': 'application/json' },
      })
         .then(async (response) => {
            const result = await handleResponse(response);
            return result.data;
         })
         .catch(handleError);
   }
}
