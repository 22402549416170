import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function ProjectIcon(props: any) {
   return (
      <SvgIcon {...props} viewBox="0 0 65 40">
         <path d="M0 0 L0 40 L50 40 L50 0 L0 0 L0 3 L47 3 L47 37 L3 37 L3 3" />
         <path d="M3 40 L15 10 L30 10 L35 6 L50 6 L55 10 L65 10 L50 40 L3 40" />
      </SvgIcon>
   );
}
